import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import HelpCenterEmployer from "../components/helpcenter/Helpcenteremployer"
import HomeFooter from "../components/homeFooter/HomeFooter";

const EmployerHelpCenter = () => {
    return (
        <>
            <EmployeeNav2 />
            <HelpCenterEmployer />
            <HomeFooter />

        </>
    )
}
export default EmployerHelpCenter


// code for scoop solutions