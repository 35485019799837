import React, { useState, useEffect } from "react"
// import logo from "../../assets/navbar/logo.png"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"

Modal.setAppElement("#root");

const PostJob3 = (props) => {
  const subscription = useSelector(state=>state?.SubscriptionInfo)

  useEffect(() => {
  }, []);
  return (
    <>

      <div className="container-fluid" id="Employee">
        <div className="container">
          <nav id="header" className="navbar header-temporary-header navbar-expand-lg navbar-light">
            <div id="header-left">
              <div className="d-flex align-items-center">
                <Link className="fgh" to="/"><img className="img-fluid" src={logo} alt="" /></Link>
                <div className="in-menu d-flex h-100 align-items-center d-flex align-items-center justify-content-end">
                  <Link to="/postjob" className="employers-button-emp fgh">Post Jobs</Link> |
                  <Link to="/manage-job" className="employers-button-emp fgh">Job Management</Link> |
                  {/* <Link to="/biling" className="employers-button-emp fgh">Product &amp; Pricing</Link> | */}
                  {/* {!subscription?.isPayAsYouGo && <><Link to="/search-resume" className="employers-button-emp fgh">Search Resumes</Link> |</>} */}
                  <Link to="/get-premium" className="employers-button-emp fgh">Get Premium</Link>
                </div>
              </div>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav mr-auto">
              </ul>
              <span className=" navbar-text" id="header-right">
                <div className="d-flex h-100 align-items-center d-flex align-items-center justify-content-end">
                  <div className="dropdown header-left-dropdown2 header-left-dropdown ml-5">
                    <button className="employers-button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Employers<i className="fa fa-angle-down ml-2" aria-hidden="true" />
                    </button>
                    <div className="dropdown-menu" style={{ right: '0px !important', left: 'auto !important' }} aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>
                  <div className="dropdown header-left-dropdown ml-5">
                    <button className="employers-button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {localStorage.getItem("email")}<i className="fa fa-angle-down ml-2" aria-hidden="true" />
                    </button>
                    <div className="dropdown-menu" style={{ right: '0px !important', left: 'auto !important' }} aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#">Account</a>
                      <Link className="dropdown-item fgh" to="/signup">Employee Sign Up</Link>
                      <Link className="dropdown-item fgh" to="/employersignup">Employer Sign Up</Link>
                      <a className="dropdown-item" href="#">Post Jobs</a>
                      <a className="dropdown-item" href="#">Product</a>
                      <a className="dropdown-item" href="#">Pricing</a>
                      <Link to="/company-administrator" className="dropdown-item fgh" >Profile</Link>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </nav>
          {/* header======================================================================*/}
          <div className="main-content job-form-2">
            <div className="col-12 post-job-3 p-0">
              <div className="d-flex align-items-center">
                <h6 className="mb-0 bold-text">Company Name </h6><span className="mx-1"> -- </span>
                <h6 className="mb-0 bold-text"> Position Title</h6>
              </div>
              <p className="my-2">location Street Address</p>
              <p>City,State Zip | Employement | Pay Rate</p>
              <hr />
              <p className="mt-4 bold-text">Company Overview</p>
              <hr />
              <p className="company-post-job-3-p">
                The pain itself is important to the main adipisicing elite. We can
                avoid pain with no advantage, but never get any more painful than it does at other offices, and
                if no one wants to use that exercise for that pain? They don't know the body.
              </p>
              <p className="mt-4 bold-text">Job Description</p>
              <hr />
              <p className="company-post-job-3-p">
                The pain itself is important to the main adipisicing elite. We can
                avoid pain with no advantage, but never get any more painful than it does at other offices, and
                if no one wants to use that exercise for that pain? They don't know the body.
              </p>
              <p className="mt-4 bold-text">Main Duties &amp; Resposiblites</p>
              <hr />
              <p className="company-post-job-3-p">
                The pain itself is important to the main adipisicing elite. We can avoid pain with no advantage,
                but never get any more painful than it does at other offices, and if no one wants to use that
                exercise for that pain? They don't know the body.
              </p>
              <p className="mt-4 bold-text">Benifits Offered</p>
              <ul>
                <li>Benifits #1</li>
                <li>Benifits #2</li>
                <li>Benifits #4</li>
                <li>Benifits #7</li>
              </ul>
              <p className="mt-4 bold-text">Experience Level</p>
              <hr />
              <p className="company-post-job-3-p">
                The pain itself is important to the main adipisicing elite. We can avoid pain with no advantage,
                but never get any more painful than it does at other offices, and if no one wants to use that
                exercise for that pain? They don't know the body.
              </p>
              <p className="mt-4 bold-text">Availability Needed</p>
              <hr />
              <p className="company-post-job-3-p">
                The pain itself is important to the main adipisicing elite. We can avoid pain with no advantage,
                but never get any more painful than it does at other offices, and if no one wants to use that
                exercise for that pain? They don't know the body.
              </p>
              <p className="mt-4 bold-text">Education</p>
              <hr />
              <p className="company-post-job-3-p">
                The pain itself is important to the main adipisicing elite. We can avoid pain with no advantage,
                but never get any more painful than it does at other offices, and if no one wants to use that
                exercise for that pain? They don't know the body.
              </p>
              <p className="mt-4 bold-text">Positions Available</p>
              <hr />
              <p className="company-post-job-3-p">
                The pain itself is important to the main adipisicing elite. We can avoid pain with no advantage,
                but never get any more painful than it does at other offices, and if no one wants to use that
                exercise for that pain? They don't know the body.
              </p>
              <p className="mt-4 bold-text">Travel</p>
              <hr />
              <p className="company-post-job-3-p">
                The pain itself is important to the main adipisicing elite. We can avoid pain with no advantage,
                but never get any more painful than it does at other offices, and if no one wants to use that
                exercise for that pain? They don't know the body.
              </p>
              <p className="mt-4 bold-text">Social Media Links</p>
              <hr />
              <p className="company-post-job-3-p">
                The pain itself is important to the main adipisicing elite. We can avoid pain with no advantage,
                but never get any more painful than it does at other offices, and if no one wants to use that
                exercise for that pain? They don't know the body.
              </p>
            </div>
            <div className="main-content-session inner-session">
              <Link to="/manage-job" className="send-invitation fgh">POST JOB</Link>
            </div>
          </div>
          {/* footer====================================================================== */}

        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PostJob3);


// code for scoop solutions