import React, { useState } from "react";
import "./HomeMidSection.css";
import DiscoverFront from "../../assets/discoverfront.jpg";
import DiscoverBack from "../../assets/discoverBack.jpg";
import OpportunityFront from "../../assets/opportunityfront.jpg";
import OpportunityBack from "../../assets/opportunityback.jpg";
import { useHistory } from "react-router-dom";
import upload from "../../assets/upload.png";
import Modal from "react-modal";

const HomeMidSection = () => {
  let history = useHistory();
  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);

  const signOut1 = () => {
    localStorage.removeItem("array");
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("zip");
    localStorage.removeItem("role");
    localStorage.removeItem("role1");
    window.location = `/candidate-login${
      window.location.href.search("false") == -1 &&
      window.location.href.search("true") == -1
        ? `/`
        : `?if=${window.location.href.search("false") == -1 ? true : false}`
    }`;
  };
  
  return (
    <>
          <Modal
        isOpen={BilingmodalIsOpen2}
        onRequestClose={() => setBilingModalIsOpen2(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "fit-content",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "40px 20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h5>Do you wish to sign out?</h5>
          <div className="d-flex align-items-center mt-3">
            <button
              onClick={() => signOut1()}
              className="employeer-sign-off-btn margin_O"
            >
              Yes
            </button>
            <button
              onClick={() => setBilingModalIsOpen2(false)}
              className="employeer-sign-off-btn margin_O"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

    <div className="midSectionParentContainer">
      <div className="row pt-5 mt-md-0 p-4 p-md-5 midSectionContainer">
        <div className="row midSection mb-4 px-0 d-flex justify-content-center">
          <div className="col-12 col-md-6 col-lg-4 mb-5 mb-md-0 pl-0">
            <img
              src={DiscoverBack}
              width="100%"
              height="100%"
              style={{ objectFit: "cover" }}
              />
            <img
              src={DiscoverFront}
              width="40%"
              height="40%"
              style={{
                objectFit: "contain",
                position: "absolute",
                bottom: -65,
                right: -7,
              }}
              />
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center px-0 pl-md-3 pl-lg-5 d-flex justify-content-center">
            <div>
              <p className="firstHead mt-4 mt-md-0">
                Looking to fill an open job opportunity?
                Discover the ideal applicant for your open job roles
                <br />
                <br />
                Every employer who signs up already has one free job slot as part of the Pay as You Go Plan. Click below for details on Products and Pricing options
              </p>
              {/* <p className="secHead">
                Discover the ideal applicant for your open job roles
              </p> */}
              <button
                onClick={() =>
                  history.push(
                    `/get-premium${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                        window.location.href.search("false") == -1
                        ? true
                        : false
                      }`
                    }`
                    )
                  }
                  className={`rounded border-mute mute-next bg-dark jobBtn`}
                  >
                {/* Post a Job For Free */}
                Products & Pricing
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-4 p-md-5 mb-5 mb-md-4 job-container d-flex justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center px-0 mb-4 mb-md-2">
          <div>
            <p className="firstHead botfirstHead">Looking for a job?</p>
            <p className="secHead" style={{ width: "100%" }}>
              Find the perfect career opportunity for you
            </p>
            <div className="d-flex justify-content-center mb-3 mb-sm-3 mb-md-0 d-md-block">
              <button
                onClick={() => localStorage.getItem("role") == 1 ? setBilingModalIsOpen2(true): localStorage.getItem("role") == 0 ? 
                history.push(
                  `/updateresume${
                    window.location.href.search("false") == -1 &&
                    window.location.href.search("true") == -1
                    ? `/`
                    : `?if=${
                      window.location.href.search("false") == -1
                      ? true
                      : false
                    }`
                  }`
                  ) :
                  history.push(
                    `/candidate-login${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                        window.location.href.search("false") == -1
                        ? true
                        : false
                      }`
                    }`
                    )
                  }
                  className={`rounded border-mute mute-next bg-dark jobBtn resumeBtn `}
                  >
                Upload Your Resume
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <img
            src={OpportunityFront}
            width="30%"
            height="30%"
            style={{
              objectFit: "contain",
              position: "absolute",
              bottom: -40,
              left: -10,
            }}
            />
          <img
            src={OpportunityBack}
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            />
        </div>
      </div>
      <div className="row p-2 p-md-4 mt-4 mt-md-0 midSectionContainer">
        <div className="midSectionText">
          <span>Your Trusted Platform for Hiring or Finding Your Next Job</span>
        </div>
      </div>
    </div>
    </>
  );
};

export default HomeMidSection;
