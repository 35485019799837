import React, { useState, useEffect,useRef } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from 'react-bootstrap';
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import Footer from "../footer/Footer";
import HomeFooter from "../homeFooter/HomeFooter";
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const ForgotPasswordEmail = () => {
  const [BilingmodalIsOpenzip, setBilingModalIsOpenzip] = useState(false);
  const [email, setemail] = useState("")
  const [load, setload] = useState(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
  };


  const SendEmail =  () => {
     fetch(`${config['baseUrl']}/getemail`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "email": email
      })
    }).then(res => {
      // if (res.status !== 200 && res.status !== 201) {
        
      // }
      return res.json();
    })
    .then((response) => {
console.log("fff",response)
      localStorage.setItem("email1", response.email)
      localStorage.setItem("hash", response.hash)
      if (response.message == "successful") {
        textInput.current.value = ""
        setemail("")
        setBilingModalIsOpenzip(true)
      }
      else if(response.message == "email wrong") {
                textInput.current.value = ""
                setemail("")
        setBilingModalIsOpenzip(true)
      }
      else {
                textInput.current.value = ""
                setemail("")
        setBilingModalIsOpenzip(true)
      }

    }).catch((error) => {
      // console.log(error)
      setemail("")
      setBilingModalIsOpenzip(true)

    })
  }
  const textInput = useRef();


  return (
    <>
      {
        load == false ?
          <>
              {/* header============================================== */}
                <div style={{height:"100vh"}} className="container">
                  <Dropdownnav />
                  <div style={{ borderBottom: "none" }} className="row main-content h-100 align-items-center flex-column">
                    <h5 className="text-center">Forgot Password?</h5>
                    <div className="w-100 forget-passeord-issue-no-21">
                      <p>Please enter your email address below so we can
                        send you a link and some instructions to reset your
                        password. This may take a few minutes.
                      </p>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Email Address:</label>
                        <input ref={textInput}  onChange={(e) => setemail(e.target.value)} type="text" className="employee-input-email" placeholder="username@emailaddress.com"/>
                      </div>
                      <div className="main-content-session inner-session pt-0">
                        {
                          email!==""&&email!==null&&email!==undefined?
                          <Link onClick={()=>SendEmail()} className="send-invitation fgh">Reset Password</Link>
                          :
                          <Link  className="send-invitation fgh">Reset Password</Link>
                        }
                        
                      </div>


                    </div>
                  </div>
                  <div className="footer-wrap" style={{position:"relative" ,bottom: "0px", left: "0px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <HomeFooter />
                  </div>
                </div>
            <Modal
              isOpen={BilingmodalIsOpenzip}
              onRequestClose={() => setBilingModalIsOpenzip(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "240px",
                  top: "calc(50% - 120px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <div className="">
                  <p>If there is an account associated with this email, you will receive an email from us shortly with instructions on how to reset your password. Should you need further assistance,
                    please feel free in contacting us at <br /><br />
                    <a href="#">customerservice@hirechoices.com</a>
                  </p>
                </div>
              </div>
            </Modal>
          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
export default ForgotPasswordEmail;

// code for scoop solutions