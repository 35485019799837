import React from "react";
import EmployeeNav2 from "../components/employeenav2/Employeenav2";
import Homesearchemployee from "../components/homesearchemployee/Homesearchemployee";
import HomeMidSection from "../components/homeMidSection/HomeMidSection";
import HomeMidCardSection from "../components/homeMidCardSection/HomeMidCardSection";
import HomeSubscribeSection from "../components/homeSubscribeSection/HomeSubscribeSection";
import HomeStepSection from "../components/homeStepSection/HomeStepSection";
import HomeFooter from "../components/homeFooter/HomeFooter";
import { useRef } from "react";
const SearchHomeEmployee = () => {
  const resultRef = useRef(null);
  const id = "searchBox";
  return (
    <div
      className="container-fluid pt-2"
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <EmployeeNav2 />
      <Homesearchemployee ref={resultRef} />
      <HomeMidSection />
      {/* <HomeMidCardSection /> */}
      {/* <HomeSubscribeSection /> */}
      <HomeStepSection />
      <HomeFooter resultRef={resultRef} id={id} />
    </div>
  );
};
export default SearchHomeEmployee;

// code for scoop solutions
