import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import Footer from "../footer/Footer";
import HomeFooter from "../homeFooter/HomeFooter";


const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const ForgotPassword = (props) => {
  const [modalwidth, setmodalwidth] = useState(400)
  const [checkpass, setcheckpass] = useState(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
  const [BilingmodalIsOpen3, setBilingModalIsOpen3] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [modal5, setmodal5] = useState(false)
  const [require1, setrequire1] = useState(false)
  const [require2, setrequire2] = useState(false)
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text")
      return;
    }
    setPasswordType1("password")
  }
  const [newpassword, setnewpassword] = useState("")
  const [connewpassword, setconnewpassword] = useState("")
  const [load, setload] = useState(false)
  const [borderred, setborderred] = useState(false)
  const resetpassword = async () => {
    if (newpassword == "") {
      setborderred(true)
    }
    else if (newpassword !== connewpassword) {
      // setmodal5(true)
      setrequire1(true)
    }
    else if (checkpass.test(newpassword) === false) {
      setBilingModalIsOpen3(true)
    }
    else {
      await fetch(`${config['baseUrl']}/forgotpassword`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "email": localStorage.getItem("email1"),
          "hash": localStorage.getItem("hash"),
          "password": newpassword
        })
      }).then(res => {

        if (res.status !== 200 && res.status !== 201) {
          
        }
        return res.json();
      }).then((response) => {

        if (response.message == "Password Change Successfully.") {
          window.location = "/"
        }
        else {
          console.log("Something went wrong..")
        }

      }).catch((error) => {
        console.log(error)

      })
    }
  }



  return (
    <>
      <div>
        {/* header============================================== */}
        <div className="container-fluid">
          <div className="container height-handred">
            <Dropdownnav />
            {/* header======================================================================*/}
            <div className="row main-content h-100 align-items-center flex-column">
              <h5 className="text-center mb-0">Forgot Password?</h5>
              <div className="w-25">
                <div className={require1==true?"form-group mt-2 mb-0":"form-group mt-2"}>
                  <label htmlFor="exampleInputEmail1">New Password:</label>
                  <input style={{ boxShadow: borderred == true ? "#ff4141 0px 0px 16px -3px" : "" }} onChange={(e) => {
                    setborderred(false)
                    setnewpassword(e.target.value)
                    setrequire1(false)
                  }} type={passwordType} className="form-control employee-input-email" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="New Password" />
                 {require1 == true ? <small className="text-danger" style={{ color: "red" }}>Passwords do not match</small> : ""}
                  <span toggle="#password-field4" onClick={togglePassword} >{passwordType === "password" ? <i className="fa fa-eye-slash field-icon toggle-password pl-2"></i> : <i className="fa fa-eye field-icon toggle-password pl-2"></i>}</span>
                </div>
                {checkpass.test(newpassword) === true ? <span style={{ color: "red" }}></span> : <span style={{ color: "red" }}>Password must contain at least 8 characters, both capital and lower case letters, at least one number and one special character (!@#$%^&*)</span>}
                <div className="form-group mt-2">
                  <label htmlFor="exampleInputEmail1">Confirm New Password:</label>
                  <input onChange={(e) =>{
                     setconnewpassword(e.target.value)
                     setrequire1(false)
                  }} type={passwordType1} className="form-control employee-input-email" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Confirm New Password" />
                  {require1 == true ? <small className="text-danger" style={{ color: "red" }}>Passwords do not match</small> : ""}
                  <span toggle="#password-field4" onClick={togglePassword1} >{passwordType1 === "password" ? <i className="fa fa-eye-slash field-icon toggle-password pl-2"></i> : <i className="fa fa-eye field-icon toggle-password pl-2"></i>}</span>
                </div>
                <div className="main-content-session inner-session pt-0">
                  <Link onClick={() => resetpassword()} className="send-invitation fgh">Reset Password</Link>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="footer-wrap" style={{ bottom: "0px", left: "0px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <HomeFooter />
        </div>
      </div>
      <Modal
        isOpen={modal5}
        onRequestClose={() => setmodal5(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "210px",
            top: "calc(50% - 105px)",
            left: "0",
            right: "0",
            bottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>confirm password not match.</h4>
          <div className="d-flex  align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setmodal5(false)}>OK</Link>
          </div>

        </div>
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpen3}
        onRequestClose={() => setBilingModalIsOpen3(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h5>Password must contain at least 8 characters, both capital and lower case letters, at least one number and one special character (!@#$%^&*)</h5>
          <div className="d-flex align-items-center">
            <button onClick={() => setBilingModalIsOpen3(false)} className="employeer-sign-off-btn mt-2">Ok</button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default ForgotPassword;


// code for scoop solutions