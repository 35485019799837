const config = require('../helpers/config.json');
export const createRegister = (firstname, lastname, title, companyname, city, state, zip, number, email, password) => {
    return (dispatch) => {
        dispatch({
            type: "RESET_REGISTER_MESSAGE",
            loading: false,
        });

        fetch(`${config['baseUrl']}/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({

                "first_name": firstname,
                "last_name": lastname,
                "position_title": title !== "" && title !== null && title !== undefined ? title : null,
                "company_name": companyname !== "" && companyname !== null && companyname !== undefined ? companyname : "",
                "city": city,
                "state": state,
                "zip_code": zip,
                "phone": number,
                "email": email,
                "password": password,
                "source": "simple",
                "role": 0

            })
        }).then(res => {
            return res.json();
        }).then((response) => {
            if (response.message !== "The user has been successfully inserted.") {
                console.log("Can't add new command when connection is in closed state")
            } else {
                window.location = `/candidate-login${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`
            }
            const auth_id = response.data.auth_id;
            const empId = response.data.id;
            const first_name = response.data.first_name;
            dispatch({
                type: "REGISTER_IN",
                authError: null,
                authMessage: "Signing you in...",
                loading: true,
            });
        }).catch((error) => {
            const authError = JSON.stringify(error)
            console.log(authError);
            dispatch({
                type: "REGISTER_IN",
                authError,
                authMessage: "Error occurred in signing in!",
                loading: true,
            });
            // 
        })
    };
}


// code for scoop solutions