import { AxiosGET } from "../libs/axios";

const config = require("../helpers/config.json");

export const setNewApplicantInJob = () => {
  return async (dispatch) => {
    try {
      const { data } = await AxiosGET(
        `${config["baseUrl"]}/jobs/apply/get/${localStorage.getItem("userid")}`
      );
      const jobIds =
        data?.job?.filter((i) => i?.is_new)?.map((i) => i?.job_id) || [];
        
      dispatch({
        type: "SET_NEW_APPLICANT_JOB",
        jobIds,
      });
    } catch (error) {
      console.log("setNewApplicantInJob ~ error:", error);
    }
  };
};
