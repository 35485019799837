
import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { ResumeGetById } from "../../actions/Resumegetbyid";
import EmployerNav from "../dropdownnav/Employernav";
import "./pdfcss.css"
import { Drawer, Button, Space, Radio } from 'antd';
import Modal from "react-modal";
import moblogo from "../../assets/mob-logo.png"
import Contact from "../../assets/contact.PNG"
import Education from "../../assets/edu.PNG"
import Contactingo from "../../assets/contactinfo.PNG"
import Education1 from "../../assets/education3.PNG"
import dayjs from "dayjs";
import Awards from "../../assets/awards.PNG"
import Ex from "../../assets/ex.PNG"
import Mili from "../../assets/mili.PNG"
import { PDFExport } from "@progress/kendo-react-pdf";




const config = require('../../helpers/config.json');
Modal.setAppElement("#root");

const SeachResumeMB = (props) => {
  const [pgno, setpgno] = useState(0)
  const [totnopgs, settotnopgs] = useState(0)
  const [downloads, setdownloads] = useState(null)
  const [remainingdownloads, setremainingdownloads] = useState(null)
  const [allcom, setallcom] = useState([])
  const [contactremain, setcontactremain] = useState([])
  const [remaincontact, setremaincontact] = useState("")
  const [totalremaincontact, settotalremaincontact] = useState("")
  const [currentpage, setcurrentpage] = useState(1)
  const [postperpage, setpostperpage] = useState(10)
  const indexoflastpost = currentpage * postperpage;
  const indexoffirstpost = indexoflastpost - postperpage;
  const [allArray, setallArray] = useState([])
  const currentpost = allArray && allArray.length > 0 && allArray[0].job && allArray[0].job.length > 0 ? allArray[0].job : [];
  const pdfExportComponent = React.useRef(null);
  const [temp, settemp] = useState([])
  const [dis, setdis] = useState("")
  const [BilingmodalIsOpen3, setBilingModalIsOpen3] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [searchItem1, setSearchItem1] = useState("");
  const [alldownloads, setalldownloads] = useState([])
  const [allArray1, setallArray1] = useState([])
  const [load, setload] = useState(false)
  const [saveresumeid, setsaveresumeid] = useState("")
  const [filterid, setfilterid] = useState(null)
  const [filteredPosts, setfilteredPosts] = useState([])


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const foo = params.get('id');

  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState('left');

  const showDrawer = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setVisible(false);
  };







  const [relecolor, setrelecolor] = useState(false)

  const pages = new Array(totnopgs).fill(null).map((v, i) => i);
  useEffect(() => {
    setload(true)
    fetch(`${config['baseUrl']}/resume/get/${localStorage.getItem("userid")}?page=${pgno}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response.job
      var temp = []
      temp = alljobs
      var idd = alljobs[0].id
      settotnopgs(response.totalpages)
      props.ResumeGetById(idd)
      setallArray([...temp])
    }).catch((error) => {
      console.log("error", error);
      setload(false)
    })
  }, [pgno])
  const gotoprevious = () => {
    setpgno(Math.max(0, pgno - 1))
  }
  const gotonext = () => {
    setpgno(Math.min(totnopgs - 1, pgno + 1))
  }


  // props.ResumeGetById(foo)

  const [savebtn, setsavebtn] = useState(false)
  const savedresume = async (id) => {
    setsavebtn(true)
    props.ResumeGetById(id)
    await fetch(`${config['baseUrl']}/resume/save`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "user_id": localStorage.getItem("userid"),
        "resume_id": saveresumeid
      })
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
        console.log("Limit Over")
      }
      return res.json();
    }).then((response) => {
      if (response.message == "Resume Already Saved.") {
        setBilingModalIsOpen2(false)
        setBilingModalIsOpen9(true)
      }
      else {
        window.location.reload(false)
      }
    }).catch((error) => {
      console.log(error)
      // 
    })
  }


  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const [BilingmodalIsOpen9, setBilingModalIsOpen9] = useState(false);

  const savemodal = (e) => {
    setBilingModalIsOpen2(true)
    setsaveresumeid(e)
  }

  const paginate = (data) => {
    setcurrentpage(data)
  }



  return (
    <>

      <div className="container-fluid" id="Employee">
        <div className="container">
          <EmployerNav />
          {/* header======================================================================*/}
          <div className="main-content">
            <div className="row resume-search-radio">
              <div className="col-lg-12 col-12 py-0 pl-0 pr-0">
                {
                  props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.job && props.ResumeGetByIdReducer.alljobs.job.length > 0 ? props.ResumeGetByIdReducer.alljobs.job.map(val => (
                    val.status !== 0 ?
                      <>
                        <div className="search-result-3-scrool-box p-2">
                        {props.GetUserReducer.users && props.GetUserReducer.users.Is_packaged !== null && props.GetUserReducer.users.Is_packaged !== undefined && props.GetUserReducer.users.Is_packaged !== "" && props.GetUserReducer.users.Is_packaged !== 0 ?
                                   <>
                                   <a href="#">Candidate’s Name </a>
                                   <p>{`${val.first_name} ${val.middle_name} ${val.last_name}`}</p>
                                 </>
                                  :""}
                         

                          <a href="#">Professional Summary</a>
                          <p>{val.professional_summary}</p>
                          <a href="#">Education</a>
                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.education && props.ResumeGetByIdReducer.alljobs.education.length > 0 ? props.ResumeGetByIdReducer.alljobs.education.map(edu => (
                              <>
                                <p>{edu.level_of_education}</p>
                                <p>{edu.school}</p>
                                <p>{edu.degree}</p>
                                <p>{edu.major}</p>
                                <p>{edu.city}</p>
                                <p>{edu.date_of_completion}</p>
                                <hr />
                              </>
                            )) : ""
                          }
                          <a href="#">Work Experience</a>
                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.experience && props.ResumeGetByIdReducer.alljobs.experience.length > 0 ? props.ResumeGetByIdReducer.alljobs.experience.map(exp => (
                              <>
                                <p>{exp.job_title}</p>
                                <p>{exp.company}</p>
                                <p>{exp.city}</p>
                                <p>{exp.state}</p>
                                <p>{exp.time_period_start}</p>
                                <p>{exp.time_period_end}</p>
                                <p>{exp.duties}</p>
                                <hr />
                              </>
                            )) : ""
                          }
                          <a href="#">Military Service</a>
                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.military && props.ResumeGetByIdReducer.alljobs.military.length > 0 ? props.ResumeGetByIdReducer.alljobs.military.map(mil => (
                              <>
                                <p>{mil.country}</p>
                                <p>{mil.rank}</p>
                                <div className="d-flex align-items-center">
                                  <p>{dayjs(mil.start_date).format("MM-DD-YYYY")}</p><span className="px-1">-</span>
                                  <p>{dayjs(mil.end_date).format("MM-DD-YYYY")}</p>
                                </div>
                                <p>{mil.security_clearance}</p>
                                {
                                  props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.mos && props.ResumeGetByIdReducer.alljobs.mos.length > 0 ? props.ResumeGetByIdReducer.alljobs.mos.map(mo => (
                                    <>
                                      <p>{mo.mos}</p>
                                    </>
                                  )) : ""
                                }
                                {
                                  props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.branch && props.ResumeGetByIdReducer.alljobs.branch.length > 0 ? props.ResumeGetByIdReducer.alljobs.branch.map(bra => (
                                    <>
                                      <p>{bra.branch}</p>
                                    </>
                                  )) : ""
                                }
                                <hr />
                              </>
                            )) : ""
                          }
                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.language && props.ResumeGetByIdReducer.alljobs.language.length > 0 ?
                              <a href="#">Languages</a>
                              : ""
                          }

                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.language && props.ResumeGetByIdReducer.alljobs.language.length > 0 ? props.ResumeGetByIdReducer.alljobs.language.map(lan => (
                              <>
                                <p>{lan.lang}</p>
                                <p>{lan.level}</p>
                                <hr />
                              </>
                            )) : ""
                          }

                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.award && props.ResumeGetByIdReducer.alljobs.award.length > 0 ?
                              <a href="#">Award</a>
                              : ""
                          }

                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.award && props.ResumeGetByIdReducer.alljobs.award.length > 0 ? props.ResumeGetByIdReducer.alljobs.award.map(awa => (
                              <>
                                <p>{awa.title}</p>
                                <p>{dayjs(awa.date).format("MM-DD-YYYY")}</p>
                                <hr />
                              </>
                            )) : ""
                          }
                             {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.certification && props.ResumeGetByIdReducer.alljobs.certification.length > 0 ?
                              <a href="#">Certifications</a>
                              : ""
                          }

                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.certification && props.ResumeGetByIdReducer.alljobs.certification.length > 0 ? props.ResumeGetByIdReducer.alljobs.certification.map(cer => (
                              <>
                                <p>{cer.title}</p>
                                <p>{dayjs(cer.date).format("MM-DD-YYYY")}</p>
                                <hr />
                              </>
                            )) : ""
                          }

                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.volunteer && props.ResumeGetByIdReducer.alljobs.volunteer.length > 0 ?
                              <a href="#">Volunteer</a>
                              : ""
                          }

                          {
                            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.volunteer && props.ResumeGetByIdReducer.alljobs.volunteer.length > 0 ? props.ResumeGetByIdReducer.alljobs.volunteer.map(vol => (
                              <>
                                <p>{vol.title}</p>
                                <div className="d-flex align-items-center">
                                  <p>{dayjs(vol.start).format("MM-DD-YYYY")}</p><span className="px-1">-</span>
                                  <p>{vol.end !== "I currently volunteer here" ? dayjs(vol.end).format("MM-DD-YYYY") : "I currently volunteer here"}</p>
                                </div>
                                <hr />
                              </>
                            )) : ""
                          }
                        </div>
                      </>
                      : ""
                  )) : ""
                }


              </div>
            </div>

          </div>
          {/* footer====================================================================== */}

        </div>
      </div>
      <Modal
        isOpen={BilingmodalIsOpen2}
        onRequestClose={() => setBilingModalIsOpen2(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "340px",
            height: "200px",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className=" justify-content-center align-items-center pt-3">
          <h5 className="text-center" style={{ color: "black" }}>Do you want to save this Resume?</h5>
          <div className="d-flex justify-content-center mt-4">
            {
              savebtn == false ?
                <p onClick={(e) => savedresume()} className="p-3 rounded" style={{ color: "white", cursor: "pointer", backgroundColor: "#8dc63f" }}><b>CONTINUE</b></p>
                :
                <p disabled={true} className="p-3 rounded" style={{ color: "white", cursor: "pointer", backgroundColor: "#8dc63f" }}><b>CONTINUE</b></p>
            }
            <p onClick={() => setBilingModalIsOpen2(false)} className="ml-5 pt-3" style={{ color: "black", cursor: "pointer" }}><b>Cancel</b></p>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={BilingmodalIsOpen3}
        onRequestClose={() => setBilingModalIsOpen3(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "360px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please buy subscription...</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpen3(false)}>OK</Link>
          </div>

        </div>
      </Modal>
      <Modal
        isOpen={BilingmodalIsOpen9}
        onRequestClose={() => setBilingModalIsOpen9(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "360px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Resume Already Saved.</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => window.location.reload(false)}>OK</Link>
          </div>

        </div>
      </Modal>
      <div
        style={{
          position: "absolute",
          left: "-30000px",
          top: 0,
        }}
      >
        <PDFExport paperSize="A4" ref={pdfExportComponent}>
          {
            props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.job && props.ResumeGetByIdReducer.alljobs.job.length > 0 ? props.ResumeGetByIdReducer.alljobs.job.map(val => (
              val.status !== 0 ?
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center w-100">
                    <img style={{ height: 150 }} src={moblogo} alt="" />
                  </div>
                  <div className="row">
                    <div className="col-12 text-dark px-5">
                      <div className="">
                      {props.GetUserReducer.users && props.GetUserReducer.users.Is_packaged !== null && props.GetUserReducer.users.Is_packaged !== undefined && props.GetUserReducer.users.Is_packaged !== "" && props.GetUserReducer.users.Is_packaged !== 0 ?
                      <p><span style={{ color: "orange" }}>{`${val.first_name} ${val.middle_name} ${val.last_name}`}</span></p>
:""}
                        
                        <p>{val.professional_summary}</p>
                      </div>

                      <div className="pt-4">
                        <img style={{ width: "60%" }} src={Contactingo} alt="" />
                      </div>

                      <div className="d-flex flex-column mt-3" style={{ border: "1px solid black", borderRadius: 10, padding: 10, width: "100%" }}>
                        {!props?.SubscriptionInfo?.isPayAsYouGo && <div className="d-flex align-items-center">
                          <p>Phone Number</p><p className="mx-1">-</p><p style={{ color: "orange" }}>{val.contact_number}</p>
                        </div>}
                        {!props?.SubscriptionInfo?.isPayAsYouGo && <div className="d-flex align-items-center">
                          <p>Additional Number</p><p className="mx-1">-</p><p style={{ color: "orange" }}>{val.add_contact_number}</p>
                        </div>}
                        {!props?.SubscriptionInfo?.isPayAsYouGo && <div className="d-flex align-items-center">
                          <p>Email</p><p className="mx-1">-</p><p style={{ color: "orange" }}>{val.email}</p>
                        </div>}
                        <div className="d-flex align-items-center">
                          <p>State</p><p className="mx-1">-</p><p style={{ color: "orange" }}>{val.state}</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <p>Zip Code</p><p className="mx-1">-</p><p style={{ color: "orange" }}>{val.zipcode}</p>
                        </div>
                      </div>

                      <div className="pt-4">
                        <img style={{ width: "60%" }} src={Education1} alt="" />
                      </div>
                      {
                        props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.education && props.ResumeGetByIdReducer.alljobs.education.length > 0 ? props.ResumeGetByIdReducer.alljobs.education.map(edu => (
                          <div className="mt-4">
                            <div className="d-flex flex-column" style={{ border: "1px solid black", borderRadius: 10, padding: 10, width: "100%" }}>
                              <span style={{ color: "orange" }}>{edu.level_of_education}</span>
                              <span style={{ color: "orange" }}>{edu.major}</span>
                              <span style={{ color: "orange" }}>{edu.school}</span>
                              <span style={{ color: "orange" }}>{edu.degree}</span>
                              <span style={{ color: "orange" }}>{edu.date_of_completion}</span>
                            </div>
                          </div>
                        )) : ""
                      }
                      {
                        props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.award && props.ResumeGetByIdReducer.alljobs.award.length > 0 ?
                          <div className="py-4">
                            <img style={{ width: "60%" }} src={Awards} alt="" />
                          </div>
                          : ""
                      }
                      {
                        props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.award && props.ResumeGetByIdReducer.alljobs.award.length > 0 ? props.ResumeGetByIdReducer.alljobs.award.map(awa => (
                          <div className="py-4">
                            <div className="d-flex flex-column" style={{ border: "1px solid black", borderRadius: 10, padding: 10, width: "100%" }}>
                              <span style={{ color: "orange" }}>{awa.title}</span>
                              <span style={{ color: "orange" }}>{dayjs(awa.date).format("MM-DD-YYYY")}</span>
                            </div>
                          </div>
                        )) : ""
                      }
                      {
                        props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.experience && props.ResumeGetByIdReducer.alljobs.experience.length > 0 ?
                          <div className="py-4">
                            <img style={{ width: "60%" }} src={Ex} alt="" />
                          </div>
                          : ""
                      }
                      {
                        props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.experience && props.ResumeGetByIdReducer.alljobs.experience.length > 0 ? props.ResumeGetByIdReducer.alljobs.experience.map(exp => (
                          <div className="py-4">
                            <div className="d-flex flex-column" style={{ border: "1px solid black", borderRadius: 10, padding: 10, width: "100%" }}>
                              <div className="d-flex w-100 align-items-center justify-content-between">
                                <span style={{ color: "orange" }}>{exp.job_title}</span>
                                <span style={{ color: "orange" }}>{exp.time_period_start} - {exp.time_period_end}</span>
                              </div>
                              <span style={{ color: "orange" }}>Company name</span>
                              <span style={{ color: "orange" }}>{exp.city}/{exp.state}</span>
                              <span style={{ color: "black" }}>{exp.duties}</span>
                            </div>
                          </div>
                        )) : ""
                      }
                      {
                        props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.military && props.ResumeGetByIdReducer.alljobs.military.length > 0 ?
                          <div className="py-4">
                            <img style={{ width: "60%" }} src={Mili} alt="" />
                          </div>
                          : ""
                      }
                      {
                        props.ResumeGetByIdReducer.alljobs && props.ResumeGetByIdReducer.alljobs.military && props.ResumeGetByIdReducer.alljobs.military.length > 0 ? props.ResumeGetByIdReducer.alljobs.military.map(mil => (
                          <div className="py-4">
                            <div className="d-flex flex-column" style={{ border: "1px solid black", borderRadius: 10, padding: 10, width: "100%" }}>
                              <div className="d-flex w-100 align-items-center justify-content-between">
                                <span style={{ color: "orange" }}><exose style={{ color: "black" }}>Country</exose> : {mil.country}</span>
                                <span style={{ color: "orange" }}>{dayjs(mil.start_date).format("MM-DD-YYYY")} - {dayjs(mil.end_date).format("MM-DD-YYYY")}</span>
                              </div>
                              <span style={{ color: "orange" }}><exose style={{ color: "black" }}>Rank</exose> : {mil.rank}</span>
                              <span style={{ color: "orange" }}><exose style={{ color: "black" }}>Security Clearance</exose> : {mil.security_clearance}</span>
                            </div>
                          </div>
                        )) : ""
                      }
                    </div>
                  </div>
                </div>
                :
                ""
            )) : ""
          }

        </PDFExport>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  ResumeGetByIdReducer: state.ResumeGetByIdReducer,
  SubscriptionInfo: state?.SubscriptionInfo
});

const mapDispatchToProps = (dispatch) => ({
  ResumeGetById: (userId) =>
    dispatch(ResumeGetById(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SeachResumeMB);


// code for scoop solutions
