import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { AppliedJob } from "../../actions/Appliedjobaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import { createSearch } from "../../actions/Jobsearchaction";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import dayjs from "dayjs";
const config = require('../../helpers/config.json');


Modal.setAppElement("#root");

const ApplicationHistory = (props) => {
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [allArray, setallArray] = useState([])
  const [allArray1, setallArray1] = useState([])
  const [load, setload] = useState(false)
  useEffect(() => {
    setload(true)
    fetch(`${config['baseUrl']}/application/history/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      setallArray([...allArray, alljobs])

    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
    setload(true)
    fetch(`${config['baseUrl']}/getAlljobs`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response.resume
      var temp = []
      temp = alljobs
      setallArray1([...temp])
    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
  }, []);
  return (
    <>
      {
        load == false ?
          <>
            <div className="container-fluid">
              <div className="container">
                <Dropdownnav />
                {/* header======================================================================*/}
                <div className="main-content">
                  <div className="d-flex main-content-session inner-session py-2">
                    <h5>Application History</h5>
                  </div>
                  <div className="w-100 usr-tbl">
                    <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                      <thead>
                        <tr role="row">
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                            Job</th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '28% !important' }}>
                            Company</th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Date Applied
                          </th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          allArray?.length > 0 ? allArray?.map(value => (
                            value.job && value.job.length > 0 ? value.job?.sort((a,b)=>String(b.date_applied).localeCompare(a.date_applied)).map(joob => (
                              <tr>
                                <td>{`${joob.job_applied !== null && joob?.job_applied !== undefined && joob?.job_applied !== "" ? joob?.job_applied : ""}`} </td>
                                <td>{allArray1 && allArray1?.length > 0 ? allArray1?.filter(data => data?.id == joob?.job_id)?.[0]?.company_name : ""}</td>
                                <td>{`${joob?.date_applied !== null && joob?.date_applied !== undefined && joob?.date_applied !== "" ? dayjs(joob?.date_applied).format("MM-DD-YYYY") : ""}`}</td>
                                <td>{`${joob?.pause !== null && joob?.pause !== undefined && joob?.pause !== "" ? joob?.pause == 1 ? "Open" : "Closed" : ""}`}</td>
                              </tr>
                            )) : <p style={{ color: "#f48a20" }}>History not found</p>
                          )) : ""
                        }



                      </tbody>
                    </table>
                  </div>
                  <div className="col-6">
                  </div>
                </div>

              </div>
            </div>
          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  ApplicationHistoryReducer: state.ApplicationHistoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationHistory);

// code for scoop solutions