export const createLinkedInData = (code) => {
    return (dispatch) => {
        dispatch({
            type: "LINKEDIN_RESET",
        });
        var data = {
            "code": code,
        };
        /// post request
        fetch(`https://api.cvvlogs.com/cv-tube/api.v.1/user/linkedin.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "code": code
            })
        }).then(res => {
            if (res.status !== 200) {
                
            }
            return res.json();
        }).then((response) => {
            const sData = response.data;
            dispatch({
                type: "LINKEDIN_SUCCESS",
                linkedInResponse: response,
                linkedIn: sData,
            });

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "LINKEDIN_FAIL",
                linkedInResponse: "creation failed",
                linkedIn: {}
            });
            // 
        })
    }
}

// code for scoop solutions