import React, { useContext, useMemo, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import EmployerNav from "../dropdownnav/Employernav";
import "../navbar/Navbar.css";
// import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Button from "../button";
import StripeCheckout from "react-stripe-checkout";
import STRIPE_PUBLISHABLE from "../../stripe";
import { AxiosGET, AxiosPOST } from "../../libs/axios";
import { useDispatch } from "react-redux";
import { setSubscriptionInfoRedux } from "../../actions/Subscriptioninfo";
import { useEffect } from "react";
import { ColorModeContext } from "../../theme";
const config = require("../../helpers/config.json");

Modal.setAppElement("#root");
const ManageCard = () => {
  const subscriptionInfo = useSelector((state) => state.SubscriptionInfo);
  const {
    customer_id,
    limit: cardLimit,
    usedAmount,
    dueAmount,
  } = subscriptionInfo;

  const subscriptionPlans = {
    isAdvanced: "Advanced",
    isBasic: "Basic",
    isPayAsYouGo: "Pay as You Go",
    isProfessional: "Professional",
  };
  let currentPlan = "";
  if (subscriptionInfo?.isProfessional) {
    currentPlan = `Current Subscription: ${subscriptionPlans["isProfessional"]}`;
  } else if (subscriptionInfo?.isAdvanced) {
    currentPlan = `Current Subscription: ${subscriptionPlans["isAdvanced"]}`;
  } else if (subscriptionInfo?.isBasic) {
    currentPlan = `Current Subscription: ${subscriptionPlans["isBasic"]}`;
  } else {
    currentPlan = `Current Subscription: Pay as You Go`;
  }

  const [limit, setLimit] = useState(cardLimit);
  const [mainLoading, setMainLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);
  const [saveCardLoading, setSaveCardLoading] = useState(false);
  const [openModal, setOpenModal] = useState("");

  const [customerData, setCustomerData] = useState({});

  const customer = !!Object.entries(customerData)?.length;

  const dispatch = useDispatch();

  const disabled = !+limit || +limit < 0 || +limit < usedAmount;

  const getCustomer = async () => {
    try {
      const res = await AxiosGET(
        `${config["baseUrl"]}/stripe/retrieve-customer/${customer_id}`
      );
      setCustomerData(res?.data);
      setMainLoading(false);
    } catch (error) {
      setOpenModal(error?.message);
    }
  };

  const handleLimitSubmit = async (e) => {
    try {
      e?.preventDefault();
      setLoading(true);
      const res = await AxiosPOST({
        url: `${config["baseUrl"]}/stripe/card-limit`,
        body: {
          user_id: localStorage.getItem("userid"),
          limit,
        },
      });

      const { limit: cardLimit, message } = res?.data;
      setOpenModal(message);
      dispatch(
        setSubscriptionInfoRedux({
          limit: cardLimit,
        })
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenModal(error?.message);
    }
  };

  const handleRetry = async (e) => {
    try {
      if (!retryLoading) {
        if (!customer_id) {
          setOpenModal("Please attach a payment card first.");
        } else {
          setRetryLoading(true);
          const res = await AxiosPOST({
            url: `${config["baseUrl"]}/stripe/retry-due-payments`,
            body: {
              user_id: localStorage.getItem("userid"),
              customer_id,
            },
          });

          const { message } = res?.data;
          setOpenModal(message);
          dispatch(
            setSubscriptionInfoRedux({
              dueAmount: 0,
            })
          );

          setRetryLoading(false);
        }
      }
    } catch (error) {
      setRetryLoading(false);
      setOpenModal(error?.message);
    }
  };

  const handleToken = async (token) => {
    try {
      setSaveCardLoading(true);
      const isEdit = !!Object.entries(customerData)?.length;
      const extraFields = isEdit
        ? {
            customer_id,
          }
        : {};
      const res = await AxiosPOST({
        url: `${config["baseUrl"]}/stripe/${
          isEdit ? "update" : "create"
        }-customer`,
        body: {
          token,
          user_id: localStorage.getItem("userid"),
          ...extraFields,
        },
      });
      const { user_id, message, customer } = res?.data;
      if (!isEdit) {
        dispatch(
          setSubscriptionInfoRedux({
            customer_id: customer?.id,
            user_id: user_id,
          })
        );
      }
      setOpenModal(message);
      setCustomerData(customer);
      setSaveCardLoading(false);
    } catch (error) {
      setSaveCardLoading(false);
      setOpenModal(error?.message);
    }
  };

  useEffect(() => {
    if (customer_id) {
      getCustomer();
    } else {
      setMainLoading(false);
    }
  }, []);
  const { mode, toggleColorMode } = useContext(ColorModeContext);

  const textColor = mode== "light" ? "black" : "white";
  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container">
          <EmployerNav />
          {/* header====================================================================== */}
          {mainLoading ? (
            <></>
          ) : (
            <div className="main-content emp-account-owner-setting">
              <div className="d-flex align-items-center justify-content-start py-2">
                {/* <h5>Card Management</h5> */}
              </div>
              <div>
                {!!dueAmount && (
                  <div
                    className={`alert alert-danger mx-auto text-center`}
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      width: "100%",
                      maxWidth: "500px",
                    }}
                    role="alert"
                  >
                    ${dueAmount} still unpaid{" "}
                    <b
                      onClick={handleRetry}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Click here
                    </b>{" "}
                    to retry.
                  </div>
                )}
                {!customer ? (
                  <div
                    className={`alert alert-warning mx-auto text-center`}
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      width: "100%",
                      maxWidth: "500px",
                    }}
                    role="alert"
                  >
                    Please attach your card.
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="mx-auto"
                  style={{ width: "100%", maxWidth: "500px" }}
                >
                  <h6 style={{ color: textColor }}> {currentPlan}</h6>
                  {!!subscriptionInfo?.isPayAsYouGo && (
                    <h6 style={{ color: textColor }}>
                      Applicants Viewed: {Number(usedAmount)}
                    </h6>
                  )}

                  <h5>
                    By attaching your credit card information, you are able to
                    view, contact and download applicant's resumes
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <StripeCheckout
                      token={handleToken}
                      stripeKey={STRIPE_PUBLISHABLE}
                      amount={0}
                      currency="USD"
                      // name={"Save Card"}
                      billingAddress
                      zipCode
                      allowRememberMe
                    >
                      <Button
                        loading={saveCardLoading}
                        title={`${customer ? "Update" : "Attach"} Card`}
                        style={{
                          backgroundColor: "#8DC63F",
                          color: "#FFF",
                          letterSpacing: "0.5px",
                          height: "40px",
                        }}
                      />
                    </StripeCheckout>
                  </div>
                  {!subscriptionInfo?.isPayAsYouGo && (
                    <p
                      className="mt-4"
                      style={{ fontWeight: 600, color: "#4968d0" }}
                    >
                      If you would like to cancel your subscription, please
                      select the Pay as You Go option in the Products & Pricing
                      page and enjoy a free job slot on us!
                    </p>
                  )}

                  {/* {customer && (
                    <div
                      style={{
                        marginTop: "40px",
                        paddingTop: "20px",
                        borderTop: "1px solid #e4e4e4",
                      }}
                    >
                      <h5>Card Limit</h5>
                      <form onSubmit={handleLimitSubmit}>
                        <div className="form-group">
                          <label htmlFor="limit">
                            How many dollars should be the maximum?
                          </label>

                          <div style={{ display: "flex" }}>
                            <input
                              value={limit}
                              type="number"
                              className="form-control employee-input-email job-search w-100"
                              id="limit"
                              placeholder="Enter an amount"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    +value > 0 &&
                                    +value >= usedAmount) ||
                                  value === ""
                                ) {
                                  setLimit(value);
                                }
                              }}
                            />

                            <Button
                              // onClick={(e) => handleLimitSubmit(e)}
                              title="Submit"
                              disabled={disabled}
                              loading={loading}
                              style={{
                                backgroundColor: disabled ? "gray" : "#8DC63F",
                                color: "#fff",
                                letterSpacing: "0.5px",
                                height: "40px",
                                marginLeft: "5px",
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal("")}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "475px",
            height: "fit-content",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "40px 20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>{openModal}</h4>
          <div className="d-flex align-items-center justify-content-center search-session">
            <Link to="#" className="fgh" onClick={() => setOpenModal("")}>
              OK
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManageCard;
