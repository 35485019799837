const initState = {
    SearchResponse: {},
    loading: true,
    success: true,
    jobIds: []
}

const AppliedJobReducer = (state = initState, action) => {
    if (action.type === 'GET_SEARCH') {
        return {
            ...state,
            SearchResponse: action.SearchResponse,
            loading: action.loading,
            success: action.success,
        }
    }
    // /// THESE ARE GENERAL APPLICABLE TO ALL API's
    else if (action.type === 'SEARCH_RESET') {
        return {
            ...state,
            SearchResponse: null,
            loading: action.loading,
            success: action.success,

        }
    } else if (action.type === 'SEARCH_SUCCESS') {
        return {
            ...state,
            SearchResponse: action.SearchResponse,
            loading: action.loading,
            success: action.success,
        }
    } else if (action.type === 'SEARCHL_FAIL') {
        return {
            ...state,
            SearchResponse: action.SearchResponse,
            loading: action.loading,
            success: action.success,
        }
    }
    else if (action.type === 'SET_NEW_APPLICANT_JOB') {
        return {
            ...state,
            jobIds: action.jobIds,
        }
    }

    return state;
}
export default AppliedJobReducer;

// code for scoop solutions