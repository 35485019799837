import React from "react"
import Employeesignup from "../components/employeesignup/Employeesignup"
import Navbar from "../components/navbar/Navbar"
import HomeFooter from "../components/homeFooter/HomeFooter";

const SignUpEmplyee = () => {
    return (
        <>
            <Navbar />
            <Employeesignup />
            <HomeFooter />
        </>
    )
}
export default SignUpEmplyee


// code for scoop solutions