import React, { useState, useEffect, useRef, useCallback } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { ReportJobAction } from "../../actions/Reportjobaction";
import { ResumeCreate } from "../../actions/Resumeaction";
import moblogo from "../../assets/mob-logo.png";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import { useLocation, useHistory } from "react-router-dom";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import dayjs from "dayjs";
import parse from "html-react-parser";
import Employernav from "../dropdownnav/Employernav";
import { createPdfFromHtml } from "../searchresume/logic";
import QuickPayment from "../quickpayment";
import { AxiosPOST } from "../../libs/axios";
import { setSubscriptionInfoRedux } from "../../actions/Subscriptioninfo";

const config = require("../../helpers/config.json");

Modal.setAppElement("#root");

const ViewResume = (props) => {
  const subscription = useSelector((state) => state?.SubscriptionInfo);
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("id");
  const jobId = new URLSearchParams(search).get("job_id");
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState("");
  const [allArray, setallArray] = useState([]);
  const [allvolunteer, setallvolunteer] = useState([]);
  const [payAsYouGoPaid, setPayAsYouGoPaid] = useState(false);
  const [load, setload] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setload(true);
    reloadResume();
  }, []);

  const Sheet = () => {
    return (
      <>
        {allArray && allArray.length > 0
          ? allArray.map((value) => {
              return (
                <div className="main-content padding-15mm px-4 py-4 step-dark-1">
                  <div className="col-12 p-0 m-0 d-flex align-items-center justify-content-center p-0 step-dark-2">
                    <div className="col-lg-12 col-sm-12 preview-full-resume-body step-dark-3">
                      <div className="d-flex flex-column align-items-center justify-content-center py-3">
                        <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                          {/* Below condition is removed as since user will only be able to download once he has paid the amount so no need to hide info from user */}
                          {/* {props.GetUserReducer.users && props.GetUserReducer.users?.Is_packaged !== null && props.GetUserReducer.users.Is_packaged !== undefined && props.GetUserReducer.users.Is_packaged !== "" && props.GetUserReducer.users.Is_packaged !== 0 ? */}
                          <h3 className="black-theme-resume-preview mb-0">{`${
                            value.job[0].first_name !== "" &&
                            value.job[0].first_name !== undefined &&
                            value.job[0].first_name !== null
                              ? value.job[0].first_name
                              : ""
                          } ${
                            value.job[0].middle_name !== "" &&
                            value.job[0].middle_name !== undefined &&
                            value.job[0].middle_name !== null
                              ? value.job[0].middle_name
                              : ""
                          } ${
                            value.job[0].last_name !== "" &&
                            value.job[0].last_name !== undefined &&
                            value.job[0].last_name !== null
                              ? value.job[0].last_name
                              : ""
                          }`}</h3>
                          {/* :""} */}
                        </div>
                        <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                          <p className="">{`${
                            value.job[0].city !== "" &&
                            value.job[0].city !== undefined &&
                            value.job[0].city !== null
                              ? value.job[0].city
                              : ""
                          },  ${
                            value.job[0].state !== "" &&
                            value.job[0].state !== undefined &&
                            value.job[0].state !== null
                              ? value.job[0].state
                              : ""
                          }`}</p>
                        </div>
                      </div>
                      <hr className="m-0" />
                      {!props?.SubscriptionInfo?.isPayAsYouGo && (
                        <div className=" py-3">
                          <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                            <h5 className="m-0">CONTACT INFORMATION</h5>
                          </div>
                          <p>
                            {value.job[0].contact_number !== "" &&
                            value.job[0].contact_number !== undefined &&
                            value.job[0].contact_number !== null
                              ? value.job[0].contact_number
                              : ""}
                          </p>
                          <p>
                            {value.job[0].email !== "" &&
                            value.job[0].email !== undefined &&
                            value.job[0].email !== null
                              ? value.job[0].email
                              : ""}
                          </p>
                        </div>
                      )}
                      <hr className="m-0" />

                      {value.job[0].professional_summary !== "" &&
                      value.job[0].professional_summary !== undefined &&
                      value.job[0].professional_summary !== null ? (
                        <div className=" py-3">
                          <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                            <h5 className="m-0">PROFESSIONAL SUMMARY</h5>
                          </div>
                          <p>
                            {value.job[0].professional_summary !== "" &&
                            value.job[0].professional_summary !== undefined &&
                            value.job[0].professional_summary !== null
                              ? parse(value.job[0].professional_summary)
                              : ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className=" py-3">
                        <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                          <h5 className="m-0">EDUCATION</h5>
                        </div>
                        {value.education && value.education.length > 0 ? (
                          value.education.map((edu) => (
                            <div className="padding-block-in-bottom-justice  flex-direction-in-res-justice d-flex align-items-center justify-content-between">
                              <div>
                                <div className="d-flex flex-column">
                                  <p
                                    className="margin-zero-in-bottom-justice  m-0"
                                    style={{ fontWeight: "800" }}
                                  >{`${
                                    edu.school !== "" &&
                                    edu.school !== undefined &&
                                    edu.school !== null
                                      ? edu.school
                                      : ""
                                  }`}</p>
                                  <div className="d-flex align-items-center">
                                    <p className="margin-zero-in-bottom-justice m-0">{`${
                                      edu.level_of_education !== "" &&
                                      edu.level_of_education !== undefined &&
                                      edu.level_of_education !== null
                                        ? edu.level_of_education
                                        : ""
                                    }`}</p>
                                    <p className="m-0 px-1">-</p>
                                    <p className="margin-zero-in-bottom-justice  m-0">{`${
                                      edu.major !== "" &&
                                      edu.major !== undefined &&
                                      edu.major !== null
                                        ? edu.major
                                        : ""
                                    }`}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex align-items-end flex-direction-in-row-justice flex-column justify-content-center">
                                <p>{`${
                                  edu.city !== "" &&
                                  edu.city !== undefined &&
                                  edu.city !== null
                                    ? edu.city
                                    : ""
                                }`}</p>
                                <p>{`${
                                  edu.date_of_completion !== "" &&
                                  edu.date_of_completion !== undefined &&
                                  edu.date_of_completion !== null
                                    ? edu.date_of_completion !==
                                      "Currently enrolled"
                                      ? dayjs(edu.date_of_completion).format(
                                          "MM-DD-YYYY"
                                        )
                                      : "Currently enrolled"
                                    : "Currently enrolled"
                                }`}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="py-4">
                            (More education info if provided…)
                          </p>
                        )}
                      </div>
                      <div className=" py-3">
                        <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                          <div className="d-flex">
                            <h5 className="m-0">WORK EXPERIENCE </h5>
                          </div>
                        </div>
                        {value.experience && value.experience.length > 0 ? (
                          value.experience.map((exp) => (
                            <>
                              <div className="padding-block-in-bottom-justice  flex-direction-in-res-justice d-flex align-items-center justify-content-between">
                                <div>
                                  <div className="d-flex flex-in-resume-direction-false mb-1">
                                    <p
                                      style={{ fontWeight: "800" }}
                                      className="mb-0 ml-1"
                                    >
                                      {exp.company}
                                    </p>
                                  </div>
                                  <div className="d-flex flex-in-resume-direction-false mb-1">
                                    <i>
                                      <p className="mb-0 ml-1">
                                        {exp.job_title}
                                      </p>
                                    </i>
                                  </div>
                                </div>
                                <div className="flex-direction-in-row-justice-exp  d-flex align-items-end  flex-column justify-content-center">
                                  <p>
                                    {exp.city}, {exp.state}
                                  </p>
                                  <p>
                                    {dayjs(exp.time_period_start).format(
                                      "MM-DD-YYYY"
                                    )}{" "}
                                    to{" "}
                                    {exp.time_period_end !==
                                    "Still currently employed here"
                                      ? dayjs(exp.time_period_end).format(
                                          "MM-DD-YYYY"
                                        )
                                      : "Still currently employed here"}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex flex-column flex-in-resume-direction-false mb-1">
                                <p className="mb-0 ml-1">{parse(exp.duties)}</p>
                              </div>
                            </>
                          ))
                        ) : (
                          <p className="py-4">
                            (More experience info if provided…)
                          </p>
                        )}
                      </div>

                      {value.military && value.military.length > 0 ? (
                        <div className=" py-3">
                          <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                            <div className="d-flex">
                              <h5 className="m-0">MILITARY SERVICE</h5>
                            </div>
                          </div>
                          {value.military && value.military.length > 0 ? (
                            value.military.map((mil) => (
                              <div className="d-flex padding-block-in-preview-resume-justice align-items-center justify-content-between">
                                <div style={{ width: "100%" }}>
                                  {value.branch && value.branch.length > 0 ? (
                                    value.branch.map((br) => (
                                      <>
                                        <div
                                          style={{ width: "100%" }}
                                          className="d-flex align-items-center flex-in-resume-direction-false mb-1"
                                        >
                                          <a
                                            style={{
                                              fontSize: "15px",
                                              color: "black",
                                              fontWeight: "bolder",
                                            }}
                                            href="#"
                                            className="mt-0 dropdddd"
                                          >
                                            Branch:
                                          </a>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              width: "100%",
                                            }}
                                          >
                                            <p className="mb-0 ml-1">
                                              {br.branch}
                                            </p>
                                            {value.mos &&
                                            value.mos.length > 0 ? (
                                              <p>
                                                {dayjs(mil.start_date).format(
                                                  "MM-DD-YYYY"
                                                )}{" "}
                                                to{" "}
                                                {dayjs(mil.end_date).format(
                                                  "MM-DD-YYYY"
                                                )}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ))
                                  ) : (
                                    <div className="d-flex">
                                      <p className="m-0">
                                        No branch of service added
                                      </p>
                                    </div>
                                  )}

                                  <div className="d-flex flex-in-resume-direction-false mb-1">
                                    <a
                                      style={{
                                        fontSize: "15px",
                                        color: "black",
                                        fontWeight: "bolder",
                                      }}
                                      href="#"
                                      className="mt-0 dropdddd"
                                    >
                                      MOS:
                                    </a>
                                    {value.mos && value.mos.length > 0 ? (
                                      value.mos.map((mos, index) => (
                                        <>
                                          <p className="mb-0 ml-1">{mos.mos}</p>
                                          {index == value.mos.length - 1 ? (
                                            ""
                                          ) : (
                                            <span className="text-dark pl-0 pr-1">
                                              ,
                                            </span>
                                          )}
                                        </>
                                      ))
                                    ) : (
                                      <p>No MOS added</p>
                                    )}
                                  </div>
                                  {value.military.length > 0 ? (
                                    value.military[0].security_clearance ==
                                      "" ||
                                    value.military[0].security_clearance ==
                                      undefined ||
                                    value.military[0].security_clearance ==
                                      null ? (
                                      ""
                                    ) : (
                                      <div className="d-flex flex-in-resume-direction-false">
                                        <a
                                          style={{
                                            fontSize: "15px",
                                            color: "black",
                                            fontWeight: "bolder",
                                          }}
                                          href="#"
                                          className="mt-0 dropdddd"
                                        >
                                          Security Clearance:
                                        </a>
                                        {value.military &&
                                        value.military.length > 0
                                          ? value.military.map((cle) => (
                                              <>
                                                <p className="mb-0 ml-1">{`${
                                                  cle.security_clearance !==
                                                    null &&
                                                  cle.security_clearance !==
                                                    undefined &&
                                                  cle.security_clearance !== ""
                                                    ? cle.security_clearance
                                                    : ""
                                                }`}</p>
                                              </>
                                            ))
                                          : ""}
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="d-flex align-items-end  flex-column justify-content-center"></div>
                              </div>
                            ))
                          ) : (
                            <p className="py-4">
                              (More military info if provided…)
                            </p>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {value.language.length > 0 ||
                      value.certification.length > 0 ||
                      value.award.length > 0 ? (
                        <div className=" py-3">
                          <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                            <div className="d-flex">
                              <h5 className="m-0">ADDITIONAL SKILLS</h5>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="pr-5 pt-2">
                              {value.language.length > 0 ? (
                                <div className="d-flex flex-in-resume-direction-false">
                                  <div className="d-flex align-items-center">
                                    <p
                                      style={{ fontWeight: "bold" }}
                                      className="mr-3"
                                    >
                                      Additional Language(s)
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {value.language &&
                                    value.language.length > 0 ? (
                                      value.language.map((lang, index) => (
                                        <>
                                          <p className="m-0">{`${
                                            lang.lang !== null &&
                                            lang.lang !== undefined &&
                                            lang.lang !== ""
                                              ? `${lang.lang} (${lang.level})`
                                              : ""
                                          }`}</p>
                                          {index ==
                                          value.language.length - 1 ? (
                                            ""
                                          ) : (
                                            <span className="text-dark pl-0 pr-1">
                                              ,
                                            </span>
                                          )}
                                        </>
                                      ))
                                    ) : (
                                      <p>Level of Fluency</p>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {value.certification.length > 0 ? (
                                <div className="d-flex flex-in-resume-direction-false">
                                  <p
                                    style={{ fontWeight: "bold" }}
                                    className="mr-3"
                                  >
                                    Certification(s)
                                  </p>
                                  {value.certification &&
                                  value.certification.length > 0 ? (
                                    value.certification.map((cert, index) => (
                                      <>
                                        <p>
                                          {`${
                                            cert.title !== null &&
                                            cert.title !== undefined &&
                                            cert.title !== ""
                                              ? cert.title
                                              : ""
                                          }`}{" "}
                                          (
                                          {`${
                                            cert.date !== null &&
                                            cert.date !== undefined &&
                                            cert.date !== ""
                                              ? dayjs(cert.date).format(
                                                  "MM-DD-YYYY"
                                                )
                                              : ""
                                          }`}
                                          )
                                        </p>
                                        {index ==
                                        value.certification.length - 1 ? (
                                          ""
                                        ) : (
                                          <span className="text-dark pl-0 pr-1">
                                            ,
                                          </span>
                                        )}
                                      </>
                                    ))
                                  ) : (
                                    <p>Date Earned</p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {value.award.length > 0 ? (
                                <div className="d-flex flex-in-resume-direction-false">
                                  <p
                                    style={{ fontWeight: "bold" }}
                                    className="mr-3"
                                  >
                                    Award(s){" "}
                                  </p>
                                  {value.award && value.award.length > 0 ? (
                                    value.award.map((awar, index) => (
                                      <>
                                        <p>
                                          {`${
                                            awar.title !== null &&
                                            awar.title !== undefined &&
                                            awar.title !== ""
                                              ? awar.title
                                              : ""
                                          }`}{" "}
                                          (
                                          {`${
                                            awar.date !== null &&
                                            awar.date !== undefined &&
                                            awar.date !== ""
                                              ? dayjs(awar.date).format(
                                                  "MM-DD-YYYY"
                                                )
                                              : ""
                                          }`}
                                          )
                                        </p>
                                        {index == value.award.length - 1 ? (
                                          ""
                                        ) : (
                                          <span className="text-dark pl-0 pr-1">
                                            ,
                                          </span>
                                        )}
                                      </>
                                    ))
                                  ) : (
                                    <p>Date Earned</p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {allvolunteer.length > 0 ? (
                        <div className=" py-3">
                          <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                            <div className="d-flex">
                              <h5 className="m-0">VOLUNTEER WORK</h5>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Start Date</th>
                                  <th scope="col">End Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allvolunteer && allvolunteer.length > 0
                                  ? allvolunteer.map((val, ind) => (
                                      <tr>
                                        <td>{val.title}</td>
                                        <td>
                                          {dayjs(val.start).format(
                                            "MM-DD-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {val.end !==
                                          "I currently volunteer here"
                                            ? dayjs(val.end).format(
                                                "MM-DD-YYYY"
                                              )
                                            : "I currently volunteer here"}
                                        </td>
                                      </tr>
                                    ))
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : "No Resume Created"}
      </>
    );
  };
  let printContent = useRef();

  const reloadResume = async () => {
    return new Promise((resolve, reject) => {
      fetch(
        `${config["baseUrl"]}/resume/getbyid/${name}/${localStorage.getItem(
          "userid"
        )}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setload(false);
          const alljobs = response;
          setallvolunteer(
            response[0].volunteer.length > 0 ? response[0].volunteer : []
          );
          var temp = [];
          temp = [...response];
          setallArray([...temp]);
          resolve(temp);
        })
        .catch((error) => {
          console.log("error", error);
          setload(false);
          reject(null);
        });
    });
  };

  const savedDownload = useCallback(async (id, value) => {
    console.log("999 in job is ", name, value?.job[0]);

    await fetch(`${config["baseUrl"]}/Save/Download`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: Number(localStorage.getItem("userid")),
        resume_id: Number(value?.job[0]?.id),
        candidate_id: Number(name),
        // type: "VIEW_CANDIDATE",
        job_id: Number(jobId),
      }),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          console.log("Limit Over");
        }
        return res.json();
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        //
      });
  }, []);
  // const handleClick = (user) => {
  const handleClick = (user, value) => {
    const { customer_id, limit, usedAmount, dueAmount } = subscription;
    if (!customer_id) {
      setOpenModal("Please attach a credit card to view applicants");
      return;
    }
    savedDownload(name, value).then(() => {
      reloadResume()
        .then(() => {
          createPdfFromHtml(
            printContent.current,
            allArray[0].job[0].first_name
          );
        })
        .catch(() => {});
    });
  };

  const handleDownloadClick = async (user, value) => {
    try {
      const { customer_id, limit, usedAmount, dueAmount } = subscription;

      if (dueAmount) {
        setOpenModal("Some payments remaining, please clear them first.");
      } else if (!customer_id) {
        setOpenModal("Please attach a credit card to view applicants");
      } else {
        const res = await AxiosPOST({
          url: `${config["baseUrl"]}/stripe/capture-payment`,
          body: {
            user_id: Number(localStorage.getItem("userid")),
            resume_id: Number(value?.job[0]?.id),
            candidate_id: Number(name),
            type: "VIEW_CANDIDATE",
            job_id: Number(jobId),
          },
        });
        dispatch(
          setSubscriptionInfoRedux({
            usedAmount: res?.data?.usedAmount,
          })
        );

        handleClick(user?.first_name, value);
      }
    } catch (error) {
      console.log(" error:", error?.message);
    }
  };

  // const handleEmailClick = async (user, value) => {
  //   try {
  //     const { customer_id, limit, usedAmount, dueAmount } = subscription;

  //     if (dueAmount) {
  //       setOpenModal("Some payments remaining, please clear them first.");
  //     } else if (!customer_id) {
  //       setOpenModal("Please attach a credit card to view applicants");
  //     } else {
  //       const res = await AxiosPOST({
  //         url: `${config["baseUrl"]}/stripe/capture-payment`,
  //         body: {
  //           user_id: Number(localStorage.getItem("userid")),
  //           resume_id: Number(value?.job[0]?.id),
  //           candidate_id: Number(name),
  //           // type: "VIEW_CANDIDATE",
  //           job_id: Number(jobId),
  //         },
  //       });
  //       dispatch(
  //         setSubscriptionInfoRedux({
  //           usedAmount: res?.data?.usedAmount,
  //         })
  //       );

  //       // handleClick(user?.first_name,value);
  //     }
  //   } catch (error) {
  //     console.log(" error:", error?.message);
  //   }
  // };

  useEffect(() => {
    if (!!subscription?.isPayAsYouGo) {
      fetch(
        `${config["baseUrl"]}/Get/Downloads/${localStorage.getItem(
          "userid"
        )}/${name}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (!!response.status) {
            setPayAsYouGoPaid(!!response?.status);
          }
        })
        .catch((error) => {
          console.log(error);
          //
        });
    }
  }, []);

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal("")}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "475px",
            height: "fit-content",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "40px 20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>{openModal}</h4>
          <div className="d-flex align-items-center justify-content-center search-session">
            <Link
              to="#"
              className="fgh"
              onClick={() => history.push("subscription")}
            >
              Subscription
            </Link>
          </div>
        </div>
      </Modal>
      {load == false ? (
        <>
          <div
            style={{ position: "absolute", left: "-3000px", maxWidth: "700" }}
            id="print"
            className="A4"
          >
            {/* <Sheet /> */}
            <div>
              <div ref={printContent}>
                <Sheet />
              </div>
            </div>
          </div>
          <div className="container-fluid" id="Employee">
            <div className="container">
              <Employernav />
              {/* header======================================================================*/}
              {allArray && allArray.length > 0
                ? allArray.map((value) => {
                    const candidateName = `${
                      value.job?.[0]?.first_name || ""
                    } ${value.job?.[0]?.middle_name || ""} ${
                      value?.job?.[0]?.last_name || ""
                    }`;
                    return (
                      <div className="main-content">
                        <div className="col-12 d-flex flex-wrap align-items-center justify-content-center p-0">
                          <div className="col-12 col-sm-6 col-lg-4 p-0 pr-2">
                            <Link
                              className="fgh"
                              to={`/send-email?cid=${name}&job_id=${jobId}`}
                            >
                              {" "}
                              <button className="send-invitation w-100 py-3 my-2">
                                Email Candidate
                              </button>
                            </Link>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-4 p-0 pl-2">
                            {/* <div style={{fontSize: "16px"}}>{!subscription?.isPayAsYouGo || !!payAsYouGoPaid ? <button onClick={()=>handleClick(value.job?.[0]?.first_name)} className="send-invitation w-100 py-3 my-2">Download</button> : <QuickPayment message="" onConfirm={()=>handleClick(value.job?.[0]?.first_name)}><button className="send-invitation w-100 py-3 my-2">Download</button></QuickPayment>}</div> */}
                            <div style={{ fontSize: "16px" }}>
                              {subscription?.isPayAsYouGo &&
                              !!payAsYouGoPaid ? (
                                <button
                                  onClick={() =>
                                    handleClick(
                                      value.job?.[0]?.first_name,
                                      value
                                    )
                                  }
                                  className="send-invitation w-100 py-3 my-2"
                                >
                                  Download
                                </button>
                              ) : (
                                <button
                                  className="send-invitation w-100 py-3 my-2"
                                  onClick={() =>
                                    handleDownloadClick(value?.job?.[0], value)
                                  }
                                >
                                  Download
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-3 d-flex align-items-center justify-content-center p-0">
                          <div className="col-lg-8 col-sm-12 preview-full-resume-body">
                            <div className="d-flex flex-column align-items-center justify-content-center py-3">
                              <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                                <h3 className="black-theme-resume-preview mb-0">
                                  {candidateName}
                                </h3>
                                <Link to="/updateresume?id=PERSONALINFO"></Link>
                              </div>
                              <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                                <p className="">{`${
                                  value.job[0].city !== "" &&
                                  value.job[0].city !== undefined &&
                                  value.job[0].city !== null
                                    ? value.job[0].city
                                    : ""
                                },  ${
                                  value.job[0].state !== "" &&
                                  value.job[0].state !== undefined &&
                                  value.job[0].state !== null
                                    ? value.job[0].state
                                    : ""
                                }`}</p>
                              </div>
                            </div>
                            <hr className="m-0" />

                            {value.job[0].professional_summary !== "" &&
                            value.job[0].professional_summary !== undefined &&
                            value.job[0].professional_summary !== null ? (
                              <div className=" py-3">
                                <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                                  <h5 className="m-0">PROFESSIONAL SUMMARY</h5>
                                  <Link to="/updateresume?id=PROFESSIONALSUMMARY"></Link>
                                </div>
                                <p>
                                  {value.job[0].professional_summary !== "" &&
                                  value.job[0].professional_summary !==
                                    undefined &&
                                  value.job[0].professional_summary !== null
                                    ? parse(value.job[0].professional_summary)
                                    : ""}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className=" py-3">
                              <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                                <h5 className="m-0">EDUCATION</h5>
                                <Link to="/updateresume?id=EDUCATION"></Link>
                              </div>
                              {value.education && value.education.length > 0 ? (
                                value.education.map((edu) => (
                                  <div className="padding-block-in-bottom-justice  flex-direction-in-res-justice d-flex align-items-center justify-content-between">
                                    <div>
                                      <div className="d-flex flex-column">
                                        <p
                                          className="margin-zero-in-bottom-justice  m-0"
                                          style={{ fontWeight: "800" }}
                                        >{`${
                                          edu.school !== "" &&
                                          edu.school !== undefined &&
                                          edu.school !== null
                                            ? edu.school
                                            : ""
                                        }`}</p>
                                        <div className="d-flex align-items-center">
                                          <p className="margin-zero-in-bottom-justice m-0">{`${
                                            edu.level_of_education !== "" &&
                                            edu.level_of_education !==
                                              undefined &&
                                            edu.level_of_education !== null
                                              ? edu.level_of_education
                                              : ""
                                          }`}</p>
                                          <p className="m-0 px-1">-</p>
                                          <p className="margin-zero-in-bottom-justice  m-0">{`${
                                            edu.major !== "" &&
                                            edu.major !== undefined &&
                                            edu.major !== null
                                              ? edu.major
                                              : ""
                                          }`}</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-end flex-direction-in-row-justice flex-column justify-content-center">
                                      <p>{`${
                                        edu.city !== "" &&
                                        edu.city !== undefined &&
                                        edu.city !== null
                                          ? edu.city
                                          : ""
                                      }`}</p>
                                      <p>{`${
                                        edu.date_of_completion !== "" &&
                                        edu.date_of_completion !== undefined &&
                                        edu.date_of_completion !== null
                                          ? edu.date_of_completion !==
                                            "Currently enrolled"
                                            ? dayjs(
                                                edu.date_of_completion
                                              ).format("MM-DD-YYYY")
                                            : "Currently enrolled"
                                          : "Currently enrolled"
                                      }`}</p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <p className="py-4">
                                  (More education info if provided…)
                                </p>
                              )}
                            </div>
                            <div className=" py-3">
                              <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                                <div className="d-flex">
                                  <h5 className="m-0">WORK EXPERIENCE </h5>
                                  <Link to="/updateresume?id=WORKEXPERIENCE"></Link>
                                </div>
                              </div>
                              {value.experience &&
                              value.experience.length > 0 ? (
                                value.experience.map((exp) => (
                                  <>
                                    <div className="padding-block-in-bottom-justice  flex-direction-in-res-justice d-flex align-items-center justify-content-between">
                                      <div>
                                        <div className="d-flex flex-in-resume-direction-false mb-1">
                                          <p
                                            style={{ fontWeight: "800" }}
                                            className="mb-0 ml-1"
                                          >
                                            {exp.company}
                                          </p>
                                        </div>
                                        <div className="d-flex flex-in-resume-direction-false mb-1">
                                          <i>
                                            <p className="mb-0 ml-1">
                                              {exp.job_title}
                                            </p>
                                          </i>
                                        </div>
                                      </div>
                                      <div className="flex-direction-in-row-justice-exp  d-flex align-items-end  flex-column justify-content-center">
                                        <p>
                                          {exp.city}, {exp.state}
                                        </p>
                                        <p>
                                          {dayjs(exp.time_period_start).format(
                                            "MM-DD-YYYY"
                                          )}{" "}
                                          to{" "}
                                          {exp.time_period_end !==
                                          "Still currently employed here"
                                            ? dayjs(exp.time_period_end).format(
                                                "MM-DD-YYYY"
                                              )
                                            : "Still currently employed here"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column flex-in-resume-direction-false mb-1">
                                      <p className="mb-0 ml-1">
                                        {parse(exp.duties)}
                                      </p>
                                    </div>
                                  </>
                                ))
                              ) : (
                                <p className="py-4">
                                  (More experience info if provided…)
                                </p>
                              )}
                            </div>
                            {!subscription?.isPayAsYouGo && (
                              <div className=" py-3">
                                <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                                  <h5 className="m-0">CONTACT INFORMATION</h5>
                                </div>
                                <p>
                                  {value.job[0].contact_number !== "" &&
                                  value.job[0].contact_number !== undefined &&
                                  value.job[0].contact_number !== null
                                    ? value.job[0].contact_number
                                    : ""}
                                </p>
                                <p>
                                  {value.job[0].email !== "" &&
                                  value.job[0].email !== undefined &&
                                  value.job[0].email !== null
                                    ? value.job[0].email
                                    : ""}
                                </p>
                              </div>
                            )}

                            {value.military && value.military.length > 0 ? (
                              <div className=" py-3">
                                <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                                  <div className="d-flex">
                                    <h5 className="m-0">MILITARY SERVICE</h5>
                                    <Link to="/updateresume?id=MILITARY"></Link>
                                  </div>
                                </div>
                                {value.military && value.military.length > 0 ? (
                                  value.military.map((mil) => (
                                    <div className="d-flex padding-block-in-preview-resume-justice align-items-center justify-content-between">
                                      <div style={{ width: "100%" }}>
                                        {value.branch &&
                                        value.branch.length > 0 ? (
                                          value.branch.map((br) => (
                                            <>
                                              <div
                                                style={{ width: "100%" }}
                                                className="d-flex align-items-center flex-in-resume-direction-false mb-1"
                                              >
                                                <a
                                                  style={{
                                                    fontSize: "15px",
                                                    color: "black",
                                                    fontWeight: "bolder",
                                                  }}
                                                  href="#"
                                                  className="mt-0 dropdddd"
                                                >
                                                  Branch:
                                                </a>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                      "space-between",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <p className="mb-0 ml-1">
                                                    {br.branch}
                                                  </p>
                                                  {value.mos &&
                                                  value.mos.length > 0 ? (
                                                    <p>
                                                      {dayjs(
                                                        mil.start_date
                                                      ).format(
                                                        "MM-DD-YYYY"
                                                      )}{" "}
                                                      to{" "}
                                                      {dayjs(
                                                        mil.end_date
                                                      ).format("MM-DD-YYYY")}
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          ))
                                        ) : (
                                          <div className="d-flex">
                                            <p className="m-0">
                                              No branch of service added
                                            </p>
                                          </div>
                                        )}

                                        <div className="d-flex flex-in-resume-direction-false mb-1">
                                          <a
                                            style={{
                                              fontSize: "15px",
                                              color: "black",
                                              fontWeight: "bolder",
                                            }}
                                            href="#"
                                            className="mt-0 dropdddd"
                                          >
                                            MOS:
                                          </a>
                                          {value.mos && value.mos.length > 0 ? (
                                            value.mos.map((mos, index) => (
                                              <>
                                                <p className="mb-0 ml-1">
                                                  {mos.mos}
                                                </p>
                                                {index ==
                                                value.mos.length - 1 ? (
                                                  ""
                                                ) : (
                                                  <span className="text-dark pl-0 pr-1">
                                                    ,
                                                  </span>
                                                )}
                                              </>
                                            ))
                                          ) : (
                                            <p>No MOS added</p>
                                          )}
                                        </div>
                                        {value.military.length > 0 ? (
                                          value.military[0]
                                            .security_clearance == "" ||
                                          value.military[0]
                                            .security_clearance == undefined ||
                                          value.military[0]
                                            .security_clearance == null ? (
                                            ""
                                          ) : (
                                            <div className="d-flex flex-in-resume-direction-false">
                                              <a
                                                style={{
                                                  fontSize: "15px",
                                                  color: "black",
                                                  fontWeight: "bolder",
                                                }}
                                                href="#"
                                                className="mt-0 dropdddd"
                                              >
                                                Security Clearance:
                                              </a>
                                              {value.military &&
                                              value.military.length > 0
                                                ? value.military.map((cle) => (
                                                    <>
                                                      <p className="mb-0 ml-1">{`${
                                                        cle.security_clearance !==
                                                          null &&
                                                        cle.security_clearance !==
                                                          undefined &&
                                                        cle.security_clearance !==
                                                          ""
                                                          ? cle.security_clearance
                                                          : ""
                                                      }`}</p>
                                                    </>
                                                  ))
                                                : ""}
                                            </div>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="d-flex align-items-end  flex-column justify-content-center"></div>
                                    </div>
                                  ))
                                ) : (
                                  <p className="py-4">
                                    (More military info if provided…)
                                  </p>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {value.language.length > 0 ||
                            value.certification.length > 0 ||
                            value.award.length > 0 ? (
                              <div className=" py-3">
                                <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                                  <div className="d-flex">
                                    <h5 className="m-0">ADDITIONAL SKILLS</h5>
                                    <Link to="/updateresume?id=ADDITIONAL"></Link>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="pr-5 pt-2">
                                    {value.language.length > 0 ? (
                                      <div className="d-flex flex-in-resume-direction-false">
                                        <div className="d-flex align-items-center">
                                          <p
                                            style={{ fontWeight: "bold" }}
                                            className="mr-3"
                                          >
                                            Additional Language(s)
                                          </p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                          {value.language &&
                                          value.language.length > 0 ? (
                                            value.language.map(
                                              (lang, index) => (
                                                <>
                                                  <p className="m-0">{`${
                                                    lang.lang !== null &&
                                                    lang.lang !== undefined &&
                                                    lang.lang !== ""
                                                      ? `${lang.lang} (${lang.level})`
                                                      : ""
                                                  }`}</p>
                                                  {index ==
                                                  value.language.length - 1 ? (
                                                    ""
                                                  ) : (
                                                    <span className="text-dark pl-0 pr-1">
                                                      ,
                                                    </span>
                                                  )}
                                                </>
                                              )
                                            )
                                          ) : (
                                            <p>Level of Fluency</p>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {value.certification.length > 0 ? (
                                      <div className="d-flex flex-in-resume-direction-false">
                                        <p
                                          style={{ fontWeight: "bold" }}
                                          className="mr-3"
                                        >
                                          Certification(s)
                                        </p>
                                        {value.certification &&
                                        value.certification.length > 0 ? (
                                          value.certification.map(
                                            (cert, index) => (
                                              <>
                                                <p>
                                                  {`${
                                                    cert.title !== null &&
                                                    cert.title !== undefined &&
                                                    cert.title !== ""
                                                      ? cert.title
                                                      : ""
                                                  }`}{" "}
                                                  (
                                                  {`${
                                                    cert.date !== null &&
                                                    cert.date !== undefined &&
                                                    cert.date !== ""
                                                      ? dayjs(cert.date).format(
                                                          "MM-DD-YYYY"
                                                        )
                                                      : ""
                                                  }`}
                                                  )
                                                </p>
                                                {index ==
                                                value.certification.length -
                                                  1 ? (
                                                  ""
                                                ) : (
                                                  <span className="text-dark pl-0 pr-1">
                                                    ,
                                                  </span>
                                                )}
                                              </>
                                            )
                                          )
                                        ) : (
                                          <p>Date Earned</p>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {value.award.length > 0 ? (
                                      <div className="d-flex flex-in-resume-direction-false">
                                        <p
                                          style={{ fontWeight: "bold" }}
                                          className="mr-3"
                                        >
                                          Award(s){" "}
                                        </p>
                                        {value.award &&
                                        value.award.length > 0 ? (
                                          value.award.map((awar, index) => (
                                            <>
                                              <p>
                                                {`${
                                                  awar.title !== null &&
                                                  awar.title !== undefined &&
                                                  awar.title !== ""
                                                    ? awar.title
                                                    : ""
                                                }`}{" "}
                                                (
                                                {`${
                                                  awar.date !== null &&
                                                  awar.date !== undefined &&
                                                  awar.date !== ""
                                                    ? dayjs(awar.date).format(
                                                        "MM-DD-YYYY"
                                                      )
                                                    : ""
                                                }`}
                                                )
                                              </p>
                                              {index ==
                                              value.award.length - 1 ? (
                                                ""
                                              ) : (
                                                <span className="text-dark pl-0 pr-1">
                                                  ,
                                                </span>
                                              )}
                                            </>
                                          ))
                                        ) : (
                                          <p>Date Earned</p>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {allvolunteer.length > 0 ? (
                              <div className=" py-3">
                                <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                                  <div className="d-flex">
                                    <h5 className="m-0">VOLUNTEER WORK</h5>
                                    <Link to="/updateresume?id=VOLLUNTEER"></Link>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <table class="table table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {allvolunteer && allvolunteer.length > 0
                                        ? allvolunteer.map((val, ind) => (
                                            <tr>
                                              <td>{val.title}</td>
                                              <td>
                                                {dayjs(val.start).format(
                                                  "MM-DD-YYYY"
                                                )}
                                              </td>
                                              <td>
                                                {val.end !==
                                                "I currently volunteer here"
                                                  ? dayjs(val.end).format(
                                                      "MM-DD-YYYY"
                                                    )
                                                  : "I currently volunteer here"}
                                              </td>
                                            </tr>
                                          ))
                                        : ""}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {name == "1" ? (
                          <div className="col-12 mt-5 d-flex align-items-center justify-content-center p-0">
                            <Link to="/" className="send-invitation fgh">
                              Complete
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
                : "No Resume Created"}
              {/* footer====================================================================== */}
            </div>
          </div>
        </>
      ) : (
        <FullPageLoader1 />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  ResumeReducer: state.ResumeReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ViewResume);

// code for scoop solutions
