import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import Employernav from "../dropdownnav/Employernav";
import Modal from "react-modal";
import InputMask from 'react-input-mask';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
const config = require('../../helpers/config.json');


const AddUser = (props) => {
  const [BilingmodalIsOpen3, setBilingModalIsOpen3] = useState(false);
  const [BilingmodalIsOpen4, setBilingModalIsOpen4] = useState(false);
  const [BilingmodalIsOpenzip, setBilingModalIsOpenzip] = useState(false);


  const [emailagainmodal, setemailagainmodal] = useState(false);

  const [load, setload] = useState(false)
  const [allArray, setallArray] = useState([])
  const [firstname, setfirstname] = useState("")
  const [lastname, setlastname] = useState("")
  const [title, settitle] = useState("")
  const [companyname, setcompanyname] = useState("")
  const [city, setcity] = useState("")
  const [state, setstate] = useState("")
  const [zip, setzip] = useState("")
  const [number, setnumber] = useState("")
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [conpassword, setconpassword] = useState("")
  const [passwordType, setPasswordType] = useState("password");
  const [conpasswordType, setconPasswordType] = useState("password");
  const [notok, setnotok] = useState(false)
  const [create_posting, setcreate_posting] = useState(false)
  const [resume, setresume] = useState(false)
  const [billing, setbilling] = useState(false)
  const [subs, setsubs] = useState("Administrator")
  const [checkpass, setcheckpass] = useState(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)

  const [errzip, seterrzip] = useState(false)
  const statecorrect = (e) => {
    setzip(e)
    const dumbdata = e.split('')
    if (dumbdata.length < 5) {
      seterrzip(true)
    }
    else {
      seterrzip(false)
    }
  }
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const contogglePassword = () => {
    if (conpasswordType === "password") {
      setconPasswordType("text")
      return;
    }
    setconPasswordType("password")
  }
  const addNewRole = (e) => {
    const reg = /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z]+)\.(com+)$/;
    const zipreg = /^\d{0,}$/;
    if (firstname == "") {
      setnotok(true)
    } else if (lastname == "") {
      setnotok(true)
    } else if (title == "") {
      setnotok(true)
    } else if (companyname == "") {
      setnotok(true)
    } else if (state == "") {
      setnotok(true)
    } else if (zip == "") {
      setnotok(true)
    } else if (zipreg.test(zip) === false) {
      setBilingModalIsOpenzip(true)
    } else if (zip.length < 5) {
      setBilingModalIsOpenzip(true)
    } else if (number == "") {
      setnotok(true)
    } else if (number.length < 10) {
      console.log("Invalid number");
    } else if (email == "") {
      setnotok(true)
    } else if (reg.test(email) === false) {
      console.log("Email is invalid");
    } else if (password == "") {
      setnotok(true)
    } else if (checkpass.test(password) === false) {
      setBilingModalIsOpen3(true)
    } else if (conpassword == "") {
      setnotok(true)
    } else if (conpassword !== password) {
      setBilingModalIsOpen4(true)
    } else {
      fetch(`${config['baseUrl']}/registered`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "first_name": firstname,
          "last_name": lastname,
          "position_title": title,
          "company_name": companyname,
          "city": address.split(",")[0],
          "state": state,
          "zip_code": zip,
          "phone": number,
          "email": email,
          "password": password,
          "role": 1,
          "create_posting": create_posting !== true ? Number(0) : Number(1),
          "resume": resume !== true ? Number(0) : Number(1),
          "billing": billing !== true ? Number(0) : Number(1),
          "employer_id": Number(localStorage.getItem("userid")),
          "subs": subs,
          "latitude": corr.lat,
          "longitude": corr.lng

        })
      }).then(res => {

        return res.json();
      }).then((response) => {
        if (response.message !== "The user has been successfully inserted.") {
          if (response.message == "The E-mail already in use") {
            setemailagainmodal(true)
          }
        } else {
          window.location = "/account-management"
        }
      }).catch((error) => {
        const authError = JSON.stringify(error)
        console.log(authError);
      })
    }
  };
  const [address, setaddress] = useState("")
  const [corr, setcorr] = useState({
    lat: null,
    lng: null
  })
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)

    const getZipCode = result?.[0]?.address_components?.find(
      (component) => component?.types?.includes('postal_code')
      )?.long_name;
      if(getZipCode){
        statecorrect(getZipCode)
      }
    const ll = await getLatLng(result[0])
    setstate(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
    setaddress(value)
    setcorr(ll)


  }
  return (
    <>

      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          {/* header======================================================================*/}
          <div className="main-content">
            <div className="col-lg-6 col-12 p-0">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">First Name:  <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                    <input
                      onChange={(e) => setfirstname(e.target.value)}
                      type="text" style={{ width: "100%" }} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Last Name:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                    <input
                      onChange={(e) => setlastname(e.target.value)}
                      type="text" style={{ width: "100%" }} className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Last Name" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Position Title:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                    <input
                      style={{ width: "100%" }}
                      onChange={(e) => settitle(e.target.value)}
                      type="text" className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Position Title" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Company Name:   <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                    <input
                      style={{ width: "100%" }}
                      onChange={(e) => setcompanyname(e.target.value)}
                      type="text" className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Company Name" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group ghg">
                    <label htmlFor="exampleInputPassword1">City:   <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                    <PlacesAutocomplete
                      value={address.split(",")[0]}
                      onChange={setaddress}
                      onSelect={handleSelect}
                      searchOptions={{ componentRestrictions: { country: 'us' } }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            id="exampleInputPassword1"
                            className="form-control employee-input-email job-search"
                            {...getInputProps({
                              placeholder: 'City',
                              className: 'location-search-input',
                            })}
                          />
                          <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item p-2 --active'
                                : 'suggestion-item p-2 ';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">State:   <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                    <select onChange={(e) => setstate(e.target.value)} style={{ width: '60px !important', height: '40px', backgroundColor: "#f2f2f2", borderRadius: 10 }} className="employee-input-ema text-dark" name id
                      value={state}
                    >
                      <option selected value="Select">Select</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA	">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Zip:   <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                    <input
                      style={{ width: "100%" }}
                      onChange={(e) => statecorrect(e.target.value)}
                      value={zip}
                      type="number" className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Zip" />
                    {errzip == true ? <small className="text-danger" style={{ color: "red" }}>*Zip Code requires at least five digits</small> : ""}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Phone Number:   <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                <InputMask {...props} mask="(999) 999-9999" value={number} className="form-control employee-input-email job-search w-100" onChange={(e) => setnumber(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Email:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                <input
                  onChange={(e) => setemail(e.target.value)}
                  type="email" className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Email" />
              </div>
              <div className="form-group">
                <div className="d-flex flex-column">
                  <label htmlFor="exampleInputPassword1">Create Password:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small>  {checkpass.test(password) === true ? <span style={{ color: "green" }}></span> : <span style={{ color: "red" }}><br />Password must contain capital, small letters (Aa-Zz), numbers (0-9) and special characters ( !@#$%^&*)</span>}</label>
                  <div style={{ position: "relative" }}>
                    <input
                      onChange={(e) => setpassword(e.target.value)}
                      id="password-field4" type={passwordType} value={password} className="employee-input-email job-search" name="password" placeholder="Create Password" />
                    <span toggle="#password-field4" onClick={togglePassword}> {passwordType === "password" ? <i style={{ top: 15 }} className="fa fa-eye-slash field-icon toggle-password"></i> : <i style={{ top: 15 }} className="fa fa-eye field-icon toggle-password"></i>}</span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex flex-column">
                  <label htmlFor="exampleInputPassword1">Confirm Password:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                  <input
                    onChange={(e) => setconpassword(e.target.value)}
                    id="password-field5" type={conpasswordType} value={conpassword} className="employee-input-email job-search" name="password" placeholder="Confirm Password" />
                  <span toggle="#password-field5" onClick={contogglePassword}> {conpasswordType === "password" ? <i className="fa fa-eye-slash field-icon toggle-password "></i> : <i className="fa fa-eye field-icon toggle-password "></i>}</span>
                </div>
              </div>
            </div>
            <div className="main-content-session">
              <p>Role <span>*</span></p>
              <div className="role">
                <label>
                  <input type="radio" onChange={(e) => setsubs("Administrator")} name="radio" defaultChecked />
                  <span className="sp-clr">Administrator</span>
                </label>
                <label>
                  <input type="radio" onChange={(e) => setsubs("Standard User")} name="radio" />
                  <span className="sp-clr">Standard User</span>
                </label>
              </div>
            </div>
            <div className="main-content-session">
              <p>Permission being granted <span>*</span></p>
              <div className="role role2">
                <label>
                  <input type="checkbox" name="radio1" onChange={() => setcreate_posting(!create_posting)} />
                  <span className="sp-clr">Able to create and manage job postings</span>
                </label>
                <label>
                  <input type="checkbox" name="radio1" onChange={() => setresume(!resume)} />
                  <span className="sp-clr">Able to review contact information provided by the
                    candidate</span>
                </label>
                <label>
                  <input type="checkbox" name="radio1" onChange={() => setbilling(!billing)} />
                  <span className="sp-clr">Able to review and edit billing information</span>
                </label>
              </div>
            </div>
            <div className="main-content-session inner-session">
              <Link onClick={() => addNewRole()} className="send-invitation fgh">Send Invitation</Link>
              <Link to="/account-management" className="cancel-button fgh" style={{ marginLeft: "10px !important" }}>Cancel</Link>
            </div>
            <div className="notice-line">
              <p className="text-dark">When you click the ‘Send Invitation’ button, you are giving this new user access to permissions
                that you have selected
                above. Should you decide to edit or remove these authorizations later, you can do so by clicking
                the ‘Edit’ or ‘Remove’
                buttons on the Account Management Page.</p>
            </div>
          </div>
        </div>
      </div>



      <Modal
        isOpen={BilingmodalIsOpen3}
        onRequestClose={() => setBilingModalIsOpen3(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "500px",
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h4>password is invalid</h4>
          <div className="d-flex align-items-center">
            <button onClick={() => setBilingModalIsOpen3(false)} className="employeer-sign-off-btn">Ok</button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpen4}
        onRequestClose={() => setBilingModalIsOpen4(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "360px",
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h4>Confirm password didn't match</h4>
          <div className="d-flex align-items-center">
            <button onClick={() => setBilingModalIsOpen4(false)} className="employeer-sign-off-btn">Ok</button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={emailagainmodal}
        onRequestClose={() => setemailagainmodal(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h4>Email is already in use</h4>
        </div>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);


// code for scoop solutions