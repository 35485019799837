import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Empty } from "antd";
import { ReportJobAction } from "../../actions/Reportjobaction";
import "./employeejobsearchresult.css";
import { AppliedJob } from "../../actions/Appliedjobaction";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import { createSearch } from "../../actions/Jobsearchaction";
import parse from "html-react-parser";
import Employernav from "../dropdownnav/Employernav";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import useWindowDimensions from "../administrator/innerwidthcom";
import { filterOptions } from "./helper";
import PlacesAutocomplete from "react-places-autocomplete";
// import axios from "axios";
import ReactGA from "react-ga4";
import opencage from "opencage-api-client";

const config = require("../../helpers/config.json");

Modal.setAppElement("#root");
const EmployeeJobSearchResult = (props) => {
  const location = useLocation();
  // const params = useParams();
  const history = useHistory();
  let tempLocation = "";

  const { search } = location;

  const jobTitles = new URLSearchParams(search).get("jobtitle");
  const jobTitle = decodeURIComponent(jobTitles);
  const Locations = new URLSearchParams(search).get("location");
  let Location = decodeURIComponent(Locations);
  Location = Location?.split(",")
  Location?.splice(Location.length - 1);

  const searches = new URLSearchParams(search).get("searched");
  const searched = decodeURIComponent(searches);

  const { width } = useWindowDimensions();
  const [loader, setLoader] = useState(true);
  const [tempLoader, setTempLoader] = useState(true);

  const [modalwidth, setmodalwidth] = useState(450);
  const [appliedarray, setappliedarray] = useState([]);

  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [BilingmodalIsOpen4, setBilingModalIsOpen4] = useState(false);
  const [professional_summarycount, setprofessional_summarycount] =
    useState("");
  const [reason, setreason] = useState(
    "Offensive and/or discriminatory content"
  );

  const [details, setdetails] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [BilingmodalIsOpentrue, setBilingmodalIsOpentrue] = useState(false);
  const [thisJob, setthisJob] = useState(false);
  const [BilingmodalIsOpentrue1, setBilingmodalIsOpentrue1] = useState(false);

  const [jjid, setjjid] = useState("");
  const [loading, setloading] = useState(false);
  const [slice, setSlice] = useState([]);

  const [sal, setsal] = useState("");

  const [jtitle, setjtitle] = useState("");
  const [jlocation, setjlocation] = useState("");
  const [jCity, setjCity] = useState("");
  const [jState, setjState] = useState("");

  const [searchres, setsearchres] = useState([]);
  const [id, setId] = useState("");

  const [resjid, setresjid] = useState("");
  const [saveerrormessage, setsaveerrormessage] = useState("");

  const [noOfElement, setnoOfElement] = useState(20);

  const [sortBy, setSortBy] = useState(filterOptions.sortBy[0].value);
  const [datePosted, setDatePosted] = useState(
    filterOptions.datePosted[0].value
  );
  const [address, setaddress] = useState(Location !== "null" ? Location : "");
  const [distance, setDistance] = useState(filterOptions.distance[0].value);
  const [isFilterDistanceFilterDisable, setIsFilterDistanceFilterDisable] = useState(false);
  const [jobType, setJobType] = useState(filterOptions.jobType[0].value);
  const [salary, setSalary] = useState(filterOptions.salary[0].value);
  const [experienceLevel, setExperienceLevel] = useState(
    filterOptions.experienceLevel[0].value
  );

  const [allArray, setallArray] = useState([]);

  // functions
  const getData = () => {
    fetch(
      `${config["baseUrl"]}/application/history/${localStorage.getItem(
        "userid"
      )}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        const alljobs = response.job;
        var temp = [];
        temp = [...response.job];
        setappliedarray(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const setjobidfunc = (e) => {
    setBilingModalIsOpen4(true);
    setjjid(e);
  };
  const CharacterCount = (e) => {
    setdetails(e.target.value);
    setprofessional_summarycount(e.target.value.length);
  };
  const createsearch = (e) => {
    props.ReportJobAction(jjid, reason, details, jobTitle, Location);
  };

  const jobsearch = (location) => {
    // window.location = `/search-job?jobtitle=${jtitle}&location=${jlocation}`;
    // const address1 =  localStorage.getItem("address")
    // const route = `/search-job?jobtitle=${jtitle}&location=${
    //   address ? location || jlocation : address1
    // }`;
    // const getValue =
    //   location?.split(",").length > 2
    // ? `${location?.split(",")[0]},${location?.split(",")[1]}`
    // : location?.split(",")[0];

    const route = `/search-job?jobtitle=${jtitle}&location=${location || address}`;

    // if(!address) setaddress(address1)

    if(!location){
      setjlocation("null");
      setjState("null");
      setjCity("null")
    }

    // let location1 = null;
    // let city = null;
    // let state = null;

    // const getValue = location?.split(",")
    //   getValue.splice(getValue.length - 1)

    //   if(getValue.length > 2){
    //     location1 = getValue[0]
    //     city = getValue[getValue.length - 2]
    //     state = stateShortNames[getValue[getValue.length - 1]?.toLowerCase()]
    //   } else if (getValue.length > 1){
    //     city = getValue[0]
    //     state = stateShortNames[getValue[1]?.toLowerCase()]
    //   } else {
    //     state = stateShortNames[getValue[0]?.toLowerCase()]
    //   }

    //   if(!state?.length) state = null;

    // setjlocation(location1);
    // setjState(state);
    // setjCity(city);

    if (!jlocation && !jCity && jState) {
      setIsFilterDistanceFilterDisable(true)
      setDistance("")
    } else if(!location){
      setIsFilterDistanceFilterDisable(true)
      setDistance("")
    } else {
      setIsFilterDistanceFilterDisable(false)
    }


    ReactGA.event({
      category: `Employer Search Job, Search Button Click`,
      label:
        localStorage.getItem("username")?.length > 1
          ? `Employeer Name: ${localStorage.getItem(
              "username"
            )} searched ${jtitle} `
          : `Un Authenticated user searched ${jtitle}`,
      action: `visit at Job Search page`,
      nonInteraction: true,
    });

    history.push(route);
    sorterFilter();
  };

  const ApplyJob = (id, title, res) => {
    let responsibility = searchres?.[0]?.responsibility?.filter(
      (idd) => idd?.job_id == id
    )?.[0]?.responsibility;
    fetch(`${config["baseUrl"]}/jobs/apply/insert`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        candidate_id: Number(localStorage.getItem("userid")),
        candidate_name: localStorage.getItem("username"),
        job_id: id,
        job_applied: title,
        email: props.GetUserReducer.users?.email || "",
        description: res?.description || "",
        responsibility: responsibility,
      }),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
        }
        return res.json();
      })
      .then((response) => {
        if (response.message == "Resume is not Available..") {
          window.location = "/createresume";
        }
        if (response.message == "Applied") {
          getData();
          // window.location.reload(false)
        }
        if (response.message == "Successfully Applied For Job.") {
          getData();
          // window.location = `/search-job?jobtitle=${jobTitle}&location=${Location}${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?``:`&if=${window.location.href.search('false')== -1 ?true:false}`}`
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const savedjob1 = async (id, fav) => {
    await fetch(`${config["baseUrl"]}/SaveJob`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: Number(localStorage.getItem("userid")),
        job_id: Number(id),
        fav: fav,
      }),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
        }
        return res.json();
      })
      .then((response) => {
        if (response.message == "Save Job") {
          fetch(
            `${config["baseUrl"]}/getJob/${localStorage.getItem("userid")}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            }
          )
            .then((res) => res.json())
            .then((response) => {
              if (response.message == "No Save Job...") {
                setallArray([]);
              } else {
                const alljobs = response.saveJob;
                var temp = [];
                temp = [...response.saveJob];
                setallArray(temp);
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const savedjob = async (id) => {
    await fetch(`${config["baseUrl"]}/SaveJob`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: Number(localStorage.getItem("userid")),
        job_id: Number(resjid),
        fav: 1,
      }),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
        }
        return res.json();
      })
      .then((response) => {
        if (response.message == "Save Job") {
          setBilingmodalIsOpentrue(false);
          setthisJob(true);
        } else if (response.message == "Already in Save Jobs") {
          setsaveerrormessage(response.message);
          setBilingmodalIsOpentrue(false);
          setBilingmodalIsOpentrue1(true);
        } else if (response.message == "Unable to Save Job...") {
          setsaveerrormessage(response.message);
          setBilingmodalIsOpentrue(false);
          setBilingmodalIsOpentrue1(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sorterFilter = async () => {
    try {
      setloading(true);
      let _distance = null;
      let geoLatitude = null;
      let geoLongitude = null;

      if (distance) {
        _distance = distance;
        geoLatitude = latitude || null;
        geoLongitude = longitude || null;
      }

      // let locationToSendAPI;
      // if(Location.split(" ").length === 1){
      //   locationToSendAPI = stateShortNames[Location]
      // } else locationToSendAPI = Locations.split(",")[0];

      let url = `${config["baseUrl"]}/job/getJobByCompany/${null}/${
        jobTitles || null
      }/${jlocation || null}/${!jlocation ? jCity : null}/${!jlocation && !jCity ? jState : null}/${experienceLevel || null}/${
        jobType || null
      }/${sortBy || null}/${datePosted || null}/${
        salary || null
      }/${geoLatitude}/${geoLongitude}/${_distance}`;

      await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.job && response.job.length > 0) {
            const sData = response;
            setId(sData.job.filter((data) => data.status == 1)[0]?.id);
            const temp = [];
            temp.push(sData);
            setsearchres(temp);

            setSlice(
              temp.length > 0 && temp[0].job && temp[0].job.length > 0
                ? temp[0].job
                    .filter((data) => data.status !== 0)
                    .slice(0, noOfElement)
                : []
            );
          } else {
            setId("");
            const temp = [];
            setsearchres([]);
            setSlice([]);
          }
        });

      setloading(false);
    } catch (error) {
      setId("");
      setsearchres([]);
      setSlice([]);
      console.log("sorter ~ error:", error);
    }
  };

  const loadmore = () => {
    setnoOfElement(noOfElement + 20);
  };


  const stateShortNames = {
    alabama: "AL",
    alaska: "AK",
    arizona: "AZ",
    arkansas: "AR",
    california: "CA",
    colorado: "CO",
    connecticut: "CT",
    delaware: "DE",
    "district of columbia": "DC",
    florida: "FL",
    georgia: "GA",
    hawaii: "HI",
    idaho: "ID",
    illinois: "IL",
    indiana: "IN",
    iowa: "IA",
    kansas: "KS",
    kentucky: "KY",
    louisiana: "LA",
    maine: "ME",
    maryland: "MD",
    massachusetts: "MA",
    michigan: "MI",
    minnesota: "MN",
    mississippi: "MS",
    missouri: "MO",
    montana: "MT",
    nebraska: "NE",
    nevada: "NV",
    "new hampshire": "NH",
    "new jersey": "NJ",
    "new mexico": "NM",
    "new york": "NY",
    "north carolina": "NC",
    "north dakota": "ND",
    ohio: "OH",
    oklahoma: "OK",
    oregon: "OR",
    pennsylvania: "PA",
    "rhode island": "RI",
    "south carolina": "SC",
    "south dakota": "SD",
    tennessee: "TN",
    texas: "TX",
    utah: "UT",
    vermont: "VT",
    virginia: "VA",
    washington: "WA",
    "west virginia": "WV",
    wisconsin: "WI",
    wyoming: "WY",
  };

  const handleSelect = async (value) => {
    let location = null;
    let city = null;
    let state = null;

      const getValue = value?.split(",")
      getValue.splice(getValue.length - 1)

      if(getValue.length > 2){
        location = getValue[0]
        city = getValue[getValue.length - 2]
        state = stateShortNames[getValue[getValue.length - 1]?.toLowerCase()]
      } else if (getValue.length > 1){
        city = getValue[0]
        state = stateShortNames[getValue[1]?.toLowerCase()]
      } else {
        state = stateShortNames[getValue[0]?.toLowerCase()]
      }

      if(!state?.length) state = null;

    setaddress(getValue);
    jobsearch(getValue);
    setjlocation(location);
    setjState(state);
    setjCity(city);

    if (!location && !city && state) {
      setIsFilterDistanceFilterDisable(true)
      setDistance("")
    } else if (!location && !city && !state){
      setIsFilterDistanceFilterDisable(true)
      setDistance("")
    } else {
      setIsFilterDistanceFilterDisable(false)
    }

    await opencage
      .geocode({
        q: `${getValue}`,
        language: "en",
        key: process.env.REACT_APP_OPENCAGE_API_KEY,
      })
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry;
        setlatitude(lat);
        setlongitude(lng);
      })
      .catch((err) => {
        console.log(err, "error123");
      });
  };

  useEffect(() => {
    if (width <= 900) {
      setmodalwidth("340px");
    }
    getData();
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: `Page Visit`,
      label:
        localStorage.getItem("username")?.length > 1
          ? `Employeer Name: ${localStorage.getItem(
              "username"
            )} visited Job Search page `
          : `Un Authenticated user visited Job Search page`,
      action: `visit at Job Search page`,
      nonInteraction: true,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false);
      setSlice(
        searchres.length > 0 && searchres[0].job && searchres[0].job.length > 0
          ? searchres[0].job
              .filter((data) => data.status !== 0)
              .slice(0, noOfElement)
          : []
      );
      setId(searchres[0]?.job.filter((data) => data?.status == 1)[0]?.id);
    }, 2000);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const location = url.searchParams.get("location");

    let location1 = null;
    let city = null;
    let state = null;

      const getValue = location?.split(",")
      getValue.splice(getValue.length - 1)

      if(getValue.length > 2){
        location1 = getValue[0]
        city = getValue[getValue.length - 2]
        state = stateShortNames[getValue[getValue.length - 1]?.toLowerCase()]
      } else if (getValue.length > 1){
        city = getValue[0]
        state = stateShortNames[getValue[1]?.toLowerCase()]
      } else {
        state = stateShortNames[getValue[0]?.toLowerCase()]
      }

      if(!state?.length) state = null;

      setjlocation(location1);
      setjState(state);
      setjCity(city);

      if (!location1 && !city && state) {
        setIsFilterDistanceFilterDisable(true)
        setDistance("")
      } else if(!location1 && !city && !state){
        setIsFilterDistanceFilterDisable(true)
        setDistance("")
      } else {
        setIsFilterDistanceFilterDisable(false)
      }

    //   if(!location){
    //   navigator.geolocation.getCurrentPosition(
    //     async (position) => {
    //       setlatitude(position.coords.latitude);
    //       setlongitude(position.coords.longitude);
    //       if (
    //         position.coords.latitude &&
    //         position.coords.longitude &&
    //         searched == 0
    //       ) {
    //         await opencage
    //           .geocode({
    //             q: `${position.coords.latitude}, ${position.coords.longitude}`,
    //             language: "en",
    //             key: process.env.REACT_APP_OPENCAGE_API_KEY,
    //           })
    //           .then(({ results }) => {
    //             const getValue = `${
    //               results[0]?.components?.city ||
    //               results[0]?.components?.county?.replace(" County", "") || results[0]?.components?.state ||
    //               ""
    //             }, ${results[0]?.components?.state_code || ""} `;
    //             setaddress(getValue);
    //             localStorage.setItem('address', getValue)
    //             let jobtitle = window.location.search
    //               .split("&")[0]
    //               ?.split("=")[1];
    //             setDistance(25);
    //             history.push(
    //               `/search-job?jobtitle=${jobtitle}&location=${getValue}`
    //             );
    //             window.location.reload();
    //           })
    //           .catch((err) => {
    //             console.log(err, "error123");
    //           });
    //       }
    //     },
    //     (err) => {
    //       if (searched == 0) {
    //         console.log("err", err);
    //         return axios
    //           .get("https://ipinfo.io/?token=782457d915c643")
    //           .then(async (res) => {
    //             // console.log("res is ", res);
    //             const [lat, long] = res.data.loc.split(",");
    //             setlatitude(lat);
    //             setlongitude(long);
    //             await opencage
    //               .geocode({
    //                 q: `${lat}, ${long}`,
    //                 language: "en",
    //                 key: process.env.REACT_APP_OPENCAGE_API_KEY,
    //               })
    //               .then(({ results }) => {
    //                 // console.log("result pak is ", results);
    //                 // const value = results[0].formatted;
    //                 const getValue = `${
    //                   results[0]?.components?.city ||
    //                   results[0]?.components?.county.replace(" County", "") || results[0]?.components?.state ||
    //                   ""
    //                 }, ${results[0]?.components?.state_code || ""} `;

    //                 // console.log("value in err ", results);
    //                 setaddress(getValue);
    //                 localStorage.setItem('address', getValue)
    //                 let jobtitle = window.location.search
    //                   .split("&")[0]
    //                   ?.split("=")[1];
    //                 setDistance(25);
    //                 history.push(
    //                   `/search-job?jobtitle=${jobtitle}&location=${getValue}`
    //                 );
    //                 // window.location.reload();
    //               })
    //               .catch((err) => {
    //                 console.log(err, "error123");
    //               });
    //           });
    //       }
    //     }
    //   );
    // } else if (searched) {
       opencage
        .geocode({
          q: `${location}`,
          language: "en",
          key: process.env.REACT_APP_OPENCAGE_API_KEY,
        })
        .then(({ results }) => {
          const { lat, lng } = results[0].geometry;
          setlatitude(lat);
          setlongitude(lng);
          // setDistance(25)
          localStorage.setItem('address', location)
        })
        .catch((err) => {
          console.log(err, "error123");
        });
    // }
    if (jtitle == "") {
      setjtitle(jobTitle !== "null" ? jobTitle : "");
    }
    // if (jlocation == "") {
    //   setjlocation(Location !== "null" ? Location : "");
    // }
    // if (jlocation == "") {
    //   setjlocation(Location !== "null" ? Location : "");
    // }
    setloading(true);
    // var jobt;
    // var jobl;
    // if (jobTitle !== null && jobTitle !== undefined && jobTitle !== "") {
    //   var len = jobTitle?.split(" ").length;
    //   if (len == 2) {
    //     jobt = jobTitle?.split(" ")[0];
    //   } else if (len == 3) {
    //     jobt = jobTitle?.split(" ")[0] + " " + jobTitle?.split(" ")[2];
    //   } else {
    //     jobt = jobTitle;
    //   }
    // } else {
    //   jobt = "";
    // }
    // if (Location !== null && Location !== undefined && Location !== "") {
    //   var len = Location?.split(" ").length;
    //   if (len == 2) {
    //     jobl = Location?.split(" ")[0];
    //   } else if (len == 3) {
    //     jobl = Location?.split(" ")[0] + " " + Location?.split(" ")[2];
    //   } else {
    //     jobl = Location;
    //   }
    // } else {
    //   jobl = "";
    // }

    sorterFilter();

    // fetch(`${config["baseUrl"]}/job/search`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     jobtitle: jobTitle,
    //     location: Location,
    //   }),
    // })
    //   .then((res) => {
    //     setTemp((prev) => !prev);
    //     console.log("nulla responses is ", res, "Searches are ", searches);

    //     if (res.status !== 200) {
    //       setloading(false);
    //       setsearchres([]);
    //       setId("");
    //       return;
    //     }
    //     return res.json();
    //   })
    //   .then((response) => {
    //     setTemp((prev) => !prev);

    //     setloading(false);
    //     console.log("responses is ", response, "Searches are ", searches);
    //     let sData = response;
    //     searchres.push(sData);
    //     setsearchres(searchres);
    //     if (response.job && response.job.length > 0) {
    //       setId(response.job.filter((data) => data.status == 1)[0].id);
    //     }
    //   })
    //   .catch((error) => {
    //     setTemp((prev) => !prev);

    //     setloading(false);
    //     setsearchres([]);
    //     setId("");
    //     console.log(error);
    //   });

    fetch(`${config["baseUrl"]}/job/getSalary`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => setsal(response.salary));

    fetch(`${config["baseUrl"]}/getJob/${localStorage.getItem("userid")}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        const alljobs = response.saveJob;
        var temp = [];
        temp = [...response.saveJob];
        setallArray(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [tempLoader]);

  useEffect(() => {
    sorterFilter();
  }, [
    jobTitles,
    Locations,
    sortBy,
    datePosted,
    distance,
    jobType,
    salary,
    experienceLevel,
    latitude,
    longitude,
    tempLoader,
  ]);

  useEffect(() => {
    // slice =
    //   searchres.length > 0 && searchres[0].job && searchres[0].job.length > 0
    //     ? searchres[0].job
    //         .filter((data) => data.status !== 0)
    //         .slice(0, noOfElement)
    //     : [];
    setSlice(
      searchres.length > 0 && searchres[0].job && searchres[0].job.length > 0
        ? searchres[0].job
            .filter((data) => data.status !== 0)
            .slice(0, noOfElement)
        : []
    );
  }, [searchres.length, window.location, tempLoader, noOfElement]);

  // useEffect(() => {
    // console.log(
    //   "location?.search",
    //   location?.search,
    //   location?.search?.split("location")?.[1]
    // );
    // console.log(location?.search?.split("location")?.[1].length > 2)
    // if (location?.search?.split("location")?.[1].length > 2 && !distance) {
    //   setDistance(25);
    // }
    // setDistance(localStorage.getItem("distance"));
    // setaddress(location ?? jlocation ?? localStorage.getItem("search"))
    // return () => localStorage.removeItem("distance")
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem("distance", distance)
  // }, [distance])

  // useEffect(() => {
    // localStorage.setItem("search",address)
  // }, [address]);

  if (loader) {
    return <FullPageLoader1 />;
  }

  return (
    <>
      {!loader &&
      props.AppliedJobReducer.loading == true &&
      loading == false ? (
        <>
          <div className="container-fluid" id="Employee">
            <div className="container">
              {props.GetUserReducer.users &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== "" &&
              props.GetUserReducer.users.role !== 0 ? (
                <Employernav />
              ) : (
                <Dropdownnav />
              )}
              {/* header======================================================================*/}
              <div className="col-12 main-content">
                <div className="row align-items-center job-search-result-selects">
                  <div className="col-lg-12 d-flex align-items-center job-search-filter-selects">
                    <button>
                      <i
                        class="fa fa-filter hirehome-21"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <select
                      value={sortBy}
                      className="mx-1 w-100"
                      onChange={(e) => {
                        setSortBy(e.target.value);
                      }}
                    >
                      {filterOptions.sortBy.map(({ label, value }) => (
                        <option value={value} key={value}>
                          {" "}
                          {label}
                        </option>
                      ))}
                    </select>
                    <select
                      value={datePosted}
                      onChange={(e) => {
                        setDatePosted(e.target.value);
                      }}
                      className="mx-1 w-100"
                    >
                      {filterOptions.datePosted.map(({ label, value }) => (
                        <option value={value} key={value}>
                          {label}
                        </option>
                      ))}
                    </select>

                    <select
                      value={distance}
                      className="mx-1 w-100"
                      style={isFilterDistanceFilterDisable ? {background:'gray'} : {}}
                      disabled={isFilterDistanceFilterDisable}
                      onChange={(e) => {
                        setDistance(e.target.value);
                      }}
                    >
                      {filterOptions.distance.map(({ label, value }) => (
                        <option value={value} key={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <select
                      value={jobType}
                      onChange={(e) => {
                        setJobType(e.target.value);
                      }}
                      className="mx-1 w-100"
                    >
                      {filterOptions.jobType.map(({ label, value }) => (
                        <option value={value} key={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <select
                      value={salary}
                      className="mx-1 w-100"
                      onChange={(e) => {
                        setSalary(e.target.value);
                      }}
                    >
                      {filterOptions.salary.map(({ label, value }) => (
                        <option value={value} key={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <select
                      value={experienceLevel}
                      onChange={(e) => {
                        setExperienceLevel(e.target.value);
                      }}
                      className="mx-1 w-100"
                    >
                      {filterOptions.experienceLevel.map(({ label, value }) => (
                        <option value={value} key={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-12 px-0">
                    <div className="Mob-search-employee mt-2">
                      <div style={{ position: "relative" }}>
                        <i
                          style={{ left: 10, top: 15, position: "absolute" }}
                          className="hirehome-21 fa fa-search search-icon"
                          aria-hidden="true"
                        />
                        <input
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              jobsearch();
                            }
                          }}
                          type="text"
                          style={{ width: "100%", paddingLeft: "30px" }}
                          defaultValue={jobTitle}
                          onChange={(e) => setjtitle(e.target.value)}
                          className="job-search m-0 w-100"
                          placeholder="Job Title or Keyword"
                        />
                      </div>

                      <div style={{ position: "relative" }}>
                        <i
                          style={{ left: 10, top: 15, position: "absolute" }}
                          className="hirehome-21 fa fa-map-marker marker-icon"
                          aria-hidden="true"
                        />
                        <PlacesAutocomplete
                          value={address}
                          onChange={(e) => {
                            setaddress(e);
                            tempLocation = e;
                          }}
                          onSelect={handleSelect}
                          searchOptions={{
                            componentRestrictions: { country: "us" },
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                id="exampleInputPassword1"
                                className="form-control employee-input-email job-search"
                                {...getInputProps({
                                  placeholder: "City or Zip Code",
                                  className: "city-search m-0 w-100",
                                })}
                              />
                              <div
                                className={
                                  "autocomplete-dropdown-container mt-1 " +
                                  (suggestions?.length ? "py-2" : "")
                                }
                              >
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item p-2 --active"
                                    : "suggestion-item p-2 ";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </div>
                    <div className="search-result-3-scrool-box-main">
                      <div className="d-flex justify-content-between">
                        <h5 className="mt-4 mb-0">
                          Results:{" "}
                          {slice.length > 0
                            ? slice.filter((data) => data.status !== 0).length
                            : "0"}{" "}
                          jobs
                        </h5>
                        <p
                          className="mb-0 mt-4 align-self-end hoverclearcolor"
                          onClick={() => {
                            // const url = new URL(window.location.href);
                            // const location = url.searchParams.get("location");
                            // const searched = url.searchParams.get("searched");
                            window.location.href = `/search-job?jobtitle=&location=`;
                            // window.location.href = `/search-job?jobtitle=&location=${location}&searched=${searched}`;
                          }}
                          style={{
                            cursor: "pointer",
                            color: "var(--main-color)",
                          }}
                        >
                          Clear Search
                        </p>
                      </div>
                      <div className="search-result-3-scrool-box mt-3">
                        <div
                          className="nav flex-column nav-pills"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <div
                            className="nav flex-column nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <div
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0) 0px 0px 0px",
                              }}
                              className="Mob-search-employee search-resume-no-data"
                            >
                              {slice && slice.length > 0 ? (
                                slice.map((res) =>
                                  res.status !== 0 ? (
                                    <>
                                      <div
                                        className="job-search-result-left-box d-flex align-items-center justify-content-between"
                                        key={res.id}
                                      >
                                        <div className="searchjob-result-pices-left">
                                          <div className="d-flex flex-column justify-content-center">
                                            <Link
                                              to={`/search-job-detail?id=${res.id}`}
                                              className="m-0"
                                            >
                                              {res.job_title !== null &&
                                              res.job_title !== undefined &&
                                              res.job_title !== ""
                                                ? res.job_title
                                                : ""}
                                            </Link>
                                            <p className="mb-0 ml-1">
                                              {res.company_name !== null &&
                                              res.company_name !== undefined &&
                                              res.company_name !== ""
                                                ? res.company_name
                                                : ""}
                                            </p>
                                          </div>
                                          <p className="mt-1">
                                            {res.city !== null &&
                                            res.city !== undefined &&
                                            res.city !== ""
                                              ? res.city
                                              : ""}
                                            ,<span className="ml-1"></span>
                                            {res.state !== null &&
                                            res.state !== undefined &&
                                            res.state !== ""
                                              ? res.state
                                              : ""}
                                            <span className="ml-1"></span>
                                            {res.employment !== null &&
                                            res.employment !== undefined &&
                                            res.employment !== ""
                                              ? res.employment
                                              : ""}
                                          </p>
                                        </div>
                                        {localStorage.getItem("userid") !==
                                          null &&
                                        localStorage.getItem("userid") !==
                                          undefined &&
                                        localStorage.getItem("userid") !==
                                          "" ? (
                                          <div
                                            className="d-flex align-items-center justify-content-end"
                                            style={{
                                              cursor: "pointer !important",
                                            }}
                                          >
                                            <div
                                              className="main-content-session inner-session d-flex align-items-center"
                                              style={{
                                                cursor: "pointer !important",
                                              }}
                                            >
                                              {props.GetUserReducer.users &&
                                              props.GetUserReducer.users
                                                .role !== "" &&
                                              props.GetUserReducer.users
                                                .role !== null &&
                                              props.GetUserReducer.users
                                                .role !== undefined &&
                                              props.GetUserReducer.users.role ==
                                                0 ? (
                                                <>
                                                  {appliedarray &&
                                                  appliedarray.length > 0 ? (
                                                    appliedarray.filter(
                                                      (data) =>
                                                        data.job_id == res.id &&
                                                        data.candidate_id ==
                                                          localStorage.getItem(
                                                            "userid"
                                                          )
                                                    ).length > 0 ? (
                                                      <p
                                                        style={{
                                                          cursor:
                                                            "pointer !important",
                                                        }}
                                                        className="send-invitation"
                                                      >
                                                        APPLIED
                                                      </p>
                                                    ) : (
                                                      <p
                                                        style={{
                                                          cursor:
                                                            "pointer !important",
                                                        }}
                                                        onClick={(
                                                          jobid,
                                                          jobtitle
                                                        ) =>
                                                          ApplyJob(
                                                            res.id,
                                                            res.job_title,
                                                            res
                                                          )
                                                        }
                                                        className="send-invitation"
                                                      >
                                                        APPLY NOW
                                                      </p>
                                                    )
                                                  ) : (
                                                    <p
                                                      style={{
                                                        cursor:
                                                          "pointer !important",
                                                      }}
                                                      onClick={(
                                                        jobid,
                                                        jobtitle
                                                      ) =>
                                                        ApplyJob(
                                                          res.id,
                                                          res.job_title,
                                                          res
                                                        )
                                                      }
                                                      className="send-invitation"
                                                    >
                                                      APPLY NOW
                                                    </p>
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="d-flex align-items-center justify-content-end"
                                            style={{
                                              cursor: "pointer !important",
                                            }}
                                          >
                                            <div
                                              className="main-content-session inner-session"
                                              style={{
                                                cursor: "pointer !important",
                                                position: "relative",
                                                top: "-16px",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  cursor: "pointer !important",
                                                }}
                                                onClick={() =>
                                                  setBilingModalIsOpen(true)
                                                }
                                                className="send-invitation"
                                              >
                                                Apply Now
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )
                                )
                              ) : (
                                <Empty />
                              )}
                            </div>

                            <div className="Mob-search-employee-opp">
                              {slice && slice.length > 0 ? (
                                slice.map((res) =>
                                  res.status !== 0 ? (
                                    <a
                                      key={res?.id}
                                      className="nav-link job-search-result-left-box border-bottom m-0 p-0"
                                      id="v-pills-home-tab"
                                      data-toggle="pill"
                                      onClick={(e) => setId(res.id)}
                                      role="tab"
                                      aria-controls="v-pills-home"
                                      aria-selected="true"
                                    >
                                      <div
                                        style={{
                                          border:
                                            id == res.id
                                              ? "2px solid orange"
                                              : "",
                                        }}
                                        className=" d-flex flex-column p-2"
                                      >
                                        <h4 className="jobsearchname">
                                          {res.job_title !== null &&
                                          res.job_title !== undefined &&
                                          res.job_title !== ""
                                            ? res.job_title
                                            : ""}
                                        </h4>
                                        <h6
                                          style={{
                                            color:
                                              id == res.id ? "white" : "orange",
                                          }}
                                          className="jobsearchcompany"
                                        >
                                          {res.company_name !== null &&
                                          res.company_name !== undefined &&
                                          res.company_name !== ""
                                            ? res.company_name
                                            : ""}
                                        </h6>
                                        <span className="custom-price-tag-result">
                                          {res.display_amount !== 0 ? (
                                            <>
                                              {`${
                                                res.pay_rate_minimum !== ""
                                                  ? `${res.pay_rate_minimum}`
                                                  : ""
                                              } ${
                                                res.pay_rate_maximum !== ""
                                                  ? "To"
                                                  : ""
                                              } ${
                                                res.pay_rate_maximum !== ""
                                                  ? `${res.pay_rate_maximum}`
                                                  : ""
                                              }`}{" "}
                                              <span className="dropdddd">
                                                {" "}
                                                {res.pay_rate_minimum !== "" &&
                                                res.pay_rate_maximum !== "" &&
                                                res.pay_rate_type !== ""
                                                  ? `(${res.pay_rate_type})`
                                                  : ""}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        <p className="pb-2 pricejonb whv">
                                          {res.city !== null &&
                                          res.city !== undefined &&
                                          res.city !== ""
                                            ? res.city
                                            : ""}
                                          ,<span className="ml-1"></span>
                                          {res.state !== null &&
                                          res.state !== undefined &&
                                          res.state !== ""
                                            ? res.state
                                            : ""}
                                          <span className="ml-1"></span> |{" "}
                                          {res.employment !== null &&
                                          res.employment !== undefined &&
                                          res.employment !== ""
                                            ? res.employment
                                            : ""}{" "}
                                        </p>
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )
                                )
                              ) : (
                                <p style={{ color: "#f48a20" }}>
                                  No jobs found
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100 ">
                        <div className="w-100 d-flex align-items-center justify-content-center">
                          <div className="main-content-session inner-session">
                            <Link
                              onClick={() => loadmore()}
                              className="send-invitation"
                            >
                              Load More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8 col-12 res-dis-none">
                    <div className="d-flex search-session w-100 pt-4 pb-0">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-5 px-1">
                            <div style={{ position: "relative" }}>
                              <i
                                className="fa fa-search search-icon"
                                aria-hidden="true"
                              />
                              <input
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    jobsearch();
                                  }
                                }}
                                type="text"
                                style={{ width: "100%" }}
                                value={jtitle}
                                onChange={(e) => setjtitle(e.target.value)}
                                className="job-search m-0 w-100"
                                placeholder="Job Title or Keyword"
                              />
                            </div>
                          </div>
                          <div className="col-5 px-1">
                            <div style={{ position: "relative" }}>
                              <i
                                style={{ left: 10, top: 12 }}
                                className="fa fa-map-marker marker-icon"
                                aria-hidden="true"
                              />
                              <PlacesAutocomplete
                                value={address}
                                onChange={(e) => {
                                  setaddress(e);
                                  // setjlocation(e);
                                }}
                                onSelect={handleSelect}
                                searchOptions={{
                                  componentRestrictions: { country: "us" },
                                }}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      id="exampleInputPassword1"
                                      className="form-control employee-input-email job-search"
                                      {...getInputProps({
                                        placeholder: "City / State / Zip Code",
                                        className: "city-search m-0 w-100",
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container w-100">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item p-2 --active"
                                          : "suggestion-item p-2 ";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </div>
                          </div>
                          <div className="col-2 px-1">
                            <div className="d-flex align-items-end justify-content-center">
                              <a
                                style={{ paddingBlock: 8 }}
                                className="m-0 w-100"
                                onClick={(e) => {
                                  jobsearch("");
                                }}
                              >
                                Search
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content" id="v-pills-tabContent">
                      {searchres.length > 0 &&
                      searchres[0].job &&
                      searchres[0].job.length > 0 ? (
                        searchres[0].job
                          .filter((idd) => idd.id == id)
                          .map((res) =>
                            res.status !== 0 ? (
                              <>
                                <div
                                  key={res}
                                  className="tab-pane fade show active"
                                  id={id}
                                  role="tabpanel"
                                  aria-labelledby="v-pills-home-tab"
                                >
                                  <div className="search-result-3-scrool-box p-2 mt-3">
                                    <div className="d-flex flex-column p-2">
                                      <div className="job-search-result-left-box d-flex align-items-center justify-content-between">
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <a href className="mr-1">
                                              {res.company_name !== null &&
                                              res.company_name !== undefined &&
                                              res.company_name !== ""
                                                ? res.company_name
                                                : ""}
                                            </a>{" "}
                                            -{" "}
                                            <p className="mb-0 ml-1">
                                              {res.job_title !== null &&
                                              res.job_title !== undefined &&
                                              res.job_title !== ""
                                                ? res.job_title
                                                : ""}
                                            </p>
                                          </div>
                                          {res.display_amount !== 0 ? (
                                            <p>
                                              {res.city !== null &&
                                              res.city !== undefined &&
                                              res.city !== ""
                                                ? res.city
                                                : ""}
                                              ,<span className="ml-1"></span>
                                              {res.state !== null &&
                                              res.state !== undefined &&
                                              res.state !== ""
                                                ? res.state
                                                : ""}
                                              <span className="ml-1"></span> |{" "}
                                              {res.employment !== null &&
                                              res.employment !== undefined &&
                                              res.employment !== ""
                                                ? res.employment
                                                : ""}{" "}
                                              {res.pay_rate_minimum !== "" &&
                                              res.pay_rate_maximum !== "" &&
                                              res.pay_rate_type !== ""
                                                ? "|"
                                                : ""}{" "}
                                              {`${
                                                res.pay_rate_minimum !== ""
                                                  ? `${res.pay_rate_minimum}`
                                                  : ""
                                              } ${
                                                res.pay_rate_maximum !== ""
                                                  ? "To"
                                                  : ""
                                              } ${
                                                res.pay_rate_maximum !== ""
                                                  ? `${res.pay_rate_maximum}`
                                                  : ""
                                              }`}{" "}
                                              <span className="paytyejob">
                                                {" "}
                                                {res.pay_rate_minimum !== "" &&
                                                res.pay_rate_maximum !== "" &&
                                                res.pay_rate_type !== ""
                                                  ? `(${res.pay_rate_type})`
                                                  : ""}
                                              </span>
                                            </p>
                                          ) : (
                                            <p>
                                              {res.city !== null &&
                                              res.city !== undefined &&
                                              res.city !== ""
                                                ? res.city
                                                : ""}
                                              ,<span className="ml-1"></span>
                                              {res.state !== null &&
                                              res.state !== undefined &&
                                              res.state !== ""
                                                ? res.state
                                                : ""}
                                              <span className="ml-1"></span> |{" "}
                                              {res.employment !== null &&
                                              res.employment !== undefined &&
                                              res.employment !== ""
                                                ? res.employment
                                                : ""}
                                            </p>
                                          )}
                                        </div>
                                        {localStorage.getItem("userid") !==
                                          null &&
                                        localStorage.getItem("userid") !==
                                          undefined &&
                                        localStorage.getItem("userid") !==
                                          "" ? (
                                          <div
                                            className="d-flex align-items-center justify-content-end"
                                            style={{
                                              cursor: "pointer !important",
                                            }}
                                          >
                                            <div
                                              className="main-content-session inner-session d-flex align-items-center"
                                              style={{
                                                cursor: "pointer !important",
                                                position: "relative",
                                                top: "-16px",
                                              }}
                                            >
                                              {props.GetUserReducer.users &&
                                              props.GetUserReducer.users
                                                .role !== "" &&
                                              props.GetUserReducer.users
                                                .role !== null &&
                                              props.GetUserReducer.users
                                                .role !== undefined &&
                                              props.GetUserReducer.users.role ==
                                                0 ? (
                                                <>
                                                  {appliedarray &&
                                                  appliedarray.length > 0 ? (
                                                    appliedarray.filter(
                                                      (data) =>
                                                        data.job_id == res.id &&
                                                        data.candidate_id ==
                                                          localStorage.getItem(
                                                            "userid"
                                                          )
                                                    ).length > 0 ? (
                                                      <p
                                                        style={{
                                                          cursor:
                                                            "pointer !important",
                                                        }}
                                                        className="send-invitation"
                                                      >
                                                        APPLIED
                                                      </p>
                                                    ) : (
                                                      <p
                                                        style={{
                                                          cursor:
                                                            "pointer !important",
                                                        }}
                                                        onClick={(
                                                          jobid,
                                                          jobtitle
                                                        ) =>
                                                          ApplyJob(
                                                            res.id,
                                                            res.job_title,
                                                            res
                                                          )
                                                        }
                                                        className="send-invitation"
                                                      >
                                                        APPLY NOW
                                                      </p>
                                                    )
                                                  ) : (
                                                    <p
                                                      style={{
                                                        cursor:
                                                          "pointer !important",
                                                      }}
                                                      onClick={(
                                                        jobid,
                                                        jobtitle
                                                      ) =>
                                                        ApplyJob(
                                                          res.id,
                                                          res.job_title,
                                                          res
                                                        )
                                                      }
                                                      className="send-invitation"
                                                    >
                                                      APPLY NOW
                                                    </p>
                                                  )}
                                                  {/* <button style={{ background: "transparent", border: "none" }}> */}
                                                  {allArray &&
                                                  allArray.length > 0 ? (
                                                    allArray.filter(
                                                      (data) =>
                                                        data.job_id == res.id &&
                                                        data.user_id ==
                                                          localStorage.getItem(
                                                            "userid"
                                                          )
                                                    ).length > 0 ? (
                                                      <i
                                                        style={{
                                                          fontSize: 28,
                                                          cursor: "pointer",
                                                        }}
                                                        className="ml-3 fa fa-heart text-danger"
                                                        onClick={() =>
                                                          savedjob1(res.id, 1)
                                                        }
                                                      ></i>
                                                    ) : (
                                                      <i
                                                        style={{
                                                          fontSize: 28,
                                                          cursor: "pointer",
                                                        }}
                                                        className="ml-3 fa fa-heart-o text-danger"
                                                        onClick={() =>
                                                          savedjob1(res.id, 0)
                                                        }
                                                      ></i>
                                                    )
                                                  ) : (
                                                    <i
                                                      style={{
                                                        fontSize: 28,
                                                        cursor: "pointer",
                                                      }}
                                                      className="ml-3 fa fa-heart-o text-danger"
                                                      onClick={() =>
                                                        savedjob1(res.id, 0)
                                                      }
                                                    ></i>
                                                  )}
                                                  {/* </button> */}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="d-flex align-items-center justify-content-end"
                                            style={{
                                              cursor: "pointer !important",
                                            }}
                                          >
                                            <div
                                              className="main-content-session inner-session"
                                              style={{
                                                cursor: "pointer !important",
                                                position: "relative",
                                                top: "-16px",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  cursor: "pointer !important",
                                                }}
                                                onClick={() =>
                                                  setBilingModalIsOpen(true)
                                                }
                                                className="send-invitation"
                                              >
                                                Apply Now
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      {res.company_overview !== null &&
                                      res.company_overview !== undefined &&
                                      res.company_overview !== "" ? (
                                        <>
                                          <h5
                                            className="mb-0 mt-3"
                                            style={{ color: "orange" }}
                                          >
                                            Company Overview
                                          </h5>
                                          <p className="mt-2">
                                            {parse(res.company_overview)}
                                          </p>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {res.description !== null &&
                                      res.description !== undefined &&
                                      res.description !== "" ? (
                                        <>
                                          <h5
                                            className="mb-0 mt-3"
                                            style={{ color: "orange" }}
                                          >
                                            Description
                                          </h5>
                                          <div className="searchjobdescription_early">
                                            <p className="mt-2">
                                              {parse(res.description)}
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {searchres.length > 0 &&
                                      searchres[0].responsibility &&
                                      searchres[0].responsibility.length > 0 ? (
                                        searchres[0].responsibility.filter(
                                          (idd) => idd.job_id == id
                                        ).length > 0 ? (
                                          <h5
                                            className="mb-0 mt-3"
                                            style={{ color: "orange" }}
                                          >
                                            Responsibility
                                          </h5>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {searchres.length > 0 &&
                                      searchres[0].responsibility &&
                                      searchres[0].responsibility.length > 0
                                        ? searchres[0].responsibility
                                            .filter((idd) => idd.job_id == id)
                                            .map((res) => (
                                              <div
                                                className="searchjobdescription_early"
                                                key={res}
                                              >
                                                <p className="mt-2">
                                                  {parse(res.responsibility)}
                                                </p>
                                              </div>
                                            ))
                                        : ""}
                                      {searchres.length > 0 &&
                                      searchres[0].benefits &&
                                      searchres[0].benefits.length > 0 ? (
                                        searchres[0].benefits.filter(
                                          (idd) => idd.job_id == id
                                        ).length > 0 ? (
                                          <h5
                                            className="mb-0 mt-3"
                                            style={{ color: "orange" }}
                                          >
                                            Benefits
                                          </h5>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      <ul>
                                        {searchres.length > 0 &&
                                        searchres[0].benefits &&
                                        searchres[0].benefits.length > 0
                                          ? searchres[0].benefits
                                              .filter((idd) => idd.job_id == id)
                                              .map((res) => (
                                                <li className="m-0" key={res}>
                                                  {parse(res.benefits)}
                                                </li>
                                              ))
                                          : ""}
                                      </ul>

                                      <h5
                                        className="mb-0 mt-3"
                                        style={{ color: "orange" }}
                                      >
                                        Experience Level
                                      </h5>
                                      <p>
                                        {res.experience_level !== null &&
                                        res.experience_level !== undefined &&
                                        res.experience_level !== ""
                                          ? res.experience_level
                                          : ""}
                                      </p>

                                      <h5
                                        className="mb-0 mt-3"
                                        style={{ color: "orange" }}
                                      >
                                        Education
                                      </h5>
                                      <p>
                                        {res.education !== null &&
                                        res.education !== undefined &&
                                        res.education !== ""
                                          ? res.education
                                          : ""}
                                      </p>

                                      {res.display_amount !== null &&
                                      res.display_amount !== "" &&
                                      res.display_amount !== 0 ? (
                                        res.pay_rate_minimum !== null &&
                                        res.pay_rate_minimum !== undefined &&
                                        res.pay_rate_minimum !== "" &&
                                        res.pay_rate_maximum !== null &&
                                        res.pay_rate_maximum !== undefined &&
                                        res.pay_rate_maximum !== "" ? (
                                          <>
                                            <h5
                                              className="mb-0 mt-3"
                                              style={{ color: "orange" }}
                                            >
                                              Pay Rate
                                            </h5>
                                            <p>
                                              {res.pay_rate_minimum !== null &&
                                              res.pay_rate_minimum !==
                                                undefined &&
                                              res.pay_rate_minimum !== ""
                                                ? res.pay_rate_minimum
                                                : ""}{" "}
                                              {res.pay_rate_maximum !== null &&
                                              res.pay_rate_maximum !==
                                                undefined &&
                                              res.pay_rate_maximum !== ""
                                                ? "To"
                                                : ""}{" "}
                                              {res.pay_rate_maximum !== null &&
                                              res.pay_rate_maximum !==
                                                undefined &&
                                              res.pay_rate_maximum !== ""
                                                ? res.pay_rate_maximum
                                                : ""}
                                            </p>
                                          </>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}

                                      {res.other_compensations == "" ||
                                      res.other_compensations == "None" ? (
                                        ""
                                      ) : (
                                        <>
                                          <h5
                                            className="mb-0 mt-3"
                                            style={{ color: "orange" }}
                                          >
                                            Other Compensations
                                          </h5>
                                          <p>
                                            {res.other_compensations !== null &&
                                            res.other_compensations !==
                                              undefined &&
                                            res.other_compensations !== ""
                                              ? res.other_compensations
                                              : ""}
                                          </p>
                                        </>
                                      )}

                                      {res.position_opened !== null &&
                                      res.position_opened !== undefined &&
                                      res.position_opened !== "" ? (
                                        <h5
                                          className="mb-0 mt-3"
                                          style={{ color: "orange" }}
                                        >
                                          Positions Available
                                        </h5>
                                      ) : (
                                        ""
                                      )}
                                      {res.position_opened == "" ? (
                                        ""
                                      ) : (
                                        <p>
                                          {res.position_opened !== null &&
                                          res.position_opened !== undefined &&
                                          res.position_opened !== ""
                                            ? res.position_opened
                                            : ""}
                                        </p>
                                      )}
                                      {res.travel !== null &&
                                      res.travel !== undefined &&
                                      res.travel !== "" &&
                                      res.travel !== "None" ? (
                                        <h5
                                          className="mb-0 mt-3"
                                          style={{ color: "orange" }}
                                        >
                                          Travel
                                        </h5>
                                      ) : (
                                        ""
                                      )}
                                      {res.travel == "" ? (
                                        ""
                                      ) : (
                                        <p>
                                          {res.travel !== null &&
                                          res.travel !== undefined &&
                                          res.travel !== "" &&
                                          res.travel !== "None"
                                            ? res.travel
                                            : ""}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {props.GetUserReducer.users &&
                                props.GetUserReducer.users.role !== null &&
                                props.GetUserReducer.users.role !== undefined &&
                                props.GetUserReducer.users.role !== "" ? (
                                  props.GetUserReducer.users.role == 0 ? (
                                    <div className="row text-right justify-content-end mt-4">
                                      <Link
                                        to="#"
                                        onClick={() => setjobidfunc(res.id)}
                                        className="text-danger fgh "
                                        style={{ width: "fit-content" }}
                                      >
                                        Report this job posting
                                      </Link>
                                    </div>
                                  ) : (
                                    // onClick={() => setBilingModalIsOpen(true)}
                                    <div className="row text-right justify-content-end mt-4">
                                      <Link
                                        to="#"
                                        onClick={() => setjobidfunc(res.id)}
                                        className="text-danger fgh "
                                        style={{ width: "fit-content" }}
                                      >
                                        Report this job posting
                                      </Link>
                                    </div>
                                  )
                                ) : (
                                  <div className="row text-right justify-content-end mt-4">
                                    <Link
                                      to="#"
                                      onClick={() => setBilingModalIsOpen(true)}
                                      className="text-danger fgh "
                                      style={{ width: "fit-content" }}
                                    >
                                      Report this job posting
                                    </Link>
                                  </div>
                                )}
                              </>
                            ) : (
                              ""
                            )
                          )
                      ) : (
                        <p style={{ color: "#f48a20" }}>No jobs found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            isOpen={BilingmodalIsOpen}
            onRequestClose={() => setBilingModalIsOpen(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: modalwidth,
                height: "320px",
                top: "calc(50% - 160px)",
                left: "0",
                right: "0",
                bottom: "100px",
                border: "1px solid orange",
                background: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                outline: "none",
                padding: "20px",
                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className="text-center">
              <h4 style={{ fontSize: 24 }}>
                Please log in or create an account to apply for this job.
              </h4>
              <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                <Link
                  className="fgh"
                  to={`/signup${
                    window.location.href.search("false") == -1 &&
                    window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                          window.location.href.search("false") == -1
                            ? true
                            : false
                        }`
                  }`}
                >
                  Sign Up
                </Link>
                <Link
                  className="fgh"
                  to={`/candidate-login${
                    window.location.href.search("false") == -1 &&
                    window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                          window.location.href.search("false") == -1
                            ? true
                            : false
                        }`
                  }`}
                >
                  Sign In
                </Link>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={thisJob}
            onRequestClose={() => setthisJob(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: "490px",
                height: "200px",
                top: "calc(50% - 100px)",
                left: "0",
                right: "0",
                bottom: "100px",
                border: "1px solid orange",
                background: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                outline: "none",
                padding: "20px",
                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center inner-session">
              <h5 className="text-center" style={{ color: "black" }}>
                Your Job is saved
              </h5>
              <Link to="/save-jobs" className="pt-3 mb-0 send-invitation fgh">
                View
              </Link>
            </div>
          </Modal>

          <Modal
            isOpen={BilingmodalIsOpen4}
            onRequestClose={() => setBilingModalIsOpen4(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                scrollbarWidth: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: "450px",
                height: "520px",
                top: "calc(50% - 260px)",
                left: "0",
                right: "0",
                border: "1px solid orange",
                background: "#fff",
                outline: "none",
                padding: "20px",
                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-center py-3">
                  <h3>Report This Job</h3>
                </div>
                <hr className="m-0" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="role role2">
                  <label>
                    <input
                      type="radio"
                      name="radio32"
                      defaultChecked
                      value="Offensive and/or discriminatory content"
                      onChange={(e) => setreason(e.target.value)}
                    />
                    <span className="sp-clr">
                      Offensive and/or discriminatory content
                    </span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="radio32"
                      value="Possible Scam/Fraudulent"
                      onChange={(e) => setreason(e.target.value)}
                    />
                    <span className="sp-clr">Possible Scam/Fraudulent</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="radio32"
                      value="Problem applying to this job"
                      onChange={(e) => setreason(e.target.value)}
                    />
                    <span className="sp-clr">Problem applying to this job</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="radio32"
                      value="Advertisement"
                      onChange={(e) => setreason(e.target.value)}
                    />
                    <span className="sp-clr">Advertisement</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="radio32"
                      value="Other"
                      onChange={(e) => setreason(e.target.value)}
                    />
                    <span className="sp-clr">Other</span>
                  </label>
                </div>
              </div>
            </div>
            <hr className="m-0" />
            <div className="row mt-3">
              <div className="col-md-12">
                <h5 className="mt-3">Additional details</h5>
                <textarea
                  onChange={(e) => CharacterCount(e)}
                  maxLength={500}
                  id="coverview"
                  className="w-100"
                  name
                  cols={30}
                  rows={6}
                  defaultValue={""}
                />
                <p id="counter">
                  Characters 500 Count{" "}
                  {professional_summarycount !== "" &&
                  professional_summarycount !== undefined &&
                  professional_summarycount !== null
                    ? professional_summarycount
                    : "0"}
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="main-content-session inner-session">
                    <Link
                      to="#"
                      onClick={() => createsearch()}
                      className="send-invitation fgh"
                    >
                      Send
                    </Link>
                    <Link
                      to="#"
                      onClick={() => setBilingModalIsOpen4(false)}
                      className="send-invitation ml-3 fgh"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={BilingmodalIsOpentrue}
            onRequestClose={() => setBilingmodalIsOpentrue(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: "490px",
                height: "200px",
                top: "calc(50% - 100px)",
                left: "0",
                right: "0",
                bottom: "100px",
                border: "1px solid orange",
                background: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                outline: "none",
                padding: "20px",
                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className=" justify-content-center align-items-center pt-3">
              <h5 className="text-center" style={{ color: "black" }}>
                Do you want to save this Job?
              </h5>
              <div className="d-flex justify-content-center mt-4">
                <p
                  onClick={(e) => savedjob()}
                  className="p-3 rounded"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    backgroundColor: "#8dc63f",
                  }}
                >
                  <b>CONTINUE</b>
                </p>
                <p
                  onClick={() => setBilingmodalIsOpentrue(false)}
                  className="ml-5 pt-3"
                  style={{ color: "black", cursor: "pointer" }}
                >
                  <b>Cancel</b>
                </p>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={BilingmodalIsOpentrue1}
            onRequestClose={() => setBilingmodalIsOpentrue1(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: "490px",
                height: "200px",
                top: "calc(50% - 100px)",
                left: "0",
                right: "0",
                bottom: "100px",
                border: "1px solid orange",
                background: "#fff",

                outline: "none",
                padding: "20px",
                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className=" justify-content-center align-items-center pt-3">
              <h5 className="text-center" style={{ color: "black" }}>
                {saveerrormessage}
              </h5>
              <div className="d-flex justify-content-center mt-4">
                <p
                  onClick={(e) => setBilingmodalIsOpentrue1(false)}
                  className="p-3 rounded"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    backgroundColor: "#8dc63f",
                  }}
                >
                  <b>OK</b>
                </p>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <FullPageLoader1 />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  AppliedJobReducer: state.AppliedJobReducer,
  ReportJobReducer: state.ReportJobReducer,
});

const mapDispatchToProps = (dispatch) => ({
  AppliedJob: (jobid, jobtitle) => dispatch(AppliedJob(jobid, jobtitle)),
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
  ReportJobAction: (job_id, jobtitle, details, jobTitle, Location) =>
    dispatch(ReportJobAction(job_id, jobtitle, details, jobTitle, Location)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeJobSearchResult);
