import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import ForgotPassword from "../components/forgotpassword/Forgotpassword"
import HomeFooter from "../components/homeFooter/HomeFooter";

const PasswordForgot = () => {
    return (
        <>
            <EmployeeNav2 />
            <ForgotPassword />
        </>
    )
}
export default PasswordForgot


// code for scoop solutions