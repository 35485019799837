import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { ReportJobAction } from "../../actions/Reportjobaction";
import { ResumeCreate } from "../../actions/Resumeaction"
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import Footer from "../footer/Footer";
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import { useLocation, useHistory } from "react-router-dom";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import dayjs from "dayjs";
import parse from 'html-react-parser';
import HomeFooter from "../homeFooter/HomeFooter";

const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const EmployeeProfile = (props) => {
  const UpdateStatus = async (sta, id) => {
    await fetch(`${config['baseUrl']}/resume/update/status`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "status": sta,
        "id": id
      })
    }).then(res => {

      if (res.status !== 201) {

      }
      else {
        window.location = "/profile"
      }
      return res.json();
    }).then((response) => {

    }).catch((error) => {
      console.log(error)

    })
  }
  const [allvolunteer, setallvolunteer] = useState([])

  const [allArray, setallArray] = useState([])
  const [load, setload] = useState(false)
  useEffect(() => {
    setload(true)
    fetch(`${config['baseUrl']}/resume/getbyid/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      setallvolunteer(response[0].volunteer)

      var temp = []
      temp = response
      setallArray([...temp])

    }).catch((error) => {
      console.log("error", error);

      setload(false)
    })
    setTimeout(() => {
      setload(false)
    }, 3000);
  }, []);
  return (
    <>
      {
        load !== true ?
          <>
            <div className="container-fluid">
              <div className="container">
                <Dropdownnav />
                {/* header======================================================================*/}
                {
                  allArray.length > 0 ? allArray.map(value => (
                    <div className="main-content pre-profiel-ses">
                      <h4 style={{ color: "#f48a20" }}>Privacy Settings</h4>
                      <div className="main-content-session pt-0">
                        <div className="role role2">
                          <label>
                            <input type="checkbox"
                              defaultChecked={value.job[0].status == 1 ? true : false}
                              name="radio21" onChange={(e) => UpdateStatus(1, value.job[0].id)} />
                            <span className="sp-clr">Your resume will be visible to employers searching for candidates, but will only have access to your contact information in accordance with<br />our terms and policies.  Only your city and state will be displayed when your resume is viewed.</span>
                          </label>
                          <label className="mt-4">
                            <input type="checkbox"
                              defaultChecked={value.job[0].status == 0 ? true : false}
                              name="radio21" onChange={(e) => UpdateStatus(0, value.job[0].id)} />
                            <span className="sp-clr">Make your resume not viewable by anyone for the time being.  Although no one will be able to find during a search, you will still be able to<br />apply for jobs.</span>
                          </label>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex mt-4 align-items-center">
                        <h4 className="m-0" style={{ color: "#f48a20" }}>Personal Information </h4>
                        <Link to="/updateresume?id=PERSONALINFO"><i className="fa fa-pencil-square-o ml-2 preview-edit" aria-hidden="true" /></Link>
                      </div>
                      <div className="mt-3">

                        <div className="d-flex align-items-start">
                          <p className="mb-0 ml-0">{`${value.job[0].first_name !== "" && value.job[0].first_name !== undefined && value.job[0].first_name !== null ? value.job[0].first_name : ""} ${value.job[0].middle_name !== "" && value.job[0].middle_name !== undefined && value.job[0].middle_name !== null ? value.job[0].middle_name : ""} ${value.job[0].last_name !== "" && value.job[0].last_name !== undefined && value.job[0].last_name !== null ? value.job[0].last_name : ""}`}</p>
                        </div>


                        <div className="d-flex align-items-start">
                          <p className="mb-0 ml-0">{`${value.job[0].city !== "" && value.job[0].city !== undefined && value.job[0].city !== null ? value.job[0].city : ""}, ${value.job[0].state !== "" && value.job[0].state !== undefined && value.job[0].state !== null ? value.job[0].state : ""}`}</p>
                        </div>



                        <div className="d-flex align-items-start">
                          <p className="mb-0 ml-0">{localStorage.getItem("email")}</p>
                        </div>

                        {value.job[0].contact_number !== "" && value.job[0].contact_number !== undefined && value.job[0].contact_number !== null ?
                          <div className="d-flex align-items-start">
                            <p className="mb-0 ml-0">{`${value.job[0].contact_number !== "" && value.job[0].contact_number !== undefined && value.job[0].contact_number !== null ? value.job[0].contact_number : ""}`}</p>
                          </div>
                          : ""
                        }


                        {value.job[0].add_contact_number !== "" && value.job[0].add_contact_number !== undefined && value.job[0].add_contact_number !== null ?
                          <div className="d-flex align-items-start">
                            <p className="mb-0 ml-0">{`${value.job[0].add_contact_number !== "" && value.job[0].add_contact_number !== undefined && value.job[0].add_contact_number !== null ? value.job[0].add_contact_number : ""}`}</p>
                          </div>
                          : ""
                        }


                        <div className="d-flex align-items-start">
                          <p className="mb-0 ml-0">{`${value.job[0].employment_eligibility !== "" && value.job[0].employment_eligibility !== undefined && value.job[0].employment_eligibility !== null ? value.job[0].employment_eligibility : ""}`}</p>
                        </div>
                      </div>
                      <hr />



                      <div className="d-flex mt-4 align-items-center">
                        <h4 className="m-0" style={{ color: "#f48a20" }}>Education</h4>
                        <Link to="/updateresume?id=EDUCATION"><i className="fa fa-pencil-square-o ml-2 preview-edit" aria-hidden="true" /></Link>
                      </div>
                      {value.education && value.education.length > 0 ? value.education.map(edu => (
                        <div className="mt-3">

                          {edu.school !== "" && edu.school !== undefined && edu.school !== null ?
                            <div className="d-flex align-items-start">
                              <p className="mb-0 ml-0">{`${edu.school !== "" && edu.school !== undefined && edu.school !== null ? edu.school : ""}`}</p>
                            </div>
                            : ""}
                          {edu.level_of_education !== "" && edu.level_of_education !== undefined && edu.level_of_education !== null ?
                            <div className="d-flex align-items-start">
                              <p className="mb-0 ml-0">{`${edu.level_of_education !== "" && edu.level_of_education !== undefined && edu.level_of_education !== null ? edu.level_of_education : ""}`}</p>
                            </div>
                            : ""}

                          {edu.city !== "" && edu.city !== undefined && edu.city !== null ?
                            <div className="d-flex align-items-start">
                              <p className="mb-0 ml-0">{`${edu.city !== "" && edu.city !== undefined && edu.city !== null ? edu.city : ""} `}</p> <p className="m-0 px-1">-</p>

                              {edu.date_of_completion !== undefined && edu.date_of_completion !== null ?
                                <p className="mb-0 ml-0">{`${edu.date_of_completion !== "" && edu.date_of_completion !== undefined && edu.date_of_completion !== null ? edu.date_of_completion !== "Currently enrolled" ? dayjs(edu.date_of_completion).format("MM-DD-YYYY") : "Currently enrolled" : "Currently enrolled"} `}</p>
                                : ""}
                            </div>
                            : ""}


                          <hr />
                        </div>
                      )) : ""
                      }
                      <div className="d-flex mt-4 align-items-center" >
                        <h4 className="m-0" style={{ color: "#f48a20" }}>Professional Summary</h4>
                        <Link to="/updateresume?id=PROFESSIONALSUMMARY"><i className="fa fa-pencil-square-o ml-2 preview-edit" aria-hidden="true" /></Link>
                      </div>

                      {value.job[0].professional_summary !== "" && value.job[0].professional_summary !== undefined && value.job[0].professional_summary !== null ?
                        <div className="d-flex align-items-start flex-column py-4">
                          <p className="mb-0 ml-0">{value.job[0].professional_summary !== "" && value.job[0].professional_summary !== undefined && value.job[0].professional_summary !== null ? parse(value.job[0].professional_summary) : ""}</p>
                        </div>
                        : ""}

                      <hr />

                      <div className="d-flex mt-4 align-items-center" >
                        <h4 className="m-0" style={{ color: "#f48a20" }}>Experience</h4>
                        <Link to="/updateresume?id=WORKEXPERIENCE"><i className="fa fa-pencil-square-o ml-2 preview-edit" aria-hidden="true" /></Link>
                      </div>
                      {
                        value.experience && value.experience.length > 0 ? value.experience.map(exp => (
                          <div className="mt-3">
                            <div className="d-flex align-items-start">
                              {exp.company !== "" && exp.company !== undefined && exp.company !== null ?
                                <>
                                  <p style={{ fontWeight: "800" }} className="mb-0 ml-0">{exp.company}</p><p className="m-0 pr-1 pl-0">,</p>
                                </>
                                : ""}
                              {exp.city !== "" && exp.city !== undefined && exp.city !== null ?
                                <>
                                  <p className="mb-0 ml-1">{exp.city}, {exp.state}</p><p className="m-0 pr-1 pl-0">,</p>
                                </>
                                : ""}
                              {exp.time_period_start !== "" && exp.time_period_start !== undefined && exp.time_period_start !== null ?
                                <p className="mb-0 ml-1">{exp.time_period_start !== "Still currently employed here" ? dayjs(exp.time_period_start).format("MM-DD-YYYY") : "Still currently employed here"} to {exp.time_period_end !== "Still currently employed here" ? dayjs(exp.time_period_end).format("MM-DD-YYYY") : "Still currently employed here"} </p>
                                : ""}
                            </div>

                            {exp.job_title !== "" && exp.job_title !== undefined && exp.job_title !== null ?
                              <div className="d-flex align-items-start">
                                <p className="mb-0 ml-0">{exp.job_title} </p>
                              </div>
                              : ""}










                            {exp.duties !== "" && exp.duties !== undefined && exp.duties !== null ?
                              <div className="d-flex align-items-start flex-column">
                                <p className="mb-0 ml-0">{parse(exp.duties)}</p>
                              </div>
                              : ""}





                            <hr />
                          </div>
                        )) : ""}

                      {value.military && value.military.length > 0 ?
                        <>
                          <div className="d-flex mt-4 align-items-center">
                            <h4 className="m-0" style={{ color: "#f48a20" }}>Military Service</h4>
                            <Link to="/updateresume?id=MILITARY"><i className="fa fa-pencil-square-o ml-2 preview-edit" aria-hidden="true" /></Link>
                          </div>
                          {
                            value.military && value.military.length > 0 ? value.military.map(mil => (
                              <div className="mt-3">



                                {value.branch && value.branch.length > 0 ? value.branch.map(br => (
                                  <div className="d-flex align-items-start">
                                    <p className="mb-0 ml-0">{br.branch}</p>
                                  </div>
                                )) : ""}

                                <div className="d-flex flex-in-resume-direction-false">
                                  {
                                    value.mos && value.mos.length > 0 ? value.mos.map((mos, index) => (
                                      <>
                                        <p className="mb-0 ml-0">{mos.mos}</p>
                                        {index == value.mos.length - 1 ? "" : <span className="text-dark pl-0 pr-1">,</span>}

                                      </>
                                    )) : <p>No MOS added</p>

                                  }
                                </div>
                                <div className="d-flex align-items-start">
                                  <p className="mb-0 ml-0">{dayjs(mil.start_date).format("MM-DD-YYYY")} to {dayjs(mil.end_date).format("MM-DD-YYYY")}</p>
                                </div>

                                {value.military && value.military.length > 0 ? <>


                                </> : ""}
                                <hr />

                              </div>
                            )) : ""}
                        </>
                        : ""}
                      {value.language && value.language.length > 0 || value.certification && value.certification.length > 0 || value.award && value.award.length > 0 || value.reference && value.reference.length > 0 || allvolunteer && allvolunteer.length > 0 ?
                        <>
                          <div className=" py-3">
                            <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                              <div className="d-flex align-items-center">
                                <h4 className="m-0" style={{ color: "#f48a20" }}>Additional Information</h4>
                                <Link to="/updateresume?id=ADDITIONAL"><i className="fa fa-pencil-square-o ml-2 preview-edit" aria-hidden="true" /></Link>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="pr-5 pt-2">
                                {value.language && value.language.length > 0 ? <>
                                  <div className="d-flex flex-in-resume-direction-false mb-1">
                                    <a style={{ fontSize: "15px", color: "black", fontWeight: "bolder" }} href="#" className="mt-0 dropdddd">Additional Language(s):</a>
                                    <div className="d-flex align-items-center">
                                      {value.language && value.language.length > 0 ? value.language.map((lang, index) => (
                                        <>
                                          <p className="mb-0 ml-1">{`${lang.lang !== null && lang.lang !== undefined && lang.lang !== "" ? `${lang.lang} (${lang.level})` : ""}`}</p>
                                          {index == value.language.length - 1 ? "" : <span className="text-dark pl-0 pr-1">,</span>}
                                        </>
                                      )) : <p>No Language Found</p>
                                      }
                                    </div>
                                  </div>
                                </> : ""}


                                {value.certification && value.certification.length > 0 ? <>
                                  <div className="d-flex flex-in-resume-direction-false mb-1">
                                    <a style={{ fontSize: "15px", color: "black", fontWeight: "bolder" }} href="#" className="mt-0 dropdddd">Certification(s):</a>
                                    {value.certification && value.certification.length > 0 ? value.certification.map((cert, index) => (
                                      <>
                                        <p className="mb-0 ml-1">{`${cert.title !== null && cert.title !== undefined && cert.title !== "" ? cert.title : ""}`} ({`${cert.date !== null && cert.date !== undefined && cert.date !== "" ? dayjs(cert.date).format("MM-DD-YYYY") : ""}`})</p>
                                        {index == value.certification.length - 1 ? "" : <span className="ml-0 mr-1">,</span>}
                                      </>

                                    )) : <p>No Certification Found</p>
                                    }
                                  </div>
                                </> : ""}


                                {value.award && value.award.length > 0 ? <>
                                  <div className="d-flex flex-in-resume-direction-false mb-1">
                                    <a style={{ fontSize: "15px", color: "black", fontWeight: "bolder" }} href="#" className="mt-0 dropdddd">Award(s):</a>
                                    {value.award && value.award.length > 0 ? value.award.map((awar, index) => (
                                      <>
                                        <p className="mb-0 ml-1">{`${awar.title !== null && awar.title !== undefined && awar.title !== "" ? awar.title : ""}`} ({`${awar.date !== null && awar.date !== undefined && awar.date !== "" ? dayjs(awar.date).format("MM-DD-YYYY") : ""}`})</p>
                                        {index == value.award.length - 1 ? "" : <span className="ml-0 mr-1">,</span>}
                                      </>
                                    )) : <p>No Award Found</p>
                                    }
                                  </div>
                                </> : ""}

                                <div className="d-flex flex-in-resume-direction-false mb-1">
                                  {value.military && value.military.length > 0 ? value.military.map(cle => (
                                    <>
                                      <a style={{ fontSize: "15px", color: "black", fontWeight: "bolder" }} href="#" className="mt-0 dropdddd">Security Clearance:</a>
                                      <p className="mb-0 ml-1">{`${cle.security_clearance !== null && cle.security_clearance !== undefined && cle.security_clearance !== "" ? cle.security_clearance : ""}`}</p>
                                    </>
                                  )) : <p></p>
                                  }
                                </div>


                                {value.resumelinks && value.resumelinks.length > 0 ? <>
                                  <div className="d-flex flex-in-resume-direction-false mb-1">
                                    <a style={{ fontSize: "15px", color: "black", fontWeight: "bolder" }} href="#" className="mt-0 dropdddd">Links(s):</a>
                                    {value.resumelinks && value.resumelinks.length > 0 ? value.resumelinks.map((awar, index) => (
                                      <>
                                        <p className="mb-0 ml-1">{awar.link}</p>
                                        {index == value.resumelinks.length - 1 ? "" : <span className="ml-0 mr-1">,</span>}
                                      </>
                                    )) : <p>No links Found</p>
                                    }
                                  </div>
                                </> : ""}

                              </div>
                            </div>
                            <hr />
                          </div>
                        </> : ""}

                      {allvolunteer && allvolunteer.length > 0 ? <>
                        <div className=" py-3">
                          <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                            <div className="d-flex align-items-center">
                              <h4 className="m-0" style={{ color: "#f48a20" }}>Volunteer Work</h4>
                              <Link to="/updateresume?id=VOLLUNTEER"><i className="fa fa-pencil-square-o ml-2 preview-edit" aria-hidden="true" /></Link>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-3">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Start Date</th>
                                  <th scope="col">End Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allvolunteer && allvolunteer.length > 0 ? allvolunteer.map((val, ind) => (
                                  <tr>
                                    <td>{val.title}</td>
                                    <td>{dayjs(val.start).format("MM-DD-YYYY")}</td>
                                    <td>{val.end !== "I currently volunteer here" ? dayjs(val.end).format("MM-DD-YYYY") : "I currently volunteer here"}</td>
                                  </tr>
                                )) : ""
                                }
                              </tbody>
                            </table>
                          </div>
                          <hr />
                        </div>
                      </> : ""}

                      {value.reference && value.reference.length > 0 ? <>
                        <div className=" py-3">
                          <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                            <div className="d-flex align-items-center">
                              <h4 style={{ color: "#f48a20" }} className="m-0">References</h4>
                              <Link to="/updateresume#additional"><i className="fa fa-pencil-square-o ml-2 preview-edit" aria-hidden="true" /></Link>
                            </div>
                          </div>
                          <div className="mt-3 d-flex flex-column">
                            {value.reference && value.reference.length > 0 ? value.reference.map(reff => (
                              <>
                                <div class="d-flex mt-2">

                                  {reff.name !== null && reff.name !== undefined && reff.name !== "" ?
                                    <div className="d-flex flex-column flex-in-resume-direction-false mb-1">
                                      <p className="mb-0 ml-0">{`${reff.name !== null && reff.name !== undefined && reff.name !== "" ? reff.name : ""}`}</p>


                                      {reff.contact_number !== null && reff.contact_number !== undefined && reff.contact_number !== "" ?
                                        <p className="mb-0 ml-0">{`${reff.contact_number !== null && reff.contact_number !== undefined && reff.contact_number !== "" ? reff.contact_number : ""}`}</p>
                                        : ""}
                                    </div>
                                    : ""}
                                  {reff.email !== null && reff.email !== undefined && reff.email !== "" ?
                                    <div className="d-flex flex-column flex-in-resume-direction-false mb-1 ml-4">
                                      {reff.relationship !== null && reff.relationship !== undefined && reff.relationship !== "" ?
                                        <p className="mb-0 ml-0">{`${reff.relationship !== null && reff.relationship !== undefined && reff.relationship !== "" ? reff.relationship : ""}`}</p>
                                        : ""}
                                      <p className="mb-0 ml-0">{`${reff.email !== null && reff.email !== undefined && reff.email !== "" ? reff.email : ""}`}</p>

                                    </div>
                                    : ""}
                                </div>
                              </>
                            )) : ""}
                          </div>
                        </div>
                      </> : ""}


                    </div>
                  )) :
                    <div className="container main-content pre-profiel-ses border-0">
                      <div className="row">
                        <div className="col-md-12 text-center mt-5">
                          {
                            props.GetUserReducer.users && props.GetUserReducer.users.theme !== null && props.GetUserReducer.users.theme !== undefined && props.GetUserReducer.users.theme !== "" && props.GetUserReducer.users.theme !== 0 ?
                              <h2 className="pt-5 text-white">Please create your resume first to view your profile.</h2>
                              :
                              <h2 className="pt-5 text-dark">Please create your resume first to view your profile.</h2>
                          }

                        </div>
                      </div>
                    </div>
                }
                {/* footer====================================================================== */}

              </div>
            </div>
            {
              allArray.length > 0 ?
                <HomeFooter />
                :
                <div className="footer-wrap" style={{ bottom: "0px", left: "0px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                  <HomeFooter />
                </div>
            }


          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  EmployeeProfileReducer: state.EmployeeProfileReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile);


// code for scoop solutions