import {initializeApp} from "firebase/app"
import {getAuth} from "firebase/auth"
const firebaseConfig = {
    apiKey: "AIzaSyDC5JziVfzqkqJtLblqiW6amDrGJ6erSnI",
    authDomain: "hirechoices-d3362.firebaseapp.com",
    projectId: "hirechoices-d3362",
    storageBucket: "hirechoices-d3362.appspot.com",
    messagingSenderId: "872205393327",
    appId: "1:872205393327:web:a0b9291715d53a3d31a35a",
    measurementId: "G-G5LHEN39E0"
  };
  
  // Initialize Firebase
const app=initializeApp(firebaseConfig);
export const authantication=getAuth(app)