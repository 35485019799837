import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import { JobByID } from "../../actions/jobbyid";
import CurrencyInput from 'react-currency-input';
import logo from "../../assets/logo.png"
import InputMask from 'react-input-mask';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';
import Employernav from "../dropdownnav/Employernav";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import PlacesAutocomplete from 'react-places-autocomplete';
import useWindowDimensions from '../administrator/innerwidthcom';

import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const config = require('../../helpers/config.json');


const EditJob = (props) => {
  const [modal1, setmodal1] = useState(false)
  const [trans, setrans] = useState("transformlogo");
  const listenScrollEvent = (e) => {
    if (window.scrollY > 30) {
      setrans("ultra");
    } else {
      setrans("transformlogo");
    }
  };

  const { height, width } = useWindowDimensions();
  const [modalwidth, setmodalwidth] = useState(450)
  useEffect(() => {
    if (width <= 900) {
      setmodalwidth("340px")
    }
  }, []);


  const [BilingmodalIsOpenzip, setBilingModalIsOpenzip] = useState("")
  const [BilingmodalIsOpenzip1, setBilingModalIsOpenzip1] = useState("")
  const [BilingmodalIsOpenzip2, setBilingModalIsOpenzip2] = useState("")
  const [BilingmodalIsOpenzip1comp,setBilingModalIsOpenzip1comp]=useState("")
  const [mainduty, setmainduty] = useState("")
  const [maindutycomp, setmaindutycomp] = useState("")
  const [overcompany, setovercompany] = useState()
  const [first_name, setfirst_name] = useState("")
  const [last_name, setlast_name] = useState("")
  const [company_name, setcompany_name] = useState("")
  const [company_size, setcompany_size] = useState("")
  const [company_size1, setcompany_size1] = useState("")
  const [phone_number, setphone_number] = useState("")
  const [hear_about_us, sethear_about_us] = useState("")
  const [job_title, setjob_title] = useState("")
  const [location, setlocation] = useState("")
  const [state, setstate] = useState("")
  const [zip_code, setzip_code] = useState("")
  const [employment, setemployment] = useState("")
  const [experience_level, setexperience_level] = useState("")
  const [education, seteducation] = useState("")
  const [pay_rate_minimum, setpay_rate_minimum] = useState("")
  const [pay_rate_maximum, setpay_rate_maximum] = useState("")
  const [pay_rate_type, setpay_rate_type] = useState("")
  const [other_compensations, setother_compensations] = useState("")
  const [description, setdescription] = useState("")
  const [company_overview, setcompany_overview] = useState("")
  const [travel, settravel] = useState("")
  const [position_opened, setposition_opened] = useState("")
  const [display_amount, setdisplay_amount] = useState(false)
  const [benefits, setbenefits] = useState([])
  const [linked, setlinked] = useState("")
  const [twitter, settwitter] = useState("")
  const [facebook, setfacebook] = useState("")
  const [blog, setblog] = useState("")
  const [responsibility, setresponsibility] = useState([])
  const [latt, setlatt] = useState(null)
  const [pay_rate_typeEX, setpay_rate_typeEX] = useState(2)
  const [langg, setlangg] = useState(null)
  const[checkcity,setcheckcity]=useState(false)
  const [respons, setrespons] = useState("")
  const [responscomp, setresponscomp] = useState("")
  const [errzip, seterrzip] = useState(false)
  const [descriptioncount, setdescriptioncount] = useState("")
  const statecorrect = (e) => {
    setzip_code(e)
    const dumbdata = e.split('')
    if (dumbdata.length < 5) {
      seterrzip(true)
    }
    else {
      seterrzip(false)
    }
  }
  const [comcon, setcomcon] = useState(false)
  const CharacterCount = (e) => {
    if (e.length > 0) {
      setcomcon(true)
      setresponscomp(e)
      setovercompany(e.length)
      if(e.length > 2500){
        var text2=responscomp
        text2.substring(0, 2500)
        setresponscomp(text2)
      }
    }
    else {
      setcomcon(false)
      setresponscomp(e)
      setovercompany(e.length)
    }
  }
  const [rescon, setrescon] = useState(false)
  const CharacterCount2 = (e) => {
    if (e.length > 0) {
      setrescon(true)
      setrespons(e)
      setmainduty(e.length)
      if(e.length > 2500){
        var text1=respons
        text1.substring(0, 2500)
        setrespons(text1)
      }
    }
    else {
      setrescon(false)
      setrespons(e)
      setmainduty(e.length)
    }
  }


  const [discon, setdiscon] = useState(false)
  const CharacterCount3 = (e) => {
    if (e.length > 0) {
      setdiscon(true)
      setdescription(e)
      setdescriptioncount(e.length)
      if(e.length > 2500){
        var text=description
        text.substring(0, 2500)
        setdescription(text)
      }
    }
    else {
      setdiscon(false)
      setdescription(e)
      setdescriptioncount(e.length)
    }
  }

  //   const [editorState, setEditorState] = useState(() =>
  //   editorState.createEmpty()
  //   );
  const [load1, setload1] = useState(false)
  const search = useLocation().search;
  const jobid = new URLSearchParams(search).get("id");
  const [allArray, setallArray] = useState([])
  useEffect(async () => {
    window.addEventListener("scroll", listenScrollEvent);
    setload1(true)
    await fetch(`${config['baseUrl']}/jobs/getbyid/${jobid}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload1(false)
      const alljobs = response
      setallArray([...allArray, alljobs])
      //
      var companysize = alljobs.job.length > 0 ? alljobs.job[0].company_size : ""
      setcompany_size(companysize)
      //
      var hearaboutus = alljobs.job.length > 0 ? alljobs.job[0].hear_about_us : ""
      sethear_about_us(hearaboutus)
      //
      var state = alljobs.job.length > 0 ? alljobs.job[0].state : ""
      setstate(state)
      //
      var employementt = alljobs.job.length > 0 ? alljobs.job[0].employment : ""
      setemployment(employementt)
      //
      var experience = alljobs.job.length > 0 ? alljobs.job[0].experience_level : ""
      setexperience_level(experience)
      //
      var education = alljobs.job.length > 0 ? alljobs.job[0].education : ""
      seteducation(education)
      //
      var paytype = alljobs.job.length > 0 ? alljobs.job[0].pay_rate_type : ""
      setpay_rate_type(paytype)
      //
      var damount = alljobs.job.length > 0 ? alljobs.job[0].display_amount : ""
      damount == 1 ? setdisplay_amount(false) : setdisplay_amount(true)
      //
      var othercomp = alljobs.job.length > 0 ? alljobs.job[0].other_compensations : ""
      setother_compensations(othercomp)
      //
      var des = alljobs.job.length > 0 ? alljobs.job[0].description : ""
      setdescription(des)
      setdescriptioncount(des.length)
      //
      var responsibility = alljobs.responsibility && alljobs.responsibility.length > 0 ? alljobs.responsibility[0].responsibility : ""
      setrespons(responsibility)
      setmainduty(responsibility.length)
      //
      var position = alljobs.job.length > 0 ? alljobs.job[0].position_opened : ""
      setposition_opened(position)
      //
      var travel = alljobs.job.length > 0 ? alljobs.job[0].travel : ""
      settravel(travel)
      //
      var paymin = alljobs.job.length > 0 ? alljobs.job[0].pay_rate_minimum : ""
      setpay_rate_minimum(paymin)
      //
      var paymax = alljobs.job.length > 0 ? alljobs.job[0].pay_rate_maximum : ""
      setpay_rate_maximum(paymax)
      //
      var jobtt = alljobs.job.length > 0 ? alljobs.job[0].job_title : ""
      setjob_title(jobtt)
      //
      var companyy = alljobs.job.length > 0 ? alljobs.job[0].company_name : ""
      setcompany_name(companyy)
      //
      var loc = alljobs.job.length > 0 ? alljobs.job[0].location : ""
      setlocation(loc)
      //
      var zipp = alljobs.job.length > 0 ? alljobs.job[0].zip_code : ""
      setzip_code(zipp)
      //
      var fir = alljobs.job.length > 0 ? alljobs.job[0].first_name : ""
      setfirst_name(fir)
      var lin = alljobs.job.length > 0 ? alljobs.job[0].linkedin : ""
      setlinked(lin)
      var fac = alljobs.job.length > 0 ? alljobs.job[0].facebook : ""
      setfacebook(fac)
      var twi = alljobs.job.length > 0 ? alljobs.job[0].twitter : ""
      settwitter(twi)
      var bl = alljobs.job.length > 0 ? alljobs.job[0].blog : ""
      setblog(bl)
      //
      var las = alljobs.job.length > 0 ? alljobs.job[0].last_name : ""
      setlast_name(las)
      //
      var pho = alljobs.job.length > 0 ? alljobs.job[0].phone_number : ""
      setphone_number(pho)
      //
      var cit = alljobs.job.length > 0 ? alljobs.job[0].city : ""
      setaddress(cit)
      //
      var latii = alljobs.job.length > 0 ? alljobs.job[0].latitude : ""
      setlatt(latii)
      //
      var longii = alljobs.job.length > 0 ? alljobs.job[0].longitude : ""
      setlangg(longii)
      //
      var over = alljobs.job.length > 0 ? alljobs.job[0].company_overview : ""
      setcompany_overview(over)
      setresponscomp(over)
      setmaindutycomp(over.length)
      setovercompany(over.length)
      //
      var benn = alljobs.benefit
      setbenefits([...benn])
    }).catch((error) => {
      console.log("error", error);

      setload1(false)
    })
  }, []);
  const onchange = (event, ev) => {
    const ischecked = event
    if (ischecked) {
      benefits.push({
        benefits: ev
      })
      setbenefits(benefits)
    } else {
      const index = benefits.indexOf(ev)
      benefits.splice(index, 1)
      setbenefits(benefits)

    }
  }



  const [require1, setrequire1] = useState(false)
  const [require2, setrequire2] = useState(false)
  const [require3, setrequire3] = useState(false)
  const [require4, setrequire4] = useState(false)
  const [require6, setrequire6] = useState(false)
  const [require7, setrequire7] = useState(false)
  const [require8, setrequire8] = useState(false)
  const [require12, setrequire12] = useState(false)
  const [changehead, setchangehead] = useState(false)
  const createjob = async () => {
    responsibility.push({
      resp: respons
    })
    setresponsibility(responsibility)
    await fetch(`${config['baseUrl']}/jobs/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "user_id": Number(localStorage.getItem("userid")),
        "first_name": first_name,
        "last_name": last_name,
        "company_name": company_name,
        "company_size": company_size,
        "phone_number": phone_number,
        "hear_about_us": hear_about_us,
        "job_title": job_title,
        "location": address.split(",")[0],
        "city": location,
        "latitude": corr.lat !== null && corr.lat !== undefined && corr.lat !== "" ? corr.lat : latt,
        "longitude": corr.lng !== null && corr.lng !== undefined && corr.lng !== "" ? corr.lng : langg,
        "state": state,
        "zip_code": zip_code,
        "employment": employment,
        "experience_level": experience_level,
        "education": education,
        "pay_rate_minimum": pay_rate_minimum,
        "pay_rate_maximum": pay_rate_maximum,
        "pay_rate_type": pay_rate_type,
        "other_compensations": other_compensations,
        "display_amount": display_amount == false ? 1 : 0,
        "description": description,
        "company_overview": responscomp,
        "travel": travel,
        "position_opened": position_opened,
        "salary": `${pay_rate_minimum}-${pay_rate_maximum}`,
        "linkedin": linked,
        "facebook": facebook,
        "twitter": twitter,
        "blog": blog,
        "id": jobid,
        "benefits": benefits,
        "responsibility": responsibility,
        "email": localStorage.getItem("email")
      })
    }).then(res => {
      // 
      setchangehead(true)
      if (res.status !== 200 && res.status !== 201) {
        
      }
      return res.json();
    }).then((response) => {

      if (response.message == "The Job has been successfully Updated.") {
        setTimeout(() => {
          window.location = "/manage-job"
        }, 3000);
      }
      else {
        console.log("Something went wrong..")
      }

    }).catch((error) => {
      console.log(error)

    })

  }
  const [step1, setstep1] = useState(true)
  const [step2, setstep2] = useState(false)
  const [step3, setstep3] = useState(false)
  const step1func = () => {
    if (first_name !== "" && last_name !== "" && company_name !== "") {
      setstep2(true)
      setstep1(false)
      setstep3(false)
    }
    else {
      if (first_name == "") {
        setrequire1(true)
        setmodal1(true)
      }
      if (last_name == "") {
        setrequire2(true)
        setmodal1(true)

      }
      if (company_name == "") {
        setrequire3(true)
        setmodal1(true)

      }
    }
  }
  const step2func = () => {
    if (descriptioncount>0 && descriptioncount > 2500) {
      setBilingModalIsOpenzip1(true)
    }
    else if (rescon == true && (mainduty < 50 || mainduty > 2500)) {
      setBilingModalIsOpenzip2(true)
    }
    else if (overcompany>0 && (overcompany < 50 || overcompany > 2500)) {
      setBilingModalIsOpenzip1comp(true)
    }
    else {
      if (job_title !== "" && address !== "" && state !== "" && employment !== "" && experience_level !== "" && education !== "" && respons !== "" ) {
        if (zip_code !== "" && zip_code.length < 5) {
          setBilingModalIsOpenzip(true)
        }
      //  else if (corr.lat==null&&address!=="" ) {
      //     setcheckcity(true)
      //   }
        else {
          setstep2(false)
          setstep1(false)
          setstep3(true)
        }
      }
      else {
        if (job_title == "") {
          setrequire4(true)
        setmodal1(true)

        }
        if (address == "") {
          // setrequire5(true)
          setcheckcity(true)
        setmodal1(true)

        }
        if (state == "") {
          setrequire6(true)
        setmodal1(true)

        }
        if (zip_code == "") {
          setrequire7(true)
        setmodal1(true)

        }
        if (employment == "") {
          setrequire8(true)
        setmodal1(true)

        }
        if (experience_level == "") {
          setrequire8(true)
        setmodal1(true)

        }
        if (education == "") {
          setrequire8(true)
        setmodal1(true)

        }

        if (respons == "") {
          setrequire12(true)
        setmodal1(true)

        }
      }
    }
  }
  const step2backfunc = () => {
    setstep2(false)
    setstep1(true)
    setstep3(false)
  }
  const step3backfunc = () => {
    setstep2(true)
    setstep1(false)
    setstep3(false)
  }
  const [address, setaddress] = useState("")
  const [corr, setcorr] = useState({
    lat: null,
    lng: null
  })
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)
        
    const getZipCode = result?.[0]?.address_components?.find(
      (component) => component?.types?.includes('postal_code')
      )?.long_name;
      if(getZipCode){
        console.log("getZipCode", getZipCode)
        statecorrect(getZipCode)
      }
      const getCity =
        result?.[0]?.address_components?.find((component) =>
          component?.types?.includes("locality")
        )?.long_name || "";
        if(getCity){
          setlocation(getCity);
        }

    const ll = await getLatLng(result[0])
    setstate(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
    setaddress(value)
    setcorr(ll)
    setlatt(corr.lat)
    setlangg(corr.lng)
  }

  const clearForm = ()=> {
    // setmainduty('')
    // setmaindutycomp("")
    // setovercompany('')
    // setfirst_name("")
    // setlast_name("")
    // setcompany_name("")
    // setcompany_size("")
    // setcompany_size1("")
    // setphone_number("")
    // sethear_about_us("")
    setjob_title("")
    setaddress("")
    setlocation("")
    setstate("")
    setzip_code("")
    setemployment("")
    setexperience_level("")
    seteducation("")
    setpay_rate_minimum("")
    setpay_rate_maximum("")
    setpay_rate_type("")
    setother_compensations("")
    setdescription("")
    setcompany_overview("")
    settravel("")
    setposition_opened("")
    setdisplay_amount(false)
    setbenefits([])
    setlinked("")
    settwitter("")
    setfacebook("")
    setblog("")
    setresponsibility([])
    setlatt(null)
    setpay_rate_typeEX(2)
    setlangg(null)
    setcheckcity(false)
    setrespons("")
    setresponscomp("")
    seterrzip(false)
    setdescriptioncount("")
  }
  return (
    <>
      {load1 == false ? (
        <>
          {allArray.length > 0
            ? allArray.map((val) => (
                <div className="container-fluid" id="Employee">
                  <div className="container">
                    <Employernav />
                    {/* header======================================================================*/}

                    <div className="main-content emp-sign-up">
                      {step1 == true ? (
                        <div className="col-lg-6 col-12 p-0 ">
                          <div className="d-flex">
                            <div className="w-50">
                              <div className="form-group ">
                                <label htmlFor="exampleInputEmail1">
                                  First Name <span className="allred">*</span>
                                </label>
                                <input
                                  onChange={(e) =>
                                    setfirst_name(e.target.value)
                                  }
                                  // defaultValue={first_name !== "" ? first_name : val.job && val.job.length > 0 && val.job[0].first_name !== "" ? val.job[0].first_name : ""}
                                  value={first_name}
                                  className="employee-input-email"
                                  type="text"
                                  placeholder="First Name"
                                />
                                {require1 == true ? (
                                  <small
                                    className="text-danger"
                                    style={{ color: "red" }}
                                  >
                                    Please complete this field
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="w-50 ml-1">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Last Name <span className="allred">*</span>
                                </label>
                                <input
                                  onChange={(e) => setlast_name(e.target.value)}
                                  // defaultValue={last_name !== "" ? last_name : val.job && val.job.length > 0 && val.job[0].last_name !== "" ? val.job[0].last_name : ""}
                                  value={last_name}
                                  className="employee-input-email"
                                  type="text"
                                  placeholder="Last Name"
                                />
                                {require2 == true ? (
                                  <small
                                    className="text-danger"
                                    style={{ color: "red" }}
                                  >
                                    Please complete this field
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex btm-frm-mgn">
                            <div className="w-100">
                              <label htmlFor="exampleInputEmail1">
                                Company Name <span className="allred">*</span>
                              </label>
                              <input
                                onChange={(e) =>
                                  setcompany_name(e.target.value)
                                }
                                // defaultValue={company_name !== "" ? company_name : val.job && val.job.length > 0 && val.job[0].company_name !== "" ? val.job[0].company_name : ""}
                                value={company_name}
                                className="employee-input-email"
                                type="text"
                                placeholder="Company Name"
                              />
                              {require3 == true ? (
                                <small
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  Please complete this field
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="d-flex btm-frm-mgn">
                            {company_size !== "" ? (
                              <div className="w-100">
                                <label htmlFor="exampleInputEmail1">
                                  Company Size
                                </label>
                                <div className="role role2">
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "1-49" ? true : false : false}
                                      checked={
                                        company_size == "1-49" ? true : false
                                      }
                                      onChange={(e) => setcompany_size("1-49")}
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">1-49</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "50-149" ? true : false : false}
                                      checked={
                                        company_size == "50-149" ? true : false
                                      }
                                      onChange={(e) =>
                                        setcompany_size(e.target.value)
                                      }
                                      value="50-149"
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">50-149</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "150-249" ? true : false : false}
                                      checked={
                                        company_size == "150-249" ? true : false
                                      }
                                      onChange={(e) =>
                                        setcompany_size(e.target.value)
                                      }
                                      value="150-249"
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">150-249</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "250-349" ? true : false : false}
                                      checked={
                                        company_size == "250-349" ? true : false
                                      }
                                      onChange={(e) =>
                                        setcompany_size("250-349")
                                      }
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">250-349</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "350-449" ? true : false : false}
                                      checked={
                                        company_size == "350-449" ? true : false
                                      }
                                      onChange={(e) =>
                                        setcompany_size("350-449")
                                      }
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">350-449</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "450-549" ? true : false : false}
                                      checked={
                                        company_size == "450-549" ? true : false
                                      }
                                      onChange={(e) =>
                                        setcompany_size("450-549")
                                      }
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">450-549</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "550-649" ? true : false : false}
                                      checked={
                                        company_size == "550-649" ? true : false
                                      }
                                      onChange={(e) =>
                                        setcompany_size("550-649")
                                      }
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">550-649</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "650-749" ? true : false : false}
                                      checked={
                                        company_size == "650-749" ? true : false
                                      }
                                      onChange={(e) =>
                                        setcompany_size("650-749")
                                      }
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">650-749</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "750-999" ? true : false : false}
                                      checked={
                                        company_size == "750-999" ? true : false
                                      }
                                      onChange={(e) =>
                                        setcompany_size("750-999")
                                      }
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">750-999</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      // checked={company_size !== null && company_size !== undefined && company_size !== "" ? company_size == "1000+" ? true : false : false}
                                      checked={
                                        company_size == "1000+" ? true : false
                                      }
                                      onChange={(e) => setcompany_size("1000+")}
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="sp-clr">1000+</span>
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <div className="w-100">
                                <label htmlFor="exampleInputEmail1">
                                  Company Size
                                </label>
                                <div className="role role2">
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("1-49");
                                        setcompany_size("1-49");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">1-49</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("50-149");
                                        setcompany_size("50-149");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">50-149</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("150-249");
                                        setcompany_size("150-249");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">150-249</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("250-349");
                                        setcompany_size("250-349");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">250-349</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("350-449");
                                        setcompany_size("350-449");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">350-449</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("450-549");
                                        setcompany_size("450-549");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">450-549</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("550-649");
                                        setcompany_size("550-649");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">550-649</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("650-749");
                                        setcompany_size("650-749");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">650-749</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("750-999");
                                        setcompany_size("750-999");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">750-999</span>
                                  </label>
                                  <label className="mx-2">
                                    <input
                                      onChange={(e) => {
                                        setcompany_size1("1000+");
                                        setcompany_size("1000+");
                                      }}
                                      type="radio"
                                      name="radiooo"
                                    />
                                    <span className="sp-clr">1000+</span>
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="d-flex btm-frm-mgn">
                            <div className="w-100">
                              <label htmlFor="exampleInputEmail1">
                                Phone Number:
                              </label>
                              <InputMask
                                {...props}
                                mask="(999) 999-9999"
                                // defaultValue={phone_number !== "" ? phone_number : val.job && val.job.length > 0 && val.job[0].phone_number !== "" ? val.job[0].phone_number : ""}
                                value={phone_number}
                                className="form-control employee-input-email job-search w-100"
                                onChange={(e) =>
                                  setphone_number(e.target.value.toString())
                                }
                                maskChar=" "
                                placeholder="(xxx) xxx-xxxx"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="w-100">
                              <label htmlFor="exampleInputEmail1">
                                How did you hear about us?
                              </label>
                              <select
                                onChange={(e) =>
                                  sethear_about_us(e.target.value)
                                }
                                className="employee-input-email"
                                style={{ height: "45px" }}
                                name
                                id
                              >
                                {hear_about_us !== "" &&
                                hear_about_us !== null &&
                                hear_about_us !== undefined ? (
                                  <option value={hear_about_us}>
                                    {hear_about_us}
                                  </option>
                                ) : (
                                  <option value="Select Option">
                                    Select Option
                                  </option>
                                )}
                                <option value="TV">TV</option>
                                <option value="Radio (AM/FM/XM)">
                                  Radio (AM/FM/XM)
                                </option>
                                <option value="Search Engine">
                                  Search Engine
                                </option>
                                <option value="Facebook">Facebook</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Tik Tok">Tik Tok</option>
                                <option value="LinkedIn">LinkedIn</option>
                                <option value="Twitter">Twitter</option>
                                <option value="YouTube">YouTube</option>
                                <option value="Billboard">Billboard</option>
                                <option value="Word of Mouth">
                                  Word of Mouth
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="main-content-session inner-session">
                            <Link
                              onClick={() => step1func()}
                              className="send-invitation fgh"
                            >
                              CONTINUE
                            </Link>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {step2 == true ? (
                        <>
                          <div className="col-lg-7 col-md-7 col-12 p-0 mt-5">
                              <div className="mb-5 !max-w-[200px] !width-[200px] clear-form-container">
                                <div onClick={clearForm} className="clear-form">
                                  CLEAR FORM
                                </div>
                              </div>
                            <div className="d-flex my-2">
                              <div className="w-100">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    Job Title <span className="allred">*</span>
                                  </label>
                                  <input
                                    onChange={(e) =>
                                      setjob_title(e.target.value)
                                    }
                                    className="employee-input-email"
                                    // defaultValue={job_title !== "" ? job_title : val.job && val.job.length > 0 && val.job[0].job_title !== "" ? val.job[0].job_title : ""} type="text" placeholder="Job Title" />
                                    value={job_title}
                                    type="text"
                                    placeholder="Job Title"
                                  />
                                  {require4 == true ? (
                                    <small
                                      className="text-danger"
                                      style={{ color: "red" }}
                                    >
                                      Please complete this field
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex my-2">
                              <div className="w-100">
                                <label htmlFor="exampleInputEmail1">
                                  Location Street Address
                                </label>
                                <PlacesAutocomplete
                                  value={address.split(",")[0]}
                                  onChange={setaddress}
                                  onSelect={handleSelect}
                                  searchOptions={{
                                    componentRestrictions: { country: "us" },
                                  }}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div>
                                      <input
                                        id="exampleInputPassword1"
                                        style={{ height: 40 }}
                                        className="form-control employee-input-email job-search w-25"
                                        {...getInputProps({
                                          placeholder: "Street Address",
                                          className: "location-search-input",
                                        })}
                                      />

                                      <div
                                        className={
                                          "autocomplete-dropdown-container mt-1 " +
                                          (suggestions?.length ? "py-2" : "")
                                        }
                                      >
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item p-2 --active"
                                            : "suggestion-item p-2 ";
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                              };
                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              </div>
                            </div>
                            <div className="d-flex my-4">
                              <div className="w-50">
                                <label htmlFor="exampleInputEmail1">
                                  City <span className="allred">*</span>
                                </label>
                                <input
                                  onChange={(e) => {
                                    setcheckcity(false);
                                    setlocation(e.target.value);
                                  }}
                                  className="employee-input-email"
                                  value={location}
                                  type="text"
                                  placeholder="City"
                                />
                                {checkcity == true ? (
                                  <small
                                    className="text-danger"
                                    style={{ color: "red" }}
                                  >
                                    Please complete this field
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="w-25 ml-1 pr-1">
                                <label htmlFor="exampleInputEmail1">
                                  State <span className="allred">*</span>
                                </label>
                                <select
                                  onChange={(e) => setstate(e.target.value)}
                                  style={{
                                    width: "60px !important",
                                    height: "40px",
                                  }}
                                  className="employee-input-ema"
                                  name
                                  id
                                  value={state}
                                >
                                  {state !== "" &&
                                  state !== null &&
                                  state !== undefined ? (
                                    <option selected value={state}>
                                      {state}
                                    </option>
                                  ) : (
                                    <option selected value="Select">
                                      Select
                                    </option>
                                  )}
                                  <option value="AL">Alabama</option>
                                  <option value="AK">Alaska</option>
                                  <option value="AZ">Arizona</option>
                                  <option value="AR">Arkansas</option>
                                  <option value="CA">California</option>
                                  <option value="CO">Colorado</option>
                                  <option value="CT">Connecticut</option>
                                  <option value="DE">Delaware</option>
                                  <option value="DC">
                                    District Of Columbia
                                  </option>
                                  <option value="FL">Florida</option>
                                  <option value="GA	">Georgia</option>
                                  <option value="HI">Hawaii</option>
                                  <option value="ID">Idaho</option>
                                  <option value="IL">Illinois</option>
                                  <option value="IN">Indiana</option>
                                  <option value="IA">Iowa</option>
                                  <option value="KS">Kansas</option>
                                  <option value="KY">Kentucky</option>
                                  <option value="LA">Louisiana</option>
                                  <option value="ME">Maine</option>
                                  <option value="MD">Maryland</option>
                                  <option value="MA">Massachusetts</option>
                                  <option value="MI">Michigan</option>
                                  <option value="MN">Minnesota</option>
                                  <option value="MS">Mississippi</option>
                                  <option value="MO">Missouri</option>
                                  <option value="MT">Montana</option>
                                  <option value="NE">Nebraska</option>
                                  <option value="NV">Nevada</option>
                                  <option value="NH">New Hampshire</option>
                                  <option value="NJ">New Jersey</option>
                                  <option value="NM">New Mexico</option>
                                  <option value="NY">New York</option>
                                  <option value="NC">North Carolina</option>
                                  <option value="ND">North Dakota</option>
                                  <option value="OH">Ohio</option>
                                  <option value="OK">Oklahoma</option>
                                  <option value="OR">Oregon</option>
                                  <option value="PA">Pennsylvania</option>
                                  <option value="RI">Rhode Island</option>
                                  <option value="SC">South Carolina</option>
                                  <option value="SD">South Dakota</option>
                                  <option value="TN">Tennessee</option>
                                  <option value="TX">Texas</option>
                                  <option value="UT">Utah</option>
                                  <option value="VT">Vermont</option>
                                  <option value="VA">Virginia</option>
                                  <option value="WA">Washington</option>
                                  <option value="WV">West Virginia</option>
                                  <option value="WI">Wisconsin</option>
                                  <option value="WY">Wyoming</option>
                                </select>
                                {require6 == true ? (
                                  <small
                                    className="text-danger"
                                    style={{ color: "red" }}
                                  >
                                    Please complete this field
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="w-25 ml-1">
                                <label htmlFor="exampleInputEmail1">Zip</label>
                                <input
                                  onChange={(e) => statecorrect(e.target.value)}
                                  maxLength={20}
                                  className="employee-input-email"
                                  value={zip_code}
                                  type="text"
                                  placeholder="Zip Code"
                                />
                                {errzip == true ? (
                                  <small
                                    className="text-danger"
                                    style={{ color: "red" }}
                                  >
                                    *Zip Code requires at least five digits
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="d-flex flex-column mt-4 mb-0">
                              <h5 className="mb-0">
                                Job Requirements{" "}
                                <span className="allred">*</span>
                              </h5>
                              {require8 == true ? (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                  }}
                                  className="mb-2"
                                >
                                  *Zip code needs to be 5 digits
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="row mb-2 mt-0 pt-0 role role2 main-content-session job-req-post-job">
                              {
                                <div className="col-lg-4 col-12">
                                  <h5 className="mb-3 hirehome">Employment</h5>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        // checked={
                                        //   employment == "Full-Time"
                                        //     ? true
                                        //     : false
                                        // }
                                      checked={employment}
                                        onChange={(e) =>
                                          setemployment("Full-Time")
                                        }
                                        type="radio"
                                        name="radio12"
                                      />
                                      <span className="sp-clr">Full-Time</span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        // checked={
                                        //   employment !== "" &&
                                        //   employment == "Part-Time"
                                        //     ? true
                                        //     : false
                                      // }
                                      checked={employment !== "" &&
                                           employment == "Part-Time"
                                             ? true
                                             : false}
                                        onChange={(e) =>
                                          setemployment(e.target.value)
                                        }
                                        value="Part-Time"
                                        type="radio"
                                        name="radio12"
                                      />
                                      <span className="sp-clr">Part-Time</span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          employment !== "" &&
                                          employment == "Contract/Temporary"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setemployment(e.target.value)
                                        }
                                        value="Contract/Temporary"
                                        type="radio"
                                        name="radio12"
                                      />
                                      <span className="sp-clr">
                                        Contract/Temporary
                                      </span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          employment !== "" &&
                                          employment == "Internship"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setemployment(e.target.value)
                                        }
                                        value="Internship"
                                        type="radio"
                                        name="radio12"
                                      />
                                      <span className="sp-clr">Internship</span>
                                    </label>
                                  </div>
                                </div>
                              }
                              {
                                <div className="col-lg-4 col-12">
                                  <h5 className="mb-3 hirehome">
                                    Experience Level
                                  </h5>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          experience_level == "Not-Disclosed"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setexperience_level("Not-Disclosed")
                                        }
                                        type="radio"
                                        name="radio2"
                                      />
                                      <span className="sp-clr">
                                        Not-Disclosed
                                      </span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          experience_level == "Less Than 1 Year"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setexperience_level(
                                            "Less Than 1 Year"
                                          )
                                        }
                                        type="radio"
                                        name="radio2"
                                      />
                                      <span className="sp-clr">
                                        Less Than 1 Year
                                      </span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          experience_level == "1-2 Years"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setexperience_level("1-2 Years")
                                        }
                                        type="radio"
                                        name="radio2"
                                      />
                                      <span className="sp-clr">1-2 Years</span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          experience_level == "2-5 Years"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setexperience_level("2-5 Years")
                                        }
                                        type="radio"
                                        name="radio2"
                                      />
                                      <span className="sp-clr">2-5 Years</span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          experience_level == "5+ Years"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setexperience_level("5+ Years")
                                        }
                                        type="radio"
                                        name="radio2"
                                      />
                                      <span className="sp-clr">5+ Years</span>
                                    </label>
                                  </div>
                                </div>
                              }
                              {
                                <div className="col-lg-4 col-12">
                                  <h5 className="mb-3 hirehome">Education</h5>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          education == "Not Specified"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          seteducation(e.target.value)
                                        }
                                        value="Not Specified"
                                        type="radio"
                                        name="radio3"
                                      />
                                      <span className="sp-clr">
                                        Not Specified
                                      </span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          education == "High School"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          seteducation(e.target.value)
                                        }
                                        value="High School"
                                        type="radio"
                                        name="radio3"
                                      />
                                      <span className="sp-clr">
                                        High School
                                      </span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          education == "Associates Degree"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          seteducation(e.target.value)
                                        }
                                        value="Associates Degree"
                                        type="radio"
                                        name="radio3"
                                      />
                                      <span className="sp-clr">
                                        Associates Degree
                                      </span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          education == "Bachelor’s Degree"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          seteducation(e.target.value)
                                        }
                                        value="Bachelor’s Degree"
                                        type="radio"
                                        name="radio3"
                                      />
                                      <span className="sp-clr">
                                        Bachelor’s Degree
                                      </span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          education == "Graduate Degree"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          seteducation(e.target.value)
                                        }
                                        value="Graduate Degree"
                                        type="radio"
                                        name="radio3"
                                      />
                                      <span className="sp-clr">
                                        Graduate Degree
                                      </span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          education == "Doctorate"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          seteducation(e.target.value)
                                        }
                                        value="Doctorate"
                                        type="radio"
                                        name="radio3"
                                      />
                                      <span className="sp-clr">Doctorate</span>
                                    </label>
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="d-flex mt-4">
                              <h5>Pay Rate</h5>
                            </div>
                            <div className="d-flex flex-direction-response-column-price align-items-center amount-input-decoration">
                              {pay_rate_type == "Hourly" ? (
                                <CurrencyInput
                                  boolean={true}
                                  prefix="$"
                                  maxLength={7}
                                  decimalScale={pay_rate_typeEX}
                                  value={
                                    pay_rate_minimum !== ""
                                      ? pay_rate_minimum
                                      : val.job &&
                                        val.job.length > 0 &&
                                        val.job[0].pay_rate_minimum !== ""
                                      ? parseInt(val.job[0].pay_rate_minimum)
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setpay_rate_minimum(e);
                                  }}
                                />
                              ) : (
                                <CurrencyInput
                                  precision="0"
                                  prefix="$"
                                  maxLength={13}
                                  value={
                                    pay_rate_minimum !== ""
                                      ? pay_rate_minimum
                                      : val.job &&
                                        val.job.length > 0 &&
                                        val.job[0].pay_rate_minimum !== ""
                                      ? parseInt(val.job[0].pay_rate_minimum)
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setpay_rate_minimum(e);
                                  }}
                                />
                              )}
                              <span className="mx-2 sp-clr">To</span>
                              {pay_rate_type == "Annually" ? (
                                <CurrencyInput
                                  precision="0"
                                  prefix="$"
                                  maxLength={13}
                                  value={
                                    pay_rate_maximum !== ""
                                      ? pay_rate_maximum
                                      : val.job &&
                                        val.job.length > 0 &&
                                        val.job[0].pay_rate_maximum !== ""
                                      ? parseInt(val.job[0].pay_rate_maximum)
                                      : ""
                                  }
                                  onChange={(e) => setpay_rate_maximum(e)}
                                />
                              ) : (
                                <CurrencyInput
                                  boolean={true}
                                  prefix="$"
                                  maxLength={7}
                                  decimalScale={pay_rate_typeEX}
                                  value={
                                    pay_rate_maximum !== ""
                                      ? pay_rate_maximum
                                      : val.job &&
                                        val.job.length > 0 &&
                                        val.job[0].pay_rate_maximum !== ""
                                      ? parseInt(val.job[0].pay_rate_maximum)
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setpay_rate_maximum(e);
                                  }}
                                />
                              )}
                              {
                                <div className="d-flex role role2">
                                  <div className="d-flex  align-items-center ml-2 ">
                                    <label>
                                      <input
                                        checked={
                                          pay_rate_type == "Hourly"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          setpay_rate_minimum("");
                                          setpay_rate_maximum("");
                                          setpay_rate_type("Hourly");
                                          setpay_rate_typeEX(0);
                                        }}
                                        type="radio"
                                        name="radio4"
                                      />
                                      <span className="sp-clr">Hourly</span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center ml-2">
                                    <label>
                                      <input
                                        checked={
                                          pay_rate_type == "Annually"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          setpay_rate_typeEX(2);
                                          setpay_rate_type("Annually");
                                        }}
                                        type="radio"
                                        name="radio4"
                                      />
                                      <span className="sp-clr">Annually</span>
                                    </label>
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="d-flex align-items-center my-3 role role2">
                              {display_amount == false ? (
                                <label style={{ flexDirection: "column" }}>
                                  <input
                                    type="radio"
                                    name="radio5"
                                    checked={display_amount}
                                    onClick={() => setdisplay_amount(true)}
                                  />
                                  <span className="sp-clr">
                                    {" "}
                                    Do not display compensation in job
                                    description
                                  </span>
                                  <p style={{ margin: "5px 0px 5px 25px" }}>
                                    *Displaying the pay rate is recommended to
                                    increase job viewing
                                  </p>
                                </label>
                              ) : (
                                <label style={{ flexDirection: "column" }}>
                                  <input
                                    type="radio"
                                    name="radio5"
                                    onClick={() => setdisplay_amount(false)}
                                    checked={display_amount}
                                  />
                                  <span className="sp-clr">
                                    {" "}
                                    Do not display compensation in job
                                    description
                                  </span>
                                  <p style={{ margin: "5px 0px 5px 25px" }}>
                                    *Displaying the pay rate is recommended to
                                    increase job viewing
                                  </p>
                                </label>
                              )}
                            </div>
                            <div className="d-flex flex-column role role2 my-4">
                              <p className="m-0 mb-0">
                                Other compensation included:
                              </p>
                              <div className="d-flex flex-direction-response-column">
                                <div className="d-flex align-items-center ml-0 my-2">
                                  <label>
                                    <input
                                      checked={
                                        other_compensations == "Bonus"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        setother_compensations("Bonus")
                                      }
                                      type="radio"
                                      name="radio6"
                                    />
                                    <span className="sp-clr">Bonus</span>
                                  </label>
                                </div>
                                <div className="d-flex align-items-center ml-2 flex-direction-response-column-ml-0 my-2">
                                  <label>
                                    <input
                                      checked={
                                        other_compensations == "Commission"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        setother_compensations("Commission")
                                      }
                                      type="radio"
                                      name="radio6"
                                    />
                                    <span className="sp-clr">Commission</span>
                                  </label>
                                </div>
                                <div className="d-flex align-items-center ml-2 flex-direction-response-column-ml-0 my-2">
                                  <label>
                                    <input
                                      checked={
                                        other_compensations == "Tips"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        setother_compensations("Tips")
                                      }
                                      type="radio"
                                      name="radio6"
                                    />
                                    <span className="sp-clr">Tips</span>
                                  </label>
                                </div>
                                <div className="d-flex align-items-center ml-2 flex-direction-response-column-ml-0 my-2">
                                  <label>
                                    <input
                                      checked={
                                        other_compensations == "None"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        setother_compensations("None")
                                      }
                                      type="radio"
                                      name="radio6"
                                    />
                                    <span className="sp-clr">None</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              <h5>Job Description</h5>
                              <p>
                                This Job Description has a 2,500 character limit
                                that includes HTML formatting.
                              </p>
                              <div style={{ textAlign: "right" }} id="counter">
                                {descriptioncount !== "" &&
                                descriptioncount !== null &&
                                descriptioncount !== undefined
                                  ? descriptioncount
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : val.job &&
                                    val.job.length > 0 &&
                                    val.job[0].description !== ""
                                  ? val.job[0].description.length
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "0"}
                                /2,500
                              </div>
                            </div>
                            <div className="form-group create-resume1 d-flex flex-column">
                              <CKEditor
                                editor={ClassicEditor}
                                data={description}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  CharacterCount3(data);
                                }}
                              />
                            </div>
                            <div>
                              <div className="d-flex flex-column mt-4 mb-0">
                                <h5 className="mb-0">
                                  {" "}
                                  Main Duties &amp; Responsibilities{" "}
                                  <span className="allred">*</span>
                                </h5>
                                {require12 == true ? (
                                  <small
                                    className="text-danger mb-2"
                                    style={{ color: "red" }}
                                  >
                                    Please complete this field
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                              <p>
                                The Main Duties & Responsibilities have a 2,500
                                character limit that includes HTML formatting
                                (50 characters minimum).
                              </p>
                            </div>
                            <div className="form-group create-resume1 d-flex flex-column">
                              <div style={{ textAlign: "right" }} id="counter">
                                {mainduty !== "" &&
                                mainduty !== null &&
                                mainduty !== undefined
                                  ? mainduty
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "0"}
                                /2,500
                              </div>
                              <CKEditor
                                editor={ClassicEditor}
                                data={respons}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  CharacterCount2(data);
                                  setrequire12(false);
                                }}
                              />
                            </div>
                            <div className="mt-4">
                              <h5 className="mb-2 mt-2">Company Overview</h5>
                              <p>
                                The Company Overview has a 2,500 character limit
                                that includes HTML formatting.
                              </p>
                              <div
                                style={{
                                  marginTop: "0.25rem",
                                  textAlign: "right",
                                }}
                                id="counter"
                              >
                                {overcompany !== "" &&
                                overcompany !== null &&
                                overcompany !== undefined
                                  ? overcompany
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "0"}
                                /2,500
                              </div>
                              <div className="form-group create-resume1 d-flex flex-column">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={responscomp}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    CharacterCount(data);
                                    // setoverview(data)
                                    // setovercompany(data.length);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-flex mt-4">
                              <h5>Benefits Offered</h5>
                            </div>
                            {
                              <div className="row my-2">
                                <div className="col-lg-4 col-12">
                                  <div className="d-flex flex-column role role2">
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits == "Health Insurance"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Health Insurance"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Health Insurance"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Health Insurance"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Health Insurance"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}

                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Health Insurance
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits == "Dental Insurance"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Dental Insurance"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Dental Insurance"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Dental Insurance"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Dental Insurance"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}

                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Dental Insurance
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits == "Vision Insurance"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Vision Insurance"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Vision Insurance"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Vision Insurance"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Vision Insurance"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Vision Insurance
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) => data.benefits == "401(k)"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits == "401(k)"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="401(k)"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="401(k)"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="401(k)"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        401(k)
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits ==
                                            "Paid Time Off (PTO)"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Paid Time Off (PTO)"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Paid Time Off (PTO)"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Paid Time Off (PTO)"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Paid Time Off (PTO)"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Paid Time Off (PTO)
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits == "Life Insurance"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Life Insurance"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Life Insurance"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Life Insurance"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Life Insurance"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Life Insurance
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                  <div className="d-flex flex-column role role2">
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits ==
                                            "Short-Term Disability"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Short-Term Disability"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Short-Term Disability"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Short-Term Disability"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Short-Term Disability"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Short-Term Disability
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits ==
                                            "Long-Term Disability"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Long-Term Disability"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Long-Term Disability"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Long-Term Disability"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Long-Term Disability"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Long-Term Disability
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits == "Referral Bonus"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Referral Bonus"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Referral Bonus"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Referral Bonus"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Referral Bonus"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Referral Bonus
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits ==
                                            "Tuition Reimbursement"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Tuition Reimbursement"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Tuition Reimbursement"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Tuition Reimbursement"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Tuition Reimbursement"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Tuition Reimbursement
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits ==
                                            "Employee Discounts"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Employee Discounts"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Employee Discounts"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Employee Discounts"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Employee Discounts"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Employee Discounts
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits == "Flexible Schedule"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Flexible Schedule"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Flexible Schedule"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Flexible Schedule"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Flexible Schedule"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Flexible Schedule
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                  <div className="d-flex flex-column role role2">
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits ==
                                            "Retirement Options"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Retirement Options"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Retirement Options"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Retirement Options"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Retirement Options"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Retirement Options
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) =>
                                            data.benefits ==
                                            "Relocation Assistance"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) =>
                                                data.benefits ==
                                                "Relocation Assistance"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Relocation Assistance"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Relocation Assistance"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Relocation Assistance"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Relocation Assistance
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) => data.benefits == "Other"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) => data.benefits == "Other"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="Other"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="Other"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="Other"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        Other
                                      </span>
                                    </label>
                                    <label>
                                      {benefits.length > 0 ? (
                                        benefits.filter(
                                          (data) => data.benefits == "None"
                                        ).length > 0 ? (
                                          benefits
                                            .filter(
                                              (data) => data.benefits == "None"
                                            )
                                            .map((val) => (
                                              <input
                                                type="checkbox"
                                                checked={
                                                  val !== "" &&
                                                  val !== undefined &&
                                                  val !== null
                                                    ? true
                                                    : false
                                                }
                                                className="mt-1 mr-2"
                                                value="None"
                                                onChange={(e) =>
                                                  onchange(
                                                    e.target.checked,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ))
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-2"
                                            value="None"
                                            onChange={(e) =>
                                              onchange(
                                                e.target.checked,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-2"
                                          value="None"
                                          onChange={(e) =>
                                            onchange(
                                              e.target.checked,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <span
                                        className="resume-data sp-clr"
                                        style={{
                                          borderRadius: "10px",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        None
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            }
                            <div className="d-flex mt-5">
                              <h5>Travel</h5>
                            </div>
                            {
                              <div className="row  role role2 ">
                                <div className="col-lg-4 col-12">
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          travel == "Not Disclosed"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          settravel(e.target.value)
                                        }
                                        value="Not Disclosed"
                                        type="radio"
                                        name="radio1"
                                      />
                                      <span className="sp-clr">
                                        {" "}
                                        Not Disclosed
                                      </span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          travel == "None" ? true : false
                                        }
                                        onChange={(e) =>
                                          settravel(e.target.value)
                                        }
                                        value="None"
                                        type="radio"
                                        name="radio1"
                                      />
                                      <span className="sp-clr">None</span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          travel == "Up to 25%" ? true : false
                                        }
                                        onChange={(e) =>
                                          settravel(e.target.value)
                                        }
                                        value="Up to 25%"
                                        type="radio"
                                        name="radio1"
                                      />
                                      <span className="sp-clr">Up to 25%</span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          travel == "Up to 50%" ? true : false
                                        }
                                        onChange={(e) =>
                                          settravel(e.target.value)
                                        }
                                        value="Up to 50%"
                                        type="radio"
                                        name="radio1"
                                      />
                                      <span className="sp-clr">Up to 50%</span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center my-2">
                                    <label>
                                      <input
                                        checked={
                                          travel == "Road Warrior"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          settravel(e.target.value)
                                        }
                                        value="Road Warrior"
                                        type="radio"
                                        name="radio1"
                                      />
                                      <span className="sp-clr">
                                        Road Warrior
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            }
                            <div className="d-flex my-2">
                              <div className="w-50">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    <h5 className="mb-2 mt-3">
                                      Positions Open
                                    </h5>
                                  </label>
                                  <select
                                    onChange={(e) =>
                                      setposition_opened(e.target.value)
                                    }
                                    className="employee-input-email"
                                    name
                                    id
                                  >
                                    {position_opened !== "" &&
                                    position_opened !== null &&
                                    position_opened !== undefined ? (
                                      <option selected value={position_opened}>
                                        {position_opened}
                                      </option>
                                    ) : (
                                      <option value="Numbers of candidate needed">
                                        Numbers of candidate needed
                                      </option>
                                    )}
                                    {position_opened !== "1" ? (
                                      <option value="1">1</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "2" ? (
                                      <option value="2">2</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "3" ? (
                                      <option value="3">3</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "4" ? (
                                      <option value="4">4</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "5" ? (
                                      <option value="5">5</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "6" ? (
                                      <option value="6">6</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "7" ? (
                                      <option value="7">7</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "8" ? (
                                      <option value="8">8</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "9" ? (
                                      <option value="9">9</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "10" ? (
                                      <option value="10">10</option>
                                    ) : (
                                      ""
                                    )}
                                    {position_opened !== "10+" ? (
                                      <option value="10+">10+</option>
                                    ) : (
                                      ""
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex my-2">
                              <div className="w-75">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    <h5 className="mb-0 mt-3">
                                      Social Media Links
                                    </h5>
                                  </label>
                                  <div className="d-flex">
                                    <div className="w-50 my-2">
                                      <input
                                        onChange={(e) =>
                                          setlinked(e.target.value)
                                        }
                                        value={linked}
                                        className="employee-input-email"
                                        type="text"
                                        placeholder="LinkedIn"
                                      />
                                    </div>
                                    <div className="w-50 my-2  ml-2">
                                      <input
                                        onChange={(e) =>
                                          settwitter(e.target.value)
                                        }
                                        value={twitter}
                                        className="employee-input-email"
                                        type="text"
                                        placeholder="Twitter"
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div className="w-50 my-2">
                                      <input
                                        onChange={(e) =>
                                          setfacebook(e.target.value)
                                        }
                                        value={facebook}
                                        className="employee-input-email"
                                        type="text"
                                        placeholder="Facebook"
                                      />
                                    </div>
                                    <div className="w-50 my-2 ml-2">
                                      <input
                                        onChange={(e) =>
                                          setblog(e.target.value)
                                        }
                                        value={blog}
                                        className="employee-input-email"
                                        type="text"
                                        placeholder="Blog"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="main-content-session inner-session">
                            <Link
                              className="send-invitation fgh"
                              onClick={() => step2backfunc()}
                            >
                              BACK
                            </Link>
                            <Link
                              onClick={() => step2func()}
                              className="send-invitation fgh ml-4"
                            >
                              CONTINUE
                            </Link>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {step3 == true ? (
                        <>
                          <div className="job-form-2 mt-2">
                            <div className="col-12 post-job-3 p-0">
                              <div className={trans}>
                                {trans == "ultra" ? (
                                  <img
                                    height="100px"
                                    width="100px"
                                    className="mr-3"
                                    src={logo}
                                    alt=""
                                  />
                                ) : (
                                  ""
                                )}
                                <div
                                  className="themeeditjobheader px-0"
                                  style={{
                                    width: "80%",
                                    zIndex: 200,
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <h6 className="mb-0 bold-text">
                                      {company_name !== ""
                                        ? company_name
                                        : "Company Name"}
                                    </h6>
                                    <span className="mx-1"> -- </span>
                                    <h6 className="mb-0 bold-text">
                                      {" "}
                                      {job_title !== ""
                                        ? job_title
                                        : "Position Title"}
                                    </h6>
                                  </div>
                                  <p className="my-2">
                                    {location !== "" ? location : ""}
                                  </p>
                                  <p>
                                    {address !== "" ? address : "City"}
                                    <asd className="pl-0 pr-1">,</asd>
                                    {state !== "" ? state : "State"}{" "}
                                    {zip_code !== "" ? zip_code : ""} |{" "}
                                    {employment !== ""
                                      ? employment
                                      : ":Employement"}
                                    {display_amount == false ? (
                                      <>
                                        {" "}
                                        {pay_rate_maximum !== "" &&
                                        pay_rate_maximum !== "$0.00" &&
                                        pay_rate_maximum !== "$0"
                                          ? "|"
                                          : ""}{" "}
                                        {pay_rate_minimum !== "" &&
                                        pay_rate_minimum !== "$0.00" &&
                                        pay_rate_minimum !== "$0"
                                          ? `${pay_rate_minimum}`
                                          : ""}{" "}
                                        {pay_rate_maximum !== "" &&
                                        pay_rate_maximum !== "$0.00" &&
                                        pay_rate_maximum !== "$0"
                                          ? "To"
                                          : ""}{" "}
                                        {pay_rate_maximum !== "" &&
                                        pay_rate_maximum !== "$0.00" &&
                                        pay_rate_maximum !== "$0"
                                          ? `${pay_rate_maximum}`
                                          : ""}{" "}
                                        {pay_rate_maximum !== "" &&
                                        pay_rate_maximum !== "$0.00" &&
                                        pay_rate_maximum !== "$0"
                                          ? `${pay_rate_type}`
                                          : ""}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </div>
                              </div>
                              {responscomp == "" ? (
                                ""
                              ) : (
                                <>
                                  <hr />
                                  <p className="mt-4 bold-text all-tags-edit-3">
                                    Company Overview
                                  </p>
                                  <p className="company-post-job-3-p">
                                    {parse(responscomp)}
                                  </p>
                                </>
                              )}
                              {description == "" ? (
                                ""
                              ) : (
                                <>
                                  <hr />
                                  <p className="mt-4 bold-text all-tags-edit-3">
                                    Job Description
                                  </p>
                                  <p className="company-post-job-3-p">
                                    {parse(description)}
                                  </p>
                                </>
                              )}

                              {respons == "" ? (
                                ""
                              ) : (
                                <>
                                  <hr />
                                  <p className="mt-4 bold-text all-tags-edit-3">
                                    Main Duties &amp; Resposiblites
                                  </p>

                                  <p className="company-post-job-3-p">
                                    {parse(respons)}
                                  </p>
                                </>
                              )}
                              {benefits.length > 0 ? (
                                <>
                                  <hr />
                                  <p className="mt-4 bold-text all-tags-edit-3">
                                    Benefits Offered
                                  </p>
                                  <ul className="hjh">
                                    {benefits.length > 0
                                      ? benefits.map((ben) => (
                                          <li>{ben.benefits}</li>
                                        ))
                                      : ""}
                                  </ul>
                                </>
                              ) : (
                                ""
                              )}

                              <hr />
                              <p className="mt-4 bold-text all-tags-edit-3">
                                Experience Level
                              </p>

                              <p className="company-post-job-3-p">
                                {experience_level}
                              </p>
                              <hr />
                              <p className="mt-4 bold-text all-tags-edit-3">
                                Education
                              </p>

                              <p className="company-post-job-3-p">
                                {education}
                              </p>
                              {position_opened == "" ? "" : <hr />}
                              {position_opened == "" ? (
                                ""
                              ) : (
                                <>
                                  <p className="mt-4 bold-text all-tags-edit-3">
                                    Positions Available
                                  </p>
                                  <p className="company-post-job-3-p">
                                    {position_opened}
                                  </p>
                                </>
                              )}
                              {travel == "" || travel == "None" ? "" : <hr />}
                              {travel == "" || travel == "None" ? (
                                ""
                              ) : (
                                <>
                                  <p className="mt-4 bold-text all-tags-edit-3">
                                    Travel
                                  </p>

                                  <p className="company-post-job-3-p">
                                    {travel}
                                  </p>
                                </>
                              )}
                              {(facebook !== null &&
                                facebook !== undefined &&
                                facebook !== "") ||
                              (twitter !== null &&
                                twitter !== undefined &&
                                twitter !== "") ||
                              (linked !== null &&
                                linked !== undefined &&
                                linked !== "") ||
                              (blog !== null &&
                                blog !== undefined &&
                                blog !== "") ? (
                                <hr />
                              ) : (
                                ""
                              )}
                              {(facebook !== null &&
                                facebook !== undefined &&
                                facebook !== "") ||
                              (twitter !== null &&
                                twitter !== undefined &&
                                twitter !== "") ||
                              (linked !== null &&
                                linked !== undefined &&
                                linked !== "") ||
                              (blog !== null &&
                                blog !== undefined &&
                                blog !== "") ? (
                                <>
                                  <p className="mt-4 bold-text all-tags-edit-3">
                                    Social Links
                                  </p>
                                </>
                              ) : (
                                ""
                              )}
                              {facebook !== null &&
                              facebook !== undefined &&
                              facebook !== "" ? (
                                <p className="company-post-job-3-p">
                                  <span className="mr-0">Facebook</span>:
                                  <span className="ml-1">
                                    {facebook !== null &&
                                    facebook !== undefined &&
                                    facebook !== ""
                                      ? facebook
                                      : ""}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                              {twitter !== null &&
                              twitter !== undefined &&
                              twitter !== "" ? (
                                <p className="company-post-job-3-p">
                                  <span className="mr-0">Twitter</span>:
                                  <span className="ml-1">
                                    {twitter !== null &&
                                    twitter !== undefined &&
                                    twitter !== ""
                                      ? twitter
                                      : ""}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                              {linked !== null &&
                              linked !== undefined &&
                              linked !== "" ? (
                                <p className="company-post-job-3-p">
                                  <span className="mr-0">Linkedin</span>:
                                  <span className="ml-1">
                                    {linked !== null &&
                                    linked !== undefined &&
                                    linked !== ""
                                      ? linked
                                      : ""}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                              {blog !== null &&
                              blog !== undefined &&
                              blog !== "" ? (
                                <p className="company-post-job-3-p">
                                  <span className="mr-0">Blog</span>:
                                  <span className="ml-1">
                                    {blog !== null &&
                                    blog !== undefined &&
                                    blog !== ""
                                      ? blog
                                      : ""}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="main-content-session inner-session">
                              <Link
                                className="send-invitation fgh"
                                onClick={() => step3backfunc()}
                              >
                                BACK
                              </Link>
                              <Link
                                className="send-invitation fgh ml-4"
                                onClick={() => createjob()}
                              >
                                UPDATE JOB
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))
            : ""}

          <Modal
            isOpen={modal1}
            onRequestClose={() => setmodal1(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: modalwidth,
                height: "240px",
                top: "calc(50% - 120px)",
                left: "0",
                right: "0",
                bottom: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#fff",

                outline: "none",
                padding: "20px",

                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className="text-center mt-3">
              <h5>
                Please complete all required fields marked with an asterisk to
                continue
              </h5>
              <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                <Link className="fgh" onClick={() => setmodal1(false)}>
                  OK
                </Link>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={BilingmodalIsOpenzip}
            onRequestClose={() => setBilingModalIsOpenzip(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: modalwidth,
                height: "180px",
                top: "calc(50% - 90px)",
                left: "0",
                right: "0",
                bottom: "100px",

                background: "#fff",

                outline: "none",
                padding: "20px",

                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className="text-center mt-3">
              <h5>Please enter valid zip code</h5>
              <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                <Link
                  className="fgh"
                  onClick={() => setBilingModalIsOpenzip(false)}
                >
                  OK
                </Link>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={BilingmodalIsOpenzip1}
            onRequestClose={() => setBilingModalIsOpenzip1(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: modalwidth,
                height: "240px",
                top: "calc(50% - 120px)",
                left: "0",
                right: "0",
                bottom: "100px",

                background: "#fff",

                outline: "none",
                padding: "20px",

                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className="text-center mt-3">
              <h5>
                Description must be more than 50 characters and less than 2,500
                characters includes HTML formatting
              </h5>
              <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                <Link
                  className="fgh"
                  onClick={() => setBilingModalIsOpenzip1(false)}
                >
                  OK
                </Link>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={BilingmodalIsOpenzip1comp}
            onRequestClose={() => setBilingModalIsOpenzip1comp(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: modalwidth,
                height: "240px",
                top: "calc(50% - 120px)",
                left: "0",
                right: "0",
                bottom: "100px",

                background: "#fff",

                outline: "none",
                padding: "20px",

                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className="text-center mt-3">
              <h5>
                The Company Overview has a 2,500 character limit that includes
                HTML formatting.
              </h5>
              <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                <Link
                  className="fgh"
                  onClick={() => setBilingModalIsOpenzip1comp(false)}
                >
                  OK
                </Link>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={BilingmodalIsOpenzip2}
            onRequestClose={() => setBilingModalIsOpenzip2(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: "100",
              },
              content: {
                position: "absolute",
                margin: "0 auto",
                width: modalwidth,
                height: "240px",
                top: "calc(50% - 120px)",
                left: "0",
                right: "0",
                bottom: "100px",

                background: "#fff",

                outline: "none",
                padding: "20px",

                background: "#fff",
                border: "1px solid #fff",
              },
            }}
          >
            <div className="text-center mt-3">
              <h5>
                Main duties & responsibilities must be more than 50 characters
                and less than 2,500 characters includes HTML formatting
              </h5>
              <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                <Link
                  className="fgh"
                  onClick={() => setBilingModalIsOpenzip2(false)}
                >
                  OK
                </Link>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <FullPageLoader1 />
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  JobsByIdReducer: state.JobsByIdReducer
});

const mapDispatchToProps = (dispatch) => ({
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
  JobByID: (userId) =>
    dispatch(JobByID(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditJob);

// code for scoop solutions