import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { AppliedJob } from "../../actions/Appliedjobaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import { createSearch } from "../../actions/Jobsearchaction";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import dayjs from 'dayjs';
const config = require('../../helpers/config.json');


Modal.setAppElement("#root");

const SavedJobs = (props) => { 
  const [allArray, setallArray] = useState([])
  const [allArray1, setallArray1] = useState([])
  const [load, setload] = useState(false)
  useEffect(() => {
    setload(true)
    fetch(`${config['baseUrl']}/getJob/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response?.saveJob
      var temp = []
      temp = [...response?.saveJob]
      setallArray(temp)
        ;
    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
    setload(true)
    fetch(`${config['baseUrl']}/getAlljobs`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response?.resume
      var temp1 = []
      temp1 = [...response?.resume]
      setallArray1(temp1)

    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
  }, []);
  const DeleteJob = async (id) => {
    await fetch(`${config['baseUrl']}/savejobs/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id,
      })
    }).then(res => {

      if (res.status !== 200 && res.status !== 201) {
        
      }
      return res.json();
    }).then((response) => {

      if (response.message == "Job has been Deleted") {
        window.location = "/save-jobs"
      }
      else {
        console.log("Something went wrong..")
      }

    }).catch((error) => {
      console.log(error)

    })
  }
  return (
    <>
      {
        load == false ?
          <>
            <div className="container-fluid">
              <div className="container">
                <Dropdownnav />
                {/* header======================================================================*/}
                <div className="main-content">
                  <div className="d-flex main-content-session inner-session py-2">
                    <h5>Saved Jobs</h5>
                  </div>
                  <div className="w-100 usr-tbl">
                    <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                      <thead>
                        <tr role="row">
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                            Job</th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '28% !important' }}>
                            Company</th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Date Save
                          </th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          allArray.length > 0 ? allArray.map(value => (
                            <tr>
                              <td>{allArray1.length > 0 ? allArray1.filter(data => data?.id == value?.job_id)?.[0]?.job_title : ""} </td>
                              <td>{allArray1.length > 0 ? allArray1.filter(data => data?.id == value?.job_id)?.[0]?.company_name : ""}</td>
                              <td>{`${value?.saved_at !== null && value?.saved_at !== undefined && value?.saved_at !== "" ? dayjs(new Date(value?.saved_at)).format("MM-DD-YYYY") : ""}`}</td>
                              <td> <a onClick={(e) => DeleteJob(value?.id)} className="tab-remove ml-2" data- toggle="modal" data-target="#exampleModal-remove-3" href><i className="fa fa-trash" aria-hidden="true" /></a></td>
                            </tr>
                          )) : <p style={{ color: "#f48a20" }}>Content Not Found</p>
                        }



                      </tbody>
                    </table>
                  </div>
                  <div className="col-6">
                  </div>
                </div>

              </div>
            </div>
          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  ApplicationHistoryReducer: state.ApplicationHistoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(SavedJobs);

// code for scoop solutions