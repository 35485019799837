const config = require('../helpers/config.json');

export const ReportJobAction = (job_id, reason, details, jobTitle, Location) => {
    return (dispatch) => {
        dispatch({
            type: "SEARCH_RESET",
            loading: false,
            success: false,
        });
        fetch(`${config['baseUrl']}/report/job`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "job_id": Number(job_id), "user_id": localStorage.getItem("userid"), "reason": reason, "details": details })
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) {
            }
            return res.json();
        }).then((response) => {
            dispatch({
                type: "SEARCH_SUCCESS",
                SearchResponse: response,
                loading: true,
                success: true,

            });
            if (response.message == "Job Reported Successfully") {
                window.location = `/search-job?jobtitle=${jobTitle}&location=${Location}${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?``:`&if=${window.location.href.search('false')== -1 ?true:false}`}`
            }
            else {
            }
        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "SEARCH_FAIL",
                SearchResponse: "creation failed",
                loading: true,
                success: false,
                //pageName: PGN.COLORS_PAGE_NAME
            });
            // 
        })
    }
}

// code for scoop solutions

