const initState = {
    theme: false,
}

const ThemeReducer = (state = initState, action) => {

    if (action.type === 'GET_THEME') {
        return {
            ...state,
            theme: action.theme,
        }
    }

    return state;
}
export default ThemeReducer;

// code for scoop solutions