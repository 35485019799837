export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

export const setLoginError = (errorMessage) => ({
  type: SET_LOGIN_ERROR,
  errorMessage: errorMessage,
});

export const clearLoginError = () => ({
  type: CLEAR_LOGIN_ERROR,
});
