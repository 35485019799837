import React, { useState, useEffect } from "react";
// import logo from "../../assets/navbar/logo.png"
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png";
import mbloghome from "../../assets/main-logo.png";
import mbloghomeWhite from "../../assets/main-logo-white.png";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import FullPageLoader from "../fullpageloader/fullPageLoader";
import Employernav from "../dropdownnav/Employernav";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import Footer from "../../components/footer/Footer";
import HomeFooter from "../homeFooter/HomeFooter";

Modal.setAppElement("#root");

const HelpCenter = (props) => {
  return (
    <>
      <div>
        {/* header============================================== */}
        <div className="container-fluid no-fluid" id="Employee">
          <div className="container height-handred">
            {props.GetUserReducer.users &&
            props.GetUserReducer.users &&
            props.GetUserReducer.users.role !== null &&
            props.GetUserReducer.users.role !== undefined &&
            props.GetUserReducer.users.role !== "" &&
            props.GetUserReducer.users.role !== 0 ? (
              <Employernav />
            ) : (
              <Dropdownnav />
            )}
            {/* header======================================================================*/}
            <div className="minH">
              <div className="row mt-5 align-items-center justify-content-center header-center">
                <div className="col-lg-4 align-items-center col-10 d-flex flex-column text-center">
                  {props.GetUserReducer.users &&
                  props.GetUserReducer.users.theme !== null &&
                  props.GetUserReducer.users.theme !== undefined &&
                  props.GetUserReducer.users.theme !== "" ? (
                    props.GetUserReducer.users.theme !== 0 ? (
                      <img
                        style={{ width: "75%" }}
                        src={mbloghomeWhite}
                        alt=""
                      />
                    ) : (
                      <img style={{ width: "75%" }} src={mbloghome} alt="" />
                    )
                  ) : window.location.href.search("true") == -1 ? (
                    <img style={{ width: "75%" }} src={mbloghome} alt="" />
                  ) : (
                    <img style={{ width: "75%" }} src={mbloghomeWhite} alt="" />
                  )}
                  <p className="pt-4">
                    <b>
                      <i>Hire Choices for Job Seekers and Employers</i>
                    </b>
                  </p>
                </div>
                <div className="d-flex flex-column ">
                  <div className="d-flex search-session mt-2 justify-content-center">
                    <div style={{ position: "relative" }}>
                      <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                        <Link
                          className="fgh"
                          to={`/help-center-employee${
                            window.location.href.search("false") == -1 &&
                            window.location.href.search("true") == -1
                              ? `/`
                              : `?if=${
                                  window.location.href.search("false") == -1
                                    ? true
                                    : false
                                }`
                          }`}
                        >
                          JOB SEEKER
                        </Link>
                      </div>
                    </div>
                    <div style={{ position: "relative" }}>
                      <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                        <Link
                          className="fgh"
                          to={`/help-center-employer${
                            window.location.href.search("false") == -1 &&
                            window.location.href.search("true") == -1
                              ? `/`
                              : `?if=${
                                  window.location.href.search("false") == -1
                                    ? true
                                    : false
                                }`
                          }`}
                        >
                          EMPLOYERS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <HomeFooter />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  GetUserReducer: state.GetUserReducer,
  ThemeReducer: state.ThemeReducer,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(HelpCenter);
