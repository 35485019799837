import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader from "../fullpageloader/fullPageLoader";
import Employernav from "../dropdownnav/Employernav";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import parse from 'html-react-parser';
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const HelpCenterEmployee = (props) => {
  const [allArray, setallArray] = useState([])
  const [load, setload] = useState(false)
  useEffect(() => {
    setload(true)
    fetch(`${config['baseUrl']}/help/get`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      setallArray([...allArray, alljobs])

    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
  }, []);
  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container">
          {
            props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.role !== null && props.GetUserReducer.users.role !== undefined && props.GetUserReducer.users.role !== "" && props.GetUserReducer.users.role !== 0 ?
              <Employernav /> :
              <Dropdownnav />
          }
          {/* header======================================================================*/}
          <div className="main-content contact-or-rejection">
            <h4>Job Seeker Help Center</h4>
            <div id="accordion">
              {
                allArray && allArray.length > 0 ? allArray.map(val => (
                  val.job && val.job.length > 0 ? val.job.filter(data => data.role == 0).map(ter => (
                    <div className="card">
                      <div className="card-header" data-toggle="collapse" data-target={`#collapse${ter.id}`} aria-expanded="true" aria-controls={`collapse${ter.id}`}>
                        <h5 className="mb-0">
                          <button className="btn">
                            {ter.question}
                          </button>
                        </h5>
                      </div>
                      <div id={`collapse${ter.id}`} className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          {parse(ter.answer)}
                        </div>
                      </div>
                    </div>
                  )) : ""
                )) : ""
              }
            </div></div></div></div>


    </>
  )
}
const mapStateToProps = (state) => ({
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(HelpCenterEmployee);


// code for scoop solutions