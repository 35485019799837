const config = require('../helpers/config.json');

export const createSearch = (jobtitle, location) => {
    return (dispatch) => {
        dispatch({
            type: "SEARCH_RESETT",
            loading: false,
        });
        var data =
        {
            "jobtitle": jobtitle,
            "location": location,

        }
            ;
        fetch(`${config['baseUrl']}/job/search`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "jobtitle": jobtitle !== "" && jobtitle !== undefined && jobtitle !== null ? jobtitle : "", "location": location !== "" && location !== undefined && location !== null ? location : "" })
        }).then(res => {
            if (res.status !== 200) {
            }
            return res.json();
        }).then((response) => {
            const sData = response.job;
            dispatch({
                type: "SEARCH_SUCCESSS",
                SearchResponse: response,
                searchData: sData,
                loading: true,

            });
        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "SEARCH_FAILL",
                SearchResponse: "creation failed",
                searchData: [],
                loading: true,
                //pageName: PGN.COLORS_PAGE_NAME
            });
            // 
        })
    }
}


// code for scoop solutions
