import { combineReducers } from 'redux';
import persistReducer from "redux-persist/es/persistReducer";
import storage from 'redux-persist/lib/storage';
import Registerreducer from './Registerreducer';
import SearchReducer from "./Jobsearchreducer"
import authReducer from "./loginreducer"
import AppliedJobReducer from "./Appliedjobreducer"
import ReportJobReducer from "./Reportjobreducer"
import ResumeReducer from './Resumereducer';
import employerRegisterReducer from './Employerregisterreducer';
import employerAuthReducer from './Employerloginreducer';
import ApplicationHistoryReducer from './Applicationhistory';
import EmployeeProfileReducer from './Employeeprofilereducer';
import GetUserReducer from './Getuserreducer';
import AllJobsReducer from './Alljobsreducer';
import JobsByIdReducer from "./Jobsbyidreducer"
import ResumeGetByIdReducer from './Resumegetbyidreducer';
import LinkedInReducer from './linked_inData';
import ThemeReducer from './localthemereducer';
import SubscriptionInfo from './Subscriptioninfo';
import loginErrorReducer from './loginErrorReducer';


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}
const mainReducer = combineReducers({
  Registerreducer: Registerreducer,
  SearchReducer: SearchReducer,
  authReducer: authReducer,
  AppliedJobReducer: AppliedJobReducer,
  ReportJobReducer: ReportJobReducer,
  ResumeReducer: ResumeReducer,
  employerRegisterReducer: employerRegisterReducer,
  employerAuthReducer: employerAuthReducer,
  ApplicationHistoryReducer: ApplicationHistoryReducer,
  EmployeeProfileReducer: EmployeeProfileReducer,
  GetUserReducer: GetUserReducer,
  AllJobsReducer: AllJobsReducer,
  JobsByIdReducer: JobsByIdReducer,
  ResumeGetByIdReducer: ResumeGetByIdReducer,
  LinkedInReducer: LinkedInReducer,
  ThemeReducer: ThemeReducer,
  SubscriptionInfo: SubscriptionInfo,
  loginErrorReducer: loginErrorReducer,
});

export default persistReducer(persistConfig, mainReducer);

// code for scoop solutions