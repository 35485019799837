import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Employernav from "../dropdownnav/Employernav";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import parse from 'html-react-parser';
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const PrivacyPolicy = (props) => {
  const [allArray, setallArray] = useState([])
  const [load, setload] = useState(false)
  useEffect(() => {
    setload(true)
    fetch(`${config['baseUrl']}/privacy/get`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      setallArray([...allArray, alljobs])

    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
  }, []);

  return (
    <>
      {
        load == false ?
          <>
            <div className="container-fluid" id="Employee">
              <div className="container">
                {
                  props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.role !== null && props.GetUserReducer.users.role !== undefined && props.GetUserReducer.users.role !== "" && props.GetUserReducer.users.role !== 0 ?
                    <Employernav /> :
                    <Dropdownnav />
                }
                {/* header======================================================================*/}
                <div className="black-theme-privacy main-content emp-sign-up">
                  {
                    allArray && allArray.length > 0 ? allArray.map(val => (
                      val.job && val.job.length > 0 ? val.job.map(ter => (
                        <>
                          {parse(ter.privacy)}
                          <a style={{ position: "relative", top: "-52px", left: "510px", width: "fit-content" }} className="d-lg-block d-md-none d-none" href="mailto:customerservice@hirechoices.com" >customerservice@hirechoices.com</a>
                          <a style={{ width: "fit-content" }} className="d-lg-none d-md-block d-block" href="mailto:customerservice@hirechoices.com" >customerservice@hirechoices.com</a>
                        </>
                      )) : ""
                    )) : ""
                  }
                </div>


              </div>
            </div>

          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);

// code for scoop solutions