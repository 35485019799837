import React from "react";

export const ColorModeContext = React.createContext({});

export const useColorMode = () => {
  const [mode, setMode] = React.useState(() => {
    return localStorage.getItem("colorMode") || "light";
  });

  const toggleColorMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    localStorage.setItem("colorMode", newMode);
  };

  return { mode, toggleColorMode };
};
