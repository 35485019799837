import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import Updateresume from "../components/resumes/Updateresume"
import HomeFooter from "../components/homeFooter/HomeFooter";

const ResumeUpdate = () => {
    return (
        <>
            <EmployeeNav2 />
            <Updateresume />
            <HomeFooter />
        </>
    )
}
export default ResumeUpdate


// code for scoop solutions