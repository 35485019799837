import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { ReportJobAction } from "../../actions/Reportjobaction";
import { ResumeCreate } from "../../actions/Resumeaction"
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import { useLocation, useHistory } from "react-router-dom";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import dayjs from "dayjs";
import parse from 'html-react-parser';
import Employernav from "../dropdownnav/Employernav";

const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const SavedPreviewResume = (props) => {
    const search = useLocation().search;
  const name = new URLSearchParams(search).get('id');
  const [allArray, setallArray] = useState([])
  const [allvolunteer, setallvolunteer] = useState([])
  const [load, setload] = useState(false)
  useEffect(() => {
    setload(true)
    fetch(`${config['baseUrl']}/resume/getbyid/${name}/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      setallvolunteer(response[0].volunteer.length > 0 ? response[0].volunteer : [])
      var temp = []
      temp = [...response]
      setallArray([...temp])
    }).catch((error) => {
      console.log("error", error);

      setload(false)
    })
  }, []);
  const signOut = () => {
    localStorage.removeItem("array");
    localStorage.removeItem("token")
    localStorage.removeItem("userid")
    localStorage.removeItem("username")
    localStorage.removeItem("email")
    localStorage.removeItem("zip")
    window.location = `/`
  }
  return (
    <>
      {
        load == false ?
          <>

            <div className="container-fluid" id="Employee">
              <div className="container">
              <Employernav />
                {/* header======================================================================*/}
                {
                  allArray && allArray.length > 0 ? allArray.map((value) => {
                    return (
                      <div className="main-content">
                        <div className="col-12 mt-5 d-flex align-items-center justify-content-center p-0">
                          <div className="col-lg-8 col-sm-12 preview-full-resume-body">
                            <div className="d-flex flex-column align-items-center justify-content-center py-3">
                              <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                              {props.GetUserReducer.users && props.GetUserReducer.users.Is_packaged !== null && props.GetUserReducer.users.Is_packaged !== undefined && props.GetUserReducer.users.Is_packaged !== "" && props.GetUserReducer.users.Is_packaged !== 0 ?
                                <h3 className="black-theme-resume-preview mb-0">{`${value.job[0].first_name !== "" && value.job[0].first_name !== undefined && value.job[0].first_name !== null ? value.job[0].first_name : ""} ${value.job[0].middle_name !== "" && value.job[0].middle_name !== undefined && value.job[0].middle_name !== null ? value.job[0].middle_name : ""} ${value.job[0].last_name !== "" && value.job[0].last_name !== undefined && value.job[0].last_name !== null ? value.job[0].last_name : ""}`}</h3>
                    :""
                            }
                               
                              </div>
                              <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                                <p className="">{`${value.job[0].city !== "" && value.job[0].city !== undefined && value.job[0].city !== null ? value.job[0].city : ""},  ${value.job[0].state !== "" && value.job[0].state !== undefined && value.job[0].state !== null ? value.job[0].state : ""}`}</p>
                              </div>
                            </div>
                            <hr className="m-0" />

                            {value.job[0].professional_summary !== "" && value.job[0].professional_summary !== undefined && value.job[0].professional_summary !== null ?
                              <div className=" py-3">
                                <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                                  <h5 className="m-0">PROFESSIONAL SUMMARY</h5>
                                
                                </div>
                                <p>{value.job[0].professional_summary !== "" && value.job[0].professional_summary !== undefined && value.job[0].professional_summary !== null ? parse(value.job[0].professional_summary) : ""}</p>
                              </div>
                              : ""}
                            <div className=" py-3">
                              <div className="d-flex align-items-center justify-content-start preview-full-resume-content-area">
                                <h5 className="m-0">EDUCATION</h5>
                               
                              </div>
                              {value.education && value.education.length > 0 ? value.education.map(edu => (
                                <div className="padding-block-in-bottom-justice  flex-direction-in-res-justice d-flex align-items-center justify-content-between">
                                  <div>
                                    <div className="d-flex flex-column">
                                      <p className="margin-zero-in-bottom-justice  m-0" style={{ fontWeight: "800" }}>{`${edu.school !== "" && edu.school !== undefined && edu.school !== null ? edu.school : ""}`}</p>
                                      <div className="d-flex align-items-center">
                                        <p className="margin-zero-in-bottom-justice m-0">{`${edu.level_of_education !== "" && edu.level_of_education !== undefined && edu.level_of_education !== null ? edu.level_of_education : ""}`}</p><p className="m-0 px-1">-</p>
                                        <p className="margin-zero-in-bottom-justice  m-0">{`${edu.major !== "" && edu.major !== undefined && edu.major !== null ? edu.major : ""}`}</p>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="d-flex align-items-end flex-direction-in-row-justice flex-column justify-content-center">
                                    <p>{`${edu.city !== "" && edu.city !== undefined && edu.city !== null ? edu.city : ""}`}</p>
                                    <p>{`${edu.date_of_completion !== "" && edu.date_of_completion !== undefined && edu.date_of_completion !== null ? edu.date_of_completion !== "Currently enrolled" ? dayjs(edu.date_of_completion).format("MM-DD-YYYY") : "Currently enrolled" : "Currently enrolled"}`}</p>
                                  </div>
                                </div>
                              )) : <p className="py-4">(More education info if provided…)</p>
                              }
                            </div>
                            <div className=" py-3">
                              <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                                <div className="d-flex">
                                  <h5 className="m-0">WORK EXPERIENCE </h5>
                                 
                                </div>
                              </div>
                              {
                                value.experience && value.experience.length > 0 ? value.experience.map(exp => (
                                  <>
                                    <div className="padding-block-in-bottom-justice  flex-direction-in-res-justice d-flex align-items-center justify-content-between">
                                      <div>
                                        <div className="d-flex flex-in-resume-direction-false mb-1">
                                          <p style={{ fontWeight: "800" }} className="mb-0 ml-1">{exp.company}</p>
                                        </div>
                                        <div className="d-flex flex-in-resume-direction-false mb-1">
                                          <i><p className="mb-0 ml-1">{exp.job_title}</p></i>
                                        </div>

                                      </div>
                                      <div className="flex-direction-in-row-justice-exp  d-flex align-items-end  flex-column justify-content-center">
                                        <p>{exp.city}, {exp.state}</p>
                                        <p>{dayjs(exp.time_period_start).format("MM-DD-YYYY")} to {exp.time_period_end !== "Still currently employed here" ? dayjs(exp.time_period_end).format("MM-DD-YYYY") : "Still currently employed here"}</p>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column flex-in-resume-direction-false mb-1">
                                      <p className="mb-0 ml-1">{parse(exp.duties)}</p>
                                    </div>
                                  </>
                                )) : <p className="py-4">(More experience info if provided…)</p>
                              }
                            </div>




                            {
                              value.military && value.military.length > 0 ?
                                <div className=" py-3">
                                  <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                                    <div className="d-flex">
                                      <h5 className="m-0">MILITARY SERVICE</h5>
                                     
                                    </div>
                                  </div>
                                  {
                                    value.military && value.military.length > 0 ? value.military.map(mil => (
                                      <div className="d-flex padding-block-in-preview-resume-justice align-items-center justify-content-between">
                                        <div style={{ width: "100%" }}>
                                          {
                                            value.branch && value.branch.length > 0 ? value.branch.map(br => (
                                              <>
                                                <div style={{ width: "100%" }} className="d-flex align-items-center flex-in-resume-direction-false mb-1">
                                                  <a style={{ fontSize: "15px", color: "black", fontWeight: "bolder" }} href="#" className="mt-0 dropdddd">Branch:</a>
                                                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                    <p className="mb-0 ml-1">{br.branch}</p>
                                                    {value.mos && value.mos.length > 0 ?
                                                      <p>{dayjs(mil.start_date).format("MM-DD-YYYY")} to {dayjs(mil.end_date).format("MM-DD-YYYY")}</p>
                                                      : ""}
                                                  </div>
                                                </div>
                                              </>
                                            )) : <div className="d-flex">
                                              <p className="m-0">No branch of service added</p>
                                            </div>

                                          }

                                          <div className="d-flex flex-in-resume-direction-false mb-1">
                                            <a style={{ fontSize: "15px", color: "black", fontWeight: "bolder" }} href="#" className="mt-0 dropdddd" >MOS:</a>
                                            {
                                              value.mos && value.mos.length > 0 ? value.mos.map((mos, index) => (
                                                <>
                                                  <p className="mb-0 ml-1">{mos.mos}</p>
                                                  {index == value.mos.length - 1 ? "" : <span className="text-dark pl-0 pr-1">,</span>}
                                                </>
                                              )) : <p>No MOS added</p>

                                            }


                                          </div>
                                          {value.military.length > 0 ? value.military[0].security_clearance == "" || value.military[0].security_clearance == undefined || value.military[0].security_clearance == null ?
                                            "" :
                                            <div className="d-flex flex-in-resume-direction-false">
                                              <a style={{ fontSize: "15px", color: "black", fontWeight: "bolder" }} href="#" className="mt-0 dropdddd" >Security Clearance:</a>
                                              {value.military && value.military.length > 0 ? value.military.map(cle => (
                                                <>
                                                  <p className="mb-0 ml-1">{`${cle.security_clearance !== null && cle.security_clearance !== undefined && cle.security_clearance !== "" ? cle.security_clearance : ""}`}</p>
                                                </>
                                              )) : ""
                                              }
                                            </div>
                                            : ""
                                          }


                                        </div>
                                        <div className="d-flex align-items-end  flex-column justify-content-center">

                                        </div>

                                      </div>

                                    )) : <p className="py-4">(More military info if provided…)</p>
                                  }

                                </div> : ""}



                            {value.language.length > 0 || value.certification.length > 0 || value.award.length > 0 ?
                              <div className=" py-3">
                                <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                                  <div className="d-flex">
                                    <h5 className="m-0">ADDITIONAL SKILLS</h5>
                                   
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="pr-5 pt-2">
                                    {value.language.length > 0 ?
                                      <div className="d-flex flex-in-resume-direction-false">
                                        <div className="d-flex align-items-center">
                                          <p style={{ fontWeight: "bold" }} className="mr-3">Additional Language(s)</p>
                                        </div>
                                        <div className="d-flex align-items-center">

                                          {value.language && value.language.length > 0 ? value.language.map((lang, index) => (
                                            <>
                                              <p className="m-0">{`${lang.lang !== null && lang.lang !== undefined && lang.lang !== "" ? `${lang.lang} (${lang.level})` : ""}`}</p>
                                              {index == value.language.length - 1 ? "" : <span className="text-dark pl-0 pr-1">,</span>}
                                            </>
                                          )) : <p>Level of Fluency</p>
                                          }
                                        </div>

                                      </div>
                                      : ""}
                                    {value.certification.length > 0 ?
                                      <div className="d-flex flex-in-resume-direction-false">
                                        <p style={{ fontWeight: "bold" }} className="mr-3">Certification(s)</p>
                                        {value.certification && value.certification.length > 0 ? value.certification.map((cert, index) => (
                                          <>
                                            <p>{`${cert.title !== null && cert.title !== undefined && cert.title !== "" ? cert.title : ""}`} ({`${cert.date !== null && cert.date !== undefined && cert.date !== "" ? dayjs(cert.date).format("MM-DD-YYYY") : ""}`})</p>
                                            {index == value.certification.length - 1 ? "" : <span className="text-dark pl-0 pr-1">,</span>}
                                          </>

                                        )) : <p>Date Earned</p>
                                        }
                                      </div>
                                      : ""}
                                    {value.award.length > 0 ?
                                      <div className="d-flex flex-in-resume-direction-false">
                                        <p style={{ fontWeight: "bold" }} className="mr-3">Award(s) </p>
                                        {value.award && value.award.length > 0 ? value.award.map((awar, index) => (
                                          <>
                                            <p>{`${awar.title !== null && awar.title !== undefined && awar.title !== "" ? awar.title : ""}`} ({`${awar.date !== null && awar.date !== undefined && awar.date !== "" ? dayjs(awar.date).format("MM-DD-YYYY") : ""}`})</p>
                                            {index == value.award.length - 1 ? "" : <span className="text-dark pl-0 pr-1">,</span>}
                                          </>
                                        )) : <p>Date Earned</p>
                                        }
                                      </div>
                                      : ""
                                    }


                                  </div>
                                </div>
                              </div>
                              : ""}


















                            {allvolunteer.length > 0 ?
                              <div className=" py-3">
                                <div className="d-flex align-items-end justify-content-start preview-full-resume-content-area">
                                  <div className="d-flex">
                                    <h5 className="m-0">VOLUNTEER WORK</h5>
                                   
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <table class="table table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {allvolunteer && allvolunteer.length > 0 ? allvolunteer.map((val, ind) => (
                                        <tr>
                                          <td>{val.title}</td>
                                          <td>{dayjs(val.start).format("MM-DD-YYYY")}</td>
                                          <td>{val.end !== "I currently volunteer here" ? dayjs(val.end).format("MM-DD-YYYY") : "I currently volunteer here"}</td>
                                        </tr>
                                      )) : ""
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              : ""}
                          </div>
                        </div>
                      </div>
                    )
                  }) : "No Resume Created"
                }
                {/* footer====================================================================== */}

              </div>
            </div>
          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  ResumeReducer: state.ResumeReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(SavedPreviewResume);


// code for scoop solutions