const config = require('../helpers/config.json');

export const JobByID = (userId) => {
    return (dispatch) => {
        /// get request
        fetch(`${config['baseUrl']}/jobs/getbyid/${userId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', },
        }).then(res => res.json()).then((response) => {
            const alljobsbyid = response

            dispatch({
                type: "GET_JOBSBYID",
                alljobsbyid: alljobsbyid
            });
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: "GET_JOBSBYID",
                alljobsbyid: {}
            });
            // 
        })

    }


}

// code for scoop solutions