import React, { useState, useEffect } from "react"
import Employernav from "../components/dropdownnav/Employernav";
import HomeFooter from "../components/homeFooter/HomeFooter";
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import dayjs from "dayjs";
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
const config = require('../helpers/config.json');


const Invoice = () => {
  const [invoicemap, setinvoicemap] = useState([])
  const [pendinginvoicemap, setpendinginvoicemap] = useState([])
  useEffect(() => {
    fetch(`${config['baseUrl']}/All/PaymentRecievef/${localStorage.getItem("userid")}`)
      .then(res => res.json())
      .then(response => setinvoicemap(response.invoices))

    fetch(`${config['baseUrl']}/All/DueInvoicesf/${localStorage.getItem("userid")}`)
      .then(res => res.json())
      .then(response => setpendinginvoicemap(response.invoices))
  }, [])

  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          <div className="main-content ">
            <div className="usr-tbl w-50">
              <h5>Payment Due</h5>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Amount</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Invoice Number</th>
                  </tr>
                </thead>
                <tbody>
                  {pendinginvoicemap && pendinginvoicemap.length > 0 ? pendinginvoicemap.map(val =>
                    <tr>
                      <td>{val.payment_recieve}</td>
                      <td>{val.enddate !== null && val.enddate !== undefined && val.enddate !== "" ? dayjs(val.enddate).format("MM-DD-YYYY") : ""}</td>
                      <td>{val.invoice_no}</td>
                    </tr>
                  ) : ""}
                </tbody>
              </table>
            </div>
            <div className="usr-tbl mt-5 w-100">
              <h5>Transaction History </h5>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Date Received</th>
                    <th scope="col">Activity</th>
                    <th scope="col">Payment Amount</th>
                    <th scope="col">Invoice Number</th>
                  </tr>
                </thead>
                <tbody>
                  {invoicemap && invoicemap.length > 0 ? invoicemap.map(val =>
                    <tr>
                      <td>{val.created_At !== null && val.created_At !== undefined && val.created_At !== "" ? dayjs(val.created_At).format("MM-DD-YYYY") : ""}</td>
                      <td>{val.status == 1 ? "Payment Received" : "Payment Pending"}</td>
                      <td>{val.payment_recieve}</td>
                      <td>{val.invoice_no}</td>
                    </tr>
                  ) : ""}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />

    </>
  )
}

export default Invoice;


// code for scoop solutions