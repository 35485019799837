import React from "react"
import AddUser from "../components/accountmanagement/Adduser"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import HomeFooter from "../components/homeFooter/HomeFooter";

const UserAdd = () => {
    return (
        <>
            <EmployeeNav2 />
            <AddUser />
            <HomeFooter />
        </>
    )
}
export default UserAdd

// code for scoop solutions