import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import Employernav from "../dropdownnav/Employernav";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import "../navbar/Navbar.css";
import Card from "./Card";
import config from '../../helpers/config.json';
import dayjs from 'dayjs'
import ReactGA from 'react-ga4';

Modal.setAppElement("#root");

const GetPremium = (props) => {
  const {
    cancelled_at,
    message
  } = props.SubscriptionInfo

  console.log("cancelled at ",props.SubscriptionInfo)

  const [load, setload] = useState(true)
  const [allArray, setallArray] = useState([])
  useEffect(async () => {
    loadgetjob()
  }, []);
  const loadgetjob = async () => {
    setload(true)
    await fetch(`${config['baseUrl']}/packages/get`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response.job
      setallArray(...allArray, alljobs)
        
    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
    return null
  }
// console.log("sub info is ",props?.SubscriptionInfo)
  useEffect(()=>{

    ReactGA.event({
      category: `Page Visit`,
      label: localStorage.getItem("username")?.length > 1 ?`Employeer Name: ${localStorage.getItem("username")} visited Product & Pricing page ` :`Un Authenticated user visited Product & Pricing page page`,
      action:`visit at Product & Pricing page`,
      nonInteraction:true
    });
  },[])

  return (
    <>
      {
        load == false ?
          <>

            <div className="container-fluid" id="Employee" >
              <div className="container">
                {
                  props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.role !== null && props.GetUserReducer.users.role !== undefined && props.GetUserReducer.users.role !== "" && props.GetUserReducer.users.role !== 0 ?
                    <Employernav /> :
                    <Dropdownnav />
                }
                {/* header======================================================================*/}

                <div className="main-content">
                  {cancelled_at&&<div className="row">
                    <div className="alert alert-warning" role="alert" >
                        {message}
                    </div>

                  </div>}
                  <div className="row">
                    {
                      allArray && allArray.length > 0 ? allArray.map((pac, ind) => {return(
                        <Card key={ind+1} pac={pac} allArray={allArray} />
                      )}) : ""
                    }

                  </div>
                </div>
                {/* footer====================================================================== */}

              </div>
            </div>
          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  GetUserReducer: state.GetUserReducer,
  SubscriptionInfo: state.SubscriptionInfo,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(GetPremium);


// code for scoop solutions