import {
  CLEAR_LOGIN_ERROR,
  SET_LOGIN_ERROR,
} from "../actions/LoginErrorAction";

const initialState = {
  loginError: {
    hasError: false,
    errorMessage: undefined,
  },
};

const loginErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: {
          hasError: true,
          errorMessage: action.errorMessage || undefined,
        },
      };
    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        loginError: {
          hasError: false,
          errorMessage: undefined,
        },
      };
    default:
      return state;
  }
};

export default loginErrorReducer;
