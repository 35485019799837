export const filterOptions = {
  sortBy: [
    { label: "Sort By", value: "" },
    { label: "Sort by Date", value: "date" },
    { label: "Sort by Relevance", value: "rel" },
  ],
  datePosted: [
    { label: "Date Posted", value: "" },
    { label: "Last 24 hours", value: "lastDay" },
    { label: "Last 3 Days", value: "last3Days" },
    { label: "Last 7 Days", value: "last7Days" },
    { label: "Last 14 Days", value: "last14Days" },
    { label: "Last 30 Days", value: "lastMonth" },
  ],
  distance: [
    { label: "Distance", value: "" },
    { label: "5 (miles)", value: "5" },
    { label: "10 (miles)", value: "10" },
    { label: "15 (miles)", value: "15" },
    { label: "25 (miles)", value: "25" },
    { label: "50 (miles)", value: "50" },
    { label: "100 (miles)", value: "100" },
  ],
  jobType: [
    { label: "Job Type", value: "" },
    { label: "Full-Time", value: "Full-Time" },
    { label: "Part-Time", value: "Part-Time" },
    { label: "Temporary/Contract", value: "Contract" },
    { label: "Internship", value: "Internship" },
  ],
  salary: [
    { label: "Salary", value: "" },
    { label: "$10K - $29K", value: "10-30" },
    { label: "$30K - $49K", value: "30-50" },
    { label: "$50K - $69K", value: "50-70" },
    { label: "$70K - $99K", value: "70-100" },
    { label: "$100K - $199K", value: "100-200" },
    { label: "$200K+", value: "200" },
  ],
  experienceLevel: [
    { label: "Experience Level", value: "" },
    { label: "Less Than 1 Year", value: "Less" },
    { label: "1-2 Years", value: "1-2" },
    { label: "2-5 Years", value: "2-5" },
    { label: "5+ Years", value: "5+" },
  ],
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);