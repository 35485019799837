import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import Previewresume from "../components/resumes/Previewresume"
import HomeFooter from "../components/homeFooter/HomeFooter";

const ResumePreview = () => {
    return (
        <>
            <EmployeeNav2 />
            <Previewresume />
            <HomeFooter />
        </>
    )
}
export default ResumePreview


// code for scoop solutions