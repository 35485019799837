import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import HelpCenterEmployee from "../components/helpcenter/Helpcenteremployee"
import HomeFooter from "../components/homeFooter/HomeFooter";

const EmployeerHelpCenter = () => {
    return (
        <>
            <EmployeeNav2 />
            <HelpCenterEmployee />
            <HomeFooter />
        </>
    )
}
export default EmployeerHelpCenter


// code for scoop solutions