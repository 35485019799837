const config = require('../helpers/config.json');

export const AppliedJob = (jobid, jobtitle) => {
    return (dispatch) => {
        dispatch({
            type: "SEARCH_RESET",
            loading: false,
            success: false,
        });
        fetch(`${config['baseUrl']}/jobs/apply/insert`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "candidate_id": Number(localStorage.getItem("userid")), "candidate_name": localStorage.getItem("username"), "job_id": jobid, "job_applied": jobtitle })
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) {
            }
            return res.json();
        }).then((response) => {
            dispatch({
                type: "SEARCH_SUCCESS",
                SearchResponse: response,
                loading: true,
                success: true,

            });
            if (response.message == "Successfully Applied For Job.") {
                window.location = "/search-job"
            }
            else {
            }

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "SEARCH_FAIL",
                SearchResponse: "creation failed",
                loading: true,
                success: false,
            });
            // 
        })
    }
}


// code for scoop solutions