import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { AppliedJob } from "../../actions/Appliedjobaction";
import { getUser } from "../../actions/Getuseraction";
import { Drawer, Button, Space, Radio } from "antd";
import moblogo from "../../assets/mob-logo.png";
import twi from "../../assets/ftr-twitter.png";
import fb from "../../assets/ftr-fb.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import FullPageLoader from "../fullpageloader/fullPageLoader";
import { getTheme } from "../../actions/localtheme";
import { createSearch } from "../../actions/Jobsearchaction";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
const config = require("../../helpers/config.json");

Modal.setAppElement("#root");

const DropDownNav = (props) => {
  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const search = useLocation();
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [allArray, setallArray] = useState([]);
  const [load, setload] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("themee") == null) {
      localStorage.setItem("themee", false);
    }
  }, []);

  const signOut = () => {
    console.log(props.GetUserReducer.users.theme);
    localStorage.removeItem("array");
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("zip");
    localStorage.removeItem("role");
    localStorage.removeItem("role1");
    window.location = `/`;
  };
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("left");
  const signOut1 = () => {
    localStorage.removeItem("array");
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("zip");
    localStorage.removeItem("role");
    localStorage.removeItem("role1");
    window.location = `/employer-login${
      window.location.href.search("false") == -1 &&
      window.location.href.search("true") == -1
        ? `/`
        : `?if=${window.location.href.search("false") == -1 ? true : false}`
    }`;
  };
  const [themechange, setthemechange] = useState(true);

  const theme = async (e) => {
    fetch(`${config["baseUrl"]}/theme`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        theme: Number(e),
        id: localStorage.getItem("userid"),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.message !== "successfull") {
          console.log(
            "Can't add new command when connection is in closed state"
          );
        } else {
          window.location = `${search?.pathname + search?.search}`;
        }
      })
      .catch((error) => {
        const authError = JSON.stringify(error);
        console.log(authError);
      });
  };
  const themechangelocal = (e) => {
    if (e == true) {
      document.getElementById("tg").classList.remove("white");
      document.getElementById("tg").classList.add("black");
      props.getTheme(true);
    } else if (e == false) {
      document.getElementById("tg").classList.remove("black");
      document.getElementById("tg").classList.add("white");
      props.getTheme(false);
    }
  };
  const [themeanime, setthemeanime] = useState(true);
  const showDrawer = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setVisible(false);
  };
  const menu = (
    <Menu>
      <Menu.ItemGroup>
        <Menu.Item>
          {props.GetUserReducer.users &&
          props.GetUserReducer.users.role !== null &&
          props.GetUserReducer.users.role !== undefined &&
          props.GetUserReducer.users.role !== "" &&
          props.GetUserReducer.users.role == 0 ? (
            <Link onClick={() => setBilingModalIsOpen2(true)} className="fgh">
              Sign In
            </Link>
          ) : (
            <Link
              to={`/employer-login${
                window.location.href.search("false") == -1 &&
                window.location.href.search("true") == -1
                  ? `/`
                  : `?if=${
                      window.location.href.search("false") == -1 ? true : false
                    }`
              }`}
              className="fgh"
            >
              Sign In
            </Link>
          )}
        </Menu.Item>

        <Menu.Item>
          {props.GetUserReducer.users &&
          props.GetUserReducer.users &&
          props.GetUserReducer.users.role !== "" &&
          props.GetUserReducer.users.role !== null &&
          props.GetUserReducer.users.role !== undefined ? (
            props.GetUserReducer.users.role !== 0 ? (
              <Link className="fgh" to="/postjob">
                Post Jobs
              </Link>
            ) : (
              <Link className="fgh" onClick={() => setBilingModalIsOpen2(true)}>
                Post Jobs
              </Link>
            )
          ) : (
            <Link
              className="fgh"
              to={`/employer-login${
                window.location.href.search("false") == -1 &&
                window.location.href.search("true") == -1
                  ? `/`
                  : `?if=${
                      window.location.href.search("false") == -1 ? true : false
                    }`
              }`}
            >
              Post Jobs
            </Link>
          )}
        </Menu.Item>

        <Menu.Item>
          {props.GetUserReducer.users &&
          props.GetUserReducer.users &&
          props.GetUserReducer.users.role !== 0 ? (
            allArray.length > 0 ? (
              <Link
                className=" fgh"
                to={`/get-premium${
                  window.location.href.search("false") == -1 &&
                  window.location.href.search("true") == -1
                    ? `/`
                    : `?if=${
                        window.location.href.search("false") == -1
                          ? true
                          : false
                      }`
                }`}
              >
                Products and Pricing
              </Link>
            ) : (
              <Link
                className=" fgh"
                to={`/get-premium${
                  window.location.href.search("false") == -1 &&
                  window.location.href.search("true") == -1
                    ? `/`
                    : `?if=${
                        window.location.href.search("false") == -1
                          ? true
                          : false
                      }`
                }`}
              >
                Products and Pricing
              </Link>
            )
          ) : (
            ""
          )}
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
  const menu2 = (
    <Menu>
      <Menu.ItemGroup>
        <Menu.Item>
          {props.GetUserReducer.users &&
          props.GetUserReducer.users.is_resume !== null &&
          props.GetUserReducer.users.is_resume !== undefined &&
          props.GetUserReducer.users.is_resume !== "" &&
          props.GetUserReducer.users.is_resume == 0 ? (
            <Link className="fgh" to="/profile">
              Profile
            </Link>
          ) : (
            <Link className="fgh" to="/profile">
              Profile
            </Link>
          )}
        </Menu.Item>
        <Menu.Item>
          <Link className="fgh" to="/save-jobs">
            Saved Jobs
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            className="fgh"
            to={`/help-center-employee${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
          >
            Help Center
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link className="fgh" onClick={() => signOut()}>
            Sign Out
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
  return (
    <>
      <nav
        id="header"
        className="col-12 navbar header-temporary-header navbar-expand-lg navbar-light p-0"
      >
        <div id="header-left">
          <div className="d-flex align-items-center">
            <Link
              className="fgh"
              to={`/${
                window.location.href.search("false") == -1 &&
                window.location.href.search("true") == -1
                  ? ``
                  : `?if=${
                      window.location.href.search("false") == -1 ? true : false
                    }`
              }`}
            >
              <img className="img-fluid" src={logo} alt="" />
            </Link>
            <div className="dropdown header-left-dropdown ml-left res-dis-none">
              <div className="dropdown header-left-dropdown">
                <Dropdown overlay={menu}>
                  <a
                    className="ant-dropdown-link dropdddd"
                    onClick={(e) => e.preventDefault()}
                  >
                    Employers <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse simpkle-nav-res-padding"
          id="navbarText"
        >
          <ul className="navbar-nav mr-auto"></ul>
          <span className="navbar-text" id="header-right">
            <div className="h-100  d-flex align-items-center justify-content-end">
              {props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role == 0 ? (
                props.GetUserReducer.users &&
                props.GetUserReducer.users &&
                props.GetUserReducer.users.is_resume !== null &&
                props.GetUserReducer.users.is_resume !== undefined &&
                props.GetUserReducer.users.is_resume !== "" ? (
                  props.GetUserReducer.users.is_resume == 0 ? (
                    <>
                      <Link
                        to="/createresume"
                        className="employers-button-emp fgh"
                      >
                        Post Resume
                      </Link>
                    </>
                  ) : (
                    <>
                      {window?.location?.pathname == "/" && (
                        <>
                          {/* <Link
                            to={`/candidate-login${
                              window.location.href.search("false") == -1 &&
                              window.location.href.search("true") == -1
                                ? `/`
                                : `?if=${
                                    window.location.href.search("false") == -1
                                      ? true
                                      : false
                                  }`
                            }`}
                            className="employers-button-emp res-dis-none fgh m-0"
                          >
                            Blogs
                          </Link>
                          <glitch className="employers-button-emp res-dis-none">
                            |
                          </glitch> */}
                        </>
                      )}
                      <Link
                        to="/previewresume"
                        className="employers-button-emp fgh res-dis-none"
                      >
                        Preview Resume
                      </Link>
                    </>
                  )
                ) : (
                  ""
                )
              ) : (
                <>
                  {window?.location?.pathname == "/" && (
                    <>
                      {/* <Link
                        to={`/candidate-login${
                          window?.location?.href?.search("false") == -1 &&
                          window?.location?.href?.search("true") == -1
                            ? `/`
                            : `?if=${
                                window.location.href.search("false") == -1
                                  ? true
                                  : false
                              }`
                        }`}
                        className="employers-button-emp res-dis-none fgh m-0"
                      >
                        Blogs
                      </Link>
                      <glitch className="employers-button-emp res-dis-none">
                        |
                      </glitch> */}
                    </>
                  )}
                  <Link
                    to={`/candidate-login${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                        ? `/`
                        : `?if=${
                            window.location.href.search("false") == -1
                              ? true
                              : false
                          }`
                    }`}
                    className="employers-button-emp fgh res-dis-none m-0"
                  >
                    Post Resume
                  </Link>
                </>
              )}

              {props.GetUserReducer.users &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role == 0 ? (
                <>
                  <glitch className="employers-button-emp res-dis-none">
                    |
                  </glitch>
                  <Link
                    to="/applicationhistory"
                    className="employers-button-emp res-dis-none fgh"
                  >
                    Application History
                  </Link>{" "}
                  <glitch className="employers-button-emp res-dis-none">
                    |
                  </glitch>
                  <div className="dropdown d-flex align-items-center header-left-dropdown ml-1">
                    <Dropdown overlay={menu2}>
                      <a
                        className="ant-dropdown-link dropdddd"
                        onClick={(e) => e.preventDefault()}
                      >
                        {localStorage.getItem("email")} <DownOutlined />
                      </a>
                    </Dropdown>
                    <button
                      onClick={showDrawer}
                      className="res-bar-ham res-dis-none-op"
                    >
                      <i class="dropdddd fa fa-bars" aria-hidden="true"></i>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <glitch className="employers-button-emp res-dis-none">
                    |
                  </glitch>
                  <Link
                    to={`/candidate-login${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                        ? `/`
                        : `?if=${
                            window.location.href.search("false") == -1
                              ? true
                              : false
                          }`
                    }`}
                    className="employers-button res-dis-none fgh"
                  >
                    Sign In
                  </Link>
                  <div className="custom-hamburger-menu res-dis-none-op">
                    <div className="d-flex aling-items-center">
                      <button
                        onClick={() => {
                          setBilingModalIsOpen(true);
                        }}
                        className="res-ham-icon"
                      >
                        <i class="fa fa-user" aria-hidden="true"></i> Sign In
                      </button>
                      <button onClick={showDrawer} className="res-bar-ham">
                        <i class="dropdddd fa fa-bars" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </span>
        </div>
      </nav>
      <Drawer
        placement={placement}
        width={300}
        onClose={onClose}
        visible={visible}
      >
        {localStorage.getItem("role") != 0 ? (
          <>
            <div>
              <Link className="text-dark" to={`/employer-login/`}>
                Sign in
              </Link>
            </div>
            <hr />
            <div>
              <Link className="text-dark" to={`/employer-login/`}>
                Post Jobs
              </Link>
            </div>
            <hr />
            <div>
              <Link
                className="text-dark"
                to={`/get-premium${
                  window.location.href.search("false") == -1 &&
                  window.location.href.search("true") == -1
                    ? `/`
                    : `?if=${
                        window.location.href.search("false") == -1
                          ? true
                          : false
                      }`
                }`}
              >
                Products &amp; Pricing
              </Link>
            </div>
            <hr />
          </>
        ) : (
          <>
            {/* <div>
              <Link
                to={`/candidate-login${
                  window.location.href.search("false") == -1 &&
                  window.location.href.search("true") == -1
                    ? `/`
                    : `?if=${
                        window.location.href.search("false") == -1
                          ? true
                          : false
                      }`
                }`}
                className="text-dark"
              >
                Blogs
              </Link>
            </div> */}
            <hr />
            <div>
              <Link to="/previewresume" className="text-dark">
                Preview Resume
              </Link>
            </div>
            <hr />
            <div>
              <Link to="/applicationhistory" className="text-dark">
                Application History
              </Link>
            </div>
            <hr />
          </>
        )}

        <div className="d-flex align-items-start flex-column justify-content-center pt-3 px-0">
          <div className="d-flex footer-img-small">
            <a href="https://www.facebook.com/Hire-Choices-LLC-106119302081524/">
              {" "}
              <img src={fb} />
            </a>
            <a href="https://instagram.com/hirechoices?igshid=YmMyMTA2M2Y=">
              <img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
            </a>
            <a href="https://www.linkedin.com/in/hire-choices-llc-64a95a231/">
              <img src="https://img.icons8.com/color/48/000000/linkedin.png" />
            </a>
            <a href="https://www.youtube.com/channel/UC3Le1tWkmOnCIBvM27_kmOA">
              <img src={yt} />
            </a>
          </div>
          <p>© 2022 - Hire Choices</p>
          <div className="d-flex align-items-center">
            {props.GetUserReducer.users &&
            props.GetUserReducer.users.theme !== null &&
            props.GetUserReducer.users.theme !== undefined &&
            props.GetUserReducer.users.theme !== "" ? (
              props.GetUserReducer.users &&
              props.GetUserReducer.users.theme == 0 ? (
                <div>
                  <div className="d-flex align-items-center">
                    <b>Dark Mode: </b>
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="checkbox"
                      defaultChecked={
                        props.GetUserReducer.users &&
                        props.GetUserReducer.users.theme == 0
                          ? false
                          : true
                      }
                      onChange={() => {
                        setthemeanime(false);
                        theme(1);
                        console.log("dd1");
                      }}
                    />
                    <label for="checkbox" className="label ml-3">
                      {props.GetUserReducer.users.theme == 1 ? (
                        <i class="fa fa-sun-o"></i>
                      ) : (
                        <i class="fa fa-moon-o"></i>
                      )}
                      <div
                        style={{
                          transform:
                            themeanime == false
                              ? "translateX(-18px)"
                              : "translateX(0px)",
                        }}
                        class="ball"
                      ></div>
                    </label>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex align-items-center">
                    <b>Dark Mode: </b>
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="checkbox"
                      defaultChecked={
                        props.GetUserReducer.users &&
                        props.GetUserReducer.users.theme == 0
                          ? false
                          : true
                      }
                      onChange={() => {
                        theme(0);
                        setthemeanime(false);
                        console.log("dd2");
                      }}
                    />
                    <label for="checkbox" className="label ml-3">
                      {props.GetUserReducer.users.theme == 1 ? (
                        <i class="fa fa-sun-o"></i>
                      ) : (
                        <i class="fa fa-moon-o"></i>
                      )}
                      <div
                        style={{
                          transform:
                            themeanime == false
                              ? "translateX(-18px)"
                              : "translateX(0px)",
                        }}
                        class="ball"
                      ></div>
                    </label>
                  </div>
                </div>
              )
            ) : (
              <>
                {themeanime == true ? (
                  <div onClick={() => setthemeanime(false)}>
                    <div className="d-flex align-items-center">
                      <b>Dark Mode: </b>
                      <input
                        type="checkbox"
                        class="checkbox"
                        id="checkbox"
                        defaultChecked={
                          localStorage.getItem("themee") !== false
                            ? true
                            : false
                        }
                        onChange={() => {
                          setthemechange(!themechange);
                          themechangelocal(themechange);
                          setthemeanime(false);
                        }}
                      />
                      <label for="checkbox" className="label ml-3">
                        <i class="fa fa-moon-o"></i>
                        <div
                          style={{
                            transform:
                              themeanime == false
                                ? "translateX(-18px)"
                                : "translateX(0px)",
                          }}
                          class="ball"
                        ></div>
                      </label>
                    </div>
                  </div>
                ) : (
                  <div onClick={() => setthemeanime(true)}>
                    <div className="d-flex align-items-center">
                      <b>Dark Mode: </b>
                      <input
                        type="checkbox"
                        class="checkbox"
                        id="checkbox"
                        defaultChecked={
                          localStorage.getItem("themee") !== false
                            ? true
                            : false
                        }
                        onChange={() => {
                          setthemechange(!themechange);
                          themechangelocal(themechange);
                          setthemeanime(true);
                          console.log("dd4");
                        }}
                      />
                      <label for="checkbox" className="label ml-3">
                        <i
                          style={{ transform: "translateX(18px)" }}
                          class="fa fa-sun-o"
                        ></i>
                        <div
                          style={{
                            transform:
                              themeanime == true
                                ? "translateX(0px)"
                                : "translateX(-18px)",
                          }}
                          class="ball"
                        ></div>
                      </label>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Drawer>

      <Modal
        isOpen={BilingmodalIsOpen}
        onRequestClose={() => setBilingModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "80%",
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Link
            to={`/employer-login${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
            className="responsive-login-btn"
          >
            Employer <i class="fa fa-angle-right" aria-hidden="true"></i>
          </Link>
          <Link
            to={`/candidate-login${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
            className="responsive-login-btn"
          >
            Job Seeker <i class="fa fa-angle-right" aria-hidden="true"></i>
          </Link>
        </div>
      </Modal>
      <Modal
        isOpen={BilingmodalIsOpen}
        onRequestClose={() => setBilingModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "80%",
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Link
            to={`/employer-login${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
            className="responsive-login-btn"
          >
            Employer <i class="fa fa-angle-right" aria-hidden="true"></i>
          </Link>
          <Link
            to={`/candidate-login${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
            className="responsive-login-btn"
          >
            Job Seeker <i class="fa fa-angle-right" aria-hidden="true"></i>
          </Link>
        </div>
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpen2}
        onRequestClose={() => setBilingModalIsOpen2(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "500px",
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h4>Do you wish to sign out?</h4>
          <div className="d-flex align-items-center">
            <button
              onClick={() => signOut1()}
              className="employeer-sign-off-btn"
            >
              Yes
            </button>
            <button
              onClick={() => setBilingModalIsOpen2(false)}
              className="employeer-sign-off-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  AppliedJobReducer: state.AppliedJobReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  AppliedJob: (jobid, jobtitle) => dispatch(AppliedJob(jobid, jobtitle)),
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
  getUser: () => dispatch(getUser()),
  getTheme: (theme) => dispatch(getTheme(theme)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DropDownNav);

// code for scoop solutions
