import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Employernav from "../dropdownnav/Employernav";
import Footer from "../footer/Footer";
import HomeFooter from "../homeFooter/HomeFooter";
const config = require("../../helpers/config.json");

Modal.setAppElement("#root");

const NewMessageTemplate = (props) => {
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("name");
  const ids = new URLSearchParams(search).get("id");
  const cids = new URLSearchParams(search).get("cid");
  const [introduction, setintroduction] = useState(`Hi ${name}`);
  const [description, setdescription] = useState("");
  const [template_name, settemplate_name] = useState("");
  const [allArray, setallArray] = useState([]);
  const [load, setload] = useState(false);

  const saveTemplate = async () => {
    if (template_name == "") {
      console.log("enter template name");
    } else if (introduction == "") {
      console.log("enter introduction");
    } else {
      await fetch(`${config["baseUrl"]}/template/insert`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: localStorage.getItem("userid"),
          template_name: template_name,
          introduction: introduction,
          description: description,
        }),
      })
        .then((res) => {
          if (res.status !== 200 && res.status !== 201) {
          }
          return res.json();
        })
        .then((response) => {
          if (response.message == "The Message template is Saved.") {
            window.location = `/send-message?id=${ids}&cid=${cids}`;
          } else if (response.message == "Limit Over.") {
            console.log("Limit Over");
          } else {
            console.log("Something went wrong..");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          {/* header======================================================================*/}
          <div className="main-content contact-or-rejection">
            <form>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  User's Name <span>*</span>
                </label>
                <input
                  type="email"
                  className="form-control employee-input-email"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="User's Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">
                  Title of Opportunity <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control employee-input-email"
                  id="exampleInputPassword1"
                  placeholder="Job Title"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Company</label>
                <input
                  type="text"
                  className="form-control employee-input-email"
                  id="exampleInputPassword1"
                  placeholder="Company"
                />
              </div>
              <div className="form-group d-flex flex-column">
                <label htmlFor="exampleInputPassword1">Templates</label>
                <select className="employee-input-email" name id>
                  <option value>Select Template</option>
                  <option value>Contact</option>
                  <option value>Rejection</option>
                </select>
                <small id="emailHelp" className="form-text  text-danger">
                  * You are at the max amount of message templates.
                  <br />
                  You can update current messages and save the changes.
                </small>
              </div>
              <div className="form-group d-flex flex-column">
                <label htmlFor="exampleInputPassword1">Introduction</label>
                <textarea
                  name
                  id
                  cols={30}
                  className="employee-input-email form-control"
                  rows={4}
                  placeholder="Hi {CANDIDATE_NAME},
                  Enter a brief introduction message here."
                  defaultValue={""}
                />
              </div>
              <div className="main-content-session pt-0 inner-session">
                <a href="#" className="send-invitation">
                  Save Message
                </a>
              </div>
              <small
                id="emailHelp"
                className="btm-mgn form-text  text-dark smalltextdark"
              >
                Type {"{"}CANDIDATE_NAME{"}"} in your introduction
                <br />
                and it will automatically enter the candidate’s <br />
                name into the message. You can also enter <br />
                {"{"}JOB_TITLE{"}"} and this will enter the job title as well.{" "}
                <br />
              </small>
              <div className="form-group d-flex flex-column">
                <label htmlFor="exampleInputPassword1">
                  Job Description (Optional)
                </label>
                <textarea
                  className="employee-input-email form-control"
                  name
                  id
                  cols={30}
                  rows={4}
                  placeholder="Type or copy and paste job description here."
                  defaultValue={""}
                />
              </div>
              <div className="inner-session">
                <a href="#" className="send-invitation">
                  Send Message
                </a>
              </div>
            </form>
          </div>
          {/* footer====================================================================== */}
          <HomeFooter />
        </div>
      </div>
    </>
  );
};
export default NewMessageTemplate;

// code for scoop solutions
