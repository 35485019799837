import React from "react"
import Applicationhistory from "../components/applicationhistory/Applicationhistory"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import HomeFooter from "../components/homeFooter/HomeFooter";

const HistoryApplication = () => {
    return (
        <>
            <EmployeeNav2 />
            <Applicationhistory />
            <HomeFooter />
        </>
    )
}
export default HistoryApplication

// code for scoop solutions