import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader from "../fullpageloader/fullPageLoader";
import InputMask from 'react-input-mask';
import Employernav from "../dropdownnav/Employernav";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import { connect } from "react-redux";
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const ContactUs = (props) => {
  const [require1, setrequire1] = useState(false)
  const [require2, setrequire2] = useState(false)
  const [require3, setrequire3] = useState(false)
  const [load, setload] = useState(false)
  const [BilingmodalIsOpen3, setBilingModalIsOpen3] = useState(false);
  const [BilingmodalIsOpen4, setBilingModalIsOpen4] = useState(false);
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [first_name, setfirst_name] = useState("")
  const [last_name, setlast_name] = useState("")
  const [company, setcompany] = useState("")
  const [contact_number, setcontact_number] = useState("")
  const [email, setemail] = useState("")
  const [subject, setsubject] = useState("")


  const createContact = async () => {
    const reg = /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z]+)\.(com+)$/;
    if (first_name == "") {
      setrequire1(true)
    }
    else if (last_name == "") {
      setrequire2(true)
    }
    else if (email == "") {
      setrequire3(true)
    }
    else if (reg.test(email) === false) {
      setBilingModalIsOpen3(true)
      setrequire3(true)
    }
    else {
      await fetch(`${config['baseUrl']}/contact/insert`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "first_name": first_name,
          "last_name": last_name,
          "company": company,
          "contact_number": contact_number,
          "email": email,
          "subject": subject,
        })
      }).then(res => {

        if (res.status !== 200 && res.status !== 201) {

        }
        return res.json();
      }).then((response) => {

        if (response.message == "Details saved successfully.") {
          setfirst_name("")
          setlast_name("")
          setcompany("")
          setemail("")
          setsubject("")
          setcontact_number("")
          setBilingModalIsOpen(true)
        }
        else {
          console.log("Something went wrong..")
        }

      }).catch((error) => {
        console.log(error)

      })
    }
  }

  return (
    <>

      <div className="container-fluid" id="Employee">
        <div className="container">
          {
            props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.role !== null && props.GetUserReducer.users.role !== undefined && props.GetUserReducer.users.role !== "" && props.GetUserReducer.users.role !== 0 ?
              <Employernav /> :
              <Dropdownnav />
          }
          {/* header======================================================================*/}
          <div className="main-content contact-or-rejection">
            <form>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">First Name:  <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                <input type="text" onChange={(e) => {
                  setrequire1(false)
                  setfirst_name(e.target.value)}} value={first_name} className="form-control employee-input-email" aria-describedby="emailHelp" placeholder="First Name" />
                {require1 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Last Name:  <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                <input type="text" onChange={(e) => {
                  setrequire2(false)
                  setlast_name(e.target.value)}} value={last_name} className="form-control employee-input-email" aria-describedby="emailHelp" placeholder="Last Name" />
                  {require2 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Company / Organization</label>
                <input type="text" onChange={(e) => setcompany(e.target.value)} value={company} className="form-control employee-input-email" id="exampleInputPassword1" placeholder="Company / Organization" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Contact Number</label>
                <InputMask {...props} mask="(999) 999-9999" value={contact_number} className="form-control employee-input-email" onChange={(e) => setcontact_number(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Email Address:  <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                <input type="email" onChange={(e) => {
                  setrequire3(false)
                  setemail(e.target.value)}} value={email} className="form-control employee-input-email" id="exampleInputPassword1" placeholder="Email" />
                {require3 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}

              </div>
              <div className="form-group d-flex flex-column">
                <label htmlFor="exampleInputPassword1">Subject</label>
                <textarea onChange={(e) => setsubject(e.target.value)} value={subject} className="employee-input-email" maxLength={2000} name id cols={30} rows={5} placeholder="Please comment here." defaultValue={""} />
              </div>
              <div className="inner-session">
                <Link onClick={() => createContact()} className="send-invitation fgh">Submit Here</Link>
              </div>
            </form>
          </div>

        </div>
      </div>


      <Modal
        isOpen={BilingmodalIsOpen}
        onRequestClose={() => setBilingModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "140px",
            top: "calc(50% -70px)",
            left: "0",
            right: "0",
            top: "45%",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h5 style={{ color: "black" }} className="text-center">Your request has been submitted.</h5><br />
          <button className="btn btn-success border-0 rounded" onClick={() => setBilingModalIsOpen(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpen4}
        onRequestClose={() => setBilingModalIsOpen4(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            top: "45%",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please complete all of the required fields to continue</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpen4(false)}>OK</Link>
          </div>

        </div>
      </Modal>


      <Modal
        isOpen={BilingmodalIsOpen3}
        onRequestClose={() => setBilingModalIsOpen3(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "240px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please enter a valid email address</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpen3(false)}>OK</Link>
          </div>

        </div>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);

// code for scoop solutions