import React, { useState, useEffect } from "react"
import "./Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import { useSelector } from "react-redux";

Modal.setAppElement("#root");

const Navbar = (props) => {
  const subscription = useSelector(state=>state?.SubscriptionInfo)

  useEffect(() => {
  }, []);
  return (
    <>

      <div>
        <div className="over-menu " />
        <div id="wrap">
          <header className="header" id="header-sroll">
            <div className="row">
              <div className="col-md-12">
                <div className="desk-menu">
                  <nav className="box-menu">
                    <div className="menu-container">
                      <div className="menu-head">
                        <a href="index.html" className="e1">
                          <img src={moblogo} alt="CLICK" />
                        </a>
                      </div>
                      <div className="menu-header-container">
                        <ul id="cd-primary-nav" className="menu">
                          <li className="menu-item">
                            <a href="#">Home</a>
                          </li>
                          <li className="contact menu-item">
                            <a href=" #">Post Jobs</a>
                          </li>
                          <li className="contact menu-item">
                            <Link className="fgh" to="/">Job Management</Link>
                          </li>
                          <li className="contact menu-item">
                            <a href=" #">Product &amp; Pricing</a>
                          </li>
                          {/* {!subscription?.isPayAsYouGo && <li className="contact menu-item">
                            <Link className="fgh" to="/search-resume">Search Resumes</Link>
                          </li>} */}
                          <li className="contact menu-item">
                            <Link className="fgh" to={`/get-premium${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>Get Premium</Link>
                          </li>
                          <li className="menu-item menu-item-has-children">
                            <a href="#">{localStorage.getItem("email")}</a>
                            <ul className="sub-menu">
                              <li className="menu-item">
                                <a href="#">Account</a>
                              </li>
                              <li className="menu-item">
                                <a href="h#">Post Jobs</a>
                              </li>
                              <li className="menu-item">
                                <a href={`/get-premium${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>Products &amp; Pricing</a>
                              </li>
                              <li className="menu-item">
                                <Link className="fgh" to="/profile">Profile</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="line" />
                        </ul>
                      </div>
                      <div className="menu-foot">
                        <div className="social">
                          <a href="#" target="_blank"><img src={fb} /></a>
                          <a href="#" target="_blank"><img src={twi} /></a>
                          <a href="#" target="_blank"><img src={yt} /></a>
                          <a href="#" target="_blank"><img src={lin} /></a>
                        </div>
                        <hr />
                        <address>
                          <span className="email"><i className="icon-mail" />© 2022 - Hire Choices</span>
                        </address>
                        <address>
                          <span className="email">
                            <p> <Link className="fgh" to={`/contact-us${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>Contact Us</Link> | <Link className="fgh" to={`/about-us${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>About Us | </Link><Link className="fgh" to={`/help-center${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>Help
                              Center</Link> | <a href>Terms of
                                Services</a> | <Link className="fgh" to={`/privacy-policy${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>Privacy Policy</Link></p>
                          </span>
                        </address>
                      </div>
                    </div>
                    <div className="hamburger-menu">
                      <div className="bar" />
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>



    </>
  )
}
export default Navbar;


// code for scoop solutions