import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import TermsAndCondition from "../components/termsandcondition/Termsandcondition"
import HomeFooter from "../components/homeFooter/HomeFooter";

const TermsAndConditions = () => {
    return (
        <>
            <EmployeeNav2 />
            <TermsAndCondition />
            <HomeFooter />
        </>
    )
}
export default TermsAndConditions

// code for scoop solutions