import React ,{useState,useEffect}from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import parse from 'html-react-parser';
const config = require('../../helpers/config.json');

export default function PrintPage(props){
       
    return (
      <>
           
      </>
    );
  
}


