import React, { useEffect } from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import SendMessageEmail from "../components/templates/Sendmessageemail"
import HomeFooter from "../components/homeFooter/HomeFooter";
import ReactGA from 'react-ga4';

const MessageSendEmail = () => {
    useEffect(()=>{

        ReactGA.event({
          category: `Page Visit`,
          label: localStorage.getItem("username")?.length > 1 ?`Employeer Name: ${localStorage.getItem("username")} visited Messaging Page ` :`Un Authenticated user visited Messaging Page page`,
          action:`visit at Messaging Page`,
          nonInteraction:true
        });
      },[])

    return (
        <>
            <EmployeeNav2 />
            <SendMessageEmail />
            <HomeFooter />
        </>
    )
}
export default MessageSendEmail


// code for scoop solutions