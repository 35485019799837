import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import ForgotPasswordModal from "../components/forgotpassword/Forgotpasswordmodal"
import HomeFooter from "../components/homeFooter/HomeFooter";

const PasswordForgotModal = () => {
    return (
        <>
            <EmployeeNav2 />
            <ForgotPasswordModal />
            <HomeFooter />
        </>
    )
}
export default PasswordForgotModal


// code for scoop solutions