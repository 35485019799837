import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { AppliedJob } from "../../actions/Appliedjobaction";
import { getUser } from "../../actions/Getuseraction";
import moblogo from "../../assets/mob-logo.png";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import FullPageLoader from "../fullpageloader/fullPageLoader";
import { createSearch } from "../../actions/Jobsearchaction";
const config = require("../../helpers/config.json");

Modal.setAppElement("#root");

const EmployeeNav2 = (props) => {

  const signOut = () => {
    localStorage.removeItem("array");
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("zip");
    localStorage.removeItem("role");
    localStorage.removeItem("role1");
    window.location = `/`;
  };
  const [themechange, setthemechange] = useState(true);
  const theme = async (e) => {
    fetch(`${config["baseUrl"]}/theme`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        theme: Number(e),
        id: localStorage.getItem("userid"),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.message !== "successfull") {
          console.log(
            "Can't add new command when connection is in closed state"
          );
        } else {
          window.location = "/";
        }
      })
      .catch((error) => {
        const authError = JSON.stringify(error);
        console.log(authError);
      });
  };
  return (
    <>
      <div>
        <div className="over-menu" />
        <div id="wrap">
          <header className="header" id="header-sroll">
            <div className="row">
              <div className="col-md-12">
                <div className="desk-menu">
                  <nav className="box-menu">
                    <div className="menu-container">
                      <div className="menu-head">
                        <a href="index.html" className="e1">
                          <img src={moblogo} alt="CLICK" />
                        </a>
                      </div>
                      <div className="menu-header-container">
                        <ul id="cd-primary-nav" className="menu">
                          <li className="menu-item">
                            <a href="#">Home</a>
                          </li>
                          <li className="menu-item menu-item-has-children">
                            <a href="#">Employers</a>
                            <ul className="sub-menu">
                              <li className="menu-item">
                                <Link
                                  className="fgh"
                                  to={`/candidate-login${
                                    window.location.href.search("false") ==
                                      -1 &&
                                    window.location.href.search("true") == -1
                                      ? `/`
                                      : `?if=${
                                          window.location.href.search(
                                            "false"
                                          ) == -1
                                            ? true
                                            : false
                                        }`
                                  }`}
                                >
                                  Sign In
                                </Link>
                              </li>
                              {props.GetUserReducer.users &&
                              props.GetUserReducer.users &&
                              props.GetUserReducer.users.zip_code !== null &&
                              props.GetUserReducer.users.zip_code !==
                                undefined &&
                              props.GetUserReducer.users.zip_code !== "" ? (
                                <li className="menu-item">
                                  <Link className="fgh" to="/postjob">
                                    Post Jobs
                                  </Link>
                                </li>
                              ) : (
                                <li className="menu-item">
                                  <Link
                                    className="fgh"
                                    to={`/employer-login${
                                      window.location.href.search("false") ==
                                        -1 &&
                                      window.location.href.search("true") == -1
                                        ? `/`
                                        : `?if=${
                                            window.location.href.search(
                                              "false"
                                            ) == -1
                                              ? true
                                              : false
                                          }`
                                    }`}
                                  >
                                    Post Jobs
                                  </Link>
                                </li>
                              )}
                              <li className="menu-item">
                                <Link
                                  className="fgh"
                                  to={`/get-premium${
                                    window.location.href.search("false") ==
                                      -1 &&
                                    window.location.href.search("true") == -1
                                      ? `/`
                                      : `?if=${
                                          window.location.href.search(
                                            "false"
                                          ) == -1
                                            ? true
                                            : false
                                        }`
                                  }`}
                                >
                                  Products &amp; Pricing
                                </Link>
                              </li>
                            </ul>
                          </li>
                          {props.GetUserReducer.users &&
                          props.GetUserReducer.users &&
                          props.GetUserReducer.users.zip_code !== null &&
                          props.GetUserReducer.users.zip_code !== undefined &&
                          props.GetUserReducer.users.zip_code !== "" ? (
                            props.GetUserReducer.users &&
                            props.GetUserReducer.users &&
                            props.GetUserReducer.users.is_resume !== null &&
                            props.GetUserReducer.users.is_resume !==
                              undefined &&
                            props.GetUserReducer.users.is_resume !== "" ? (
                              props.GetUserReducer.users.is_resume == 0 ? (
                                <>
                                  <li className="contact menu-item">
                                    <Link className="fgh" to="/createresume">
                                      Post Resume
                                    </Link>
                                  </li>
                                  <li className="contact menu-item">
                                    {props.GetUserReducer.users &&
                                    props.GetUserReducer.users.theme == 0 ? (
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            props.GetUserReducer.users &&
                                            props.GetUserReducer.users.theme ==
                                              0
                                              ? false
                                              : true
                                          }
                                          onChange={() => theme(1)}
                                        />
                                        <span className="slider round" />
                                      </label>
                                    ) : (
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            props.GetUserReducer.users &&
                                            props.GetUserReducer.users.theme ==
                                              0
                                              ? false
                                              : true
                                          }
                                          onChange={() => theme(0)}
                                        />
                                        <span className="slider round" />
                                      </label>
                                    )}
                                  </li>
                                </>
                              ) : (
                                <>
                                  <li className="contact menu-item">
                                    <Link
                                      className="fgh res-dis-none"
                                      to="/previewresume"
                                    >
                                      {" "}
                                      Resume
                                    </Link>
                                  </li>
                                  <li className="contact menu-item">
                                    {props.GetUserReducer.users &&
                                    props.GetUserReducer.users.theme == 0 ? (
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            props.GetUserReducer.users &&
                                            props.GetUserReducer.users.theme ==
                                              0
                                              ? false
                                              : true
                                          }
                                          onChange={() => theme(1)}
                                        />
                                        <span className="slider round" />
                                      </label>
                                    ) : (
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            props.GetUserReducer.users &&
                                            props.GetUserReducer.users.theme ==
                                              0
                                              ? false
                                              : true
                                          }
                                          onChange={() => theme(0)}
                                        />
                                        <span className="slider round" />
                                      </label>
                                    )}
                                  </li>
                                </>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            <>
                              <li className="contact menu-item">
                                <Link
                                  className="fgh"
                                  to={`/candidate-login${
                                    window.location.href.search("false") ==
                                      -1 &&
                                    window.location.href.search("true") == -1
                                      ? `/`
                                      : `?if=${
                                          window.location.href.search(
                                            "false"
                                          ) == -1
                                            ? true
                                            : false
                                        }`
                                  }`}
                                >
                                  Post Resume
                                </Link>
                              </li>
                              <li className="contact menu-item">
                                {props.GetUserReducer.users &&
                                props.GetUserReducer.users.theme == 0 ? (
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        props.GetUserReducer.users &&
                                        props.GetUserReducer.users.theme == 0
                                          ? false
                                          : true
                                      }
                                      onChange={() => theme(1)}
                                    />
                                    <span className="slider round" />
                                  </label>
                                ) : (
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        props.GetUserReducer.users &&
                                        props.GetUserReducer.users.theme == 0
                                          ? false
                                          : true
                                      }
                                      onChange={() => theme(0)}
                                    />
                                    <span className="slider round" />
                                  </label>
                                )}
                              </li>
                            </>
                          )}

                          <li className="contact menu-item">
                            <Link className="fgh" to="/applicationhistory">
                              Application History
                            </Link>
                          </li>
                          <li className="contact menu-item">
                            <Link
                              className="fgh"
                              to={`/candidate-login${
                                window.location.href.search("false") == -1 &&
                                window.location.href.search("true") == -1
                                  ? `/`
                                  : `?if=${
                                      window.location.href.search("false") == -1
                                        ? true
                                        : false
                                    }`
                              }`}
                            >
                              Sign In
                            </Link>
                          </li>
                          <li className="line" />
                        </ul>
                      </div>
                      <div className="menu-foot">
                        <div className="social">
                          <a href="#" target="_blank">
                            <img src={fb} />
                          </a>
                          <a href="#" target="_blank">
                            <img src={twi} />
                          </a>
                          <a href="#" target="_blank">
                            <img src={yt} />
                          </a>
                          <a href="#" target="_blank">
                            <img src={lin} />
                          </a>
                        </div>
                        <hr />
                        <address>
                          <span className="email">
                            <i className="icon-mail" />© 2022 - Hire Choices
                          </span>
                        </address>
                        <address>
                          <span className="email">
                            <p>
                              {" "}
                              <Link
                                className="fgh"
                                to={`/contact-us${
                                  window.location.href.search("false") == -1 &&
                                  window.location.href.search("true") == -1
                                    ? `/`
                                    : `?if=${
                                        window.location.href.search("false") ==
                                        -1
                                          ? true
                                          : false
                                      }`
                                }`}
                              >
                                Contact Us
                              </Link>{" "}
                              |{" "}
                              <Link
                                className="fgh"
                                to={`/about-us${
                                  window.location.href.search("false") == -1 &&
                                  window.location.href.search("true") == -1
                                    ? `/`
                                    : `?if=${
                                        window.location.href.search("false") ==
                                        -1
                                          ? true
                                          : false
                                      }`
                                }`}
                              >
                                About Us |{" "}
                              </Link>
                              <Link
                                className="fgh"
                                to={`/help-center${
                                  window.location.href.search("false") == -1 &&
                                  window.location.href.search("true") == -1
                                    ? `/`
                                    : `?if=${
                                        window.location.href.search("false") ==
                                        -1
                                          ? true
                                          : false
                                      }`
                                }`}
                              >
                                Help Center
                              </Link>{" "}
                              | <a href>Terms of Services</a> |{" "}
                              <Link
                                className="fgh"
                                to={`/privacy-policy${
                                  window.location.href.search("false") == -1 &&
                                  window.location.href.search("true") == -1
                                    ? `/`
                                    : `?if=${
                                        window.location.href.search("false") ==
                                        -1
                                          ? true
                                          : false
                                      }`
                                }`}
                              >
                                Privacy Policy
                              </Link>
                            </p>
                          </span>
                        </address>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  AppliedJobReducer: state.AppliedJobReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  AppliedJob: (jobid, jobtitle) => dispatch(AppliedJob(jobid, jobtitle)),
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
  getUser: () => dispatch(getUser()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeNav2);

// code for scoop solutions
