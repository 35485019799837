import React from "react";
import { connect, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CreateEmployeeBiling from "../pages/Createemployeebiling";
import HistoryApplication from "../pages/Historyapplication";
import JobEdit from "../pages/Jobedit";
import JobPost1 from "../pages/Jobpost1";
import JobPost2 from "../pages/Jobpost2";
import JobPost3 from "../pages/Jobpost3";
import JobReport from "../pages/Jobreport";
import JobSerachResultEmployee from "../pages/Jobsearchresultemployee";
import JobSerachResultResponsive from "../pages/Jobsearchresultresponsive";
import LoginEmployee from "../pages/Loginemployee";
import LoginEmployer from "../pages/Loginemployer";
import ManageJob from "../pages/Managejob";
import PreviouslyContact from "../pages/Previouslycontact";
import ProfileEmployee from "../pages/Profileemployee";
import ResumeCreate from "../pages/Resumecreate";
import ResumePreview from "../pages/Resumepreview";
import ResumeSaved from "../pages/Resumesaved";
import ResumeSearch from "../pages/Resumesearch";
import ResumeSearchMobile from "../pages/ResumesearchMobile";
import Pdf from "../components/searchresume/pdf";
import ResumeUpdate from "../pages/ResumeUpdate";
import SearchHomeEmployee from "../pages/Searchhomeemployee";
import ShowEditEmployeeBiling from "../pages/Showeditemployeebiling";
import SignUpEmplyee from "../pages/Signupemployee";
import SignUpEmplyer from "../pages/Signupemployer";
import HelpCenterOriginal from "../pages/Helpcenteroriginal";
import EmployerHelpCenter from "../pages/Employerhelpcenter";
import EmployeerHelpCenter from "../pages/Employeehelpcenter";
import AboutForUs from "../pages/Aboutforus";
import ContactForUs from "../pages/Contactforus";
import PrivacyForPolicy from "../pages/Privacyforpolicy";
import TermsAndConditions from "../pages/Termsandconditions";
import ShowAdministrator from "../pages/Showadministrator";
import MessageNewTemplate from "../pages/Messagenewtemplate";
import ApplyCandidateToJob from "../pages/Applycandidatetojob";
import PasswordForgotEmail from "../pages/Passwordforgotemail";
import PasswordForgotModal from "../pages/Passwordforgotmodal";
import PasswordForgot from "../pages/Passwordforgot";
import UserAdd from "../pages/Useradd";
import UserEdit from "../pages/Useredit";
import ManagementAccount from "../pages/Managementaccount";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import PremiumGet from "../pages/Premiumget";
import Invoice from "../pages/invoice";
import JobsSaved from "../pages/Jobssaved";
import UploadFile from "../pages/Uploadfile";
import MessageSendEmail from "../pages/Messageemailsend";
import Error from "../pages/Error";
import EmailVerify from "../components/homesearchemployee/emailverification";
import EmailVerifyAgain from "../components/homesearchemployee/emailverificationagain";
import ResumePreviewSaved from "../pages/Resumepreviewsaved";
import ApplyCandidateToJobSpecific from "../pages/Applycandidatetojobspecific";
import NewTemplateSave from "../pages/Newtempltesave";
import ResumeView from "../pages/Resumeview";
import ManageCardPage from "../pages/ManageCard";

function Routing(props) {
  const subscription = useSelector(state=>state?.SubscriptionInfo)

  return (
    <div>
      <Router>
        <Switch>
          <Route path="/" exact component={SearchHomeEmployee} />
          <Route path="/email-verify" exact component={EmailVerify} />
          <Route
            path="/email-verify-again"
            exact
            component={EmailVerifyAgain}
          />
          <Route path="/signup" exact component={SignUpEmplyee} />
          <Route
            path="/candidate-login"
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== ""
                ? Error
                : LoginEmployee
            }
          />
          <Route path="/search-job" exact component={JobSerachResultEmployee} />
          <Route
            path="/search-job-detail/:id"
            exact
            component={JobSerachResultResponsive}
          />

          <Route exact path="/linkedin" component={LinkedInPopUp} />

          <Route path="/reportjob" exact component={JobReport} />
          <Route
            path={`/createresume`}
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== "" &&
              props.GetUserReducer.users.role !== 1
                ? ResumeCreate
                : Error
            }
          />
          <Route path="/employersignup" exact component={SignUpEmplyer} />
          <Route
            path="/previewresume"
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== "" &&
              props.GetUserReducer.users.role !== 1
                ? ResumePreview
                : Error
            }
          />
          <Route
            path="/saved-preview-resume"
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== "" &&
              props.GetUserReducer.users.role !== 0
                ? ResumePreviewSaved
                : Error
            }
          />
          <Route
            path="/employer-login"
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== ""
                ? Error
                : LoginEmployer
            }
          />
          <Route
            path="/applicationhistory"
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== "" &&
              props.GetUserReducer.users.role !== 1
                ? HistoryApplication
                : Error
            }
          />
          <Route
            path="/save-jobs"
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== "" &&
              props.GetUserReducer.users.role !== 1
                ? JobsSaved
                : Error
            }
          />
          <Route
            path="/profile"
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== "" &&
              props.GetUserReducer.users.role !== 1
                ? ProfileEmployee
                : Error
            }
          />
          <Route
            path="/updateresume"
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== "" &&
              props.GetUserReducer.users.role !== 1
                ? ResumeUpdate
                : Error
            }
          />

          <Route path="/resumepdf" exact component={Pdf} />

          <Route path="/saved-resume" exact component={ResumeSaved} />
          <Route path="/help-center" exact component={HelpCenterOriginal} />
          <Route
            path="/help-center-employer"
            exact
            component={EmployerHelpCenter}
          />
          <Route
            path="/help-center-employee"
            exact
            component={EmployeerHelpCenter}
          />
          <Route path="/about-us" exact component={AboutForUs} />
          <Route path="/contact-us" exact component={ContactForUs} />
          <Route path={`/privacy-policy`} exact component={PrivacyForPolicy} />
          <Route
            path={`/terms-condition`}
            exact
            component={TermsAndConditions}
          />

          <Route
            path="/forgot-password-email"
            exact
            component={PasswordForgotEmail}
          />
          <Route
            path="/forgot-password-modal"
            exact
            component={PasswordForgotModal}
          />
          <Route path="/forgot-password" exact component={PasswordForgot} />
          <Route
            path="/get-premium"
            exact
            component={
              props.GetUserReducer &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.role !== null &&
              props.GetUserReducer.users.role !== undefined &&
              props.GetUserReducer.users.role !== ""
                ? props.GetUserReducer.users.role !== 0
                  ? PremiumGet
                  : Error
                : PremiumGet
            }
          />

          {props.GetUserReducer &&
          props.GetUserReducer.users &&
          props.GetUserReducer.users.role !== null &&
          props.GetUserReducer.users.role !== undefined &&
          props.GetUserReducer.users.role !== "" &&
          props.GetUserReducer.users.role !== 0 ? (
            <>
              {/* <Route
                path="/account-management"
                exact
                component={ManagementAccount}
              /> */}
              <Route path="/view-resume" exact component={ResumeView} />
              <Route path="/add-user" exact component={UserAdd} />
              <Route path="/edit-user" exact component={UserEdit} />
              <Route path="/files" exact component={UploadFile} />
              <Route path="/send-email" exact component={MessageSendEmail} />
              <Route path="/new-template" exact component={NewTemplateSave} />
              <Route path="/edit-template" exact component={()=><NewTemplateSave isEdit />} />
              <Route
                path="/company-administrator"
                exact
                component={ShowAdministrator}
              />

                <Route
                  path="/subscription"
                  exact
                  component={ManageCardPage}
                />

              <Route
                path="/message-template"
                exact
                component={MessageNewTemplate}
              />
              <Route
                path="/apply-to-job"
                exact
                component={ApplyCandidateToJob}
              />
              <Route
                path="/apply-to-job-specific"
                exact
                component={ApplyCandidateToJobSpecific}
              />
              <Route
                path="/previously-contact"
                exact
                component={PreviouslyContact}
              />
              <Route path="/edit-job" exact component={JobEdit} />
              {/* <Route
                path="/search-resume"
                exact
                component={
                  props.GetUserReducer &&
                  props.GetUserReducer.users &&
                  props.GetUserReducer.users.resume_info !== null &&
                  props.GetUserReducer.users.resume_info !== undefined &&
                  props.GetUserReducer.users.resume_info !== "" &&
                  props.GetUserReducer.users.resume_info !== 0 &&
                  !subscription?.isPayAsYouGo
                    ? ResumeSearch
                    : Error
                }
              /> */}
              <Route
                path="/search-resumee"
                exact
                component={!subscription?.isPayAsYouGo ? ResumeSearchMobile : Error}
              />
              <Route path="/postjob2" exact component={JobPost2} />
              <Route path="/postjob3" exact component={JobPost3} />
              <Route
                path="/manage-job"
                exact
                component={
                  props.GetUserReducer &&
                  props.GetUserReducer.users &&
                  props.GetUserReducer.users.create_posting !== null &&
                  props.GetUserReducer.users.create_posting !== undefined &&
                  props.GetUserReducer.users.create_posting !== "" &&
                  props.GetUserReducer.users.create_posting !== 0
                    ? ManageJob
                    : Error
                }
              />
              {/* <Route
                path="/biling"
                exact
                component={
                  props.GetUserReducer &&
                  props.GetUserReducer.users &&
                  props.GetUserReducer.users.billing_info !== null &&
                  props.GetUserReducer.users.billing_info !== undefined &&
                  props.GetUserReducer.users.billing_info !== "" &&
                  props.GetUserReducer.users.billing_info !== 0
                    ? CreateEmployeeBiling
                    : Error
                }
              />
              <Route
                path="/bilinginfo"
                exact
                component={
                  props.GetUserReducer &&
                  props.GetUserReducer.users &&
                  props.GetUserReducer.users.billing_info !== null &&
                  props.GetUserReducer.users.billing_info !== undefined &&
                  props.GetUserReducer.users.billing_info !== "" &&
                  props.GetUserReducer.users.billing_info !== 0
                    ? ShowEditEmployeeBiling
                    : Error
                }
              /> */}
              <Route path="/postjob" exact component={JobPost1} />
              <Route
                path="/invoice"
                exact
                component={
                  props.GetUserReducer &&
                  props.GetUserReducer.users &&
                  props.GetUserReducer.users.billing_info !== null &&
                  props.GetUserReducer.users.billing_info !== undefined &&
                  props.GetUserReducer.users.billing_info !== "" &&
                  props.GetUserReducer.users.billing_info !== 0
                    ? Invoice
                    : Error
                }
              />
            </>
          ) : (
            <Route path="*" exact component={Error} />
          )}
        </Switch>
      </Router>
    </div>
  );
}

const mapStateToProps = (state) => ({
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Routing);

// code for scoop solutions
