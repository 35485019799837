import React, { useState } from "react";
import OkIcon from "../../assets/ok-icon.svg";
// import MStripe from "../../stripeBtn";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { setSubscriptionInfoRedux } from "../../actions/Subscriptioninfo";
import config from "../../helpers/config.json";
import { AxiosPOST } from "../../libs/axios";
import STRIPE_PUBLISHABLE from "../../stripe";
import Modal from "react-modal";
import dayjs from "dayjs";
import Button from "../button";

Modal.setAppElement("#root");

const Card = ({ pac }) => {
  const [alertMsg, setAlertMsg] = useState("");
  const [dueAmountAlert, setDueAmountAlert] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelNowLoading, setCancelNowLoading] = useState(false);
  const [cancelAtPeriodLoading, setCancelAtPeriodLoading] = useState(false);
  const [wantToCancel, setWantToCancel] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    subscription_id,
    status,
    cancelled_at,
    customer_id,
    dueAmount,
    usedAmount,
    max_status_purchased
  } = useSelector((state) => state.SubscriptionInfo);
  const { name, amount, benefits, price_id, value } = pac;

  const userId = () => localStorage.getItem("userid");

  // const isMatch = value == status; // active
  // const isMatchNotCancelled = isMatch && !cancelled_at; // select
  // const isMatchWithCancelled = value== max_status_purchased && cancelled_at; // reactive
  // const disabled = isMatchNotCancelled || loading || isMatch;

  const isMatch = value == status; // active
  const isMatchNotCancelled = isMatch  // select
  const isMatchWithCancelled = value == max_status_purchased; // reactive
  const disabled = isMatchNotCancelled || loading || isMatch;


  console.log("is match",isMatch,isMatchNotCancelled,isMatchWithCancelled,cancelled_at)

const dispatch = useDispatch();

  const handleClose = () => {
    setCancelNowLoading(false);
    setCancelAtPeriodLoading(false);
    setWantToCancel(false);
    setModalOpen(false);
  };
  const handleToken = (token) => {
    AxiosPOST({
      url: `${config["baseUrl"]}/stripe/get-subscription`,
      body: {
        token,
        price_id,
        user_id: userId(),
        package_id:pac?.id,
        max_status_purchased:!max_status_purchased ? value : value > max_status_purchased ? value : max_status_purchased
      },
    })
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          dispatch(setSubscriptionInfoRedux({...data?.info, message: data.message}));
          setAlertMsg(data?.message);
        } else {
          setAlertMsg(res?.error);
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
        setAlertMsg(error.response.data.error);
      });
  };

  const getSubscriptionWithExistingCustomer = async () => {
    try {
      if (!confirmLoading) {
        setConfirmLoading(true);
        const res = await AxiosPOST({
          url: `${config["baseUrl"]}/stripe/subscription-of-existing-customer`,
          body: {
            customer_id,
            user_id: userId(),
            price_id,
            package_id:pac?.id
          },
        });
        const info = res?.data?.info;
        dispatch(setSubscriptionInfoRedux({...info, message: res?.data?.message}));
        setAlertMsg(res.data.message);
        setConfirmAlert(false);
        setConfirmLoading(false);
      }
    } catch (error) {
      setConfirmAlert(false);
      setConfirmLoading(false);
      setAlertMsg(error.response.data.error);
    }
  };
  console.log("max purchase ",max_status_purchased ,"val", value ,"status",status,cancelled_at, "condition",value > max_status_purchased ? value : max_status_purchased)
  const updateSubscription = async () => {
    // const isDowngrade = value < max_status_purchased || value < status ? false : true 
    const isDowngrade = !max_status_purchased ? false : value < max_status_purchased ? true : false 
    // !max_status_purchased ? value : value > max_status_purchased ? value : max_status_purchased
    // const isDowngrade = value < status
    console.log(isDowngrade, "isDowngrade")
    try {
      if (!disabled && !loading) {
        setLoading(true);
        const res = await AxiosPOST({
          url: `${config["baseUrl"]}/stripe/update-subscription`,
          body: {
            subscription_id,
            user_id: userId(),
            price_id,
            package_id:pac?.id,
            isDowngrade,
            max_status_purchased:value > max_status_purchased ? value : max_status_purchased,
            isReactivate: max_status_purchased == value ? true : false
          },
        });
        const info = res?.data?.info;
        dispatch(setSubscriptionInfoRedux({...info, message: res?.data?.message}));
        setAlertMsg(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error:", error);
      setAlertMsg(error.response.data.error);
    }
  };

  const handleReActivate = async () => {
    try {
      if (!disabled && !loading) {
        setLoading(true);
        const res = await AxiosPOST({
          url: `${config["baseUrl"]}/stripe/re-subscribe`,
          body: {
            subscription_id,
            user_id: userId(),
            package_id:pac?.id

          },
        });
        const info = res?.data?.info;
        dispatch(setSubscriptionInfoRedux({...info, message: res?.data?.message}));
        setAlertMsg(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error:", error);
      setAlertMsg(error.response.data.error);
    }
  };

  const handleCancelNow = async () => {
    try {
      if (!cancelNowLoading && !cancelAtPeriodLoading) {
        setCancelNowLoading(true);
        const res = await AxiosPOST({
          url: `${config["baseUrl"]}/stripe/cancel-subscription`,
          body: {
            subscription_id,
            user_id: userId(),
            cancel_now: true,
            package_id:pac?.id

          },
        });
        const info = res?.data?.info;
        dispatch(setSubscriptionInfoRedux({...info, message: res?.data?.message}));
        setAlertMsg(res.data.message);
        handleClose();
        setCancelNowLoading(false);
      }
    } catch (error) {
      console.log("error:", error);
      setCancelNowLoading(false);
      setAlertMsg(error.response.data.error);
    }
  };

  const handleCancelAtPeriod = async () => {
    try {
      if (!cancelNowLoading && !cancelAtPeriodLoading) {
        setCancelAtPeriodLoading(true);
        const res = await AxiosPOST({
          url: `${config["baseUrl"]}/stripe/cancel-subscription`,
          body: {
            subscription_id,
            user_id: userId(),
            package_id:pac?.id,
            max_status_purchased
          },
        });
        const info = res?.data?.info;

        dispatch(setSubscriptionInfoRedux({...info, message: res?.data?.message}));
        setAlertMsg(
          `${res.data.message}`
        );
        handleClose();
        setCancelAtPeriodLoading(false);
      }
    } catch (error) {
      console.log("error:", error);
      setCancelAtPeriodLoading(false);
      setAlertMsg(error.response.data.error);
    }
  };

  const handleClick = () => {
    if (dueAmount) {
      setDueAmountAlert(true);
    } else {
      if (!disabled) {
        let matchWithSubscriptionId = !isMatch && subscription_id;
        if (matchWithSubscriptionId && value) {
          updateSubscription();
        } else if (matchWithSubscriptionId && !value) {
          setModalOpen(true);
        } else if (isMatchWithCancelled) {
          handleReActivate();
        } else if (!isMatch && !subscription_id && customer_id && value) {
          setConfirmAlert(true);
        } else {
          console.log("else condition is running");
        }
      }
    }
  };

  return (
    <div
      className="col-xs-12 col-md-6 col-xl-3 my-5 px-3"
      style={{ borderRadius: "15px" }}
      key={pac.name}
    >
      <div
        className="shadow rounded"
        style={{
          padding: "1.5rem",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <h3 className="prem-div-head font-weight-bold stand-text text-center">
            {name}
          </h3>
        </div>
        <div className="my-3 flex-grow-1">
          {(typeof benefits === "string"
            ? JSON.parse(benefits)
            : benefits
          )?.map((benefit) => {
            const data = benefit?.replaceAll(
              "Free",
              '<b style="color:#28a745">Free</b>'
            );
            return (
              <div
                key={benefit}
                className="d-flex mx-auto align-items-start mb-2"
                style={{ maxWidth: "80%" }}
              >
                <img src={OkIcon} className="w-0 me-2" />
                <p
                  className="stand-text"
                  dangerouslySetInnerHTML={{ __html: data }}
                />
              </div>
            );
          })}
        </div>
        <div>
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="stand-text">
                ${amount || 0}{" "}
                <small className="small-month stand-text smallprice"></small>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              {localStorage.getItem("userid") !== null &&
              localStorage.getItem("userid") !== undefined &&
              localStorage.getItem("userid") !== "" ? (
                !subscription_id && !customer_id && !dueAmount ? (
                  <StripeCheckout
                    token={handleToken}
                    stripeKey={STRIPE_PUBLISHABLE}
                    amount={amount * 100}
                    currency="USD"
                    name={name}
                    billingAddress
                    zipCode
                    allowRememberMe
                  >
                    <Button
                      title={isMatch ? "Active" : "Select"}
                      disabled={disabled}
                      style={{
                        backgroundColor: disabled ? "#b0b0b0" : "orange",
                        cursor: disabled ? "not-allowed" : "pointer",
                      }}
                    />
                  </StripeCheckout>
                ) : (
                  <Button
                  title={
                    loading
                      ? "Loading..."
                      : isMatch
                      ? "Active"
                      : isMatchWithCancelled
                      ? "Re-Activate"
                      : "Select"
                  } 
                  // title={
                    //   loading
                    //     ? "Loading..."
                    //     : isMatchWithCancelled
                    //     ? "Re-Activate"
                    //     : isMatchNotCancelled
                    //     ? "Active"
                    //     : "Select"
                    // }
                    onClick={handleClick}
                    disabled={disabled}
                    style={{
                      backgroundColor: disabled ? "#b0b0b0" : "orange",
                      cursor: disabled ? "not-allowed" : "pointer",
                    }}
                  />
                )
              ) : (
                <Link
                  to={`/employer-login${
                    window.location.href.search("false") == -1 &&
                    window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                          window.location.href.search("false") == -1
                            ? true
                            : false
                        }`
                  }`}
                  style={{ color: "black" }}
                >
                  <Button title="Select" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "fit-content",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "40px 20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center">
          <h5>Switch to Pay as You Go?</h5>
          <div className="d-flex flex-column gap-3 flex-sm-row mt-4 align-items-center justify-content-center search-session">
            {!wantToCancel ? (
              <>
                <Link
                  to="#"
                  className="fgh mb-0 w-100"
                  onClick={() => setModalOpen(false)}
                >
                  No
                </Link>
                <Link
                  to="#"
                  className="fgh mb-0 w-100"
                  onClick={handleCancelAtPeriod}
                >
                  Yes
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="#"
                  className="fgh mb-0 w-100"
                  onClick={handleCancelNow}
                >
                  {cancelNowLoading ? "Loading..." : "Cancel now!"}
                </Link>
                <Link
                  to="#"
                  className="fgh mb-0 w-100"
                  onClick={handleCancelAtPeriod}
                >
                  {cancelAtPeriodLoading
                    ? "Loading..."
                    : "Cancel at period end"}
                </Link>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={alertMsg}
        onRequestClose={() => setAlertMsg("")}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "fit-content",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "40px 20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center">
          <h5>{alertMsg}</h5>
          <div className="d-flex flex-column gap-3 flex-sm-row mt-4 align-items-center justify-content-center search-session">
            <Link
              to="#"
              className="fgh mb-0 w-100"
              onClick={() => setAlertMsg("")}
            >
              Ok
            </Link>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={confirmAlert}
        onRequestClose={() => setConfirmAlert(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "fit-content",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "40px 20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center">
          <h5>Do you want to update subscription with {name} Plan?</h5>
          {/* {!!(usedAmount || 0) && (
            <p>
              <b>Note: </b>
              Scheduled payments will be deduct.
            </p>
          )} */}
          <div className="d-flex flex-column gap-3 flex-sm-row mt-4 align-items-center justify-content-center search-session">
            <Link
              to="#"
              className="fgh mb-0 w-100"
              onClick={() => setConfirmAlert(false)}
            >
              No
            </Link>
            <Link
              to="#"
              className="fgh mb-0 w-100"
              onClick={getSubscriptionWithExistingCustomer}
            >
              {confirmLoading ? "Loading..." : "Yes!"}
            </Link>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={dueAmountAlert}
        onRequestClose={() => setDueAmountAlert(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "fit-content",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "40px 20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center">
          <h5>Some payments remaining, please clear them first.</h5>
          <div className="d-flex flex-column gap-3 flex-sm-row mt-4 align-items-center justify-content-center search-session">
            <Link
              to="/subscription"
              className="fgh mb-0 w-100"
              onClick={() => setDueAmountAlert(false)}
            >
              Pay Now!
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Card;
