import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { ReportJobAction } from "../../actions/Reportjobaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader from "../fullpageloader/fullPageLoader";
import { useLocation, useHistory } from "react-router-dom";
import Dropdownnav from "../dropdownnav/Dropdownnav";

Modal.setAppElement("#root");

const ReportJob = (props) => {
  const search = useLocation().search.trim();
  const job_id = new URLSearchParams(search).get("id");
  const jobTitles = new URLSearchParams(search).get("jobtitle");
  const jobTitle = decodeURIComponent(jobTitles)
  const Locations = new URLSearchParams(search).get("location");
  const Location = decodeURIComponent(Locations)
  const [professional_summarycount, setprofessional_summarycount] = useState("")
  const CharacterCount = (e) => {
    setdetails(e.target.value)
    setprofessional_summarycount(e.target.value.length)
  }

  useEffect(() => {
  }, []);
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [reason, setreason] = useState("")
  const [details, setdetails] = useState("")
  const createsearch = (e) => {
    props.ReportJobAction(job_id, reason, details, jobTitle, Location);

  };
  const signOut = () => {
    localStorage.removeItem("array");
    localStorage.removeItem("token")
    localStorage.removeItem("userid")
    localStorage.removeItem("username")
    localStorage.removeItem("email")
    localStorage.removeItem("zip")
    window.location = `/`
  }
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <Dropdownnav />
          {/* header======================================================================*/}
          <div className="main-content  p-5">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-6 report-jobs">
                <div className="d-flex align-items-center justify-content-center py-3">
                  <h3>Report This Job</h3>
                </div>
                <hr className="m-0" />
                <div className=" py-3">
                  <div className="main-content-session">
                    <div className="role role2">
                      <label>
                        <input type="radio" name="radio32"
                          value="Offensive and/or discriminatory content"
                          onChange={(e) => setreason(e.target.value)}
                        />
                        <span className="sp-clr">Offensive and/or discriminatory content</span>
                      </label>
                      <label>
                        <input type="radio" name="radio32"
                          value="Possible Scam/Fraudulent"
                          onChange={(e) => setreason(e.target.value)}
                        />
                        <span className="sp-clr">Possible Scam/Fraudulent</span>
                      </label>
                      <label>
                        <input type="radio" name="radio32"
                          value="Problem applying to this job"
                          onChange={(e) => setreason(e.target.value)}
                        />
                        <span className="sp-clr">Problem applying to this job</span>
                      </label>
                      <label>
                        <input type="radio" name="radio32"
                          value="Advertisement"
                          onChange={(e) => setreason(e.target.value)}
                        />
                        <span className="sp-clr">Advertisement</span>
                      </label>
                      <label>
                        <input type="radio" name="radio32"
                          value="Other"
                          onChange={(e) => setreason(e.target.value)}
                        />
                        <span className="sp-clr">Other</span>
                      </label>
                    </div>
                  </div>
                </div>
                <hr className="m-0" />
                <h5 className="mt-3">Additional details</h5>
                <textarea
                  onChange={(e) => CharacterCount(e)}
                  maxLength={500} id="coverview" name cols={30} rows={6} defaultValue={""} />
                <p id="counter">Characters 500 Count {professional_summarycount !== "" && professional_summarycount !== undefined && professional_summarycount !== null ? professional_summarycount : "0"}</p>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="main-content-session inner-session">
                    <Link onClick={() => createsearch()} className="send-invitation fgh">SAVE</Link>
                    <Link to="/search-job" className="cancel-button fgh">Cancel</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* footer====================================================================== */}

        </div>
        {props.ReportJobReducer.loading == true ? (
          <></>
        ) : (
          <FullPageLoader />
        )}
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  ReportJobReducer: state.ReportJobReducer,
  authReducer: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
  ReportJobAction: (job_id, jobtitle, details, jobTitle, Location) =>
    dispatch(ReportJobAction(job_id, jobtitle, details, jobTitle, Location)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportJob);

// code for scoop solutions