const initState = {
    auth_id: null,
    authError: null,
    authMessage: null,
    loading: true,
}

const employerAuthReducer = (state = initState, action) => {
    if (action.type === 'SIGN_IN') {
        return {
            ...state,
            auth_id: action.auth_id,
            authError: action.authError,
            authMessage: action.authMessage,
            loading: action.loading,
        }
    } else if (action.type === 'SIGN_UP') {
        return {
            ...state,
            auth_id: action.auth_id,
            authError: action.authError,
            authMessage: action.authMessage,
        }
    } else if (action.type === 'SIGN_OUT') {
        return {
            ...state,
            auth_id: null,
            authError: null,
            authMessage: null,
        }
    } else if (action.type === 'RESET_AUTH_MESSAGE') {
        return {
            ...state,
            authMessage: null,
            authError: null,
            loading: action.loading,
        }
    }

    return state;
}

export default employerAuthReducer;

// code for scoop solutions