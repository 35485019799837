import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import Reportjob from "../components/reportjob/Reportjob"
import HomeFooter from "../components/homeFooter/HomeFooter";

const JobReport = () => {
    return (
        <>
            <EmployeeNav2 />
            <Reportjob />
            <HomeFooter />
        </>
    )
}
export default JobReport


// code for scoop solutions