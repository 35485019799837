import React, { useContext, useState } from "react";
import { Menu, Dropdown } from "antd";
import Box from "@mui/material/Box";
import { DownOutlined } from "@ant-design/icons";
import "./style.css";
import { ColorModeContext } from "../../theme";

const StatusDropdown = ({
  defaultValue,
  onChange = () => {},
  options = [],
}) => {
  const isLightMode = window.location.href.includes("true");
  const { mode, toggleColorMode } = useContext(ColorModeContext);

  const [value, setValue] = useState(defaultValue);
  const menu = (
    <Menu onChange={onChange}>
      <Menu.ItemGroup>
        {options.map((i, index) => (
          <Menu.Item
            onClick={() => {
              setValue(i);
              onChange({ value: i, index });
            }}
          >
            {i}
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <a
        onClick={(e) => e.preventDefault()}
        id="helllllllllllo"
        style={{
          marginBottom: 0,
          // borderColor: isLightMode ? "#000" : "#fff" + " !important",
          borderColor: mode === "light" ? "black" : "white",
          // color: isLightMode ? "#000" : "#fff" + " !important",
          // color: !isLightMode ? "#000" : "#fff",
          color: mode === "light" ? "black" : "white"
        }}
      >
        {value}
        <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default StatusDropdown;
