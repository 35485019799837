import React , {useState,useEffect} from "react"
// import logo from "../../assets/navbar/logo.png"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";

Modal.setAppElement("#root");

const ForgotPasswordModal = (props) => {
    return (
      <>
    <div>
        {/* header============================================== */}
        <div className="container-fluid">
          <div className="container height-handred">
       <Dropdownnav />
            {/* header======================================================================*/}
            <div className="row main-content h-100 align-items-center flex-column">
        <h5 className="text-center">Hire Choices</h5>
        <div className="op-pages">
          <p>If there is an account associated with this email, you will receive an email from us shortly with instructions on how to reset your password. Should you need further assistance,
            please feel free in contacting us at <br /><br />
            <a href="#">customerservice@hirechoices.com</a>
          </p>
        </div>
      </div>
          </div>
        </div>
      </div>
  </>
    )
}
export default ForgotPasswordModal;
// export default Navbar