const initState = {
    authError: null,
    authMessage: null,
    loading: true,
}

const registerReducer = (state = initState, action) => {
    if (action.type === 'REGISTER_IN') {
        return {
            ...state,
            authError: action.authError,
            authMessage: action.authMessage,
            loading: action.loading,
        }
    } else if (action.type === 'REGISTER_UP') {
        return {
            ...state,
            authError: action.authError,
            authMessage: action.authMessage,
            loading: action.loading,
        }
    } else if (action.type === 'REGISTER_OUT') {
        return {
            ...state,
            authError: null,
            authMessage: null,
            loading: action.loading,
        }
    } else if (action.type === 'RESET_REGISTER_MESSAGE') {
        return {
            ...state,
            authMessage: null,
            authError: null,
            loading: action.loading,
        }
    }

    return state;
}

export default registerReducer;

// code for scoop solutions