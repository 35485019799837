const initState = {
    alljobs: {},
    SearchResponse: null,
    loading: true,
}

const ResumeGetByIdReducer = (state = initState, action) => {

    if (action.type === 'GET_ALLRESUME') {
        return {
            ...state,
            alljobs: action.alljobs,
            SearchResponse: action.SearchResponse,
            loading: action.loading,
        }
    }
    return state;
}
export default ResumeGetByIdReducer;

// code for scoop solutions