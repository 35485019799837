import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import PrivacyPolicy from "../components/privacypolicy/Privacypolicy"
import HomeFooter from "../components/homeFooter/HomeFooter";

const PrivacyForPolicy = () => {
    return (
        <>
            <EmployeeNav2 />
            <PrivacyPolicy />
            <HomeFooter />
        </>
    )
}
export default PrivacyForPolicy


// code for scoop solutions