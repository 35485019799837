import React, { useEffect } from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import Postjob1 from "../components/postjobs/Postjob1"
import HomeFooter from "../components/homeFooter/HomeFooter";
import ReactGA from 'react-ga4';

const JobPost1 = () => {
    useEffect(()=>{

        ReactGA.event({
          category: `Page Visit`,
          label: localStorage.getItem("username")?.length > 1 ?`Employeer Name: ${localStorage.getItem("username")} visited Post Jobs page ` :`Un Authenticated user visited Post Jobs page page`,
          action:`visit at Post Jobs page`,
          nonInteraction:true
        });
      },[])
    return (
        <>
            <EmployeeNav2 />
            <Postjob1 />
            <HomeFooter />
        </>
    )
}
export default JobPost1


// code for scoop solutions