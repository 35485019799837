import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Employernav from "../dropdownnav/Employernav";
import dayjs from "dayjs";
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const CandidateApplyToJob = (props) => {
  const [load, setload] = useState(false)
  const [allArray, setallArray] = useState([])
  const [error, setError] = useState("")
  const subscription = useSelector(state=>state?.SubscriptionInfo)
  const [idd, setidd] = useState("")
  const [cidd, setcidd] = useState("")
  useEffect(async () => {
    loadgetjob()
  }, []);
  const setids = (jid, cid) => {
    setError("")
    setidd(jid)
    if(!cidd?.includes(cid))
      {
        if(cidd?.length < 20)
          setcidd(prev=>[...new Set([...prev, cid])])
        else 
          setError("You can select upto 20 candidates only")
      }
    else
      setcidd(prev=>prev?.filter(i=>i!==cid))
  }
  const loadgetjob = async () => {
    setload(true)
    await fetch(`${config['baseUrl']}/jobs/apply/get/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response.job
      setallArray(alljobs)
        ;
    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
    return null
  }
  const [order, setorder] = useState("ASC")
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("DSC")
    }
    if (order === "DSC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("ASC")
    }

  }
  const [order1, setorder1] = useState("DSC")
  const sorting1 = (col) => {
    if (order1 === "ASC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder1("DSC")
    }
    if (order1 === "DSC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder1("ASC")
    }

  }
  const updatestatus = async (val, id) => {
    var value = val.target.value
    await fetch(`${config['baseUrl']}/jobs/applystatus/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "status": value == "Status" ? 0 : value == "Rejected" ? 1 : value == "Contacted" ? 2 : value == "Reviewed" ? 3 : "",
        "empid": Number(localStorage.getItem("userid")),
        "id": id
      })
    }).then(res => {

      if (res.status !== 200 && res.status !== 201) {
        
      }
      return res.json();
    }).then((response) => {

      if (response.message == "Status Updated Successfully.") {
        window.location = "/apply-to-job"
      }
      else {
        console.log("Something went wrong..")
      }

    }).catch((error) => {
      console.log(error)

    })
  }

  const history = useHistory()

  const handleClick=()=>{
    if(cidd?.length){
      history.push(`/send-email?id=${idd}&cid=${cidd.join(",")}`)
    }else{
      setError("Please select a candidate to email.")
    }
  }

  return (
    <>
      {
        load == false ?
          <>
            <div className="container-fluid" id="Employee">
              <div className="container">
                <Employernav />
                {/* header======================================================================*/}
                <div className="main-content candidate-applies-to-job inner-session">
                <div className="d-flex align-items-end flex-column justify-content-center mb-3 py-2">
                    {
                      props.GetUserReducer.users && props.GetUserReducer.users.Is_packaged !== null && props.GetUserReducer.users.Is_packaged !== undefined && props.GetUserReducer.users.Is_packaged !== "" && props.GetUserReducer.users.Is_packaged !== 0 ?
                      idd !== "" ?
                          <>
                            {/* <Link to={`/send-email?id=${idd}&cid=${cidd.join(",")}`} className="text-light fgh mb-1">Email Selected Candidate</Link> */}
                            <button onClick={handleClick} className="text-light fgh mb-1">Email Selected Candidate</button>
                            <div style={{minWidth: '208px', textAlign: 'center'}}><small className="text-danger" style={{ color: "red" }}>{error}</small></div>
                          </>
                          :
                          <>
                            <button onClick={()=>setError("Please select a candidate to email.")} className="text-light fgh mb-1">Email Selected Candidate</button>
                            <div style={{minWidth: '208px', textAlign: 'center'}}><small className="text-danger" style={{ color: "red" }}>{error}</small></div>
                          </>
                        :
                        <>
                          <Link to={`/get-premium${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`} className="text-light fgh">Email Selected Candidate</Link>
                          <div style={{minWidth: '208px', textAlign: 'center'}}><small className="text-danger" style={{ color: "red" }}>{error}</small></div>
                        </>
                    }

                  </div>
                  <div className="usr-tbl">
                    <table id="example" className="table  table-striped table-bordered dataTable" cellSpacing={0} width="100%" role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                      <thead>
                        <tr role="row">
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '20%', textAlign: 'center' }}>
                            Candidate Name {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} />}</th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '15%', textAlign: 'center' }}>
                            Job Applied
                             {/* {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("job_applied")} />} */}
                             </th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '15%', textAlign: 'center' }}>Date
                            Applied {order1 == "ASC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting1("date_applied")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting1("date_applied")} />}
                          </th>
                          <th className="sorting_desc" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Age: activate to sort column ascending" aria-sort="descending" style={{ width: '15%', textAlign: 'center' }}>
                            Status
                             {/* {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("status")} />} */}
                             </th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Start date: activate to sort column ascending" style={{ width: '15%', textAlign: 'center' }}>
                            Contact 
                            {/* {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("contact")} />} */}
                            </th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Salary: activate to sort column ascending" style={{ width: '20%', textAlign: 'center' }}>
                            Date Responded 
                            {/* {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("date_responded")} />} */}
                          </th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Salary: activate to sort column ascending" style={{ width: '20%', textAlign: 'center' }}>
                            Resume
                          </th>
                        </tr>
                      </thead>
                      {
                        allArray && allArray.length > 0 ? allArray.map(job => (
                          <tbody>

                            <tr role="row" className="odd text-center">
                            <td className>{!!subscription?.isPayAsYouGo ? `${job.candidate_name[0]}...` :  job?.candidate_name || ""}</td>
                              <td>{job.job_applied !== "" && job.job_applied !== null && job.job_applied !== undefined ? job.job_applied : ""}</td>
                              <td>{job.date_applied !== "" && job.date_applied !== null && job.date_applied !== undefined ? dayjs(job.date_applied).format("MM-DD-YYYY") : ""}</td>
                              <td style={{ textAlign: 'center' }}>
                                <select
                                  name="status"
                                  id="status"
                                  onChange={(e) => updatestatus(e, job.id)}
                                  className="form-select border border-secondary"
                                >
                                  <option value="Status">Status</option>
                                  <option value="Rejected">Rejected</option>
                                  <option value="Contacted">Contacted</option>
                                  <option value="Reviewed">Reviewed</option>
                                </select>
                              </td>

                              <td style={{ textAlign: 'center' }}>
                                <div className="role">
                                  {/* {
                                    job.contact !== 0 ?
                                      <div className="account-managmrnt-custom-radio-0">
                                      </div>
                                      : */}
                                      <label for={job.id}>
                                        <input type="checkbox" onChange={(e) => setids(job.id, job.candidate_id)} id={job.id} name="fav_language" className="mt-1 mr-2" />
                                        <span className="resume-data" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                        </span>
                                      </label>
                                  {/* } */}
                                </div>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {job.date_responded !== "" && job.date_responded !== null && job.date_responded !== undefined ? dayjs(job.date_responded).format("MM-DD-YYYY") : "Not Responded Yet"}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                              <Link to={`/view-resume?id=${job.candidate_id}`} className=" mb-0">View</Link>
                              </td>
                            </tr>
                          </tbody>
                        ))

                          : <div className="d-flex w-100 aliign-items-center justify-content-center not-found px-5 nofournd">
                            no Candidate found
                          </div>}
                    </table>
                  </div>
                </div>


              </div>
            </div>

          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(CandidateApplyToJob);


// code for scoop solutions