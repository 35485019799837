const initState = {
    users: {},
    usersresponse: null,
    loading: true,
}

const GetUserReducer = (state = initState, action) => {

    if (action.type === 'GET_USERS') {
        return {
            ...state,
            users: action.users,
            usersresponse: action.usersresponse,
            loading: action.loading,
        }
    }

    return state;
}
export default GetUserReducer;


// code for scoop solutions