import React, { useState, useEffect } from "react"
// import logo from "../../assets/navbar/logo.png"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import textbox from "../../assets/textbox.PNG"

Modal.setAppElement("#root");

const PostJob2 = (props) => {
  const subscription = useSelector(state=>state?.SubscriptionInfo)

  useEffect(() => {
  }, []);
  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container">
          <nav id="header" className="navbar header-temporary-header navbar-expand-lg navbar-light">
            <div id="header-left">
              <div className="d-flex align-items-center">
                <Link className="fgh" to="/"><img className="img-fluid" src={logo} alt="" /></Link>
                <div className="in-menu d-flex h-100 align-items-center d-flex align-items-center justify-content-end">
                  <Link to="/postjob" className="employers-button-emp fgh">Post Jobs</Link> |
                  <Link to="/manage-job" className="employers-button-emp fgh">Job Management</Link> |
                  {/* <Link to="/biling" className="employers-button-emp fgh">Product &amp; Pricing</Link> | */}
                  {/* {!subscription?.isPayAsYouGo && <><Link to="/search-resume" className="employers-button-emp fgh">Search Resumes</Link> |</>} */}
                  <Link to="/get-premium" className="employers-button-emp fgh">Get Premium</Link>
                </div>
              </div>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav mr-auto">
              </ul>
              <span className=" navbar-text" id="header-right">
                <div className="d-flex h-100 align-items-center d-flex align-items-center justify-content-end">
                  <div className="dropdown header-left-dropdown2 header-left-dropdown ml-5">
                    <button className="employers-button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Employers<i className="fa fa-angle-down ml-2" aria-hidden="true" />
                    </button>
                    <div className="dropdown-menu" style={{ right: '0px !important', left: 'auto !important' }} aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>
                  <div className="dropdown header-left-dropdown ml-5">
                    <button className="employers-button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {localStorage.getItem("email")}<i className="fa fa-angle-down ml-2" aria-hidden="true" />
                    </button>
                    <div className="dropdown-menu" style={{ right: '0px !important', left: 'auto !important' }} aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#">Account</a>
                      <Link className="dropdown-item fgh" to="/signup">Employee Sign Up</Link>
                      <Link className="dropdown-item fgh" to="/employersignup">Employer Sign Up</Link>
                      <a className="dropdown-item" href="#">Post Jobs</a>
                      <a className="dropdown-item" href="#">Product</a>
                      <a className="dropdown-item" href="#">Pricing</a>
                      <Link to="/company-administrator" className="dropdown-item fgh" >Profile</Link>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </nav>
          {/* header======================================================================*/}
          <div className="main-content job-form-2">
            <div className="col-lg-7 col-md-7 col-12 p-0 mt-5">
              <div className="d-flex my-2">
                <div className="w-100">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Job Title <span>*</span></label>
                    <input className="employee-input-email" type="text" placeholder="Job Title" />
                  </div>
                </div>
              </div>
              <div className="d-flex my-2">
                <div className="w-100">
                  <label htmlFor="exampleInputEmail1">Location Street Address</label>
                  <input className="employee-input-email" type="text" placeholder="Street Address" />
                </div>
              </div>
              <div className="d-flex my-4">
                <div className="w-50">
                  <label htmlFor="exampleInputEmail1">City <span>*</span></label>
                  <input className="employee-input-email" type="text" placeholder="City" />
                </div>
                <div className="w-25 ml-1">
                  <label htmlFor="exampleInputEmail1">State <span>*</span></label>
                  <select className="employee-input-email" name id>
                    <option value>State</option>
                  </select>
                </div>
                <div className="w-25 ml-1">
                  <label htmlFor="exampleInputEmail1">Zip <span>*</span></label>
                  <input maxLength={5} className="employee-input-email" type="text" placeholder="Zip Code" />
                </div>
              </div>
              <div className="d-flex my-4">
                <h5>Job Requirements:</h5>
              </div>
              <div className="row my-2 role role2 main-content-session">
                <div className="col-lg-4 col-12">
                  <p>Employment <span>*</span></p>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio1" defaultChecked />
                      <span className="sp-clr">Full-Time</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio1" defaultChecked />
                      <span className="sp-clr">Part-Time</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio1" defaultChecked />
                      <span className="sp-clr">Contract/Temporary</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio1" defaultChecked />
                      <span className="sp-clr">Internship</span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p>Experience Level <span>*</span></p>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio2" defaultChecked />
                      <span className="sp-clr">Not-Disclosed</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio2" defaultChecked />
                      <span className="sp-clr">Less Than 1 Year</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio2" defaultChecked />
                      <span className="sp-clr">1-2 Years</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio2" defaultChecked />
                      <span className="sp-clr">2-5 Years</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio2" defaultChecked />
                      <span className="sp-clr">5+ Years</span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p>Education <span>*</span></p>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio3" defaultChecked />
                      <span className="sp-clr">Not Specified</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio3" defaultChecked />
                      <span className="sp-clr">High School</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio3" defaultChecked />
                      <span className="sp-clr">2 Year Degree</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio3" defaultChecked />
                      <span className="sp-clr">4 Year Degree</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio3" defaultChecked />
                      <span className="sp-clr">Graduate Degree</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio3" defaultChecked />
                      <span className="sp-clr">Doctorate</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <label>
                      <input type="radio" name="radio3" defaultChecked />
                      <span className="sp-clr">None</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex my-4">
                <h5>Pay Rate</h5>
              </div>
              <div className="d-flex align-items-center">
                <input className="employee-input-email" type="number" placeholder="$25,000" />
                <span className="mx-2 sp-clr">To</span>
                <input className="employee-input-email" type="number" placeholder="$45,000" />
                <div className="d-flex role role2">
                  <div className="d-flex align-items-center ml-2">
                    <label>
                      <input type="radio" name="radio4" defaultChecked />
                      <span className="sp-clr">Hourly</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center ml-2">
                    <label>
                      <input type="radio" name="radio4" defaultChecked />
                      <span className="sp-clr">Annually</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center my-3 role role2">
                <label>
                  <input type="radio" name="radio5" defaultChecked />
                  <span className="sp-clr"> Do not display compensation in job description
                  </span></label>
              </div>
              <div className="d-flex flex-column role role2 my-4">
                <p className="m-0 mb-0">Other compensation included:</p>
                <div className="d-flex">
                  <div className="d-flex align-items-center ml-0 my-2">
                    <label>
                      <input type="radio" name="radio6" defaultChecked />
                      <span className="sp-clr">Bounus</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center ml-2 my-2">
                    <label>
                      <input type="radio" name="radio6" defaultChecked />
                      <span className="sp-clr">Commission</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center ml-2 my-2">
                    <label>
                      <input type="radio" name="radio6" defaultChecked />
                      <span className="sp-clr">Tips</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="my-4">
                <h5>Description:</h5>
                <p>This Job Description has a 30,000 character limit that includes HTML formatting. Minimum of
                  50 characters required</p>
              </div>
              <div className="my-4">
                <img src={textbox} alt="" className="img-fluid" />
              </div>
              <div>
                <p> <span>*</span> Main Duties &amp; Responsibilities
                  These Job Responsibilities have a 20,000 character limit that includes HTML formatting.
                </p>
              </div>
              <div className="my-4">
                <img src={textbox} alt="" className="img-fluid" />
              </div>
              <div className="my-4">
                <p className="mb-0">Company Overview</p>
                <div style={{ marginTop: '0.25rem', textAlign: 'right' }} id="counter">0/1000</div>
                <textarea id="coverview" cols={30} rows={5} maxLength={1000} placeholder="Company Overview" defaultValue={""} />
              </div>
              <div className="d-flex mt-4">
                <h5>Benifits Offered:</h5>
              </div>
              <div className="row my-2">
                <div className="col-lg-4 col-12">
                  <div className="d-flex flex-column role role2 my-4">
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Health Insurance</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Dental Insurance</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Vision Insurance</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        401(k)</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Paid Time Off (PTO)</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Life Insurance</span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="d-flex flex-column role role2 my-4">
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Short-Term Disability</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Long-Term Disability</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Referral Bonus</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Tuition Reimbursement</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Employee Discounts</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Flexible Schedule</span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="d-flex flex-column role role2 my-4">
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Retirement Options</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Relocation Assistance</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Other</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        None</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-4">
                <h5>Travel:</h5>
              </div>
              <div className="row my-2">
                <div className="col-lg-4 col-12">
                  <div className="d-flex flex-column role role2 my-4">
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Not Disclosed</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        None</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Up to 25%</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Up to 50%</span>
                    </label>
                    <label>
                      <input type="checkbox" className="mt-1 mr-2" />
                      <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                        Road Warrior</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex my-2">
                <div className="w-50">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Positions Open <span>*</span></label>
                    <select className="employee-input-email" name id>
                      <option value>Numbers of candidate needed</option>
                      <option value>1</option>
                      <option value>2</option>
                      <option value>3</option>
                      <option value>4</option>
                      <option value>5</option>
                      <option value>6</option>
                      <option value>7</option>
                      <option value>8</option>
                      <option value>9</option>
                      <option value>10</option>
                      <option value>10+</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="d-flex my-2">
                <div className="w-75">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Social Media Links <span>*</span></label>
                    <div className="d-flex">
                      <div className="w-50 my-2">
                        <input className="employee-input-email" type="text" placeholder="Linkedin" />
                      </div>
                      <div className="w-50 my-2  ml-2">
                        <input className="employee-input-email" type="text" placeholder="Twitter" />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="w-50 my-2">
                        <input className="employee-input-email" type="text" placeholder="Facebook" />
                      </div>
                      <div className="w-50 my-2 ml-2">
                        <input className="employee-input-email" type="text" placeholder="blog" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex my-2">
                <div className="w-100">
                  <label htmlFor="exampleInputEmail1">Email <span>*</span></label>
                  <input className="employee-input-email" type="text" placeholder="Appliciant Recipient" />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <p>The email of the primary person who receive the applications </p>
            </div>
            <div className="main-content-session inner-session">
              <Link to="/postjob3" className="send-invitation fgh">CONTINUE</Link>
            </div>
          </div>
        </div>
        {/* footer====================================================================== */}
        <div className="d-flex header-temporary-footer-inner align-items-center flex-column justify-content-center pt-3 pb-3">
          <div className="d-flex">
            <img src={fb} />
            <img src={twi} />
            <img src={yt} />
            <img src={lin} />
          </div>
          <p>© 2022 - Hire Choices</p>
          <p> <Link className="fgh" to={`/contact-us${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>Contact Us</Link> | <Link className="fgh" to={`/about-us${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>About Us | </Link><Link className="fgh" to={`/help-center${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>Help Center</Link> | <a href>Terms of
            Services</a> | <Link className="fgh" to={`/privacy-policy${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}>Privacy Policy</Link></p>
        </div>
      </div>

    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PostJob2);


// code for scoop solutions