import React, { useContext, useState } from "react";
import FooterPic from "../../assets/footer.jpg";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import "./HomeFooter.css";
import { useTheme } from "@mui/material";
import { ColorModeContext } from "../../theme";

const HomeFooter = ({ id }) => {
  const { mode,toggleColorMode } = useContext(ColorModeContext);

  
  const [themechange, setthemechange] = useState(true);
  const [themeanime, setthemeanime] = useState(true);
  const search = useLocation();
  const history = useHistory();
  const themechangelocal = (e) => {
    if (
      window.location.href.search("false") == -1 &&
      window.location.href.search("true") == -1 && localStorage.getItem('mytheme') == "1"
    ) {
      setTimeout(() => {
        window.location.href = `${search?.pathname}${
          search.search == "" ? `?if=${true}` : `${search.search}&if=${true}`
        }`;
      }, 500);
    } else if (
      window.location.href.search("false") !== -1 &&
      localStorage.getItem("mytheme") == "0"
    ) {
      setTimeout(() => {
        window.location.href = `${search?.pathname}${
          search.search == ""
            ? `?`
            : `${search?.search?.replace("false", "true")}`
        }`;
      }, 500);
    } else {
      setTimeout(() => {
        window.location.href = `${search?.pathname}${
          search.search == ""
            ? `?`
            : `${search?.search?.replace("true", "false")}`
        }`;
      }, 500);
    }
    // window.location.reload(false)
    // if (e == true) {
    //   document.getElementById("tg").classList.remove('white');
    //   document.getElementById("tg").classList.add('black');
    //   props.getTheme(true)
    // }
    // else if (e == false) {
    //   document.getElementById("tg").classList.remove('black');
    //   document.getElementById("tg").classList.add('white');
    //   props.getTheme(false)
    // }
  };

  const jobsearch = (id) => {
    const violation = document.getElementById(id);
    window.scrollTo({
      top: violation,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="footerParentContainer ">
        <div className="row footerContainer px-1 px-md-5  pb-md-0">
          <div className="col-sm-12 col-md-6 col-lg-3 pl-4 pl-md-4 pt-4 pr-5 ">
            <img src={FooterPic} height={100} />
            <p className="footerPara mb-0 mb-md-4">
              Hire Choices is committed to providing a seamless and efficient
              job search and hiring experience. With its intuitive user
              interface and robust job search, Hire Choices is the go-to job
              portal for both job seekers and employers alike.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 pl-4 pl-md-4  d-md-flex justify-content-center">
            <div>
              <p className="footerHead pt-3 pt-md-5">Jobs</p>
              <a
                href={`/search-job?jobtitle=&location=&${
                  window.location.href.search("false") == -1 &&
                  window.location.href.search("true") == -1
                    ? ``
                    : `?if=${
                        window.location.href.search("false") == -1
                          ? true
                          : false
                      }`
                }`}
                className="footerPara"
                style={{
                  cursor: "pointer",
                }}
              >
                Job Search
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 pl-4 pl-md-4  d-lg-flex justify-content-center">
            <div>
              <p className="footerHead pt-3  pt-md-5 ">Support</p>
              <p
                className="footerPara"
                onClick={() => history.push("contact-us")}
                style={{
                  cursor: "pointer",
                }}
              >
                Contact Support
              </p>
              {/* <p
                className="footerPara"
                style={{
                  cursor: "pointer",
                }}
              >
                <Link
                  to={`/candidate-login${
                    window.location.href.search("false") == -1 &&
                    window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                          window.location.href.search("false") == -1
                            ? true
                            : false
                        }`
                  }`}
                  style={{ color: "#fff" }}
                >
                  Blogs
                </Link>
              </p> */}
              <p
                className="footerPara"
                style={{
                  cursor: "pointer",
                }}
              >
                <Link
                  to={`/help-center${
                    window.location.href.search("false") == -1 &&
                    window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                          window.location.href.search("false") == -1
                            ? true
                            : false
                        }`
                  }`}
                  style={{ color: "#fff" }}
                >
                  Help Center
                </Link>
              </p>
              <p
                className="footerPara"
                style={{
                  cursor: "pointer",
                }}
              >
                <Link
                  to={`/about-us${
                    window.location.href.search("false") == -1 &&
                    window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                          window.location.href.search("false") == -1
                            ? true
                            : false
                        }`
                  }`}
                  style={{ color: "#fff" }}
                >
                  About Us
                </Link>
              </p>
              <p
                className="footerPara"
                style={{
                  cursor: "pointer",
                }}
              >
                <Link
                  to={`/terms-condition${
                    window.location.href.search("false") == -1 &&
                    window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                          window.location.href.search("false") == -1
                            ? true
                            : false
                        }`
                  }`}
                  style={{ color: "#fff" }}
                >
                  Terms of Services
                </Link>
              </p>
              <p
                className="footerPara"
                style={{
                  cursor: "pointer",
                }}
              >
                <Link
                  to={`/privacy-policy${
                    window.location.href.search("false") == -1 &&
                    window.location.href.search("true") == -1
                      ? `/`
                      : `?if=${
                          window.location.href.search("false") == -1
                            ? true
                            : false
                        }`
                  }`}
                  style={{ color: "#fff" }}
                >
                  Privacy Policy
                </Link>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 pl-4 pl-md-4 pb-3 pb-md-0 d-md-flex justify-content-center">
            <div>
              <p className="footerHead pt-3 pt-md-5 ">Follow Us</p>
              <p
                className="footerPara"
                style={{
                  cursor: "pointer",
                }}
              >
                <a
                  target="#"
                  href="https://www.linkedin.com/in/hire-choices-llc-64a95a231/"
                  style={{ color: "#fff" }}
                >
                  LinkedIn
                </a>
              </p>
              <p
                className="footerPara"
                style={{
                  cursor: "pointer",
                }}
              >
                <a
                  target="#"
                  href="https://www.facebook.com/Hire-Choices-LLC-106119302081524/"
                  style={{ color: "#fff" }}
                >
                  Facebook
                </a>
              </p>
              <p
                className="footerPara"
                style={{
                  cursor: "pointer",
                }}
              >
                <a
                  target="#"
                  href="https://instagram.com/hirechoices?igshid=YmMyMTA2M2Y="
                  style={{ color: "#fff" }}
                >
                  Instagram
                </a>
              </p>
              <div>
                <div className="d-flex align-items-center">
                  <b style={{ color: "#fff" }}>Dark Mode: </b>
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="checkbox"
                    defaultChecked={
                      localStorage.getItem("themee") !== false ? true : false
                    }
                    onChange={() => {
                       toggleColorMode()
                      setthemechange(!themechange);
                      themechangelocal(themechange);
                      localStorage.setItem(
                        "mytheme",
                        localStorage.getItem("mytheme") == 0
                          ? "1"
                          : "0"
                      );
                      setthemeanime(false);
                    }}
                  />
                  <label for="checkbox" className="label ml-3">
                    <i class="fa fa-sun-o"></i>
                    <div
                      style={{
                        transform:
                          window.location.href ==
                            "https://hirechoices.com/?if=false" ||
                          themeanime == false
                            ? "translateX(-18px)"
                            : "translateX(0px)",
                      }}
                      class="ball"
                    ></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row w-100 mx-0"
          style={{ backgroundColor: "#F48A20", margin: 0 }}
        >
          <p className="col-12 footerBot text-center pt-3">
            Copyright, Hire Choices 2023, All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};
export default HomeFooter;
