import React from "react"
import Employeelogin from "../components/employeelogin/Employeelogin"
import Navbar from "../components/navbar/Navbar"
import HomeFooter from "../components/homeFooter/HomeFooter";

const LoginEmployee = () => {
    return (
        <>
            <Navbar />
            <Employeelogin />
            <HomeFooter />

        </>
    )
}
export default LoginEmployee


// code for scoop solutions