import React, { useState, useRef } from "react";
import { BiCalendarAlt } from "react-icons/bi";
import Box from "@mui/material/Box";
import style from "./style";
import Menu from "@mui/material/Menu";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";

const voidFunction = () => {};

const DatePicker = ({
  onChange = voidFunction,
  onBlur = voidFunction,
  value,
  sx = {},
}) => {
  const isLightTheme = true;
  const [anchorEl, setAnchorEl] = useState(null);
  const [view, setView] = useState("day");
  const open = Boolean(anchorEl);
  const ref = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onBlur();
  };

  const handleChange = (date) => {
    onChange(dayjs(date).format("MM-DD-YYYY"));
    if (view === "day") handleClose();
  };

  const defaultValue = dayjs(value, "MM-DD-YYYY").isValid()
    ? dayjs(value)
    : undefined;

  return (
    <Box width="100%" sx={sx}>
      <Box
        sx={style.inputContainer(isLightTheme)}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        ref={ref}
      >
        {/* <Box sx={style.value}>{dayjs(value).format("ddd, MMM D")}</Box> */}
        <Box sx={style.value}>
          {value ? dayjs(value).format("MM-DD-YYYY") : "---- ---- --------"}
        </Box>
        <Box color="#000" component={BiCalendarAlt} size="18px" ml="14px" />
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ mx: "auto" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              views={["year", "month", "day"]}
              onChange={handleChange}
              onViewChange={setView}
              value={defaultValue}
            />
          </LocalizationProvider>
        </Box>
      </Menu>
    </Box>
  );
};

export default DatePicker;
