import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import Employernav from "../dropdownnav/Employernav";
import Footer from "../footer/Footer";
import QuickPayment from "../quickpayment";
import { useCallback } from "react";
import { AxiosGET, AxiosPOST } from "../../libs/axios";
import { setSubscriptionInfoRedux } from "../../actions/Subscriptioninfo";
const config = require("../../helpers/config.json");

Modal.setAppElement("#root");

const SendMessageEmail = (props) => {
  const subscription = useSelector((state) => state?.SubscriptionInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const [payAsYouGoPaid, setPayAsYouGoPaid] = useState({});
  const [openModal, setOpenModal] = useState("");
  const [load, setload] = useState(false);
  const [username, setusername] = useState("");
  const [emailusername, setemailusername] = useState("");
  const [jobtitle, setjobtitle] = useState("");
  const [company, setcompany] = useState("");
  const [template, settemplate] = useState("");
  const [introduction, setintroduction] = useState("");
  const [description, setdescription] = useState("");
  const [esignature, setEsignatuire] = useState("");
  const [email, setemail] = useState("");
  const [error, setError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [templatearray, settemplatearray] = useState({});
  const search = useLocation().search;
  const idd = new URLSearchParams(search).get("id");
  const cidd = new URLSearchParams(search).get("cid");
  const jobId = new URLSearchParams(search).get("job_id");

  const [checkarray, setcheckarray] = useState([]);
  const [tempid, settmpid] = useState("");
  useEffect(() => {
    fetch(
      `${config["baseUrl"]}/gettemplates/${localStorage.getItem("userid")}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        const getTemp = localStorage.getItem("temp_id");
        const alljobs = response.data;
        const firstTemplate = response.data[0];
        const defaultTemplate = getTemp
          ? alljobs?.find((i) => i?.id == getTemp) || firstTemplate
          : firstTemplate;
        settemplate(defaultTemplate.template_name);
        onchangetemplate(
          `${defaultTemplate.template_name}~|~${defaultTemplate.id}`
        );
        settemplatearray(defaultTemplate);
        setcheckarray(response.data);
        localStorage.removeItem("temp_id");
      })
      .catch((error) => {
        console.log("error", error);
        localStorage.removeItem("temp_id");
      });
    setload(true);
    loadgetjob1();
  }, [window.location]);

  useEffect(() => {
    if (!!subscription?.isPayAsYouGo && cidd) {
      Promise.all(
        cidd.split(",").map(async (item) => {
          return await fetch(
            `${config["baseUrl"]}/Get/Downloads/${localStorage.getItem(
              "userid"
            )}/${item}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((response) => {
              return { [item]: !!response.status };
            })
            .catch((error) => {
              console.log(error);
              //
            });
        })
      ).then((res) => {
        // console.log(
        //   "resss",
        //   res,
        //   res.reduce((prev, curr) => (prev = { ...prev, ...curr }), {})
        // );
        setPayAsYouGoPaid(
          res.reduce((prev, curr) => (prev = { ...prev, ...curr }), {})
        );
      });
    }
  }, [emailusername]);

  const onchangetemplate = async (e) => {
    try {
      setload(true);
      const splitter = e?.split("~|~");
      const template_id = splitter[1];
      settemplate(splitter[0]);
      settmpid(template_id);

      const res = await AxiosGET(
        `${config["baseUrl"]}/template/get-specific/${template_id}`
      );
      const { introduction, job_description, esignature } = res?.data;

      setintroduction(introduction);
      setdescription(job_description);
      setEsignatuire(esignature.replace(/<br\s*\/?>/gi, "\n"));
      settemplatearray(res?.data);
      setload(false);
    } catch (error) {
      console.log("error:", error);
      setload(false);
    }
  };

  const deleteTemplate = async () => {
    try {
      setload(true);
      await AxiosGET(`${config["baseUrl"]}/template/delete/${tempid}`);
      setcheckarray((prev) =>
        prev?.filter((item) => String(item?.id) !== String(tempid))
      );
      settemplate("");
      setintroduction("");
      setdescription("");
      setload(false);
    } catch (error) {
      console.log("error:", error);
      setload(false);
    }
  };

  const savedtemplate = async () => {
    setError("");
    if (!introduction?.trim()) {
      setError({ ...error, introductionError: "Please complete this field" });
    } else {
      await fetch(`${config["baseUrl"]}/updateTemplate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          introduction: introduction,
          job_description: description,
          template_name: template,
          user_id: localStorage.getItem("userid"),
          esignature: esignature,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (response.message == "successfull updated.") {
            window.location.reload(false);
          }
        })
        .catch((error) => {
          console.log(error);
          //
        });
    }
  };
  const loadgetjob1 = async () => {
    let cids = cidd?.split(",").filter((i) => i?.trim());
    if (cids?.length === 1) {
      await fetch(`${config["baseUrl"]}/getAllResume`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((response) => {
          setload(false);
          const currUser = response?.resume?.find(
            (data) => data.user_id == cidd
          );
          var ema = currUser?.email || "";
          var emausername = currUser?.first_name?.trim() || "";
          setemailusername(emausername);
          setemail(ema);
        })
        .catch((error) => {
          setload(false);
          console.log("error", error);
        });
      return null;
    }
  };

  const savedDownload = useCallback(async (id) => {
    let localjob = "";

    await new Promise((resolve, reject) => {
      fetch(
        `${config["baseUrl"]}/resume/getbyid/${id}/${localStorage.getItem(
          "userid"
        )}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          const alljobs = response;
          var temp = [];
          temp = [...response];

          localjob = [...temp];
          // setJob([...temp])
          resolve(temp);
        })
        .catch((error) => {
          console.log("error", error);
          reject("");
        });
    });

    await fetch(`${config["baseUrl"]}/Save/Download`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: Number(localStorage.getItem("userid")),
        resume_id: Number(localjob[0]?.job[0]?.id),
        job_id: Number(jobId),
        candidate_id: Number(id),
        // type:"VIEW_CANDIDATE"
      }),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          console.log("Limit Over");
        }
        return res.json();
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        //
      });
  }, []);

  const savedmessage = async (id) => {
    setUsernameError("");
    cidd.split(",").map((item) => savedDownload(item));

    let localjob = "";
    await new Promise((resolve, reject) => {
      fetch(
        `${config["baseUrl"]}/resume/getbyid/${cidd}/${localStorage.getItem(
          "userid"
        )}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          const alljobs = response;
          let temp = [];
          temp = [...response];

          localjob = [...temp];
          // setJob([...temp])
          resolve(temp);
        })
        .catch((error) => {
          console.log("error", error);
          reject("");
        });
    });

    loadgetjob1().then(async () => {
      let cids = cidd?.split(",").filter((i) => i?.trim());
      setload(true);
      await fetch(
        `${config["baseUrl"]}/template/${
          cids?.length === 1 ? "sendemail" : "sendbulkemail"
        }`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: cidd,
            user_id: Number(localStorage.getItem("userid")),
            email: email,
            name: emailusername,
            date_responded: String(new Date()),
            // jobtitle: jobtitle,
            // company: company,
            introduction: introduction,
            description: description,
            username: username,
            temp: template !== "" ? template : "",
            esignature: esignature,
            candidate_id: cidd[0],

            resume_id: Number(localjob[0]?.job[0]?.id),
            candidate_id: Number(cidd),
            // type: "VIEW_CANDIDATE",
            job_id: Number(jobId),
          }),
        }
      )
        .then((re) => {
          setload(false);
        })
        .catch((err) => {
          setload(false);
        });
    });
  };

  const handleCapturePayment = async () => {
    try {
      const { customer_id, limit, usedAmount, dueAmount } = subscription;
      const candidate_ids = Object.entries(payAsYouGoPaid)
        .map((i) => (i?.[1] ? i?.[0] : ""))
        ?.filter((i) => i);
      const candidate_ids_string = candidate_ids.join(",");
      if (dueAmount) {
        setOpenModal("Some payments remaining, please clear them first.");
      } else if (!customer_id) {
        setOpenModal("Please attach a credit card to view applicants");
      } else {
        let localjob = "";

        await new Promise((resolve, reject) => {
          fetch(
            `${
              config["baseUrl"]
            }/resume/getbyid/${candidate_ids_string}/${localStorage.getItem(
              "userid"
            )}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            }
          )
            .then((res) => res.json())
            .then((response) => {
              const alljobs = response;
              var temp = [];
              temp = [...response];

              localjob = [...temp];
              // setJob([...temp])
              resolve(temp);
            })
            .catch((error) => {
              console.log("error", error);
              reject("");
            });
        });
        if (candidate_ids?.length) {
          const res = await AxiosPOST({
            url: `${config["baseUrl"]}/stripe/capture-payment`,
            body: {
              user_id: Number(localStorage.getItem("userid")),
              resume_id: Number(localjob[0]?.job[0]?.id), //
              candidate_id: Number(localjob[0]?.job[0]?.user_id),
              // type: "VIEW_CANDIDATE",
              job_id: Number(jobId),
            },
          });
          dispatch(
            setSubscriptionInfoRedux({
              usedAmount: res?.data?.usedAmount,
            })
          );
        }
        savedmessage();
      }
    } catch (error) {
      console.log(" error:", error);
    }
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal("")}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "475px",
            height: "fit-content",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "40px 20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>{openModal}</h4>
          <div className="d-flex align-items-center justify-content-center search-session">
            <Link
              to="#"
              className="fgh"
              onClick={() => history.push("subscription")}
            >
              Subscription
            </Link>
          </div>
        </div>
      </Modal>
      {load == false ? (
        <>
          <div className="container-fluid" id="Employee">
            <div className="container">
              <Employernav />
              {/* header======================================================================*/}
              <div className="main-content contact-or-rejection">
                <form action="javascript:void(0);">
                  <div className="form-group d-flex flex-column">
                    <label htmlFor="exampleInputPassword1">Templates</label>
                    <select
                      className="employee-input-email"
                      name
                      id
                      onChange={(e) => onchangetemplate(e.target.value)}
                    >
                      <option value="">
                        {template !== "" ? template : "Select Template"}
                      </option>
                      {checkarray && checkarray.length > 0
                        ? checkarray.map((data) =>
                            data.template_name !== template ? (
                              <option
                                value={`${data.template_name}~|~${data.id}`}
                              >
                                {data.template_name}
                              </option>
                            ) : (
                              ""
                            )
                          )
                        : ""}
                    </select>
                    <div className="main-content-session pt-2 inner-session mt-3 actionBtns">
                      <Link
                        to={`/${
                          checkarray?.length < 20
                            ? `new-template?cid=${cidd}`
                            : `edit-template?cid=${cidd}&id=${templatearray?.id}&template_name=${templatearray?.template_name}`
                        }`}
                        className="send-invitation  actionBtns"
                      >
                        {checkarray?.length < 20
                          ? "New Message"
                          : "Edit Message"}
                      </Link>
                      <button
                        onClick={deleteTemplate}
                        disabled={!template}
                        className="send-invitation ml-3 actionBtns"
                      >
                        Delete Message
                      </button>
                      <button
                        onClick={() => savedtemplate()}
                        className="send-invitation ml-3 actionBtns"
                      >
                        Save Changes
                      </button>
                    </div>
                    {checkarray?.length >= 20 ? (
                      <small id="emailHelp" className="form-text  text-danger">
                        *You have reached your maximum template messages.
                        <br />
                        You can update current messages and save the changes.
                      </small>
                    ) : (
                      <p>You may save up to 20 message templates. </p>
                    )}
                  </div>
                  <div className="form-group d-flex flex-column">
                    <label htmlFor="exampleInputPassword1">
                      Introduction{" "}
                      <small
                        className="text-danger"
                        style={{ fontSize: "15px" }}
                      >
                        *
                      </small>
                    </label>
                    <textarea
                      name
                      id
                      cols={30}
                      value={introduction}
                      onChange={(e) => {
                        setUsernameError("");
                        setintroduction(e.target.value);
                      }}
                      defaultValue={introduction}
                      className="employee-input-email form-control"
                      rows={4}
                      placeholder="Hi {CANDIDATE_NAME}, Enter a brief introduction message here."
                    />
                    {error?.introductionError && (
                      <p
                        className="text-danger mt-1 mb-0"
                        style={{ color: "red" }}
                      >
                        {error?.introductionError}
                      </p>
                    )}
                  </div>

                  <small
                    id="emailHelp"
                    className="btm-mgn form-text  text-dark smalltextdark"
                  >
                    Type {"{"}CANDIDATE_NAME{"}"} in your introduction
                    <br />
                    and it will automatically enter the candidate’s <br />
                    name into the message. You can also enter <br />
                    {"{"}JOB_TITLE{"}"} and this will enter the job title as
                    well. <br />
                  </small>
                  <div className="form-group d-flex flex-column">
                    <label htmlFor="exampleInputPassword1">
                      Job Description (Optional)
                    </label>
                    <textarea
                      className="employee-input-email form-control"
                      onChange={(e) => setdescription(e.target.value)}
                      name
                      id
                      cols={30}
                      rows={4}
                      placeholder="Type or copy and paste job description here."
                      value={description}
                    />
                  </div>

                  <div className="form-group d-flex flex-column">
                    <label htmlFor="exampleInputPassword1">
                      E-Signature (Optional)
                    </label>
                    <textarea
                      defaultValue={esignature}
                      className="employee-input-email form-control"
                      onChange={(e) => setEsignatuire(e.target.value)}
                      name
                      id
                      placeholder="Add e-signature here"
                      value={esignature}
                    />
                  </div>
                  {!subscription?.customer_id ? (
                    <div
                      className="inner-session"
                      onClick={handleCapturePayment}
                    >
                      <a
                        // onClick={() => savedmessage()}
                        className="send-invitation"
                      >
                        Send Email
                      </a>
                    </div>
                  ) : !subscription?.isPayAsYouGo ||
                    !!Object.values(payAsYouGoPaid).every((item) => !!item) ||
                    !username?.trim() ||
                    !jobtitle?.trim() ||
                    !introduction?.trim() ? (
                    <div className="inner-session">
                      <a
                        onClick={() => savedmessage()}
                        className="send-invitation"
                      >
                        Send Email
                      </a>
                    </div>
                  ) : (
                    <div
                      className="inner-session"
                      onClick={handleCapturePayment}
                    >
                      <a
                        // onClick={() => savedmessage()}
                        className="send-invitation"
                      >
                        Send Email
                      </a>
                    </div>
                  )}
                </form>
              </div>
              {/* footer====================================================================== */}
            </div>
          </div>
        </>
      ) : (
        <FullPageLoader1 />
      )}
    </>
  );
};
export default SendMessageEmail;

// code for scoop solutions
