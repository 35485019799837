import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import mbloghome from "../../assets/main-logo.png";
import mbloghomeWhite from "../../assets/main-logo-white.png";
import useWindowDimensions from "../administrator/innerwidthcom";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import Employernav from "../dropdownnav/Employernav";
import PlacesAutocomplete from "react-places-autocomplete";
import ReactGA from "react-ga4";
import { useHistory } from "react-router-dom";
import { useColorMode } from "../../theme";
// import opencage from "opencage-api-client";
// import axios from "axios";

Modal.setAppElement("#root");

const HomeSearchEmployee = (props) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const signOut1 = () => {
    localStorage.removeItem("array");
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("zip");
    localStorage.removeItem("role");
    localStorage.removeItem("role1");
    window.location = `/employer-login${
      window.location.href.search("false") == -1 &&
      window.location.href.search("true") == -1
        ? `/`
        : `?if=${window.location.href.search("false") == -1 ? true : false}`
    }`;
  };
  const [modalwidth, setmodalwidth] = useState(850);
  const [address, setaddress] = useState("");

  const handleSelect = async (value) => {
    // const getValue =
    //   value?.split(",").length > 2
    //     ? `${value?.split(",")[0]},${value?.split(",")[1]}`
    //     : value?.split(",")[0].toLowerCase();
    setaddress(value);
    window.location = `/search-job?jobtitle=${jobtitle}&location=${value}`;
  };

  useEffect(() => {
    if (width <= 900) {
      setmodalwidth("340px");
    }
  }, []);

  const [jobtitle, setjobtitle] = useState("");
  const [location, setlocation] = useState("");
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);

  useEffect(() => {
    ReactGA.event({
      category: `Page Visit`,
      label:
        localStorage.getItem("username")?.length > 1
          ? `Employeer Name: ${localStorage.getItem(
              "username"
            )} visited Home page `
          : `Un Authenticated user visited Home page page`,
      action: `visit at Home page`,
      nonInteraction: true,
    });
  }, []);

  const HandleSearchClick = () => {
    history.push(
      `/search-job?jobtitle=${jobtitle}&location=${location}`
      // `/search-job?jobtitle=${jobtitle}&location=${location}&searched=${location?.length}`
    );

    ReactGA.event({
      category: `Employer Home Page, Search Button Click`,
      label:
        localStorage.getItem("username")?.length > 1
          ? `Employeer Name: ${localStorage.getItem(
              "username"
            )} searched ${jobtitle} `
          : `Un Authenticated user searched ${jobtitle}`,
      action: `visit at Home page`,
      nonInteraction: true,
    });
  };

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     async (position) => {
  //       if (
  //         position.coords.latitude &&
  //         position.coords.longitude
  //       ) {
  //         await opencage
  //           .geocode({
  //             q: `${position.coords.latitude}, ${position.coords.longitude}`,
  //             language: "en",
  //             key: process.env.REACT_APP_OPENCAGE_API_KEY,
  //           })
  //           .then(({ results }) => {
  //             const getValue = `${
  //               results[0]?.components?.city ||
  //               results[0]?.components?.county?.replace(" County", "") || results[0]?.components?.state ||
  //               ""
  //             }, ${results[0]?.components?.state_code || ""} `;
  //             setaddress(getValue);
  //           })
  //           .catch((err) => {
  //             console.log(err, "error123");
  //           });
  //       }
  //     },
  //     (err) => {
  //         return axios
  //           .get("https://ipinfo.io/?token=782457d915c643")
  //           .then(async (res) => {
  //             const [lat, long] = res.data.loc.split(",");
  //             await opencage
  //               .geocode({
  //                 q: `${lat}, ${long}`,
  //                 language: "en",
  //                 key: process.env.REACT_APP_OPENCAGE_API_KEY,
  //               })
  //               .then(({ results }) => {
  //                 const getValue = `${
  //                   results[0]?.components?.city ||
  //                   results[0]?.components?.county.replace(" County", "") || results[0]?.components?.state ||
  //                   ""
  //                 }, ${results[0]?.components?.state_code || ""} `;
  //                 setaddress(getValue);
  //               })
  //               .catch((err) => {
  //                 console.log(err, "error123");
  //               });
  //           });
  //       }
  //   );
  // }, [])

  const { mode } = useColorMode();
  return (
    <>
      <div className="container-fluid" id="Employee">
        <div
          className="container height-handred"
          style={{ paddingTop: "10px" }}
        >
          {props.GetUserReducer.users &&
          props.GetUserReducer.users.role !== null &&
          props.GetUserReducer.users.role !== undefined &&
          props.GetUserReducer.users.role !== "" &&
          props.GetUserReducer.users.role !== 0 ? (
            <Employernav />
          ) : (
            <Dropdownnav />
          )}

          {/* header======================================================================*/}
          <div className="mt-lg-5 pt-lg-5">
            <div className="row mt-5 pt-lg-5 align-items-center justify-content-center header-center ">
              <div className="col-lg-4 mt-lg-5 align-items-center col-10 d-flex flex-column text-center">
                {mode == "light" ? (
                  <img style={{ width: "75%" }} src={mbloghome} alt="" />
                ) : (
                  <img style={{ width: "75%" }} src={mbloghomeWhite} alt="" />
                )}

                <p className="pt-4">
                  <b>
                    <i>Hire Choices for Job Seekers and Employers</i>
                  </b>
                </p>
              </div>
              <div className="d-flex flex-column " id={props.id}>
                <div className="d-flex search-session mt-2 justify-content-center">
                  <div style={{ position: "relative" }}>
                    <i
                      className="fa fa-search search-icon"
                      aria-hidden="true"
                    />
                    <input
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          window.location = `/search-job?jobtitle=${jobtitle}&location=${location}`;
                          // window.location = `/search-job?jobtitle=${jobtitle}&location=${location}&searched=${location?.length}`;
                        }
                      }}
                      onChange={(e) => setjobtitle(e.target.value)}
                      type="text"
                      className="job-search"
                      placeholder="Job Title or Keyword"
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <i
                      className="fa fa-map-marker marker-icon"
                      aria-hidden="true"
                    />
                    <PlacesAutocomplete
                      value={address}
                      onChange={setaddress}
                      onSelect={handleSelect}
                      searchOptions={{
                        componentRestrictions: { country: "us" },
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            id="exampleInputPassword1"
                            className="form-control employee-input-email job-search"
                            {...getInputProps({
                              placeholder: "City or Zip Code",
                              className: "city-search",
                            })}
                          />
                          <div className="autocomplete-dropdown-container w-100">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item p-2 --active"
                                : "suggestion-item p-2 ";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <div className="fgh" onClick={HandleSearchClick}>
                    <Link>Search</Link>
                  </div>
                  {/* <Link
                    className="fgh"
                    to={`/search-job?jobtitle=${jobtitle}&location=${location}${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                        ? ``
                        : `&if=${
                            window.location.href.search("false") == -1
                              ? true
                              : false
                          }`
                    }`}
                    >
                    Search
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center my-4 before-search-options">
              <div className="col-lg-5 col-12 align-items-center justify-content-center d-flex flex-column">
                {props.GetUserReducer.users &&
                props.GetUserReducer.users.role !== null &&
                props.GetUserReducer.users.role !== undefined &&
                props.GetUserReducer.users.role !== "" ? (
                  props.GetUserReducer.users.role == 0 &&
                  props.GetUserReducer.users &&
                  props.GetUserReducer.users.role !== null &&
                  props.GetUserReducer.users.role !== undefined &&
                  props.GetUserReducer.users.role !== 1 ? (
                    props.GetUserReducer.users &&
                    props.GetUserReducer.users.is_resume !== null &&
                    props.GetUserReducer.users.is_resume !== undefined &&
                    props.GetUserReducer.users.is_resume !== "" &&
                    props.GetUserReducer.users.is_resume == 0 ? (
                      <Link
                        to="/createresume"
                        className="employers-button2 fgh mb-3"
                      >
                        Create / Post Resume
                      </Link>
                    ) : (
                      <Link
                        to="/previewresume"
                        className="employers-button2 fgh mb-3"
                      >
                        Preview Resume
                      </Link>
                    )
                  ) : (
                    <Link
                      onClick={() => setBilingModalIsOpen2(true)}
                      className="employers-button2 fgh mb-3"
                    >
                      Create / Post Resume
                    </Link>
                  )
                ) : (
                  <Link
                    to={`/candidate-login${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                        ? `/`
                        : `?if=${
                            window.location.href.search("false") == -1
                              ? true
                              : false
                          }`
                    }`}
                    className="employers-button2 fgh mb-3"
                  >
                    Create / Post Resume
                  </Link>
                )}
                {props.GetUserReducer.users &&
                props.GetUserReducer.users.role !== null &&
                props.GetUserReducer.users.role !== undefined &&
                props.GetUserReducer.users.role !== "" ? (
                  props.GetUserReducer.users.role !== 0 &&
                  props.GetUserReducer.users &&
                  props.GetUserReducer.users.role !== null &&
                  props.GetUserReducer.users.role !== undefined &&
                  props.GetUserReducer.users.role == 1 ? (
                    <Link to="/postjob" className="employers-button2 fgh">
                      Post Job Opportunities
                    </Link>
                  ) : (
                    <Link
                      onClick={() => setBilingModalIsOpen2(true)}
                      className="employers-button2 fgh"
                    >
                      Post Job Opportunities
                    </Link>
                  )
                ) : (
                  <Link
                    to={`/employer-login${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                        ? `/`
                        : `?if=${
                            window.location.href.search("false") == -1
                              ? true
                              : false
                          }`
                    }`}
                    className="employers-button2 fgh"
                  >
                    Post Job Opportunities
                  </Link>
                )}
              </div>
            </div>
            {/* <HomeFeature /> */}
          </div>
          {/* <div
            className="footer-wrap"
            style={{
              bottom: "0px",
              left: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <HomeFooter />
          </div> */}
        </div>
      </div>
      <Modal
        isOpen={BilingmodalIsOpen}
        onRequestClose={() => setBilingModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "320px",
            top: "calc(50% - 160px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center">
          <h4>
            You Are Not Register .Please Sign In or Register Your Account.
          </h4>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link
              className="fgh"
              to={`/signup${
                window.location.href.search("false") == -1 &&
                window.location.href.search("true") == -1
                  ? `/`
                  : `?if=${
                      window.location.href.search("false") == -1 ? true : false
                    }`
              }`}
            >
              Sign Up
            </Link>
          </div>
          <div className="d-flex  align-items-center justify-content-center search-session">
            <Link
              className="fgh"
              to={`/candidate-login${
                window.location.href.search("false") == -1 &&
                window.location.href.search("true") == -1
                  ? `/`
                  : `?if=${
                      window.location.href.search("false") == -1 ? true : false
                    }`
              }`}
            >
              Sign In
            </Link>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={BilingmodalIsOpen2}
        onRequestClose={() => setBilingModalIsOpen2(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "fit-content",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "40px 20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h5>Do you wish to sign out?</h5>
          <div className="d-flex align-items-center mt-3">
            <button
              onClick={() => signOut1()}
              className="employeer-sign-off-btn margin_O"
            >
              Yes
            </button>
            <button
              onClick={() => setBilingModalIsOpen2(false)}
              className="employeer-sign-off-btn margin_O"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  authReducer: state.authReducer,
  ThemeReducer: state.ThemeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HomeSearchEmployee);

// code for scoop solutions
