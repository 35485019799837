import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import NewMessageTemplate from "../components/templates/Newmessagetemplate"
import HomeFooter from "../components/homeFooter/HomeFooter";

const MessageNewTemplate = () => {
    return (
        <>
            <EmployeeNav2 />
            <NewMessageTemplate />
            <HomeFooter />
        </>
    )
}
export default MessageNewTemplate


// code for scoop solutions