import { authantication } from "../helpers/firebase"
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
const config = require('../helpers/config.json');
export const SignIn = (email, password) => {
    return (dispatch) => {
        dispatch({
            type: "RESET_AUTH_MESSAGE",
            loading: false,
        });
        fetch(`${config['baseUrl']}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "email": email,
                "password": password,
                "role": 0

            })
        }).then(res => {
            return res.json();

        }).then((response) => {

            const token = response.token;
            const id = response.user.id
            const name = response.user.first_name
            const email = response.user.email
            const role = response.user.role
            dispatch({
                type: "SIGN_IN",
                authError: null,
                token,
                authMessage: "Signing you in...",
                loading: true,
            });

            // if(response.token!==""&&response.token!==null&&response.token!==undefined){
            if (response.user && response.user.approve !== null && response.user.approve !== undefined && response.user.approve !== 0) {
                dispatch({
                    type: "SIGN_IN",
                    loading: true,
                    load: false,
                });
                localStorage.setItem("token", token)
                localStorage.setItem("userid", id)
                localStorage.setItem("username", name)
                localStorage.setItem("email", email)
                localStorage.setItem("role", role)
                window.location = "/"
            } else {
                dispatch({
                    type: "SIGN_IN",
                    loading: false,
                    load: true,
                });
            }

            // }
            // else{
            //     alert("email or pssword is invalid..")
            // }
        }).catch((error) => {
            const authError = JSON.stringify(error)
            console.log(authError);
            dispatch({
                type: "SIGN_IN",
                authError,
                token: null,
                authMessage: null,
                loading: true,
            });
            alert("Something Went Wrong...")
        })
    };
}


export const googleLogin = (latitude, longitude) => {
    return (dispatch) => {
        const provider = new GoogleAuthProvider();
        provider.addScope('email');
        signInWithPopup(authantication, provider).then((result) => {
            var userEmail = result.user.email
            var user = result.user
            var first = user.displayName.split(" ")[0]
            var last = user.displayName.split(" ")[1]
            fetch(`${config['baseUrl']}/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    "first_name": first,
                    "last_name": last,
                    "position_title": "",
                    "company_name": "",
                    "city": "",
                    "state": "",
                    "zip_code": "",
                    "phone": "",
                    "email": userEmail,
                    "password": "null",
                    "source": "google",
                    "role": 0,
                    "latitude": latitude !== null && latitude !== undefined && latitude !== "" ? latitude : 0,
                    "longitude": longitude !== null && longitude !== undefined && longitude !== "" ? longitude : 0

                })
            }).then(res => {
                if (res.status !== 201) {
                    alert("Email or password is incorrect");
                }
                return res.json();
            }).then((response) => {
                const id = response.user.id
                const token = response.token
                    // if(response.user.role==0 || response.user[0].role==0){
                if (response.message == "Can't add new command when connection is in closed state") {
                    alert("Can't add new command when connection is in closed state")
                } else {
                    window.location = "/"
                }
                localStorage.setItem("username", first)
                localStorage.setItem("email", userEmail)
                localStorage.setItem("token", token)
                if (response.user.id !== null && response.user.id !== undefined && response.user.id !== "") {
                    localStorage.setItem("userid", response.user.id)
                }
                if (response.user[0].id !== null && response.user[0].id !== undefined && response.user[0].id !== "") {
                    localStorage.setItem("userid", response.user[0].id)
                }
                // }
                // else{
                //     window.location = "/employer-login"
                // }
            }).catch((error) => {
                console.log(error)

            })


        }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message
            var email = error.email
            var credential = error.credential
            console.log("ffff", errorMessage)
        })
    }
}

export const googleLogin2 = () => {
    return (dispatch) => {
        const provider = new GoogleAuthProvider();
        provider.addScope('email');
        signInWithPopup(authantication, provider).then((result) => {
            var userEmail = result.user.email
            var user = result.user
            var first = user.displayName.split(" ")[0]
            var last = user.displayName.split(" ")[1]
            fetch(`${config['baseUrl']}/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    "first_name": first,
                    "last_name": last,
                    "position_title": "",
                    "company_name": "",
                    "city": "",
                    "state": "",
                    "zip_code": "",
                    "phone": "",
                    "email": userEmail,
                    "password": "null",
                    "source": "google",
                    "role": 1,
                    "latitude": 0,
                    "longitude": 0

                })
            }).then(res => {
                if (res.status !== 201) {
                    alert("Email or password is incorrect");
                }
                return res.json();
            }).then((response) => {
                const id = response.user.id
                const token = response.token
                    // if((response.user.role!==null&&response.user.role!==undefined&&response.user.role!==""&&response.user.role==1) || (response.user[0].role!==null&&response.user[0].role!==undefined&&response.user[0].role!==""&&response.user[0].role==1)){
                if (response.message == "Can't add new command when connection is in closed state") {
                    alert("Can't add new command when connection is in closed state")
                } else {
                    window.location = "/"
                }
                localStorage.setItem("username", first)
                localStorage.setItem("email", userEmail)
                localStorage.setItem("token", token)
                if (response.user.id !== null && response.user.id !== undefined && response.user.id !== "") {
                    localStorage.setItem("userid", response.user.id)
                }
                if (response.user[0].id !== null && response.user[0].id !== undefined && response.user[0].id !== "") {
                    localStorage.setItem("userid", response.user[0].id)
                }
                // }
                //     else{
                //         window.location = {`/candidate-login${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}
                //     }
            }).catch((error) => {
                console.log(error)

            })


        }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message
            var email = error.email
            var credential = error.credential
            console.log("ffff", errorMessage)
        })
    }
}


export const facebookLogin = (latitude, longitude) => {
    return (dispatch) => {
        const provider = new FacebookAuthProvider();
        provider.addScope('email');
        // "public_profile"
        signInWithPopup(authantication, provider).then((result) => {
            console.log("ppp", result)
            var userEmail = result.user.email
            var user = result.user
            var first = user.displayName.split(" ")[0]
            var last = user.displayName.split(" ")[1]
            fetch(`${config['baseUrl']}/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    "first_name": first,
                    "last_name": last,
                    "position_title": "",
                    "company_name": "",
                    "city": "",
                    "state": "",
                    "zip_code": "",
                    "phone": "",
                    "email": userEmail,
                    "password": "null",
                    "source": "facebook",
                    "role": 0,
                    "latitude": latitude !== null && latitude !== undefined && latitude !== "" ? latitude : 0,
                    "longitude": longitude !== null && longitude !== undefined && longitude !== "" ? longitude : 0

                })
            }).then(res => {
                if (res.status !== 201) {
                    alert("Email or password is incorrect");
                }
                return res.json();
            }).then((response) => {
                const id = response.user.id
                const token = response.token
                    // if(response.user.role==0){
                if (response.message == "Can't add new command when connection is in closed state") {
                    alert("Can't add new command when connection is in closed state")
                } else {
                    window.location = "/"
                }
                localStorage.setItem("username", first)
                localStorage.setItem("email", userEmail)
                localStorage.setItem("token", token)
                if (response.user.id !== null && response.user.id !== undefined && response.user.id !== "") {
                    localStorage.setItem("userid", response.user.id)
                }
                if (response.user[0].id !== null && response.user[0].id !== undefined && response.user[0].id !== "") {
                    localStorage.setItem("userid", response.user[0].id)
                }
                // }
                // else{
                //     window.location = "/employer-login"
                // }
            }).catch((error) => {
                console.log(error)

            })


        }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message
            var email = error.email
            var credential = error.credential
            console.log("ffff", errorMessage)
        })
    }
}
export const facebookLogin2 = () => {
    return (dispatch) => {
        const provider = new FacebookAuthProvider();
        provider.addScope('email');
        signInWithPopup(authantication, provider).then((result) => {
            var userEmail = result.user.email
            var user = result.user
            var first = user.displayName.split(" ")[0]
            var last = user.displayName.split(" ")[1]
            fetch(`${config['baseUrl']}/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    "first_name": first,
                    "last_name": last,
                    "position_title": "",
                    "company_name": "",
                    "city": "",
                    "state": "",
                    "zip_code": "",
                    "phone": "",
                    "email": userEmail,
                    "password": "null",
                    "source": "facebook",
                    "role": 1

                })
            }).then(res => {
                if (res.status !== 201) {
                    alert("Email or password is incorrect");
                }
                return res.json();
            }).then((response) => {
                const id = response.user.id
                const token = response.token
                    // if(response.user.role==1){
                if (response.message == "Can't add new command when connection is in closed state") {
                    alert("Can't add new command when connection is in closed state")
                } else {
                    window.location = "/"
                }
                localStorage.setItem("username", first)
                localStorage.setItem("email", userEmail)
                localStorage.setItem("token", token)
                if (response.user.id !== null && response.user.id !== undefined && response.user.id !== "") {
                    localStorage.setItem("userid", response.user.id)
                }
                if (response.user[0].id !== null && response.user[0].id !== undefined && response.user[0].id !== "") {
                    localStorage.setItem("userid", response.user[0].id)
                }
                // }
                // else{
                //     window.location = {`/candidate-login${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`}
                // }
            }).catch((error) => {
                console.log(error)

            })


        }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message
            var email = error.email
            var credential = error.credential
            console.log("ffff", errorMessage)
        })
    }
}