import React from "react"
import EmployerSignUp from "../components/employersignup/Employersignup"
import Navbar from "../components/navbar/Navbar"
import HomeFooter from "../components/homeFooter/HomeFooter";

const SignUpEmplyer = () => {
    return (
        <>
            <Navbar />
            <EmployerSignUp />
            <HomeFooter />
        </>
    )
}
export default SignUpEmplyer


// code for scoop solutions