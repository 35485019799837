import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import Previouslycontacted from "../components/searchresume/Previouslycontacted"
import HomeFooter from "../components/homeFooter/HomeFooter";

const PreviouslyContact = () => {
    return (
        <>
            <EmployeeNav2 />
            <Previouslycontacted />
            <HomeFooter />
        </>
    )
}
export default PreviouslyContact


// code for scoop solutions