import React from "react"
import ContactUs from "../components/contactus/Contactus"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import HomeFooter from "../components/homeFooter/HomeFooter";

const ContactForUs = () => {
    return (
        <>
            {/* <EmployeeNav2  /> */}
            <ContactUs />
            <HomeFooter />
        </>
    )
}
export default ContactForUs


// code for scoop solutions