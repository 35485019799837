import React, { useState, useEffect } from "react"

const Error = () => {
    const [error, seterror] = useState("")

    setTimeout(() => {
        seterror("404 Error page not found")
    }, 3000);

    return (
        <>
            <h3 className="text-center mt-5">{error}</h3>

        </>
    )
}
export default Error

// code for scoop solutions