const config = require('../helpers/config.json');

export const AlljobsGet = (userId) => {
    return (dispatch) => {
        /// get request
        fetch(`${config['baseUrl']}/jobs/getbyuserid/${localStorage.getItem("userid")}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', },
        }).then(res => res.json()).then((response) => {
            const alljobs = response

            dispatch({
                type: "GET_JOBS",
                alljobs: alljobs
            });
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: "GET_JOBS",
                alljobs: {}
            });
            // 
        })

    }


}

// code for scoop solutions