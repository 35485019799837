import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import HelpCenter from "../components/helpcenter/Helpcenter"

const HelpCenterOriginal = () => {
    return (
        <>
            <EmployeeNav2 />
            <HelpCenter />

        </>
    )
}
export default HelpCenterOriginal


// code for scoop solutions