const config = require('../helpers/config.json');
export const EmployerSignIn = (email, password) => {
    return (dispatch) => {
        dispatch({
            type: "RESET_AUTH_MESSAGE",
            loading: false,
        });
        fetch(`${config['baseUrl']}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "email": email,
                "password": password,
                "role": 1

            })
        }).then(res => {
            return res.json();

        }).then((response) => {
            const token = response.token;
            const id = response.user.id
            const name = response.user.first_name
            const email = response.user.email
            const role = response.user.role
            const active = response.user.active
            dispatch({
                type: "SIGN_IN",
                authError: null,
                token,
                authMessage: "Signing you in...",
                loading: true,
            });
            if (response.user.approve !== null && response.user.approve !== undefined && response.user.approve !== 0 && response.user.approve !== "" && active == 1) {
                localStorage.setItem("token", token)
                localStorage.setItem("userid", id)
                localStorage.setItem("username", name)
                localStorage.setItem("email", email)
                localStorage.setItem("role", role)
                window.location = "/"
            }
            else {
                var acc = 1;
                localStorage.setItem("access", acc)
                var app = 1;
                localStorage.setItem("approve", app)
            }
            if (response.token !== "" && response.token !== null && response.token !== undefined) {


            } else {
                console.log("email or pssword is invalid..")
            }
        }).catch((error) => {
            const authError = JSON.stringify(error)
            console.log(authError);
            dispatch({
                type: "SIGN_IN",
                authError,
                token: null,
                authMessage: null,
                loading: true,
            });
        })
    };
}

// code for scoop solutions