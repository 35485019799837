
import React, { useState, useEffect } from 'react'
import { LinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import "./index.css";
import { createLinkedInData } from "./actions/LinkedInDataAction";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"
const config = require('./helpers/config.json');

function LinkedInPage2() {
  const [latitude,setlatitude]=useState("")
  const [longitude,setlongitude]=useState("")
  const [code, setCode] = useState("")
  const [message, setMessage] = useState("")
  const search = useLocation().search;

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position)=>{
      setlatitude(position.coords.latitude)
      setlongitude(position.coords.longitude)
    })
},[])


  const handleSuccess = (data) => {
    const code = new URLSearchParams(search).get("code");
  fetch(`${config['baseUrl']}/linked`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
        "authcode": data.code
    })
}).then(res => {
    return res.json();
}).then((response) => {
    if(response.firstname!==""&&response.firstname!==null&&response.firstname!==undefined&&response.lastname!==""&&response.lastname!==undefined&&response.lastname!==null&&response.email!==""&&response.email!==null&&response.email!==undefined){
     var first=response.firstname
     var email=response.email
      fetch(`${config['baseUrl']}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({
        "first_name": response.firstname,
        "last_name": response.lastname,
        "position_title": "",
        "company_name": "",
        "city": "",
        "state": "",
        "zip_code": "",
        "phone": "",
        "email": response.email,
        "password": "null",
        "source":"linkedin",
        "role": 0,
        "latitude": latitude !== null && latitude !== undefined && latitude !== "" ? latitude : 0,
        "longitude": longitude !== null && longitude !== undefined && longitude !== "" ? longitude : 0

        })
    }).then(res => {
        return res.json();
    }).then((responsee) => {
        const id=responsee.user.id
        const token=responsee.token
        if (responsee.message == "Can't add new command when connection is in closed state") {
            console.log("Can't add new command when connection is in closed state")
        } else {
            window.location = "/"
        }
        localStorage.setItem("username", response.firstname)
        localStorage.setItem("email", response.email)
        localStorage.setItem("token", token)
        if(responsee.user.id!==null&&responsee.user.id!==undefined&&responsee.user.id!==""){
          localStorage.setItem("userid", responsee.user.id)
          }
          if(responsee.user[0].id!==null&&responsee.user[0].id!==undefined&&responsee.user[0].id!==""){
              localStorage.setItem("userid", responsee.user[0].id)
              }
    }).catch((error) => {
        console.log(error)

    })
    }

}).catch((error) => {
    console.log(error)
})
  }

  const handleFailure = (error) => {
    setMessage(error.errorMessage)
  }
  return (
    <div>
      <LinkedIn
        clientId="8639mz9127nvj8"
        onFailure={handleFailure}
        onSuccess={handleSuccess}
        redirectUri="https://hirechoices.com/linkedin"
        scope='r_liteprofile r_emailaddress'
        state='123456'
      >
                <div className="sign-in-social-tag3">
                <i className="fa fa-linkedin mr-2 mt-n1 text-white" aria-hidden="true" />Sign in with LinkedIn
                </div>
      </LinkedIn>
    </div>
  )
}




const mapStateToProps = (state) => ({
  auth: state.auth,
  LinkedInReducer: state.LinkedInReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createLinkedInData: (userId) => dispatch(createLinkedInData(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LinkedInPage2);

// code for scoop solutions
















