import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import { JobByID } from "../../actions/jobbyid";
import Employernav from "../dropdownnav/Employernav";
import { padding } from "@mui/system";
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const FileUpload = (props) => {
  const [load, setload] = useState(false)
  const [allArray, setallArray] = useState([])
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const [idd, setidd] = useState("")
  const [cidd, setcidd] = useState("")
  useEffect(async () => {
    loadgetjob()
  }, []);
  const loadgetjob = async () => {
    setload(true)
    await fetch(`${config['baseUrl']}/get/uploadedFiles`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response.uploadedFiles
      setallArray(...allArray, alljobs)
    }).catch((error) => {
      setload(false)
    })
    return null
  }
  const [order, setorder] = useState("ASC")
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("DSC")
    }
    if (order === "DSC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("ASC")
    }

  }
  return (
    <>
      {
        load == false ?
          <>
            <div className="container-fluid" id="Employee">
              <div className="container">
                <Employernav />
                {/* header======================================================================*/}
                <div className="main-content candidate-applies-to-job inner-session">
                  <div className="usr-tbl">
                    <table id="example" className="table  table-striped table-bordered dataTable" cellSpacing={0} width="100%" role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                      <thead>
                        <tr role="row">
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '20%' }}>
                            Files</th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '15%' }}>
                            Created At</th>
                          <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '15%' }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      {
                        allArray && allArray.length > 0 ? allArray.map(job => (
                          <tbody>

                            <tr role="row" className="odd">
                              <td className>{job.filepath !== "" && job.filepath !== null && job.filepath !== undefined ? job.filepath.split("/")[5] : ""}</td>
                              <td>{job.created_At !== "" && job.created_At !== null && job.created_At !== undefined ? job.created_At.split("T")[0] : ""}</td>
                              <td><a className="p-1" href={`${config["baseUrl"]}${job.filepath}`} download={true} target="blank" style={{ margin: 0, borderRadius: "50px" }}>download</a></td>
                            </tr>
                          </tbody>
                        ))

                          : <div className="d-flex w-100 aliign-items-center justify-content-center not-found px-5 nofournd">
                            no Candidate found
                          </div>}
                    </table>
                  </div>
                </div>


              </div>
            </div>

          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
export default FileUpload;

// code for scoop solutions