import React from "react"
import AdministratorShow from "../components/administrator/Administratorshow"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import HomeFooter from "../components/homeFooter/HomeFooter";

const ShowAdministrator = () => {
    return (
        <>
            <EmployeeNav2 />
            <AdministratorShow />
            <HomeFooter />
        </>
    )
}
export default ShowAdministrator


// code for scoop solutions