const config = require('../helpers/config.json');

export const ResumeCreate = (first_name, middle_name, last_name, city, latitude, longitude, state, zipcode, email, contact_number, add_contact_number, employment_eligibility, professional_summary, award, links, certification, education, experience, language, volunteer, reference, military, military_branch, military_mos) => {
    return (dispatch) => {
        dispatch({
            type: "SEARCH_RESET",
            loading: false,
            success: false,
        });
        fetch(`${config['baseUrl']}/resume/insert`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "user_id": localStorage.getItem("userid"),
                "first_name": first_name !== null && first_name !== undefined && first_name !== "" ? first_name : "",
                "middle_name": middle_name !== null && middle_name !== undefined && middle_name !== "" ? middle_name : "",
                "last_name": last_name !== null && last_name !== undefined && last_name !== "" ? last_name : "",
                "city": city !== null && city !== undefined && city !== "" ? city : "",
                "latitude": latitude !== null && latitude !== undefined && latitude !== "" ? latitude : 0,
                "longitude": longitude !== null && longitude !== undefined && longitude !== "" ? longitude : 0,
                "state": state !== null && state !== undefined && state !== "" ? state : "",
                "zipcode": zipcode !== null && zipcode !== undefined && zipcode !== "" ? zipcode : "",
                "email": email !== null && email !== undefined && email !== "" ? email : "",
                "contact_number": contact_number !== null && contact_number !== undefined && contact_number !== "" ? contact_number : "",
                "add_contact_number": add_contact_number !== null && add_contact_number !== undefined && add_contact_number !== "" ? add_contact_number : "",
                "employment_eligibility": employment_eligibility !== null && employment_eligibility !== undefined && employment_eligibility !== "" ? employment_eligibility : "",
                "professional_summary": professional_summary !== null && professional_summary !== undefined && professional_summary !== "" ? professional_summary : "",
                "award": award,
                "links": links,
                "certification": certification,
                "education": education,
                "experience": experience,
                "language": language,
                "volunteer": volunteer,
                "reference": reference,
                "military": military,
                "military_branch": military_branch,
                "military_mos": military_mos
            })
        }).then(res => {
            if (res.status !== 201) {
            } else { }
            return res.json();
        }).then((response) => {
            dispatch({
                type: "SEARCH_SUCCESS",
                SearchResponse: response,
                loading: true,
                success: true,

            });
            if (response.message == "The Resume has been successfully inserted.") {
                window.location = "/previewresume/?id=1"
            } else {
            }

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "SEARCH_FAIL",
                SearchResponse: "creation failed",
                loading: true,
                success: false,
            });
            // 
        })
    }
}




export const UpdateResume = (first_name, middle_name, last_name, city, state, zipcode, email, contact_number, add_contact_number, employment_eligibility, professional_summary, award, certification, education, experience, language, volunteer, reference, military) => {
    return (dispatch) => {
        dispatch({
            type: "SEARCH_RESET",
            loading: false,
            success: false,
        });
        var data = {
            "user_id": localStorage.getItem("userid"),
            "first_name": first_name,
            "middle_name": middle_name,
            "last_name": last_name,
            "city": city,
            "state": state,
            "zipcode": zipcode,
            "email": email,
            "contact_number": contact_number,
            "add_contact_number": add_contact_number,
            "employment_eligibility": employment_eligibility,
            "professional_summary": professional_summary,
            "award": award,
            "certification": certification,
            "education": education,
            "experience": experience,
            "language": language,
            "volunteer": volunteer,
            "reference": reference,
            "military": military
        }
    }
}

// code for scoop solutions