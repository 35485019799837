import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import mbloghome from "../../assets/main-logo.png"
import mbloghomeWhite from "../../assets/main-logo-white.png"
import useWindowDimensions from '../administrator/innerwidthcom';
import yt from "../../assets/youtube.png"
import logo from "../../assets/logo.png"
import FullPageLoader from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import Footer from "../../components/footer/Footer"
import Employernav from "../dropdownnav/Employernav";
import HomeFooter from "../homeFooter/HomeFooter";
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const EmailVerifyAgain = (props) => {
  const { height, width } = useWindowDimensions();

  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const signOut1 = () => {
    localStorage.removeItem("array");
    localStorage.removeItem("token")
    localStorage.removeItem("userid")
    localStorage.removeItem("username")
    localStorage.removeItem("email")
    localStorage.removeItem("zip")
    localStorage.removeItem("role")
    localStorage.removeItem("role1")
    window.location = `/employer-login${window.location.href.search('false')== -1&&window.location.href.search('true')== -1?`/`:`?if=${window.location.href.search('false')== -1 ?true:false}`}`
  }
  const [modalwidth, setmodalwidth] = useState(850)

  useEffect(() => {
    if (width <= 900) {
      setmodalwidth("340px")
    }
  }, []);
  const [jobtitle, setjobtitle] = useState("")
  const [location, setlocation] = useState("")
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);

  const createsearch = (e) => {
    props.createSearch(jobtitle, location);
  };
  const theme = async (e) => {
    fetch(`${config['baseUrl']}/theme`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "theme": Number(e),
        "id": localStorage.getItem("userid")
      })
    }).then(res => {


      return res.json();
    }).then((response) => {

      if (response.message !== "successfull") {
        console.log("Can't add new command when connection is in closed state")
      } else {
        window.location = "/"
      }
    }).catch((error) => {
      const authError = JSON.stringify(error)
      console.log(authError);
    })
  }
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container height-handred">
          <Dropdownnav />
          {/* header======================================================================*/}
          <div>
            <div className="row  align-items-center justify-content-center header-center">
              <div className="col-lg-4 align-items-center col-10 d-flex flex-column text-center">
                {props.ThemeReducer.theme == false ?
                  <i className="fa fa-check fa-5x" aria-hidden="true"></i>
                  :
                  <i className="fa fa-check fa-5x" aria-hidden="true"></i>
                }
              </div>
              <div className="d-flex flex-column ">

                <div className="d-flex mt-4 align-items-center justify-content-center search-session">

                  {props.ThemeReducer.theme == false ?
                    <h2>Verification link expired. <br />Please check your inbox <br /> again for another email.</h2>
                    :
                    <h2 className="text-light">Verification link expired. <br />Please check your inbox <br /> again for another email.</h2>
                  }
                </div>


              </div>
            </div>
          </div>
          <div className="footer-wrap" style={{ bottom: "0px", left: "0px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <HomeFooter />
          </div>
        </div>
      </div>

    </>
  )
}
const mapStateToProps = (state) => ({
  ThemeReducer: state.ThemeReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(EmailVerifyAgain);


// code for scoop solutions