import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Employernav from "../dropdownnav/Employernav";
import Footer from "../footer/Footer";
import { useHistory } from "react-router-dom";
const config = require("../../helpers/config.json");

Modal.setAppElement("#root");

const SaveNewTemplate = ({ isEdit }) => {
  const search = useLocation().search;
  const cid = new URLSearchParams(search).get("cid");
  const id = new URLSearchParams(search).get("id");
  const [load, setload] = useState(false);
  const templateName = new URLSearchParams(search).get("template_name");
  const [error, setError] = useState("");
  const [BilingmodalIsOpen5, setBilingModalIsOpen5] = useState(false);
  const [BilingmodalIsOpen6, setBilingModalIsOpen6] = useState(false);
  const [template_name, settemplate_name] = useState("");
  const history = useHistory();
  const [introduction, setintroduction] = useState("");
  const [job_description, setjob_description] = useState("");
  const [esignature, setEsignatuire] = useState("");

  const savedtemplate = async () => {
    if (template_name?.trim() && introduction?.trim()) {
      await fetch(
        `${config["baseUrl"]}/template/${!!isEdit ? `update/${id}` : "insert"}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            [!!isEdit ? "id" : "user_id"]: !!isEdit
              ? id
              : localStorage.getItem("userid"),
            template_name: template_name,
            introduction: introduction,
            job_description: job_description,
            esignature: esignature,
          }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (response.message == "Limit Over") {
            setBilingModalIsOpen5(true);
          } else if (
            response.message == "this name of template already exist"
          ) {
            setBilingModalIsOpen6(true);
          } else if (response.message == "The Message template is Saved.") {
            localStorage.setItem("temp_id", response.id);
            history.push(`/send-email?cid=${cid}`);
          } else if (response.message == "Template is Updated Successfully.") {
            history.push(`/send-email?cid=${cid}`);
          }
        })
        .catch((error) => {
          console.log(error);
          //
        });
    } else {
      let temp = { ...error };
      if (!template_name?.trim()) {
        temp.templateError = "Please complete this field";
      }
      if (!introduction?.trim()) {
        temp.introductionError = "Please complete this field";
      }
      setError(temp);
    }
  };
  useEffect(() => {
    if (!!isEdit) {
      fetch(
        `${config["baseUrl"]}/gettemplatesdatabyname/${localStorage.getItem(
          "userid"
        )}/${templateName}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setintroduction(response?.data?.[0]?.introduction);
          setjob_description(response?.data?.[0]?.job_description);
          settemplate_name(response?.data?.[0]?.template_name);
          setEsignatuire(
            response?.data?.[0]?.esignature.replace(/<br\s*\/?>/gi, "\n")
          );

          setload(true);
        })
        .catch((error) => {
          setload(true);
          console.log("error", error);
        });
    } else setload(true);
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("new-template")) {
      setEsignatuire(localStorage.getItem("email"));
    }
  }, [window.location.pathname]);
  if (!load) return <FullPageLoader1 />;
  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          {/* header======================================================================*/}
          <div className="main-content contact-or-rejection">
            <div className="d-flex main-content-session inner-session py-2">
              <h5>{isEdit ? "Edit" : "New"} Message Template</h5>
            </div>
            <form>
              <div className="form-group mb-1">
                <label htmlFor="exampleInputEmail1">
                  {!!isEdit ? "" : "New "}Template Name{" "}
                  <small className="text-danger" style={{ fontSize: "15px" }}>
                    *
                  </small>
                </label>
                <input
                  value={template_name}
                  onChange={(e) => {
                    settemplate_name(e.target.value);
                    setError("");
                  }}
                  type="email"
                  className="form-control employee-input-email"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder={`${isEdit ? "" : "New "}Message Title`}
                />
              </div>
              {error?.templateError && (
                <p className="text-danger mt-0 mb-3" style={{ color: "red" }}>
                  {error?.templateError}
                </p>
              )}
              <small
                id="emailHelp"
                className=" my-4 form-text  text-dark smalltextdark"
              >
                You may save up to 20 message templates.
              </small>
              <div className="form-group d-flex flex-column mb-1">
                <label htmlFor="exampleInputPassword1">
                  Introduction{" "}
                  <small className="text-danger" style={{ fontSize: "15px" }}>
                    *
                  </small>
                </label>
                <textarea
                  value={introduction}
                  onChange={(e) => {
                    setintroduction(e.target.value);
                    setError("");
                  }}
                  className="employee-input-email form-control"
                  name
                  id
                  cols={30}
                  rows={4}
                  placeholder="Hi {CANDIDATE_NAME}, 
Enter a brief introduction message here."
                  defaultValue={""}
                />
              </div>
              {error?.introductionError && (
                <p className="text-danger mt-0 mb-3" style={{ color: "red" }}>
                  {error?.introductionError}
                </p>
              )}
              <small
                id="emailHelp"
                className=" my-4 form-text  text-dark smalltextdark"
              >
                Type {"{"}CANDIDATE_NAME{"}"} in your introduction
                <br />
                and it will automatically enter the candidate’s <br />
                name into the message. You can also enter <br />
                {"{"}JOB_TITLE{"}"} and this will enter the job title as well.{" "}
                <br />
              </small>
              <div className="form-group d-flex flex-column">
                <label htmlFor="exampleInputPassword1">
                  Job Description (Optional)
                </label>
                <textarea
                  value={job_description}
                  onChange={(e) => setjob_description(e.target.value)}
                  className="employee-input-email form-control"
                  name
                  id
                  cols={30}
                  rows={4}
                  placeholder="Type or copy and paste job description here."
                  defaultValue={""}
                />
              </div>
              <div className="form-group d-flex flex-column">
                <label htmlFor="exampleInputPassword1">
                  E-Signature (Optional)
                </label>
                <textarea
                  defaultValue={localStorage.getItem("email") || "abc"}
                  className="employee-input-email form-control"
                  onChange={(e) => setEsignatuire(e.target.value)}
                  name
                  id
                  placeholder="Add e-signature here"
                  value={esignature}
                />
              </div>
              <div className="main-content-session inner-session pt-3">
                <Link
                  onClick={() => savedtemplate()}
                  className="send-invitation"
                >
                  SAVE
                </Link>
                {/* <Link to={`/send-email?cid=${cid}`}><u>Cancel</u></Link> */}
                <div
                  onClick={() => history.push(`/send-email?cid=${cid}`)}
                  className="ml-4 py-1 d-inline-block"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <b>
                    <u>Cancel</u>
                  </b>
                </div>
              </div>
            </form>
          </div>
          {/* footer====================================================================== */}
          {/* <HomeFooter /> */}
        </div>
      </div>

      <Modal
        isOpen={BilingmodalIsOpen5}
        onRequestClose={() => setBilingModalIsOpen5(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Your limit is over.</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpen5(false)}>
              OK
            </Link>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={BilingmodalIsOpen6}
        onRequestClose={() => setBilingModalIsOpen6(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Template name already exist.</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpen6(false)}>
              OK
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SaveNewTemplate;

// code for scoop solutions
