import React, { useState, useEffect, useContext } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import { JobByID } from "../../actions/jobbyid";
import Employernav from "../dropdownnav/Employernav";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { AxiosPOST } from "../../libs/axios";
import { setNewApplicantInJob } from "../../actions/setNewApplicantInJob";
import StatusDropdown from "../StatusDropdown";
import { ColorModeContext } from "../../theme";
const config = require("../../helpers/config.json");

Modal.setAppElement("#root");

const CandidateApplyToJobSpecific = (props) => {
  const [load, setload] = useState(false);
  const subscription = useSelector((state) => state?.SubscriptionInfo);
  const [allArray, setallArray] = useState([]);
  const [error, setError] = useState("");
  const [idd, setidd] = useState("");
  const [reFetch, setReFetch] = useState();
  const [cidd, setcidd] = useState([]);
  const search = useLocation().search;
  const [job, setJob] = useState();

  const compIds = new URLSearchParams(search).get("id");

  const { customer_id, limit, usedAmount, dueAmount } = subscription;

  const removeIsNewFlag = async (job) => {
    try {
      const res = await AxiosPOST({
        url: `${config["baseUrl"]}/jobs/apply/update-specific`,
        body: {
          id: job?.job_id,
        },
      });
      props.setNewApplicantInJob();
    } catch (error) {
      console.log("removeIsNewFlag ~ error:", error);
    }
  };
  useEffect(async () => {
    if (allArray?.length) {
      const jobId = window?.location?.search?.split("?id=")[1];
      const array = props.AppliedJobReducer?.jobIds;
      const job = allArray?.find((i) => i?.job_id == jobId);
      if (array?.includes(+jobId) && job) {
        console.log("runnnnn");
        removeIsNewFlag(job);
      } else {
        console.log("else run", job, array?.includes(+jobId));
      }
    }
  }, [allArray, props.AppliedJobReducer]);
  useEffect(async () => {
    loadgetjob();
  }, [compIds, reFetch]);
  const setids = (jid, cid) => {
    setError("");
    setidd(jid);
    if (!cidd?.includes(cid)) {
      if (cidd?.length < 20) setcidd((prev) => [...new Set([...prev, cid])]);
      else setError("You can select upto 20 candidates only");
    } else setcidd((prev) => prev?.filter((i) => i !== cid));
  };

  const loadgetjob = async () => {
    setload(true);
    await fetch(
      `${config["baseUrl"]}/jobs/apply/get/${localStorage.getItem(
        "userid"
      )}/${compIds}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setload(false);
        const alljobs = response.job;
        // console.log("hellooooooooooooooresssssss", response.job);
        setallArray(alljobs.filter((data) => data.job_id == compIds));
      })
      .catch((error) => {
        setload(false);
        console.log("error", error);
      });
    return null;
  };

  const [order, setorder] = useState("ASC");
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setallArray(sorted);
      setorder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setallArray(sorted);
      setorder("ASC");
    }
  };

  const [order1, setorder1] = useState("DSC");
  const sorting1 = (col) => {
    if (order1 === "ASC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setallArray(sorted);
      setorder1("DSC");
    }
    if (order1 === "DSC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setallArray(sorted);
      setorder1("ASC");
    }
  };
  const updatestatus = async ({ index, value }, id) => {
    await fetch(`${config["baseUrl"]}/jobs/applystatus/update`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        status:
          value == "Status"
            ? 0
            : value == "Rejected"
              ? 1
              : value == "Contacted"
                ? 2
                : value == "Reviewed"
                  ? 3
                  : "",
        empid: Number(localStorage.getItem("userid")),
        id: id,
      }),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
        }
        return res.json();
      })
      .then((response) => {
        if (response.message !== "Status Updated Successfully.") {
          console.log("Something went wrong..");
        } else {
          setallArray((prev) => prev?.map((i) => ({ ...i, status: index })));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteAppliedJob = async (id) => {
    await fetch(`${config["baseUrl"]}/jobs/apply/delete`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        console.log("response", response);
        if (response.message !== "Successfully Deleted Applied Job.") {
          console.log("Something went wrong..");
        } else {
          setallArray([]);
          setReFetch(new Date().getTime());
          // setallArray((prev) => prev?.filter((item) => item?.id !== id));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const history = useHistory();

  const handleClick = () => {
    if (cidd?.length) {
      history.push(`/send-email?id=${idd}&cid=${cidd.join(",")}`);
    } else {
      setError("Please select a candidate to email.");
    }
  };

  const [openModal, setOpenModal] = useState("");

  const HandleViewClick = async (job) => {
    let localjob = "";
    if (!customer_id) {
      setOpenModal("Please attach a credit card to view applicants");
      return;
    }
    await new Promise((resolve, reject) => {
      fetch(
        `${config["baseUrl"]}/resume/getbyid/${job?.candidate_id
        }/${localStorage.getItem("userid")}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          const alljobs = response;
          var temp = [];
          temp = [...response];

          localjob = [...temp];
          setJob([...temp]);
          resolve(temp);
        })
        .catch((error) => {
          console.log("error", error);
          reject("");
        });
    });

    await fetch(`${config["baseUrl"]}/viewCandidate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: Number(localStorage.getItem("userid")),
        resume_id: Number(localjob[0]?.job[0]?.id), //
        candidate_id: Number(localjob[0]?.job[0]?.user_id),
        type: "VIEW_CANDIDATE",
        job_id: Number(job.job_id),
      }),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          console.log("Limit Over");
        }
        return res.json();
      })
      .then((response) => {
        history.push(
          `/view-resume?id=${job.candidate_id}&job_id=${job.job_id}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { mode, toggleColorMode } = useContext(ColorModeContext);

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal("")}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "475px",
            height: "fit-content",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "40px 20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>{openModal}</h4>
          <div className="d-flex align-items-center justify-content-center search-session">
            <Link
              to="#"
              className="fgh"
              onClick={() => history.push("subscription")}
            >
              Subscription
            </Link>
          </div>
        </div>
      </Modal>

      {load == false ? (
        <>
          <div className="container-fluid" id="Employee">
            <div className="container">
              <Employernav />
              {/* header======================================================================*/}
              <div className="main-content candidate-applies-to-job inner-session">
                <div className="d-flex align-items-end flex-column justify-content-center mb-3 py-2">
                  {
                    // props.GetUserReducer.users &&
                    // props.GetUserReducer.users.Is_packaged !== null &&
                    // props.GetUserReducer.users.Is_packaged !== undefined &&
                    // props.GetUserReducer.users.Is_packaged !== "" &&
                    // props.GetUserReducer.users.Is_packaged !== 0 ? (
                    idd !== "" ? (
                      <>
                        {/* <Link to={`/send-email?id=${idd}&cid=${cidd.join(",")}`} className="text-light fgh mb-1">Email Selected Candidate</Link> */}
                        <button
                          onClick={handleClick}
                          className="text-light fgh mb-1"
                        >
                          Email Selected Candidate
                        </button>
                        <div style={{ minWidth: "208px", textAlign: "center" }}>
                          <small
                            className="text-danger"
                            style={{ color: "red" }}
                          >
                            {error}
                          </small>
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() =>
                            setError("Please select a candidate to email.")
                          }
                          className="text-light fgh mb-1"
                        >
                          Email Selected Candidate
                        </button>
                        <div style={{ minWidth: "208px", textAlign: "center" }}>
                          <small
                            className="text-danger"
                            style={{ color: "red" }}
                          >
                            {error}
                          </small>
                        </div>
                      </>
                    )
                    // ) : (
                    //   <>
                    //     <Link
                    //       to={`/get-premium${
                    //         window.location.href.search("false") == -1 &&
                    //         window.location.href.search("true") == -1
                    //           ? `/`
                    //           : `?if=${
                    //               window.location.href.search("false") == -1
                    //                 ? true
                    //                 : false
                    //             }`
                    //       }`}
                    //       className="text-light fgh"
                    //     >
                    //       Email Selected Candidate
                    //     </Link>
                    //     <div style={{ minWidth: "208px", textAlign: "center" }}>
                    //       <small className="text-danger" style={{ color: "red" }}>
                    //         {error}
                    //       </small>
                    //     </div>
                    //   </>
                    // )
                  }
                </div>
                <div className="usr-tbl">
                  <table
                    id="example"
                    className="table  table-striped table-bordered dataTable"
                    cellSpacing={0}
                    width="100%"
                    role="grid"
                    aria-describedby="example_info"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Name: activate to sort column ascending"
                          style={{ width: "20%", textAlign: "center" }}
                        >
                          Candidate Name{" "}
                          {order == "DSC" ? (
                            <i
                              class="fa fa-arrow-down pl-4"
                              style={{ color: "gray", cursor: "pointer" }}
                              onClick={() => sorting("candidate_name")}
                            />
                          ) : (
                            <i
                              class="fa fa-arrow-up pl-4"
                              style={{ color: "gray", cursor: "pointer" }}
                              onClick={() => sorting("candidate_name")}
                            />
                          )}
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Position: activate to sort column ascending"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          Job Applied
                          {/* {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("job_applied")} />} */}
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Office: activate to sort column ascending"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          Date Applied{" "}
                          {order1 == "ASC" ? (
                            <i
                              class="fa fa-arrow-down pl-4"
                              style={{ color: "gray", cursor: "pointer" }}
                              onClick={() => sorting1("date_applied")}
                            />
                          ) : (
                            <i
                              class="fa fa-arrow-up pl-4"
                              style={{ color: "gray", cursor: "pointer" }}
                              onClick={() => sorting1("date_applied")}
                            />
                          )}
                        </th>
                        <th
                          className="sorting_desc"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Age: activate to sort column ascending"
                          aria-sort="descending"
                          style={{ width: "15%", textAlign: "center", color: mode === "light" ? "black" : "white" }}
                        >
                          Status
                          {/* {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("status")} />} */}
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Start date: activate to sort column ascending"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          Contact
                          {/* {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("contact")} />} */}
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: "20%", textAlign: "center" }}
                        >
                          Date Responded
                          {/* {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("candidate_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("date_responded")} />} */}
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: "20%", textAlign: "center" }}
                        >
                          Resume
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: "20%", textAlign: "center" }}
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    {allArray && allArray.length > 0 ? (
                      allArray.map((job) => (
                        <tbody key={job?.id}>
                          <tr role="row" className="odd text-center">
                            <td style={{ verticalAlign: "middle" }}>
                              {job?.candidate_name || ""}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              {job.job_applied !== "" &&
                                job.job_applied !== null &&
                                job.job_applied !== undefined
                                ? job.job_applied
                                : ""}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              {job.date_applied !== "" &&
                                job.date_applied !== null &&
                                job.date_applied !== undefined
                                ? dayjs(job.date_applied).format("MM-DD-YYYY")
                                : ""}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              <StatusDropdown
                                defaultValue={statusArr[job?.status]}
                                onChange={({ index, value }) => {
                                  updatestatus({ index, value }, job.id);
                                }}
                                options={[
                                  "Status",
                                  "Rejected",
                                  "Contacted",
                                  "Reviewed",
                                ]}
                              />
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              <div className="role">
                                <label for={job.id}>
                                  <input
                                    type="checkbox"
                                    multiple
                                    checked={cidd?.includes(job?.candidate_id)}
                                    onChange={(e) =>
                                      setids(job.id, job.candidate_id)
                                    }
                                    id={job.id}
                                    className="mt-1 mr-2"
                                  />
                                  <span
                                    className="resume-data"
                                    style={{
                                      borderRadius: "10px",
                                      alignItems: "flex-start",
                                    }}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {job.date_responded !== "" &&
                                job.date_responded !== null &&
                                job.date_responded !== undefined
                                ? dayjs(job.date_responded).format("MM-DD-YYYY")
                                : "Not Responded Yet"}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              <div onClick={() => HandleViewClick(job)}>
                                <Link className=" mb-0">View</Link>
                              </div>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                fontSize: 20,
                                verticalAlign: "middle",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  height: "40px",
                                  width: "100%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <span onClick={() => deleteAppliedJob(job.id)}>
                                  <i
                                    className="fa fa-trash cursor-pointer"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <div className="d-flex w-100 aliign-items-center justify-content-center not-found px-5 nofournd">
                        no Candidate found
                      </div>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <FullPageLoader1 />
      )}
    </>
  );
};

const statusArr = ["Status", "Rejected", "Contacted", "Reviewed"];

const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  AppliedJobReducer: state.AppliedJobReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setNewApplicantInJob: () => dispatch(setNewApplicantInJob()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateApplyToJobSpecific);

// code for scoop solutions
