import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import Employernav from "../dropdownnav/Employernav";
import Modal from "react-modal";
import InputMask from 'react-input-mask';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
const config = require('../../helpers/config.json');


const EditUser = (props) => {
  const search = useLocation().search;
  const idd = new URLSearchParams(search).get("id");
  useEffect(async () => {
    loadgetjob()
  }, []);
  const loadgetjob = async () => {
    setload(true)
    await fetch(`${config['baseUrl']}/manage-role/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      var first = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].first_name : ""
      setfirstname(first)
      var last = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].last_name : ""
      setlastname(last)
      var tit = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].position_title : ""
      settitle(tit)
      var cname = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].company_name : ""
      setcompanyname(cname)
      var cit = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].city : ""
      setaddress(cit)
      var sta = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].state : ""
      setstate(sta)
      var zi = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].zip_code : ""
      setzip(zi)
      var ph = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].phone : ""
      setnumber(ph)
      var ema = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].email : ""
      setemail(ema)
      var ema = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].email : ""
      setemail(ema)
      var sb = alljobs && alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].subscription : ""
      setsubs(sb)
      var post = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].create_posting : ""
      post == 0 ? setcreate_posting(false) : setcreate_posting(true)
      var resume = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].resume_info : ""
      resume == 0 ? setresume(false) : setresume(true)
      var bill = alljobs.length > 0 ? alljobs.filter(data => data.id == idd)[0].billing_info : ""
      bill == 0 ? setbilling(false) : setbilling(true)
      // allArray.push(alljobs)
      setallArray(...allArray, alljobs)
    }).catch((error) => {
      setload(false)

    })
    return null
  }
  const [errzip, seterrzip] = useState(false)
  const statecorrect = (e) => {
    setzip(e)
    const dumbdata = e.split('')
    if (dumbdata.length < 5) {
      seterrzip(true)
    }
    else {
      seterrzip(false)
    }
  }
  const [load, setload] = useState(false)
  const [BilingmodalIsOpenzip, setBilingModalIsOpenzip] = useState(false);
  const [allArray, setallArray] = useState([])
  const [firstname, setfirstname] = useState("")
  const [lastname, setlastname] = useState("")
  const [title, settitle] = useState("")
  const [companyname, setcompanyname] = useState("")
  const [city, setcity] = useState("")
  const [state, setstate] = useState("")
  const [zip, setzip] = useState("")
  const [number, setnumber] = useState("")
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [conpassword, setconpassword] = useState("")
  const [passwordType, setPasswordType] = useState("password");
  const [conpasswordType, setconPasswordType] = useState("password");
  const [notok, setnotok] = useState(false)
  const [create_posting, setcreate_posting] = useState(false)
  const [resume, setresume] = useState(false)
  const [billing, setbilling] = useState(false)
  const [subs, setsubs] = useState("")
  const [checkpass, setcheckpass] = useState(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const contogglePassword = () => {
    if (conpasswordType === "password") {
      setconPasswordType("text")
      return;
    }
    setconPasswordType("password")
  }
  const EditNewRole = (e) => {
    const zipreg = /^\d{0,}$/;
    if (zipreg.test(zip) === false) {
      setBilingModalIsOpenzip(true)
    } else if (zip.length < 5) {
      setBilingModalIsOpenzip(true)
    }
    else {
      fetch(`${config['baseUrl']}/update-role`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "first_name": firstname,
          "last_name": lastname,
          "position_title": title,
          "company_name": companyname,
          "city": address.split(",")[0],
          "state": state,
          "zip_code": zip,
          "phone": number,
          "email": email,
          "password": password,
          "role": 1,
          "create_posting": create_posting !== true ? Number(0) : Number(1),
          "resume": resume !== true ? Number(0) : Number(1),
          "billing": billing !== true ? Number(0) : Number(1),
          "employer_id": Number(localStorage.getItem("userid")),
          "subs": subs,
          "latitude": corr.lat,
          "longitude": corr.lng,
          "id": idd

        })
      }).then(res => {

        return res.json();
      }).then((response) => {
        if (response.message !== "successfull") {
          console.log("Can't add new command when connection is in closed state")
        } else {
          window.location = "/account-management"
        }
      }).catch((error) => {
        const authError = JSON.stringify(error)
        console.log(authError);
      })
    }

  };
  const [address, setaddress] = useState("")
  const [corr, setcorr] = useState({
    lat: null,
    lng: null
  })
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)

    const getZipCode = result?.[0]?.address_components?.find(
      (component) => component?.types?.includes('postal_code')
      )?.long_name;
      if(getZipCode){
        statecorrect(getZipCode)
      }

    const ll = await getLatLng(result[0])
    setstate(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
    setaddress(value)
    setcorr(ll)


  }
  return (
    <>

      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          {/* header======================================================================*/}
          <div className="main-content">
            <div className="col-lg-6 col-12 p-0">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">First Name:  <asterisk style={{ color: "red" }}> *</asterisk ></label>
                    <input
                      defaultValue={firstname}
                      onChange={(e) => setfirstname(e.target.value)}
                      type="text" className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Last Name:    <asterisk style={{ color: "red" }}> *</asterisk ></label>
                    <input
                      defaultValue={lastname}
                      onChange={(e) => setlastname(e.target.value)}
                      type="text" className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Last Name" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Position Title:    <asterisk style={{ color: "red" }}> *</asterisk ></label>
                <input
                  defaultValue={title}
                  onChange={(e) => settitle(e.target.value)}
                  type="text" className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Position Title" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Company Name:   <asterisk style={{ color: "red" }}> *</asterisk ></label>
                <input
                  defaultValue={companyname}
                  onChange={(e) => setcompanyname(e.target.value)}
                  type="text" className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Company Name" />
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group ghg">
                    <label htmlFor="exampleInputPassword1">City:   <asterisk style={{ color: "red" }}> *</asterisk ></label>
                    <PlacesAutocomplete
                      value={address.split(",")[0]}
                      onChange={setaddress}
                      onSelect={handleSelect}
                      searchOptions={{ componentRestrictions: { country: 'us' } }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            id="exampleInputPassword1"
                            className="form-control employee-input-email job-search"
                            {...getInputProps({
                              placeholder: 'City',
                              className: 'location-search-input',
                            })}
                          />
                          <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item p-2 --active'
                                : 'suggestion-item p-2 ';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">State:   <asterisk style={{ color: "red" }}> *</asterisk ></label>
                    <select onChange={(e) => setstate(e.target.value)} style={{ width: '60px !important', height: '40px' }} className="employee-input-ema" name id
                      value={state}
                    >
                      <option selected value="Select">Select</option>
                      <option value={state}>{state}</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA	">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Zip:   <asterisk style={{ color: "red" }}> *</asterisk ></label>
                    <input
                      defaultValue={zip}
                      onChange={(e) => statecorrect(e.target.value)}
                      type="number" className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Zip" />
                    {errzip == true ? <small className="text-danger" style={{ color: "red" }}>*Zip Code requires at least five digits</small> : ""}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Phone Number:   <asterisk style={{ color: "red" }}> *</asterisk ></label>
                <InputMask {...props} mask="(999) 999-9999" value={number} className="form-control employee-input-email job-search w-100" onChange={(e) => setnumber(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Email:    <asterisk style={{ color: "red" }}> *</asterisk ></label>
                <input
                  defaultValue={email}
                  onChange={(e) => setemail(e.target.value)}
                  type="email" className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Email" />
              </div>
            </div>
            <div className="main-content-session">
              <p>Role <span>*</span></p>
              <div className="role">
                {
                  allArray && allArray.length > 0 ? allArray.filter(data => data.id == idd)[0].subscription == "Administrator" ?
                    <label>
                      <input type="radio" onChange={(e) => setsubs("Administrator")} name="radio1" defaultChecked={true} />
                      <span className="sp-clr">Administrator</span>
                    </label>
                    :
                    <label>
                      <input type="radio" onChange={(e) => setsubs("Administrator")} name="radio1" defaultChecked={false} />
                      <span className="sp-clr">Administrator</span>
                    </label>
                    :
                    ""
                }
                {
                  allArray && allArray.length > 0 ? allArray.filter(data => data.id == idd)[0].subscription == "Standard User" ?
                    <label>
                      <input type="radio" onChange={(e) => setsubs("Standard User")} name="radio1" defaultChecked={true} />
                      <span className="sp-clr">Standard User</span>
                    </label>
                    :
                    <label>
                      <input type="radio" onChange={(e) => setsubs("Standard User")} name="radio1" defaultChecked={false} />
                      <span className="sp-clr">Standard User</span>
                    </label>
                    :
                    ""
                }

              </div>
            </div>
            <div className="main-content-session">
              <p>Permission being granted <span>*</span></p>
              <div className="role role2">
                {
                  allArray && allArray.length > 0 ? allArray.filter(data => data.id == idd)[0].create_posting == 1 ?
                    <label>
                      <input type="checkbox" name="radio1" onChange={() => setcreate_posting(!create_posting)} defaultChecked={true} />
                      <span className="sp-clr">Able to create and manage job postings</span>
                    </label>
                    :
                    <label>
                      <input type="checkbox" name="radio1" onChange={() => setcreate_posting(!create_posting)} defaultChecked={false} />
                      <span className="sp-clr">Able to create and manage job postings</span>
                    </label>
                    : ""
                }
                {
                  allArray && allArray.length > 0 ? allArray.filter(data => data.id == idd)[0].resume_info == 1 ?
                    <label>
                      <input type="checkbox" name="radio1" onChange={() => setresume(!resume)} defaultChecked={true} />
                      <span className="sp-clr">Able to review contact information provided by the
                        candidate</span>
                    </label>
                    :
                    <label>
                      <input type="checkbox" name="radio1" onChange={() => setresume(!resume)} defaultChecked={false} />
                      <span className="sp-clr">Able to review contact information provided by the
                        candidate</span>
                    </label>
                    : ""
                }
                {
                  allArray && allArray.length > 0 ? allArray.filter(data => data.id == idd)[0].billing_info == 1 ?
                    <label>
                      <input type="checkbox" name="radio1" onChange={() => setbilling(!billing)} defaultChecked={true} />
                      <span className="sp-clr">Able to review and edit billing information</span>
                    </label>
                    :
                    <label>
                      <input type="checkbox" name="radio1" onChange={() => setbilling(!billing)} defaultChecked={false} />
                      <span className="sp-clr">Able to review and edit billing information</span>
                    </label>
                    : ""
                }

              </div>
            </div>
            <div className="main-content-session inner-session">
              <Link onClick={() => EditNewRole()} className="send-invitation fgh">Update</Link>
              <Link to="/account-management" className="cancel-button fgh">Cancel</Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={BilingmodalIsOpenzip}
        onRequestClose={() => setBilingModalIsOpenzip(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please enter valid zip code</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpenzip(false)}>OK</Link>
          </div>

        </div>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);


// code for scoop solutions