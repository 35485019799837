import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createRegister } from "../../actions/Registeraction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import InputMask from 'react-input-mask';
import useWindowDimensions from '../administrator/innerwidthcom';

import Dropdownnav from "../dropdownnav/Dropdownnav";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const EmployeeSignUp = (props) => {
  const { height, width } = useWindowDimensions();

  const [modalwidth, setmodalwidth] = useState(450)
  useEffect(() => {
    if (width <= 900) {
      setmodalwidth("340px")
    }
  }, []);
  const [signUpLoading, setSignUpLoading] = useState(false)
  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [PhoneNummodalIsOpen, setPhoneNummodalIsOpen] = useState(false);
  const [BilingmodalIsOpen3, setBilingModalIsOpen3] = useState(false);
  const [BilingmodalIsOpen4, setBilingModalIsOpen4] = useState(false);
  const [BilingmodalIsOpen5, setBilingModalIsOpen5] = useState(false);
  const [BilingmodalIsOpen6, setBilingModalIsOpen6] = useState(false);
  const [BilingmodalIsOpen7, setBilingModalIsOpen7] = useState(false);
  const [BilingmodalIsOpen8, setBilingModalIsOpen8] = useState(false);
  const [BilingmodalIsOpen9, setBilingModalIsOpen9] = useState(false);
  const [BilingmodalIsOpenzip, setBilingModalIsOpenzip] = useState(false);
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState("")
  const [errorinner, seterrorinner] = useState("")
  useEffect(() => {
  }, []);
  const [firstname, setfirstname] = useState("")
  const [lastname, setlastname] = useState("")
  const [title, settitle] = useState("")
  const [companyname, setcompanyname] = useState("")
  const [city, setcity] = useState("")
  const [state, setstate] = useState("")
  const [zip, setzip] = useState("")
  const [number, setnumber] = useState("")
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [conpassword, setconpassword] = useState("")
  const [passwordType, setPasswordType] = useState("password");
  const [conpasswordType, setconPasswordType] = useState("password");
  const [notok, setnotok] = useState(false)
  const[checkcity,setcheckcity]=useState(false)
  const [require1, setrequire1] = useState(false)
  const [require2, setrequire2] = useState(false)
  const [require3, setrequire3] = useState(false)
  const [require4, setrequire4] = useState(false)
  const [require5, setrequire5] = useState(false)
  const [require6, setrequire6] = useState(false)
  const [require7, setrequire7] = useState(false)
  const [require8, setrequire8] = useState(false)
  const [require9, setrequire9] = useState(false)
  const [checkpass, setcheckpass] = useState(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const contogglePassword = () => {
    if (conpasswordType === "password") {
      setconPasswordType("text")
      return;
    }
    setconPasswordType("password")
  }
  const addNewRegister = (e) => {
    setSignUpLoading(true)
    const reg = /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z0-9]+)\.(com+)$/;
    const zipreg = /^\d{0,}$/;
    if (firstname == "") {
      setrequire1(true)
    }  if (lastname == "") {
      setrequire2(true)
    }  if (corr.lat==null ) {
      setrequire3(true)
      setcheckcity(true)
    }
    if (corr.lat!==null ) {
      setrequire3(true)
      setcheckcity(false)
    }
    if (address !== "" ) {
      setrequire3(true)
      setcheckcity(false)
    } if (address.split(",").length!==1 ) {
      setrequire3(true)
      setcheckcity(false)
    }
    if (address == "" ) {
      setrequire3(true)
      setcheckcity(true)
    } if (address.split(",").length==1 ) {
      setrequire3(true)
      setcheckcity(true)
    } if (state == "") {
      setrequire4(true)
    }  if (zip == "") {
      setrequire5(true)
    }  if (number == "") {
      setrequire6(true)
    }  if (email == "") {
      setrequire7(true)
    }  if (reg.test(email) === false) {
      setrequire7(true)
    }  if (password == "") {
      setrequire8(true)
    }  if (checkpass.test(password) === false) {
      setrequire8(true)
    }  if (conpassword == "") {
      setrequire9(true)
    }
     if (number.length !== 14) {
      setrequire6(true)
    } 
    if (conpassword !== password) {
      setBilingModalIsOpen(true)
    }


    if (firstname == "") {
      // setrequire1(true)
      // setBilingModalIsOpen4(true)
    } else if (lastname == "") {
      // setrequire2(true)
      // setBilingModalIsOpen4(true)
    } else if (corr.lat==null ) {
      setrequire3(true)
      // setcheckcity(true)
      // setBilingModalIsOpen4(true)
    }else if (address == "" ) {
      setrequire3(true)
      // setcheckcity(true)
      // setBilingModalIsOpen4(true)
    }else if (address.split(",").length==1 ) {
      setrequire3(true)
      // setcheckcity(true)
      // setBilingModalIsOpen4(true)
    }else if (state == "") {
      // setrequire4(true)
      // setBilingModalIsOpen4(true)
    } else if (zip == "") {
      // setrequire5(true)
      // setBilingModalIsOpen4(true)
    }else if (number == "") {
      // setrequire6(true)
      // setBilingModalIsOpen4(true)
    } else if (email == "") {
      // setrequire7(true)
      // setBilingModalIsOpen4(true)
    } else if (reg.test(email) === false) {
      // setrequire7(true)
      // setBilingModalIsOpen3(true)
    } else if (password == "") {
      // setrequire8(true)
      // setBilingModalIsOpen4(true)
    } else if (checkpass.test(password) === false) {
      // setrequire8(true)
      // setBilingModalIsOpen2(true)
    } else if (conpassword == "") {
      // setrequire9(true)
      // setBilingModalIsOpen4(true)
    } else if (conpassword !== password) {
      // setBilingModalIsOpen(true)
    }
    else if (number.length !== 14) {
      // setrequire6(true)
      // setPhoneNummodalIsOpen(true)
    } 
    else {
      setloading(true)
      
      
      setloading(false)

      fetch(`${config['baseUrl']}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({

          "first_name": firstname,
          "last_name": lastname,
          "position_title": title !== "" && title !== null && title !== undefined ? title : null,
          "company_name": companyname !== "" && companyname !== null && companyname !== undefined ? companyname : "",
          "city": address.split(",")[0],
          "state": state,
          "zip_code": zip,
          "phone": number,
          "email": email,
          "password": password,
          "source": "simple",
          "role": 0,
          "latitude": corr.lat,
          "longitude": corr.lng

        })
      }).then(res => {

        if (res.status == 422) {
          return res.json().then((re) => {
            seterror(re.errors[0].msg)
            setloading(false)
            setBilingModalIsOpen6(true)
          })
        }
        return res.json();
      }).then((response) => {

        if (response.message !== undefined && response.message !== null && response.message !== "" && response.message !== "The user has been successfully inserted." && response.status !== 201) {
          setBilingModalIsOpen9(response.message)
          seterror(response.message)
          setloading(false)
        } else {
          setloading(false)
          setfirstname("")
          setlastname("")
          settitle("")
          setcompanyname("")
          setcity("")
          setstate("")
          setzip("")
          setnumber("")
          setemail("")
          setpassword("")
          setconpassword("")
          setaddress("")
          setBilingModalIsOpen8(true)
        }
      }).catch((error) => {
        setloading(false)
        const authError = JSON.stringify(error)
        console.log(authError);
      })
    }
    setSignUpLoading(false)
  };
  const [address, setaddress] = useState("")
  const [corr, setcorr] = useState({
    lat: null,
    lng: null
  })
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)

    const getZipCode = result?.[0]?.address_components?.find(
      (component) => component?.types?.includes('postal_code')
      )?.long_name;
      if(getZipCode){
        setrequire5(false)
        statecorrect(getZipCode)
      }

    const ll = await getLatLng(result[0])
    setstate(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
    setaddress(value)
    setcorr(ll)
  }


  const [errzip, seterrzip] = useState(false)
  const statecorrect = (e) => {
    setzip(e)
    const dumbdata = e.split('')
    if (dumbdata.length < 5) {
      seterrzip(true)
    }
    else {
      seterrzip(false)
    }
  }
  return (
    <>
      {
        loading == false ?
          <>
            <div className="container-fluid" id="Employee">
              <div className="container">
                <Dropdownnav />
                {/* header======================================================================*/}
                <div className="main-content emp-sign-up">
                  <p>Already have an account?<Link to={`/candidate-login${window.location?.href?.search('false')== -1&&window.location?.href?.search('true')== -1?`/`:`?if=${window.location?.href?.search('false')== -1 ?true:false}`}`} className="text-heading ml-1 fgh">Sign in here</Link></p>
                  <div className="col-lg-6 col-12 p-0">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label >First Name:  <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                          <input
                            onChange={(e) => {
                              setrequire1(false)
                              setfirstname(e.target.value)}}
                            type="text" value={firstname} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" />
                        {require1 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label >Last Name:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>

                          <input
                            onChange={(e) => {
                              setrequire2(false)
                              setlastname(e.target.value)}}
                            type="text" value={lastname} className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Last Name" />
                       {require2 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>City:   <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>

                          <PlacesAutocomplete
                            value={address.split(",")[0]}
                            onChange={(e)=>{ setaddress(e);setcheckcity(false)}}
                            onSelect={handleSelect}
                            searchOptions={{ componentRestrictions: { country: 'us' } }}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <input
                                  id="exampleInputPassword1"
                                  className="form-control employee-input-email job-search"
                                  {...getInputProps({
                                    placeholder: 'City',
                                    className: 'location-search-input',
                                  })}
                                />
                                {checkcity == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                                <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? 'suggestion-item p-2 --active'
                                      : 'suggestion-item p-2 ';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>State:   <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                          <select onChange={(e) => {
                            setrequire4(false)
                            setstate(e.target.value)}} style={{ width: '60px !important', height: '40px' }} className="employee-input-ema" name id
                            value={state}
                          >
                            {
                              state !== "" ?
                                <option selected value={state}>{state}</option>
                                :
                                <option selected value="Select">Select</option>
                            }
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                          {require4 == true && state=="" ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Zip:   <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                          <input
                            onChange={(e) => {
                              setrequire5(false)
                              statecorrect(e.target.value)}}
                            type="text" value={zip} className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Zip" />
                          {require5 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Phone Number:   <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                      <InputMask {...props} mask="(999) 999-9999" value={number} className="form-control employee-input-email job-search w-100" onChange={(e) => {
                        setrequire6(false)
                        setnumber(e.target.value.toString())}} maskChar="" placeholder="(xxx) xxx-xxxx" />
                        {require6 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                    </div>
                    <div className="form-group">
                      <label>Email:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                      <input
                        onChange={(e) => {
                          setrequire7(false)
                          setemail(e.target.value)}}
                        type="email" value={email} className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Email" />
                    {require7 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                    </div>
                    <div className="form-group">
                      <div className="d-flex flex-column">
                        <label>Create Password:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small> {checkpass.test(password) === true ? <span style={{ color: "green" }}></span> : <span style={{ color: "red" }}><br />Password must contain at least 8 characters, both capital and lower case letters, at least one number and one special character (!@#$%^&*)</span>}</label>
                        
                        <div style={{ position: "relative", width: "fit-content" }}>
                          <input onChange={(e) => {
                            setrequire8(false)
                            setBilingModalIsOpen(false)
                            setpassword(e.target.value)}} id="password-field4" type={passwordType} value={password} className="employee-input-email job-search" style={{ width: 280 }} name="password" placeholder="Create Password" />
                          <span toggle="#password-field4" className="" onClick={togglePassword}> {passwordType === "password" ? <i style={{ position: "absolute", right: 10, top: 10 }} className={checkpass.test(password) === true ? "fa fa-eye-slash field-icon" : "fa fa-eye-slash field-icon"} ></i> : <i style={{ position: "absolute", right: 10, top: 10 }} className={checkpass.test(password) === true ? "fa fa-eye field-icon" : "fa fa-eye field-icon"}></i>}</span>
                        </div>
                        {require8 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : BilingmodalIsOpen == true ?
                        <small className="text-danger" style={{ color: "red" }}>Please check your password as they do not match</small>:""}
                         

                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex flex-column">
                        <label>Confirm Password:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                        
                        <div style={{ position: "relative", width: "fit-content" }}>
                          <input onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              addNewRegister();
                            }
                          }}
                            onChange={(e) => {
                              setrequire9(false)
                              setBilingModalIsOpen(false)
                              setconpassword(e.target.value)}}
                            id="password-field5" type={conpasswordType} value={conpassword} className="employee-input-email job-search" name="password" style={{ width: 280 }} placeholder="Confirm Password" />
                          <span toggle="#password-field5" onClick={contogglePassword}> {conpasswordType === "password" ? <i style={{ position: "absolute", right: 10, top: 10 }} className="fa fa-eye-slash field-icon"></i> : <i style={{ position: "absolute", right: 10, top: 10 }} className="fa fa-eye field-icon"></i>}</span>
                        </div>
                        {require9 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : BilingmodalIsOpen == true ?
                        <small className="text-danger" style={{ color: "red" }}>Please check your password as they do not match</small>:""}
                      </div>
                    </div>
                    <div className="main-content-session inner-session">
                      <Link to="#" onClick={() => {if(!signUpLoading) {addNewRegister()}}} className="send-invitation fgh">{signUpLoading? "Loading..." : "Continue"}</Link>
                    </div>
                  </div>
                </div>
                {/* footer====================================================================== */}

              </div>
            </div>
            <Modal
              isOpen={BilingmodalIsOpen2}
              onRequestClose={() => setBilingModalIsOpen2(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "200px",
                  top: "calc(50% - 100px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Password must contain both capital and
                  lower case letters, at least one number and one special
                  character</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link to="#" className="fgh" onClick={() => setBilingModalIsOpen2(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={BilingmodalIsOpen3}
              onRequestClose={() => setBilingModalIsOpen3(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "240px",
                  top: "calc(50% - 90px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",
display:"flex",
            alignItems:"center",
            justifyContent:"center",
                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Please enter a valid email address</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link to="#" className="fgh" onClick={() => setBilingModalIsOpen3(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={BilingmodalIsOpenzip}
              onRequestClose={() => setBilingModalIsOpenzip(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "180px",
                  top: "calc(50% - 90px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Please enter valid zip code</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link to="#" className="fgh" onClick={() => setBilingModalIsOpenzip(false)}>OK</Link>
                </div>

              </div>
            </Modal>


            <Modal
              isOpen={BilingmodalIsOpen4}
              onRequestClose={() => setBilingModalIsOpen4(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "200px",
                  top: "calc(50% - 100px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",
                  overFlow: "hidden",
                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Please complete all of the required fields to continue</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link to="#" className="fgh" onClick={() => setBilingModalIsOpen4(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={BilingmodalIsOpen5}
              onRequestClose={() => setBilingModalIsOpen5(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "180px",
                  top: "calc(50% - 90px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Please enter valid number</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link to="#" className="fgh" onClick={() => setBilingModalIsOpen5(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={BilingmodalIsOpen6}
              onRequestClose={() => setBilingModalIsOpen6(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "200px",
                  top: "calc(50% - 100px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>{error !== "" ? error : ""}</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link to="#" className="fgh" onClick={() => setBilingModalIsOpen6(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={BilingmodalIsOpen9}
              onRequestClose={() => {
                setBilingModalIsOpen9(false);
                seterror("")
              }}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "200px",
                  top: "calc(50% - 100px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>{error !== "" ? error : ""}</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link style={{marginBottom: 0}} to="#" className="fgh" onClick={() => setBilingModalIsOpen9(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={PhoneNummodalIsOpen}
              onRequestClose={() => setPhoneNummodalIsOpen(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: modalwidth,
                  height: "180px",
                  top: "calc(50% - 90px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Please enter valid phone number</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link to="#" className="fgh" onClick={() => setPhoneNummodalIsOpen(false)}>OK</Link>
                </div>

              </div>
            </Modal>


            <Modal
              isOpen={BilingmodalIsOpen7}
              onRequestClose={() => setBilingModalIsOpen7(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "221px",
                  top: "calc(50% - 110px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>{errorinner !== "" ? errorinner : ""}</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link to="#" className="fgh" onClick={() => setBilingModalIsOpen7(false)}>OK</Link>
                </div>

              </div>
            </Modal>



            <Modal
              isOpen={BilingmodalIsOpen8}
              onRequestClose={() => setBilingModalIsOpen8(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: "450px",
                  height: "200px",
                  top: "calc(50% - 100px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Please check your email and click the
                  button to verify your address</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                <Link to="#" className="fgh" onClick={() => window.location.reload(false)}>OK</Link>
                </div>
              </div>
            </Modal>
          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  Registerreducer: state.Registerreducer,
});

const mapDispatchToProps = (dispatch) => ({
  createRegister: (firstname, lastname, title, companyname, city, state, zip, number, email, password) =>
    dispatch(createRegister(firstname, lastname, title, companyname, city, state, zip, number, email, password)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSignUp);


// code for scoop solutions