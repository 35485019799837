import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import Employernav from "../dropdownnav/Employernav";
const config = require('../../helpers/config.json');


const AccountManagement = (props) => {
  const [load, setload] = useState(false)
  const [allArray, setallArray] = useState([])
  useEffect(async () => {
    loadgetjob()
  }, []);
  const loadgetjob = async () => {
    setload(true)
    await fetch(`${config['baseUrl']}/manage-role/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      setallArray(...allArray, alljobs)
    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
    return null
  }

  const activeRole = async (e, id) => {
    await fetch(`${config['baseUrl']}/active-role`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "active": Number(e),
        "id": Number(id)
      })
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
      }
      return res.json();
    }).then((response) => {
      if (response.message == "successfull") {
        window.location = "/account-management"
      }
      else {
      }

    }).catch((error) => {
      console.log(error)

    })
  }
  const Createposting = async (st, dd) => {
    await fetch(`${config['baseUrl']}/create-posting`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "status": Number(st),
        "id": Number(dd)
      })
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
      }
      return res.json();
    }).then((response) => {
      if (response.message == "successfull") {
        window.location = "/account-management"
      }
      else {
      }

    }).catch((error) => {
      console.log(error)

    })
  }
  const ResumeInfo = async (st, dd) => {
    await fetch(`${config['baseUrl']}/resume-info`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "resumeinfo": Number(st),
        "id": Number(dd)
      })
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
      }
      return res.json();
    }).then((response) => {
      if (response.message == "successfull") {
        window.location = "/account-management"
      }
      else {
      }

    }).catch((error) => {
      console.log(error)

    })
  }
  const BillingInfo = async (st, dd) => {
    await fetch(`${config['baseUrl']}/billing-info`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "billinginfo": Number(st),
        "id": Number(dd)
      })
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
      }
      return res.json();
    }).then((response) => {
      if (response.message == "successfull") {
        window.location = "/account-management"
      }
      else {
      }

    }).catch((error) => {
      console.log(error)

    })
  }
  const DeleteRole = async (dd) => {
    await fetch(`${config['baseUrl']}/delete-role`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "id": Number(dd)
      })
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
      }
      return res.json();
    }).then((response) => {
      if (response.message == "The Role has been successfully Deleted.") {
        window.location = "/account-management"
      }
      else {
      }

    }).catch((error) => {
      console.log(error)

    })
  }
  const [order, setorder] = useState("ASC")
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("DSC")
    }
    if (order === "DSC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("ASC")
    }

  }
  return (
    <>

      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          {/* header======================================================================*/}

          <div className="main-content">
            <div className="d-flex align-items-center justify-content-end main-content-session inner-session">
              <Link to="/add-user" className="send-invitation">Add User</Link>
            </div>
            <div className="usr-tbl">
              <table id="example" className="table table-responsive table-striped table-bordered dataTable" cellSpacing={0} width="100%" role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                <thead>
                  <tr role="row">
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '20%' }}>User
                      Email Address {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("email")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("email")} />}</th>
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '10%' }}>
                      Status</th>
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '10%' }}>Role
                    </th>
                    <th className="sorting_desc" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Age: activate to sort column ascending" aria-sort="descending" style={{ width: '10%' }}>
                      Create/manage Posting</th>
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Start date: activate to sort column ascending" style={{ width: '10%' }}>
                      Resume Info</th>
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Salary: activate to sort column ascending" style={{ width: '10%' }}>Billing
                      Info
                    </th>
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Salary: activate to sort column ascending" style={{ width: '20%' }}>Current
                      Subscription(s)
                    </th>
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Salary: activate to sort column ascending" style={{ width: '20%' }}>
                      Action
                    </th>
                  </tr>
                </thead>
                {
                  allArray && allArray.length > 0 ? allArray.map(val => (
                    <tbody>

                      <tr role="row" className="odd">
                        <td className>{val.email}</td>
                        <td>
                          <select name="" id="" onChange={(e) => activeRole(e.target.value, val.id)}>
                            {
                              val.active == 0 ?
                                <option value="0">Deactive</option>
                                :
                                <option value="1">Active</option>
                            }
                            {
                              val.active !== 0 ?
                                ""
                                : <option value="1">Active</option>
                            }
                            {
                              val.active !== 1 ?
                                ""
                                : <option value="0">Deactive</option>
                            }
                          </select>
                        </td>
                        <td>{val.subscription}</td>
                        <td style={{ textAlign: 'center' }}>
                          {
                            val.create_posting === 1 ?
                              <div className="account-managmrnt-custom-radio-0" style={{ cursor: "pointer" }} onClick={() => Createposting(0, val.id)}>
                              </div>
                              :
                              <div className="account-managmrnt-custom-radio-1" style={{ cursor: "pointer" }} onClick={() => Createposting(1, val.id)} >
                              </div>
                          }

                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {
                            val.resume_info === 1 ?
                              <div className="account-managmrnt-custom-radio-0" style={{ cursor: "pointer" }} onClick={() => ResumeInfo(0, val.id)}>
                              </div>
                              :
                              <div className="account-managmrnt-custom-radio-1" style={{ cursor: "pointer" }} onClick={() => ResumeInfo(1, val.id)} >
                              </div>
                          }
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {
                            val.billing_info == 1 ?
                              <div className="account-managmrnt-custom-radio-0" style={{ cursor: "pointer" }} onClick={() => BillingInfo(0, val.id)}>
                              </div>
                              :
                              <div className="account-managmrnt-custom-radio-1" style={{ cursor: "pointer" }} onClick={() => BillingInfo(1, val.id)} >
                              </div>
                          }
                        </td>
                        <td>No Subscription</td>
                        <td style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}><Link data-toggle="modal" data-target="#exampleModal-add-2" to={`/edit-user?id=${val.id}`} className="tab-edit"><i className="fa fa-pencil-square-o" aria-hidden="true" /></Link><a className="tab-remove" data-toggle="modal" data-target="#exampleModal-remove-3" onClick={() => DeleteRole(val.id)}><i className="fa fa-trash" aria-hidden="true" /></a></td>
                      </tr>

                    </tbody>
                  )) :
                    <div className="d-flex w-100 aliign-items-center justify-content-center not-found px-5">
                      no Roles Created...
                    </div>
                }
              </table>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountManagement);

// code for scoop solutions