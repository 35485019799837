import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import InputMask from 'react-input-mask';
import Employernav from "../dropdownnav/Employernav";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const EmployeeBilingShowEdit = (props) => {
  const [BilingmodalIsOpen3, setBilingModalIsOpen3] = useState(false);
  const [BilingmodalIsOpenzip, setBilingModalIsOpenzip] = useState(false);
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [allArray, setallArray] = useState([])
  const [load, setload] = useState(false)
  const [bilingsid, setbillingid] = useState("")
  const [first_name, setfirst_name] = useState("")
  const [last_name, setlast_name] = useState("")
  const [company_name, setcompany_name] = useState("")
  const [billing_email, setbilling_email] = useState("")
  const [phone, setphone] = useState("")
  const [fax, setfax] = useState("")
  const [address, setaddress] = useState("")
  const [address_1, setaddress_1] = useState("")
  const [address_2, setaddress_2] = useState("")
  const [city, setcity] = useState("")
  const [state, setstate] = useState("")
  const [zip, setzip] = useState("")
  const [errzip, seterrzip] = useState(false)
  const statecorrect = (e) => {
    setzip(e)
    const dumbdata = e.split('')
    if (dumbdata.length < 5) {
      seterrzip(true)
    }
    else {
      seterrzip(false)
    }
  }

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)
    const ll = await getLatLng(result[0])
    setstate(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
    setaddress(value)
  }

  useEffect(() => {
    setload(true)
    // fetch(`${config['baseUrl']}/billings/getbyid/${localStorage.getItem("userid")}`, {
    //   method: 'GET',
    //   headers: { 'Content-Type': 'application/json', },
    // }).then(res => res.json()).then((response) => {
    //   setload(false)
    //   const alljobs = response
    //   const alljobss = response.billing
    //   var temp = []
    //   temp = alljobss
    //   setallArray([...temp])
    //   //
    //   var first = alljobs.billing.length > 0 ? alljobs.billing[0].first_name : ""
    //   setfirst_name(first)
    //   //
    //   var last = alljobs.billing.length > 0 ? alljobs.billing[0].last_name : ""
    //   setlast_name(last)
    //   //
    //   var comp = alljobs.billing.length > 0 ? alljobs.billing[0].company_name : ""
    //   setcompany_name(comp)
    //   //
    //   var ema = alljobs.billing.length > 0 ? alljobs.billing[0].billing_email : ""
    //   setbilling_email(ema)
    //   //
    //   var pho = alljobs.billing.length > 0 ? alljobs.billing[0].phone : ""
    //   setphone(pho)
    //   //
    //   var faxx = alljobs.billing.length > 0 ? alljobs.billing[0].fax : ""
    //   setfax(faxx)
    //   //
    //   var add1 = alljobs.billing.length > 0 ? alljobs.billing[0].address_1 : ""
    //   setaddress_1(add1)
    //   //
    //   var add2 = alljobs.billing.length > 0 ? alljobs.billing[0].address_2 : ""
    //   setaddress_2(add2)
    //   //
    //   var cit = alljobs.billing.length > 0 ? alljobs.billing[0].city : ""
    //   setcity(cit)
    //   //
    //   var sta = alljobs.billing.length > 0 ? alljobs.billing[0].state : ""
    //   setstate(sta)
    //   //
    //   var zz = alljobs.billing.length > 0 ? alljobs.billing[0].zip : ""
    //   setzip(zz)
    // }).catch((error) => {
    //   setload(false)
    //   console.log("error", error);

    // })
  }, []);
  const billingid = (e) => {
    setbillingid(e)
    setBilingModalIsOpen(true)
  }
  const updateBilling = async () => {
    const reg = /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z]+)\.(com+)$/;
    const zipreg = /^\d{0,}$/;
    if (reg.test(billing_email) === false) {
      setBilingModalIsOpen3(true)
    }
    else if (zipreg.test(zip) === false) {
      setBilingModalIsOpenzip(true)
    }
    else if (zip.length < 5) {
      setBilingModalIsOpenzip(true)
    }
    else {
      await fetch(`${config['baseUrl']}/billings/update`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "first_name": first_name,
          "last_name": last_name,
          "company_name": company_name,
          "billing_email": billing_email,
          "phone": phone,
          "fax": fax,
          "address_1": address_1,
          "address_2": address_2,
          "city": city,
          "state": state,
          "zip": zip,
          "id": bilingsid
        })
      }).then(res => {

        if (res.status !== 200 && res.status !== 201) {

        }
        return res.json();
      }).then((response) => {

        if (response.message == "The billing has been successfully Updated.") {
          // window.location = "/bilinginfo"
        }
        else {
          console.log("Something went wrong..")
        }

      }).catch((error) => {
        console.log(error)

      })
    }
  }
  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          {/* header======================================================================*/}
          <div className="main-content emp-account-owner-setting">
            <div className="d-flex align-items-center justify-content-start py-2">
              <h4 className="text-heading">Billing Information</h4>
            </div>

            {
              allArray && allArray.length > 0 ? allArray.map(bil => (
                <>
                  <div className="d-flex align-items-center justify-content-start py-2">
                    <p>Billing Info - <Link onClick={(e) => billingid(bil.id)} className="inner-edit fgh" id="myButton" type="submit" value="update"><i className="fa fa-pencil-square-o" aria-hidden="true" /> Edit</Link>
                    </p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">First Name:</label>
                    <input className="form-control employee-input-email job-search" id="firstname" aria-describedby="emailHelp" placeholder="First Name" type="text" defaultValue={bil.first_name !== "" && bil.first_name !== null && bil.first_name !== undefined ? bil.first_name : "First Name"} readOnly />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Last Name:</label>
                    <input className="form-control employee-input-email job-search" id="lastname" aria-describedby="emailHelp" placeholder="Last Name" type="text" defaultValue={bil.last_name !== "" && bil.last_name !== null && bil.last_name !== undefined ? bil.last_name : "Last Name"} readOnly />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Company Name:</label>
                    <input className="form-control employee-input-email job-search" id="c-name" aria-describedby="emailHelp" placeholder="Company Name" type="text" defaultValue={bil.company_name !== "" && bil.company_name !== null && bil.company_name !== undefined ? bil.company_name : "Company Name"} readOnly />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Billing Email:</label>
                    <input type="email" className="form-control employee-input-email job-search" id="billing-email" aria-describedby="emailHelp" placeholder="Billing Email" defaultValue={bil.billing_email !== "" && bil.billing_email !== null && bil.billing_email !== undefined ? bil.billing_email : "Billing Email"} readOnly />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Phone Number:</label>
                    <input type="email" className="form-control employee-input-email job-search" id="phone" aria-describedby="emailHelp" placeholder="(xxx) xxx xxx" defaultValue={bil.phone !== "" && bil.phone !== null && bil.phone !== undefined ? bil.phone : "(xxx) xxx xxx"} readOnly />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Fax:</label>
                    <input type="email" className="form-control employee-input-email job-search" id="fax" aria-describedby="emailHelp" placeholder="(xxx) xxx xxx" defaultValue={bil.fax !== "" && bil.fax !== null && bil.fax !== undefined ? bil.fax : "(xxx) xxx xxx"} readOnly />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Address Line 1:</label>
                    <input type="text" className="form-control employee-input-email job-search" id="address1" aria-describedby="emailHelp" placeholder="Address Line 1" defaultValue={bil.address_1 !== "" && bil.address_1 !== null && bil.address_1 !== undefined ? bil.address_1 : "Address Line 1:"} readOnly />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Address Line 2:</label>
                    <input type="text" className="form-control employee-input-email job-search" id="address2" aria-describedby="emailHelp" placeholder="Address Line 2" defaultValue={bil.address_2 !== "" && bil.address_2 !== null && bil.address_2 !== undefined ? bil.address_2 : "Address Line 2:"} readOnly />
                  </div>
                  <div className="form-group ghg">
                    <label htmlFor="exampleInputPassword1">City:   <asterisk style={{ color: "red" }}> *</asterisk ></label>
                    <PlacesAutocomplete
                      value={address.split(",")[0]}
                      onChange={setaddress}
                      onSelect={handleSelect}
                      searchOptions={{ componentRestrictions: { country: 'us' } }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            id="exampleInputPassword1"
                            className="form-control employee-input-email job-search"
                            {...getInputProps({
                              placeholder: 'City',
                              className: 'location-search-input',
                            })}
                          />
                          <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item p-2 --active'
                                : 'suggestion-item p-2 ';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">State</label>
                    <input type="text" className="form-control employee-input-email job-search" id="state" aria-describedby="emailHelp" placeholder="State" defaultValue={bil.state !== "" && bil.state !== null && bil.state !== undefined ? bil.state : "State"} readOnly />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Zip</label>
                    <input type="text" className="form-control employee-input-email job-search" id="zip" aria-describedby="emailHelp" placeholder="ZIP" defaultValue={bil.zip !== "" && bil.zip !== null && bil.zip !== undefined ? bil.zip : "Zip"} readOnly />
                  </div>
                </>
              )) : "No Billing Found"
            }
          </div>

        </div>
      </div>

      <Modal
        isOpen={BilingmodalIsOpen}
        onRequestClose={() => setBilingModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "850px",
            height: "567px",
            top: "calc(50% - 283px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        {
          allArray.length > 0 ? allArray.map(bil => (
            <>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className=" d-flex align-items-center justify-content-between py-2">
                    <h5>Edit Billing Contact Information</h5>
                  </div>
                  <div className=" d-flex align-items-center justify-content-between py-2">
                    <p className="m-0">Account information</p>
                  </div>
                  <div className="row mt-4">
                    <div className=" col-md-6">
                      <label htmlFor="exampleInputEmail1">First Name:</label>
                      <input type="text" onChange={(e) => setfirst_name(e.target.value)} defaultValue={bil.first_name !== "" && bil.first_name !== null && bil.first_name !== undefined ? bil.first_name : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" />
                    </div>
                    <div className=" col-md-6">
                      <label htmlFor="exampleInputEmail1">Last Name:</label>
                      <input type="text" onChange={(e) => setlast_name(e.target.value)} defaultValue={bil.last_name !== "" && bil.last_name !== null && bil.last_name !== undefined ? bil.last_name : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className=" col-md-6">
                      <label htmlFor="exampleInputEmail1">Company Name:</label>
                      <input type="text" onChange={(e) => setcompany_name(e.target.value)} defaultValue={bil.company_name !== "" && bil.company_name !== null && bil.company_name !== undefined ? bil.company_name : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Company Name" />
                    </div>
                    <div className=" col-md-6">
                      <label htmlFor="exampleInputEmail1">Billing Email:</label>
                      <input type="email" onChange={(e) => setbilling_email(e.target.value)} defaultValue={bil.billing_email !== "" && bil.billing_email !== null && bil.billing_email !== undefined ? bil.billing_email : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Billing Email" />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1">Phone Number:</label>
                      <InputMask {...props} mask="(999) 999-9999" defaultValue={bil.phone !== "" && bil.phone !== null && bil.phone !== undefined ? bil.phone : ""} className="form-control w-100 employee-input-email job-search w-100" onChange={(e) => setphone(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1">Fax:</label>
                      <InputMask {...props} mask="(999) 999-9999" defaultValue={bil.fax !== "" && bil.fax !== null && bil.fax !== undefined ? bil.fax : ""} className="form-control w-100 employee-input-email job-search w-100" onChange={(e) => setfax(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
                    </div>
                  </div>

                </div>
                <div className="col-lg-12 col-md-12 col-12 ">
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1">Address Line 1:</label>
                      <input type="text" onChange={(e) => setaddress_1(e.target.value)} defaultValue={bil.address_1 !== "" && bil.address_1 !== null && bil.address_1 !== undefined ? bil.address_1 : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Address Line 1" />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1">Address Line 2:</label>
                      <input type="text" onChange={(e) => setaddress_2(e.target.value)} defaultValue={bil.address_2 !== "" && bil.address_2 !== null && bil.address_2 !== undefined ? bil.address_2 : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Address Line 2" />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1">City:</label>
                      <input type="text" onChange={(e) => setcity(e.target.value)} defaultValue={bil.city !== "" && bil.city !== null && bil.city !== undefined ? bil.city : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="City" />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1">State</label><br />
                      <select onChange={(e) => setstate(e.target.value)} style={{ width: '60px !important', height: '36px' }} className="employee-input-ema w-100" name id
                        value={state}
                      >
                        {
                          bil.state !== "" && bil.state !== null && bil.state !== undefined ?
                            <option selected value={bil.state}>{bil.state}</option>
                            :
                            <option selected value="Select">Select</option>
                        }
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA	">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1">Zip</label>
                      <input type="text" onChange={(e) => statecorrect(e.target.value)} defaultValue={bil.zip !== "" && bil.zip !== null && bil.zip !== undefined ? bil.zip : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Zip" />
                      {errzip == true ? <small className="text-danger" style={{ color: "red" }}>*Zip Code requires at least five digits</small> : ""}
                    </div>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="main-content-session inner-session">
                    <a onClick={() => updateBilling()} className="send-invitation">SAVE</a>
                    <a onClick={() => setBilingModalIsOpen(false)} className="cancel-button text-light">Cancel</a>
                  </div>
                </div>
              </div>
            </>
          )) : "No Billing Found"
        }
      </Modal>


      <Modal
        isOpen={BilingmodalIsOpen3}
        onRequestClose={() => setBilingModalIsOpen3(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "240px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",
display:"flex",
            alignItems:"center",
            justifyContent:"center",
            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please enter a valid email address</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpen3(false)}>OK</Link>
          </div>

        </div>
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpenzip}
        onRequestClose={() => setBilingModalIsOpenzip(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please enter valid zip code</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpenzip(false)}>OK</Link>
          </div>

        </div>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeBilingShowEdit);


// code for scoop solutions