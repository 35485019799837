class subscription {
  constructor(init) {
    this.id = init?.id;
    this.user_id = init?.user_id;
    this.subscription_id = init?.subscription_id;
    this.email = init?.email;
    this.customer_id = init?.customer_id;
    this.status = init?.status;
    this.cancelled_at = init?.cancelled_at;
    this.subscribed_at = init?.subscribed_at;
    this.limit = init?.limit;
    this.usedAmount = init?.usedAmount;
    this.dueAmount = init?.dueAmount;
    this.isPayAsYouGo = init?.status === 0;
    this.isBasic = init?.status === 1;
    this.isAdvanced = init?.status === 2;
    this.isProfessional = init?.status === 3;
    this.previous_status = init?.previous_status;
    this.message = init?.message;
    this.max_status_purchased = init?.max_status_purchased;
  }
}

const initState = new subscription({
  id: "",
  user_id: "",
  subscription_id: "",
  customer_id: "",
  email: "",
  status: 0,
  cancelled_at: "",
  subscribed_at: "",
  limit: "",
  usedAmount: "",
  dueAmount: 0,
  previous_status: "",
  message: "",
  max_status_purchased:''
});

const SubscriptionInfo = (state = initState, { type, payload }) => {
  if (type === "SET_SUBSCRIPTION_INFO_REDUX") {
    return {
      ...new subscription({ ...state, ...payload }),
    };
  }

  return state;
};
export default SubscriptionInfo;
