import React from "react";
import EmployeeNav2 from "../components/employeenav2/Employeenav2";
import HomeFooter from "../components/homeFooter/HomeFooter";
import ManageCard from "../components/managecard";

const ManageCardPage = () => {
  return (
    <>
      <EmployeeNav2 />
      <ManageCard />
      <HomeFooter />
    </>
  );
};
export default ManageCardPage;

// code for scoop solutions
