const initState = {
    alljobs: {},
    loading: true,
    success: true
}

const AllJobsReducer = (state = initState, action) => {
    if (action.type === 'GET_JOBS') {
        return {
            ...state,
            alljobs: action.alljobs,
            loading: action.loading,
            success: action.success,
        }
    }

    return state;
}
export default AllJobsReducer;


// code for scoop solutions