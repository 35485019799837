import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import Employernav from "../dropdownnav/Employernav";
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const AboutUs = (props) => {
  const [allArray, setallArray] = useState([])
  const [load, setload] = useState(false)
  useEffect(() => {
    setload(true)
    fetch(`${config['baseUrl']}/about/get`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      setallArray([...allArray, alljobs])
    }).catch((error) => {
      setload(false)

    })
  }, []);

  return (
    <>
      {
        load == false ?
          <>
            <div className="container-fluid" id="Employee">
              <div className="container position-relative">
                {
                  props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.role !== null && props.GetUserReducer.users.role !== undefined && props.GetUserReducer.users.role !== "" && props.GetUserReducer.users.role !== 0 ?
                    <Employernav /> :
                    <Dropdownnav />
                }
                {/* header======================================================================*/}
                {
                  allArray && allArray.length > 0 ? allArray.map(val => (
                    val.job && val.job.length > 0 ? val.job.map(job => (
                      <div className="w-100 text-center main-content">
                        <h5>{job.heading_1 !== "" && job.heading_1 !== null && job.heading_1 !== undefined ? job.heading_1 : ""}</h5>
                        <div className="text-left">
                          <p>{job.description_1 !== "" && job.description_1 !== null && job.description_1 !== undefined ? job.description_1 : ""}</p>
                        </div>
                        <div className="row mt-5 align-items-center about-headings">
                          <div className="col-lg-8 col-md-6 col-12 text-left">
                            <h5 className="mb-0">{job.heading_2 !== "" && job.heading_2 !== null && job.heading_2 !== undefined ? job.heading_2 : ""}</h5>
                            <p>{job.description_2 !== "" && job.description_2 !== null && job.description_2 !== undefined ? job.description_2 : ""}</p>
                          </div>
                          <div className="col-lg-4 col-md-6 col-12">
                            <div className="about-images">
                              <img className="img-fluid h-100" src={job.image_1 !== "" && job.image_1 !== null && job.image_1 !== undefined ? job.image_1 : ""} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5 align-items-center">
                          <div className="col-lg-4 col-md-6 col-12">
                            <div className="about-images">
                              <img className="img-fluid h-100" src={job.image_2 !== "" && job.image_2 !== null && job.image_2 !== undefined ? job.image_2 : ""} alt="" />
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-6 col-12 text-left  about-headings">
                            <h5 className="mb-0">{job.heading_3 !== "" && job.heading_3 !== null && job.heading_3 !== undefined ? job.heading_3 : ""} </h5>
                            <p>{job.description_3 !== "" && job.description_3 !== null && job.description_3 !== undefined ? job.description_3 : ""}</p>
                          </div>
                        </div>
                      </div>
                    )) : <p style={{ color: "#f48a20" }}>Content Not Found</p>
                  )) : <p style={{ color: "#f48a20" }}>Content Not Found</p>
                }
              </div>
            </div>
          </>
          :
          <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);


// code for scoop solutions