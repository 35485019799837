import { AxiosGET } from "../libs/axios";

const config = require("../helpers/config.json");
export const setSubscriptionInfoRedux = (payload) => ({
  type: "SET_SUBSCRIPTION_INFO_REDUX",
  payload,
});

export const setSubscriptionInfoOldVersion = (userId) => {
  return async (dispatch) => {
    try {
      const { data } = await AxiosGET(
        `${config["baseUrl"]}/stripe/get-subscription/${localStorage.getItem(
          "userid"
        )}`
      );
      dispatch(setSubscriptionInfoRedux(data));
    } catch (error) {
      dispatch(setSubscriptionInfoRedux(error?.response?.data?.info));
    }
  };
};
