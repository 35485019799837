const initState = {
    alljobsbyid: {},
    loading: true,
    success: true
}

const JobsByIdReducer = (state = initState, action) => {
    if (action.type === 'GET_JOBSBYID') {
        return {
            ...state,
            alljobsbyid: action.alljobsbyid,
            loading: action.loading,
            success: action.success,
        }
    }

    return state;
}
export default JobsByIdReducer;

// code for scoop solutions