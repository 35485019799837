const initState = {
    Search: [],
    SearchResponse: null,
    searchData: [],
    loading: true,
}

const SearchReducer = (state = initState, action) => {

    if (action.type === 'GET_SEARCH') {
        return {
            ...state,
            Search: action.Search,
            searchData: action.searchData,
            SearchResponse: action.SearchResponse,
            loading: action.loading,
        }
    }
    // /// THESE ARE GENERAL APPLICABLE TO ALL API's
    else if (action.type === 'SEARCH_RESETT') {
        return {
            ...state,
            searchData: action.searchData,
            SearchResponse: null,
            loading: action.loading,

        }
    } else if (action.type === 'SEARCH_SUCCESSS') {
        return {
            ...state,
            searchData: action.searchData,
            SearchResponse: action.SearchResponse,
            loading: action.loading,
        }
    } else if (action.type === 'SEARCHL_FAILL') {
        return {
            ...state,
            searchData: [],
            SearchResponse: action.SearchResponse,
            loading: action.loading,
        }
    }

    return state;
}
export default SearchReducer;


// code for scoop solutions