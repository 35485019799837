import React from "react";

const NotifyDot = ({ customStyle = {} }) => {
  return (
    <sup>
      <div
        style={{
          height: "5px",
          width: "5px",
          borderRadius: "50%",
          background: "#f48a20",
          display: "inline-block",
          ...customStyle,
        }}
      ></div>
    </sup>
  );
};

export default NotifyDot;
