import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import { getUser } from "../../actions/Getuseraction";
import { connect } from "react-redux";
import FullPageLoader from "../fullpageloader/fullPageLoader";
import InputMask from 'react-input-mask';
import EmployerNav from "../dropdownnav/Employernav"
import useWindowDimensions from './innerwidthcom';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const AdministratorShow = (props) => {
  const { height, width } = useWindowDimensions();
  const [modal5, setmodal5] = useState(false)
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const [BilingmodalIsOpen3, setBilingModalIsOpen3] = useState(false);
  const [BilingmodalIsOpen4, setBilingModalIsOpen4] = useState(false);
  const [BilingmodalIsOpen1, setBilingModalIsOpen1] = useState(false);
  const [connewpassword, setconnewpassword] = useState("")
  const [newpassword, setnewpassword] = useState("")
  const [passwordType, setPasswordType] = useState("password");
  const [conpasswordType, setconPasswordType] = useState("password");
  const [oldpasswordType, setoldPasswordType] = useState("password");
  const [checkpass, setcheckpass] = useState(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const contogglePassword = () => {
    if (conpasswordType === "password") {
      setconPasswordType("text")
      return;
    }
    setconPasswordType("password")
  }
  const oldtogglePassword = () => {
    if (oldpasswordType === "password") {
      setoldPasswordType("text")
      return;
    }
    setoldPasswordType("password")
  }
  const [load, setload] = useState(false)
  const [first_name, setfirst_name] = useState("")
  const [last_name, setlast_name] = useState("")
  const [company_name, setcompany_name] = useState("")
  const [position_title, setposition_title] = useState("")
  const [city, setcity] = useState("")
  const [state, setstate] = useState("")
  const [zip_code, setzip_code] = useState("")
  const [phone, setphone] = useState("")
  const [email, setemail] = useState("")
  const [notok, setnotok] = useState(false)
  const [oldpassword, setoldpassword] = useState("")


  const ChangePassword = async () => {
    if (newpassword == "") {
      setnotok(true)
    } else if (checkpass.test(newpassword) === false) {
      setBilingModalIsOpen3(true)
    } else if (connewpassword == "") {
      setnotok(true)
    } else if (connewpassword !== newpassword) {
      setBilingModalIsOpen4(true)
    }
    else {
      await fetch(`${config['baseUrl']}/user/password/update`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "password": newpassword,
          "cpassword": oldpassword,
          "id": localStorage.getItem("userid"),
        })
      }).then(res => {

        if (res.status !== 200 && res.status !== 201) {
        }
        return res.json();
      }).then((response) => {

        if (response.message == "The User has been successfully Updated.") {
          window.location = "/company-administrator"
        }
        else if (response.message == "Password Doesnt Match.") {
          setBilingModalIsOpen2(true)
        }
        else {
        }

      }).catch((error) => {
        console.log(error)

      })
    }
  }

  const UpdateUser = async () => {
    await fetch(`${config['baseUrl']}/user/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "first_name": first_name !== "" ? first_name : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.first_name !== null && props.GetUserReducer.users.first_name !== undefined && props.GetUserReducer.users.first_name !== "" ? props.GetUserReducer.users.first_name : "",
        "last_name": last_name !== "" ? last_name : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.last_name !== null && props.GetUserReducer.users.last_name !== undefined && props.GetUserReducer.users.last_name !== "" ? props.GetUserReducer.users.last_name : "",
        "position_title": position_title !== "" ? position_title : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.position_title !== null && props.GetUserReducer.users.position_title !== undefined && props.GetUserReducer.users.position_title !== "" ? props.GetUserReducer.users.position_title : "",
        "company_name": company_name !== "" ? company_name : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.company_name !== null && props.GetUserReducer.users.company_name !== undefined && props.GetUserReducer.users.company_name !== "" ? props.GetUserReducer.users.company_name : "",
        "city": address.split(",")[0] !== "" ? address.split(",")[0] : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.city !== null && props.GetUserReducer.users.city !== undefined && props.GetUserReducer.users.city !== "" ? props.GetUserReducer.users.city : "",
        "state": state !== "" ? state : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.state !== null && props.GetUserReducer.users.state !== undefined && props.GetUserReducer.users.state !== "" ? props.GetUserReducer.users.state : "",
        "zip_code": zip_code !== "" ? zip_code : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.zip_code !== null && props.GetUserReducer.users.zip_code !== undefined && props.GetUserReducer.users.zip_code !== "" ? props.GetUserReducer.users.zip_code : "",
        "phone": phone !== "" ? phone : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.phone !== null && props.GetUserReducer.users.phone !== undefined && props.GetUserReducer.users.phone !== "" ? props.GetUserReducer.users.phone : "",
        "email": email !== "" ? email : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.email !== null && props.GetUserReducer.users.email !== undefined && props.GetUserReducer.users.email !== "" ? props.GetUserReducer.users.email : "",
        "latitude": corr.lat !== null && corr.lat !== undefined && corr.lat !== "" ? corr.lat : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.latitude !== null && props.GetUserReducer.users.latitude !== undefined && props.GetUserReducer.users.latitude !== "" ? props.GetUserReducer.users.latitude : "",
        "longitude": corr.lng !== null && corr.lng !== undefined && corr.lng !== "" ? corr.lng : props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.longitude !== null && props.GetUserReducer.users.longitude !== undefined && props.GetUserReducer.users.longitude !== "" ? props.GetUserReducer.users.longitude : "",
        "id": localStorage.getItem("userid"),
      })
    }).then(res => {

      return res.json();
    }).then((response) => {

      if (response.message == "The User has been successfully Updated.") {
        window.location = "/company-administrator"
      }
      else {
        setmodal5(true)
      }

    }).catch((error) => {
      console.log(error)

    })

  }
  const [modalwidth, setmodalwidth] = useState(400)
  const [modalwidth21, setmodalwidth21] = useState(850)
  useEffect(() => {
    if (width <= 900) {
      setmodalwidth("340px")
      setmodalwidth21("340px")
    }
    // loaduser()
  }, []);


  
  const loaduser = async () => {
    await props.getUser()
  }
  const [address, setaddress] = useState(props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.city !== null && props.GetUserReducer.users.city !== undefined && props.GetUserReducer.users.city !== "" ? props.GetUserReducer.users.city : "")
  const [corr, setcorr] = useState({
    lat: null,
    lng: null
  })
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)

    const getZipCode = result?.[0]?.address_components?.find(
      (component) => component?.types?.includes('postal_code')
      )?.long_name;
      if(getZipCode){
        setzip_code(getZipCode)
      }

    const ll = await getLatLng(result[0])
    setstate(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
    setaddress(value)
    setcorr(ll)
  }

  useEffect(()=>{
    if(!BilingmodalIsOpen)
      setaddress(props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.city !== null && props.GetUserReducer.users.city !== undefined && props.GetUserReducer.users.city !== "" ? props.GetUserReducer.users.city : "")
  },[BilingmodalIsOpen])

  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container">
          <EmployerNav />
          {/* header======================================================================*/}
          <div className="main-content emp-account-owner-setting">
            <div className="d-flex align-items-center justify-content-start py-2">
              <h5>Company Administrator Settings - <Link onClick={() => setBilingModalIsOpen(true)} className="inner-edit fgh"><i className="fa fa-pencil-square-o" aria-hidden="true" /> Edit</Link>
              </h5>
            </div>
            <div className="w-50">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">First Name:</label>
                  <input
                    value={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.first_name !== null && props.GetUserReducer.users.first_name !== undefined && props.GetUserReducer.users.first_name !== "" ? props.GetUserReducer.users.first_name : ""}
                    type="text" readOnly className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Last Name:</label>
                  <input
                    value={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.last_name !== null && props.GetUserReducer.users.last_name !== undefined && props.GetUserReducer.users.last_name !== "" ? props.GetUserReducer.users.last_name : ""}
                    type="text" readOnly className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Last Name" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Company Name:</label>
                  <input
                    value={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.company_name !== null && props.GetUserReducer.users.company_name !== undefined && props.GetUserReducer.users.company_name !== "" ? props.GetUserReducer.users.company_name : ""}
                    type="text" readOnly className="form-control employee-input-email job-search" id="exampleInputPassword1" placeholder="Company Name" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email:</label>
                  <input
                    value={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.email !== null && props.GetUserReducer.users.email !== undefined && props.GetUserReducer.users.email !== "" ? props.GetUserReducer.users.email : ""}
                    type="email" readOnly className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Mobile Phone:</label>
                  <input
                    value={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.phone !== null && props.GetUserReducer.users.phone !== undefined && props.GetUserReducer.users.phone !== "" ? props.GetUserReducer.users.phone : ""}
                    type="text" readOnly className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="(xxx) xxx-xxxx" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Zip Code:</label>
                  <input
                    value={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.zip_code !== null && props.GetUserReducer.users.zip_code !== undefined && props.GetUserReducer.users.zip_code !== "" ? props.GetUserReducer.users.zip_code : ""}
                    maxLength={5} type="text" readOnly className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="zip code" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">City:</label>
                  <input
                    value={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.city !== null && props.GetUserReducer.users.city !== undefined && props.GetUserReducer.users.city !== "" ? props.GetUserReducer.users.city : ""}
                    type="text" readOnly className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="city" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">State:</label>
                  <input
                    value={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.state !== null && props.GetUserReducer.users.state !== undefined && props.GetUserReducer.users.state !== "" ? props.GetUserReducer.users.state : ""}
                    type="text" readOnly className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="state" />
                </div>
                <div className="main-content-session inner-session">
                  <a onClick={() => setBilingModalIsOpen1(true)} className="send-invitation">Change Password</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      {!!BilingmodalIsOpen && <Modal
        isOpen={BilingmodalIsOpen}
        onRequestClose={() => setBilingModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth21,
            height: "567px",
            top: "calc(50% - 283px)",
            left: "0",
            right: "0",
            bottom: "100px",
            background: "#fff",
            outline: "none",
            padding: "20px",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >

        <>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className=" d-flex align-items-center justify-content-between py-2">
                <h5>Edit Company Administrator Information</h5>
              </div>
              <div className="row mt-4">
                <div className=" col-md-6">
                  <label htmlFor="exampleInputEmail1">First Name:</label>
                  <input type="text" onChange={(e) => setfirst_name(e.target.value)} defaultValue={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.first_name !== null && props.GetUserReducer.users.first_name !== undefined && props.GetUserReducer.users.first_name !== "" ? props.GetUserReducer.users.first_name : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" />
                </div>
                <div className=" col-md-6">
                  <label htmlFor="exampleInputEmail1">Last Name:</label>
                  <input type="text" onChange={(e) => setlast_name(e.target.value)} defaultValue={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.last_name !== null && props.GetUserReducer.users.last_name !== undefined && props.GetUserReducer.users.last_name !== "" ? props.GetUserReducer.users.last_name : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last Name" />
                </div>
              </div>
              <div className="row mt-4">
                <div className=" col-md-6">
                  <label htmlFor="exampleInputEmail1">Company Name:</label>
                  <input type="text" onChange={(e) => setcompany_name(e.target.value)} defaultValue={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.company_name !== null && props.GetUserReducer.users.company_name !== undefined && props.GetUserReducer.users.company_name !== "" ? props.GetUserReducer.users.company_name : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Company Name" />
                </div>
                <div className=" col-md-6">
                  <label htmlFor="exampleInputEmail1">Position Title:</label>
                  <input type="email" onChange={(e) => setposition_title(e.target.value)} defaultValue={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.position_title !== null && props.GetUserReducer.users.position_title !== undefined && props.GetUserReducer.users.position_title !== "" ? props.GetUserReducer.users.position_title : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Billing Email" />
                </div>
              </div>
              <div className="row mt-4">
                <div className=" col-md-6">
                  <label htmlFor="exampleInputEmail1">Email:</label>
                  <input type="email" onChange={(e) => setemail(e.target.value)} defaultValue={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.email !== null && props.GetUserReducer.users.email !== undefined && props.GetUserReducer.users.email !== "" ? props.GetUserReducer.users.email : ""} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Billing Email" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="exampleInputEmail1">Mobile Number:</label>
                  <InputMask {...props} mask="(999) 999-9999" onChange={(e) => setphone(e.target.value)} defaultValue={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.phone !== null && props.GetUserReducer.users.phone !== undefined && props.GetUserReducer.users.phone !== "" ? props.GetUserReducer.users.phone : ""} className="form-control w-100 employee-input-email job-search w-100" maskChar=" " placeholder="(xxx) xxx-xxxx" />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12 ">
              <div className="row mt-4">
                <div className="col-md-6">
                  <label htmlFor="exampleInputEmail1">City:</label>
                  <PlacesAutocomplete
                    value={address.split(",")[0]}
                    onChange={setaddress}
                    onSelect={handleSelect}
                    searchOptions={{ componentRestrictions: { country: 'us' } }}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <input
                          id="exampleInputPassword1"
                          style={{ height: 40, width: "100%" }}
                          className="form-control employee-input-email job-search w-25"
                          {...getInputProps({
                            placeholder: 'City',
                            className: 'location-search-input',
                          })}
                        />
                        <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item p-2 --active'
                              : 'suggestion-item p-2 ';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="col-md-6">
                  <label htmlFor="exampleInputEmail1">State</label><br />
                  <select onChange={(e) => setstate(e.target.value)} style={{ width: '60px !important', height: '40px' }} className="employee-input-ema w-100" name id
                    value={state}
                  >
                    <option selected value={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.state !== null && props.GetUserReducer.users.state !== undefined && props.GetUserReducer.users.state !== "" ? props.GetUserReducer.users.state : "Select"}>{props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.state !== null && props.GetUserReducer.users.state !== undefined && props.GetUserReducer.users.state !== "" ? props.GetUserReducer.users.state : "Select"}</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA	">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <label htmlFor="exampleInputEmail1">Zip</label>
                  <input type="text" onChange={(e) => setzip_code(e.target.value)} defaultValue={props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.zip_code !== null && props.GetUserReducer.users.zip_code !== undefined && props.GetUserReducer.users.zip_code !== "" ? props.GetUserReducer.users.zip_code : zip_code} className="form-control w-100 employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Zip" />
                </div>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="main-content-session inner-session">
                <a onClick={() => UpdateUser()} className="send-invitation">SAVE</a>
                <a onClick={() => setBilingModalIsOpen(false)} className="send-invitation ml-3">Cancel</a>
              </div>
            </div>
          </div>
        </>
        {/* Footer Ends */}
      </Modal>
}


      <Modal
        isOpen={BilingmodalIsOpen1}
        onRequestClose={() => setBilingModalIsOpen1(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth21,
            height: "410px",
            top: "calc(50% - 205px)",
            left: "0",
            right: "0",
            bottom: "100px",
            background: "#fff",
            outline: "none",
            padding: "20px",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >

        <>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className=" d-flex align-items-center justify-content-between py-2">
                <h5>Change Password</h5>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="d-flex flex-column">
                    <label htmlFor="exampleInputPassword1">Old Password:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                    <div style={{ position: "relative" }}>
                      <input
                        onChange={(e) => setoldpassword(e.target.value)}
                        id="password-field4" type={oldpasswordType} value={oldpassword} className="employee-input-email change-passwod-modal-input job-search" name="password" placeholder="Old Password" />
                      <span toggle="#password-field4" onClick={oldtogglePassword}> {oldpasswordType === "password" ? <i className={checkpass.test(oldpassword) === true ? "fa fa-eye-slash field-icon pl-lg-5 toggle-password-change" : "fa fa-eye-slash field-icon pl-lg-5 toggle-password-change"} ></i> : <i className={checkpass.test(oldpassword) === true ? "fa fa-eye field-icon toggle-password-change pl-lg-4" : "fa fa-eye field-icon toggle-password-change pl-lg-5"}></i>}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="d-flex flex-column">
                    <label htmlFor="exampleInputPassword1">New Password:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small>  {checkpass.test(newpassword) === true ? <span style={{ color: "black" }}></span> : <span style={{ color: "black" }}><br />Password must contain at least 8 characters, both capital and lower case letters, at least one number and one special character (!@#$%^&*)</span>}</label>
                    <div style={{ position: "relative" }}>
                      <input
                        onChange={(e) => setnewpassword(e.target.value)}
                        id="password-field4" type={passwordType} value={newpassword} className="employee-input-email job-search change-passwod-modal-input" name="password" placeholder="Create Password" />
                      <span toggle="#password-field4" onClick={togglePassword}> {passwordType === "password" ? <i className={checkpass.test(newpassword) === true ? "fa fa-eye-slash field-icon pl-lg-5 toggle-password-change" : "fa fa-eye-slash field-icon pl-lg-5 toggle-password-change"} ></i> : <i className={checkpass.test(newpassword) === true ? "fa fa-eye field-icon toggle-password-change pl-lg-4" : "fa fa-eye field-icon toggle-password-change pl-lg-5 "}></i>}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="d-flex flex-column">
                    <label htmlFor="exampleInputPassword1">Confirm New Password:    <small className="text-danger" style={{ fontSize: "15px" }}>*</small></label>
                    <div style={{ position: "relative" }}>
                      <input
                        onChange={(e) => setconnewpassword(e.target.value)}
                        id="password-field5" type={conpasswordType} value={connewpassword} className="employee-input-email job-search change-passwod-modal-input" name="password" placeholder="Confirm Password" />
                      <span toggle="#password-field5" onClick={contogglePassword}> {conpasswordType === "password" ? <i className="fa fa-eye-slash field-icon toggle-password-change pl-lg-5"></i> : <i className="fa fa-eye field-icon toggle-password-change pl-lg-5"></i>}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="main-content-session inner-session">
                <a onClick={() => ChangePassword()} className="send-invitation">SAVE</a>
                <a onClick={() => setBilingModalIsOpen1(false)} className="send-invitation ml-3">Cancel</a>
              </div>
            </div>
          </div>
        </>
        {/* Footer Ends */}
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpen2}
        onRequestClose={() => setBilingModalIsOpen2(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h4 className="text-center">Old password doesn't Match.</h4>
          <div className="d-flex align-items-center">
            <button onClick={() => setBilingModalIsOpen2(false)} className="employeer-sign-off-btn">Ok</button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpen3}
        onRequestClose={() => setBilingModalIsOpen3(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h5>Password must contain at least 8 characters, both capital and lower case letters, at least one number and one special character (!@#$%^&*)</h5>
          <div className="d-flex align-items-center">
            <button onClick={() => setBilingModalIsOpen3(false)} className="employeer-sign-off-btn mt-2">Ok</button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpen4}
        onRequestClose={() => setBilingModalIsOpen4(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h5>Confirm password didn't match</h5>
          <div className="d-flex align-items-center">
            <button onClick={() => setBilingModalIsOpen4(false)} className="employeer-sign-off-btn">Ok</button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal5}
        onRequestClose={() => setmodal5(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "210px",
            top: "calc(50% - 105px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>Please complete all fields to continue</h4>
          <div className="d-flex  align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setmodal5(false)}>OK</Link>
          </div>

        </div>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: () =>
    dispatch(getUser()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdministratorShow);

// code for scoop solutions