import React, { useState, useEffect, useRef } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ReportJobAction } from "../../actions/Reportjobaction";
import { ResumeCreate } from "../../actions/Resumeaction"
import axios from "axios";
import dayjs from "dayjs";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import { useLocation } from "react-router-dom";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import InputMask from 'react-input-mask';
import PlacesAutocomplete from 'react-places-autocomplete';
import useWindowDimensions from '../administrator/innerwidthcom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DatePicker from "../datePicker";
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const Updateresume = (props) => {
  const [discon, setdiscon] = useState(false)
  const [description, setdescription] = useState("")
  const [overcompany, setovercompany] = useState("")
  const [datafalse, setdatafalse] = useState(true)
  const [modaleditedu, setmodaleditedu] = useState(false)
  const CharacterCountany = (e) => {
    if (e.length > 0) {
      setduties(e)
      if (e.length > 2500) {
        var text1 = duties
        text1.substring(0, 2500)
        setduties(text1)
      }
    }
    else {
      setduties(e)
    }
  }
  const CharacterCount3 = (e) => {
    setdatafalse(false)
    if (e.length > 0) {
      setdiscon(true)
      setdescription(e)
      setovercompany(e.length)
      if (e.length > 2500) {
        var text = description
        text.substring(0, 2500)
        setdescription(text)
      }
    }
    else {
      setdiscon(false)
      setdescription(e)
      setovercompany(e.length)
    }
  }
  const [BilingmodalIsOpenzip, setBilingModalIsOpenzip] = useState("")
  const [load, setload] = useState(false)
  const [top100Films, settop100Films] = useState([])
  const [allArray, setallArray] = useState([])
  const [load1, setload1] = useState(false)
  const [modal3, setmodal3] = useState(false)
  const [modal2, setmodal2] = useState(false)
  const [modalduties, setmodalduties] = useState(false)
  const [modal4, setmodal4] = useState(false)


  const search = useLocation().search;
  const nameii = new URLSearchParams(search).get('id');

  const PERSONALINFO = useRef(null)
  const PROFESSIONALSUMMARY = useRef(null)
  const EDUCATION = useRef(null)
  const WORKEXPERIENCE = useRef()
  const MILITARY = useRef(null)
  const ADDITIONAL = useRef(null)
  const VOLLUNTEER = useRef(null)

  const [filearray, setfilearray] = useState([])


  useEffect(async () => {
    setTimeout(
      () => {
        if (nameii == "PERSONALINFO") {
          PERSONALINFO?.current?.scrollIntoView()
        }
        if (nameii == "PROFESSIONALSUMMARY") {
          PROFESSIONALSUMMARY?.current?.scrollIntoView()
        }
        if (nameii == "EDUCATION") {
          EDUCATION?.current?.scrollIntoView()
        }
        if (nameii == "WORKEXPERIENCE") {
          WORKEXPERIENCE?.current?.scrollIntoView()
        }
        if (nameii == "MILITARY") {
          MILITARY?.current?.scrollIntoView()
        }
        if (nameii == "ADDITIONAL") {
          ADDITIONAL?.current?.scrollIntoView()
        }
        if (nameii == "VOLLUNTEER") {
          VOLLUNTEER?.current?.scrollIntoView()
        }
      },
      3000
    )
    setload(true)
    // await fetch(`${config['baseUrl']}/institude/get`, {
    //   method: 'GET',
    //   headers: { 'Content-Type': 'application/json', },
    // }).then(res => res.json()).then((response) => {
    //   setload(false)
      // const alljobs = response.institude
      // settop100Films(alljobs)
    // }).catch((error) => {
    //   setload(false)
    //   console.log("error", error);
    //   // 
    // })
    await fetch(`${config['baseUrl']}/get/uploadsbyuser/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response.uploadedFiles
      setfilearray(...filearray, alljobs)
    }).catch((error) => {
      setload(false)
      console.log("error", error);
      // 
    })
    setload1(true)
    await fetch(`${config['baseUrl']}/resume/getbyid/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload1(false)
      const alljobs = response
      var temp = []
      temp = [...response]
      setallArray([...temp])
      seteduarray(...eduarray, alljobs[0].education)
      setlinkarray(...linkarray, alljobs[0].resumelinks)
      setexparray(...exparray, alljobs[0].experience)
      if (alljobs[0].branch && alljobs[0].branch.length > 0 && alljobs[0].branch[0].branch !== "" && alljobs[0].branch[0].branch !== null && alljobs[0].branch[0].branch !== undefined) {
        setbrancharray(...brancharray, alljobs[0].branch)
      }
      if (alljobs[0].mos && alljobs[0].mos.length > 0 && alljobs[0].mos[0].mos !== "" && alljobs[0].mos[0].mos !== null && alljobs[0].mos[0].mos !== undefined) {
        setmosarray(...mosarray, alljobs[0].mos)
      }
      setlangarray(...langarray, alljobs[0].language)
      setcertificationarray(...certificationarray, alljobs[0].certification)
      setawardarray(...awardarray, alljobs[0].award)
      setvolunteerarray(...volunteerarray, alljobs[0].volunteer)
      setreferencearray(...referencearray, alljobs[0].reference)
      var add = alljobs[0].job[0].city
      setaddress(add)
      alljobs[0].military && alljobs[0].military.length > 0 && alljobs[0].military[0].country !== "" ? setMilitaryYes("yes") : setMilitaryYes("no")
      setemail(alljobs[0].job[0].email)
      if (alljobs.length > 0) {
        setdescription(alljobs[0].job[0].professional_summary)
        setovercompany(alljobs[0].job[0].professional_summary.length)
      }

      setfirst_name(alljobs[0].job[0].first_name)
      setlast_name(alljobs[0].job[0].last_name)
      setcity(alljobs[0].job[0].city)
      setstate(alljobs[0].job[0].state)
      setemployment_eligibility(alljobs[0].job[0].employment_eligibility)
      setzipcode(alljobs[0].job[0].zipcode)
      setprofessional_summary(alljobs[0].job[0].professional_summary)
      setprofessional_summarycount(alljobs[0].job[0].professional_summary.length)

    }).catch((error) => {
      console.log("error", error);

      setload1(false)
    })
  }, []);



  const [currentlyEnrolled, setCurrentlyEnrolled] = useState(false)
  const [stillEmployed, setStillEmployed] = useState(false)
  const [militaryYes, setMilitaryYes] = useState("no")
  const [currentlyVolunteer, setCurrentlyVolunteer] = useState(false)
  const [provideReference, setProvideReference] = useState(false)


  const [first_name, setfirst_name] = useState("")
  const [middle_name, setmiddle_name] = useState("")
  const [last_name, setlast_name] = useState("")
  const [city, setcity] = useState("")
  const [state, setstate] = useState("")
  const [zipcode, setzipcode] = useState("")
  const [email, setemail] = useState("")
  const [contact_number, setcontact_number] = useState("")
  const [add_contact_number, setadd_contact_number] = useState("")
  const [employment_eligibility, setemployment_eligibility] = useState("Authorized to work in the USA for any employer")


  const [errzip, seterrzip] = useState(false)
  const statecorrect = (e) => {
    setzipcode(e)
    const dumbdata = e.split('')
    if (dumbdata.length < 5) {
      seterrzip(true)
    }
    else {
      seterrzip(false)
    }
  }
  // education states
  const [eduarray, seteduarray] = useState([])
  const [level_of_education, setlevel_of_education] = useState("None")
  const [school, setschool] = useState("")
  const [degree, setdegree] = useState("")
  const [major, setmajor] = useState("")
  const [cityedu, setcityedu] = useState("")
  const [date_of_completion, setdate_of_completion] = useState("")
  const [date_of_completionReq, setdate_of_completionReq] = useState(false)

  const AddMoreEducation = () => {
    if (level_of_education=="") {
      setemptyerror7(true)
    }
    if (degree=="") {
      setemptyerror19(true)
    }
    if (major=="") {
      setemptyerror20(true)
    }
    if (school=="") {
      setemptyerror22(true)
    }
    if (cityedu=="") {
      setemptyerror23(true)
    } 

    if(level_of_education && degree && major && cityedu && school) {
      if (currentlyEnrolled == true) {
        eduarray?.push({
            level_of_education: level_of_education,
            school: school,
            degree: degree,
            major: major,
            city: cityedu,
            date_of_completion: currentlyEnrolled == false ? date_of_completion : "Currently enrolled"
          })
        seteduarray(eduarray)
        setlevel_of_education("")
        setschool("")
        setdegree("")
        setmajor("")
        setcityedu("")
        setdate_of_completion("")
        setediteduarray([])
        seteditschool("")
      } else {
          eduarray?.push({
            level_of_education: level_of_education,
            school: school,
            degree: degree,
            major: major,
            city: cityedu,
            date_of_completion: currentlyEnrolled == false ? date_of_completion : "Currently enrolled"
          })
          seteduarray(eduarray)
          setlevel_of_education("")
          setschool("")
          setdegree("")
          setmajor("")
          setcityedu("")
          setdate_of_completion("")
          setediteduarray([])
          seteditschool("")
      }
    }
  }
  const deleteEdu = (e) => {
    var newedu = eduarray
    newedu.splice(e, 1)
    seteduarray([...newedu])
  }
  const [editeduarray, setediteduarray] = useState([])
  const [editschool, seteditschool] = useState("")
  const editEdu = (e) => {
    if (editeduarray.length > 0) {
      setmodaleditedu(true)
    }
    else {
      editeduarray.push(eduarray[e])
      setediteduarray(editeduarray)
      setlevel_of_education(editeduarray[0].level_of_education)
      setschool(editeduarray[0].school)
      seteditschool(editeduarray[0].school)
      setdegree(editeduarray[0].degree)
      setmajor(editeduarray[0].major)
      setcityedu(editeduarray[0].city)
      setdate_of_completion(editeduarray[0].date_of_completion)
      setCurrentlyEnrolled(editeduarray[0].date_of_completion == "Currently enrolled" ? true : false)
      var newedu = eduarray
      newedu.splice(e, 1)
      seteduarray([...newedu])
    }
  }

  // links
  const [linkarray, setlinkarray] = useState([])
  const [slink, setslink] = useState("")


  const AddMoreLinks = () => {
    if (slink !== "") {
      linkarray.push({
        link: slink
      })
      setlinkarray(linkarray)
      setslink("")
    }
    else {
      console.log("Filled All Fields...")
    }
  }
  const deleteLinks = (e) => {
    var newlinks = linkarray
    newlinks.splice(e, 1)
    setlinkarray([...newlinks])
  }

  // work experience
  const [exparray, setexparray] = useState([])
  
  const [job_title, setjob_title] = useState("")
  const [job_titleErr, setjob_titleErr] = useState("")
  
  const [company, setcompany] = useState("")
  const [companyErr, setcompanyErr] = useState("")
  
  const [cityexp, setcityexp] = useState("")
  const [cityexpErr, setcityexpErr] = useState("")
  
  const [stateexp, setstateexp] = useState("")
  const [stateexpErr, setstateexpErr] = useState("")
  
  const [time_period_start, settime_period_start] = useState("")
  const [time_period_startErr, settime_period_startErr] = useState("")
   
  const [time_period_end, settime_period_end] = useState("")
  const [time_period_endErr, settime_period_endErr] = useState("")

  const [duties, setduties] = useState("")
  const [dutiesErr, setDutiesErr] = useState("")

  const AddMoreExperience = () => {
    let requiredMsg = "Please complete this field"
    let currentDate = new Date().getTime()
    let startDate = new Date(time_period_start).getTime()
    let endDate = new Date(time_period_end).getTime()

    if(job_title === ""||company === ""||cityexp === ""||stateexp === ""||time_period_start === ""||startDate>currentDate||!stillEmployed&&!time_period_end||duties === ""||startDate > endDate){
      if(job_title === ""){setjob_titleErr(requiredMsg)}
      if(company === ""){setcompanyErr(requiredMsg)}
      if(cityexp === ""){setcityexpErr(requiredMsg)}
      if(stateexp === ""){setstateexpErr(requiredMsg)}
      if(time_period_start === ""){settime_period_startErr(requiredMsg)}
      if(startDate>currentDate){settime_period_startErr("Start date can't be in future")}
      if(!stillEmployed&&!time_period_end){settime_period_endErr(requiredMsg)}
      if(duties === ""){setDutiesErr(requiredMsg)}
      if(startDate > endDate ){
        settime_period_startErr("Start date can't be greater then end data")
        settime_period_endErr("End date can't be less then start data")
      }
    }else{
      if (stillEmployed == false) {
          exparray.push({
            job_title: job_title,
            company: company,
            city: cityexp,
            state: stateexp,
            time_period_start,
            time_period_end: stillEmployed == false ? time_period_end : "Still currently employed here",
            duties: duties
          })
          setexparray(exparray)
          setjob_title("")
          setcompany("")
          setcityexp("")
          setstateexp("")
          settime_period_start("")
          settime_period_end("")
          setduties("")
          seteditexparray([])
        } else if (stillEmployed == true) {
          exparray.push({
            job_title: job_title,
            company: company,
            city: cityexp,
            state: stateexp,
            time_period_start,
            time_period_end: stillEmployed == false ? time_period_end : "Still currently employed here",
            duties: duties
          })
          setexparray(exparray)
          setjob_title("")
          setcompany("")
          setcityexp("")
          setstateexp("")
          settime_period_start("")
          settime_period_end("")
          setduties("")
          seteditexparray([])
      }
    }
  }

  const deleteExp = (e) => {
    var newexp = exparray
    newexp.splice(e, 1)
    setexparray([...newexp])

  }
  const [editexparray, seteditexparray] = useState([])
  const editExp = (e) => {
    if (editexparray.length > 0) {
      setmodaleditedu(true)
    }
    else {
      editexparray.push(exparray[e])
      seteditexparray(editexparray)
      setjob_title(editexparray[0].job_title)
      setcompany(editexparray[0].company)
      setcityexp(editexparray[0].city)
      setstateexp(editexparray[0].state)
      setduties(editexparray[0].duties)
      settime_period_start(editexparray[0].time_period_start)
      settime_period_end(editexparray[0].time_period_end)
      setStillEmployed(editexparray[0].time_period_end == "Still currently employed here" ? true : false)
      var newexp = exparray
      newexp.splice(e, 1)
      setexparray([...newexp])
    }
  }

  // professional summery
  const [professional_summary, setprofessional_summary] = useState("")
  const [professional_summarycount, setprofessional_summarycount] = useState("")
  const CharacterCount = (e) => {
    setprofessional_summary(e.target.value)
    setprofessional_summarycount(e.target.value.length)
  }


  // military
  const [militaryarray, setmilitaryarray] = useState([])
  const [country, setcountry] = useState("")
  const [branch, setbranch] = useState("")
  const [mos, setmos] = useState("")
  const [rank, setrank] = useState("")
  const [start_date, setstart_date] = useState("")
  const [end_date, setend_date] = useState("")
  const [security_clearance, setsecurity_clearance] = useState("")

  const AddMoreMilitary = () => {
    militaryarray.push({
      country: country,
      branch: branch,
      mos: mos,
      rank: rank,
      start_date: start_date,
      end_date: end_date,
      security_clearance: security_clearance
    })
    setmilitaryarray(militaryarray)
    setcountry("")
    setbranch("")
    setmos("")
    setrank("")
    setstart_date("")
    setend_date("")
    setsecurity_clearance("")
  }


  // references
  const [referencearray, setreferencearray] = useState([])
  const [name, setname] = useState("")
  const [contact_numberref, setcontact_numberref] = useState("")
  const [relationship, setrelationship] = useState("")
  const [emailref, setemailref] = useState("")

  const AddMoreReference = () => {
    if (contact_numberref !== "") {
      referencearray.push({
        name: name,
        contact_number: contact_numberref,
        relationship: relationship,
        email: emailref
      })
      setreferencearray(referencearray)
      setcontact_numberref("")
      setstart("")
      setname("")
      setrelationship("")
      setemailref("")
    }
    else {
      setmodal4(true)
    }
  }
  const deleteRef = (e) => {
    var newref = referencearray
    newref.splice(e, 1)
    setreferencearray([...newref])
  }


  // volunteer
  const [volunteerarray, setvolunteerarray] = useState([])
  const [title, settitle] = useState("")
  const [start, setstart] = useState("")
  const [end, setend] = useState("")

  const AddMoreVolunteer = () => {
    if (title !== "" && start !== "") {
      if (currentlyVolunteer == false) {
        if (title !== "" && start !== "" && start < end && (start < new Date().toISOString().substr(0, 10) || start == new Date().toISOString().substr(0, 10)) && (end < new Date().toISOString().substr(0, 10) || end == new Date().toISOString().substr(0, 10))) {
          volunteerarray.push({
            title: title,
            start: start,
            end: currentlyVolunteer == false ? end : "I currently volunteer here"
          })
          setvolunteerarray(volunteerarray)
          settitle("")
          setstart("")
          setend("")
        }
        else {
          setmodal3(true)
        }
      }
      else if (currentlyVolunteer == true) {
        if (title !== "" && start !== "" && (start < new Date().toISOString().substr(0, 10) || start == new Date().toISOString().substr(0, 10))) {
          volunteerarray.push({
            title: title,
            start: start,
            end: currentlyVolunteer == false ? end : "I currently volunteer here"
          })
          setvolunteerarray(volunteerarray)
          settitle("")
          setstart("")
          setend("")
        }
        else {
          setmodal3(true)
        }
      }
    }
    else {
      setmodal2(true)
    }
  }
  const deleteVol = (e) => {
    var newvol = volunteerarray
    newvol.splice(e, 1)
    setvolunteerarray([...newvol])
  }

  // award
  const [awardarray, setawardarray] = useState([])
  const [titleaward, settitleaward] = useState("")
  const [dateaward, setdateaward] = useState("")

  const AddMoreAward = () => {
    if (titleaward !== "" && dateaward !== "") {
      awardarray.push({
        title: titleaward,
        date: dateaward
      })
      setawardarray(awardarray)
      settitleaward("")
      setdateaward("")
    }
    else {
      console.log("Please complete all of the required fields to continue")
    }
  }
  const deleteAward = (e) => {
    var newaward = awardarray
    newaward.splice(e, 1)
    setawardarray([...newaward])
  }

  // Branch
  const [brancharray, setbrancharray] = useState([])

  const AddMoreBranch = () => {
    if (branch !== "") {
      brancharray.push({
        branch: branch,
      })
      setbrancharray(brancharray)
      setbranch("")
    }
    else {
      console.log("enter branch of service")
    }
  }
  const deleteBranch = (e) => {
    var newbranch = brancharray
    newbranch.splice(e, 1)
    setbrancharray([...newbranch])
  }

  // MOS
  const [mosarray, setmosarray] = useState([])

  const AddMoreMOS = () => {
    if (mos !== "" && mos !== null && mos !== undefined) {
      mosarray.push({
        mos: mos,
      })
      setmosarray(mosarray)
      setmos("")
    }
    else {
      console.log("Enter MOS")
    }
  }
  const deleteMOS = (e) => {
    var newmos = mosarray
    newmos.splice(e, 1)
    setmosarray([...newmos])
  }

  // certification
  const [certificationarray, setcertificationarray] = useState([])
  const [titlecertification, settitlecertification] = useState("")
  const [datecertification, setdatecertification] = useState("")

  const AddMoreCertification = () => {
    if (titlecertification !== "" && datecertification !== "") {
      certificationarray.push({
        title: titlecertification,
        date: datecertification
      })
      setcertificationarray(certificationarray)
      settitlecertification("")
      setdatecertification("")
    }
    else {
      console.log("Please complete all of the required fields to continue")
    }
  }
  const deleteCert = (e) => {
    var newcer = certificationarray
    newcer.splice(e, 1)
    setcertificationarray([...newcer])
  }


  // language
  const [langarray, setlangarray] = useState([])
  const [language, setlanguage] = useState("")
  const [level, setlevel] = useState("Basic")
  const AddMoreLanguage = () => {
    if (language !== "" && language !== null && language !== undefined) {
      langarray.push({
        lang: language,
        level: level
      })
      setlangarray(langarray)
      setlanguage("")
      setlevel("")
    }
    else {
      console.log("Please complete all of the required fields to continue")
    }
  }
  const deleteLang = (e) => {
    var newlang = langarray
    newlang.splice(e, 1)
    setlangarray([...newlang])
  }
  const handleFile = async (e) => {
    var filesize = ((e.size / 1024) / 1024).toFixed(4);
    if (filesize < 6) {
      setload(true);
      let formData = new FormData();
      formData.append("file", e);
      axios
        .post(`${config["baseUrl"]}/fileupload/${localStorage.getItem("userid")}`, formData, {
          mode: "no-cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            Accept: "form-data",
          },
        }).then(response => {
          setload(false);
          if (response.result !== "") {
            setload(true);
            // setTimeout(() => {
            //   setload(false)
            // }, 4000);
            let formData = new FormData();
            formData.append("file_name", e);
            axios
              .post(`https://api.superparser.com/parse`, formData, {
                mode: "no-cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                  Accept: "form-data",
                  "X-API-Key": "ZWDPIaELTFaR7QcLJJ6fcIsftMMjrOz6rM2pzE25"
                }
              }).then(response => {
                if (Object.keys(response.data).length > 0) {
                  setfirst_name(response.data.data.personal_details_top_heading !== null && response.data.data.personal_details_top_heading !== undefined && response.data.data.personal_details_top_heading !== "" ? response.data.data.personal_details_top_heading.split("\n").length > 0 ? response.data.data.personal_details_top_heading.split("\n")[0].split(" ")[0] : "" : "")
                  setlast_name(response.data.data.personal_details_top_heading !== null && response.data.data.personal_details_top_heading !== undefined && response.data.data.personal_details_top_heading !== "" ? response.data.data.personal_details_top_heading.split("\n").length > 0 ? response.data.data.personal_details_top_heading.split("\n")[0].split(" ")[1] : "" : "")
                  setemail(response.data.data.email[0].email !== null && response.data.data.email[0].email !== undefined && response.data.data.email[0].email !== "" ? response.data.data.email[0].email : "")
                  setcontact_number(response.data.data.phone[0].phone !== null && response.data.data.phone[0].phone !== undefined && response.data.data.phone[0].phone !== "" ? response.data.data.phone[0].phone : "")
                  setstate(response.data.data.personal_details_top_heading !== null && response.data.data.personal_details_top_heading !== undefined && response.data.data.personal_details_top_heading !== "" ? response.data.data.personal_details_top_heading.split(",").lenght > 0 ? response.data.data.personal_details_top_heading.split(",")[1].split("\n")[0] : "" : "")
                  if (response.data.data.education !== null && response.data.data.education !== undefined && response.data.data.education.length > 0) {
                    response.data.data.education.forEach(data => {
                      eduarray.push({
                        level_of_education: data.degree !== "" && data.degree !== null && data.degree !== undefined ? data.degree : "",
                        school: data.institute !== "" && data.institute !== null && data.institute !== undefined ? data.institute : "",
                        degree: data.degree !== "" && data.degree !== null && data.degree !== undefined ? data.degree : "",
                        major: data.course !== "" && data.course !== null && data.course !== undefined ? data.course : "",
                        city: data.institute !== "" && data.institute !== null && data.institute !== undefined ? data.institute.split(',').length > 0 ? data.institute.split(',')[1] !== null && data.institute.split(',')[1] !== undefined ? data.institute.split(',')[1] : "" : "" : "",
                        date_of_completion: `${data.to_year !== "" && data.to_year !== null && data.to_year !== undefined ? data.to_year : "Currently enrolled"}${data.to_month !== "" && data.to_month !== null && data.to_month !== undefined ? "-" : ""}${data.to_month !== "" && data.to_month !== null && data.to_month !== undefined ? data.to_month > 9 ? data.to_month : `0${data.to_month}` : ""}`
                      })
                      seteduarray(eduarray)
                    })
                  }
                  if (response.data.data.employer !== null && response.data.data.employer !== undefined && response.data.data.employer.length > 0) {
                    response.data.data.employer.forEach(data => {
                      exparray.push({
                        job_title: data.role !== "" && data.role !== null && data.role !== undefined ? data.role : "",
                        company: data.company_name !== "" && data.company_name !== null && data.company_name !== undefined ? data.company_name : "",
                        city: data.address !== "" && data.address !== null && data.address !== undefined ? data.address.city !== null && data.address.city !== undefined && data.address.city !== "" ? data.address.city : "" : "",
                        state: data.address !== "" && data.address !== null && data.address !== undefined ? data.address.country_code !== null && data.address.country_code !== undefined && data.address.country_code !== "" ? data.address.country_code : "" : "",
                        time_period_start: `${data.from_year !== "" && data.from_year !== null && data.from_year !== undefined ? data.from_year : ""}${data.from_month !== "" && data.from_month !== null && data.from_month !== undefined ? "-" : ""}${data.from_month !== "" && data.from_month !== null && data.from_month !== undefined ? data.from_month > 9 ? data.from_month : `0${data.from_month}` : ""}`,
                        time_period_end: `${data.to_year !== "" && data.to_year !== null && data.to_year !== undefined ? data.to_year : "Still currently employed here"}${data.to_month !== "" && data.to_month !== null && data.to_month !== undefined ? "-" : ""}${data.to_month !== "" && data.to_month !== null && data.to_month !== undefined ? data.to_month > 9 ? data.to_month : `0${data.to_month}` : ""}`,
                        duties: data.description !== "" && data.description !== null && data.description !== undefined ? data.description : ""
                      })
                      setexparray(exparray)
                    })
                  }
                  setdescription(response.data.data.profile_summary !== null && response.data.data.profile_summary !== undefined && response.data.data.profile_summary !== "" ? response.data.data.profile_summary : "")
                  setdatafalse(response.data.data.profile_summary !== null && response.data.data.profile_summary !== undefined && response.data.data.profile_summary !== "" ? false : true)
                  setload(false);
                }
              })
          }
          else {
            alert("Resume Pattern not found..")
            setload(false);
          }
        })
        .catch((error) => {
          setload(false);
          console.log("ddd", error);
        });
    }
    else {
      console.log("please select less than 6mb file")
    }
  };

  const [emptyerror1, setemptyerror1] = useState(false)
  const [emptyerror2, setemptyerror2] = useState(false)
  const [emptyerror3, setemptyerror3] = useState(false)
  const [emptyerror4, setemptyerror4] = useState(false)
  const [emptyerror5, setemptyerror5] = useState(false)
  const [emptyerror6, setemptyerror6] = useState(false)
  const [emptyerror7, setemptyerror7] = useState(false)
  const [emptyerror19, setemptyerror19] = useState(false)
  const [emptyerror20, setemptyerror20] = useState(false)
  const [emptyerror22, setemptyerror22] = useState(false)
  const [emptyerror23, setemptyerror23] = useState(false)
  const [emptyerror100, setemptyerror100] = useState(false)
  const [emptyerror8, setemptyerror8] = useState(false)
  const [emptyerror9, setemptyerror9] = useState(false)
  const [emptyerror10, setemptyerror10] = useState(false)
  const [emptyerror11, setemptyerror11] = useState(false)
  const [emptyerror12, setemptyerror12] = useState(false)
  const [emptyerror13, setemptyerror13] = useState(false)
  const [emptyerror14, setemptyerror14] = useState(false)
  const [emptyerror15, setemptyerror15] = useState(false)
  const [emptyerror21, setemptyerror21] = useState(false)
  const [emptyerror17, setemptyerror17] = useState(false)
  const [emptyerror18, setemptyerror18] = useState(false)

  const sendresume = async () => {

    if (stillEmployed === true && new Date(time_period_start).getTime() < new Date().getTime()) {
      if (exparray.length > 0 && job_title !== "" && job_title !== undefined && job_title !== null && company !== "" && company !== undefined && company !== null && duties !== "" && duties.length < 2501) {

        exparray.push({
          job_title: job_title,
          company: company,
          city: cityexp,
          state: stateexp,
          time_period_start: time_period_start,
          time_period_end: stillEmployed == false ? time_period_end : "Still currently employed here",
          duties: duties
        })
        setexparray(exparray)
      }
    }
    if (stillEmployed == false && (new Date(time_period_start).getTime() < new Date(time_period_end).getTime() &&  new Date(time_period_end).getTime()<new Date().getTime())) {
      if (exparray.length > 0 && job_title !== "" && job_title !== undefined && job_title !== null && company !== "" && company !== undefined && company !== null && duties !== "" && duties.length < 2501) {

        exparray.push({
          job_title: job_title,
          company: company,
          city: cityexp,
          state: stateexp,
          time_period_start: time_period_start,
          time_period_end: stillEmployed == false ? time_period_end : "Still currently employed here",
          duties: duties
        })
        setexparray(exparray)
      }
    }
    if (first_name == "") {
      setmodal2(true)
      setemptyerror1(true)
    }
    if (last_name == "") {
      setmodal2(true)
      setemptyerror2(true)
    }
    if (address == "") {
      setmodal2(true)
      setemptyerror3(true)
    }
    if (state == "") {
      setmodal2(true)
      setemptyerror4(true)
    }
    if (employment_eligibility == "") {
      setmodal2(true)
      setemptyerror5(true)
    }
    if (email == "") {
      setmodal2(true)
      setemptyerror6(true)
    }
    if (level_of_education == "" && eduarray.length <= 0) {
      setmodal2(true)
      setemptyerror7(true)
    }
    else if (degree == "" && eduarray.length <= 0) {
      setmodal2(true)
      setemptyerror19(true)
    }
    else if (major == "" && eduarray.length <= 0) {
      setmodal2(true)
      setemptyerror20(true)
    }
    else if (cityedu == "" && eduarray.length <= 0) {
      setmodal2(true)
      setemptyerror22(true)
    }
    if (job_title == "" && exparray.length <= 0) {
      setmodal2(true)
      setemptyerror8(true)
    }
    if (company == "" && exparray.length <= 0) {
      setmodal2(true)
      setemptyerror9(true)
    }
    if (cityexp == "" && exparray.length <= 0) {
      setmodal2(true)
      setemptyerror10(true)
    }
    if (stateexp == "" && exparray.length <= 0) {
      setmodal2(true)
      setemptyerror11(true)
    }
    if (time_period_start == "" && exparray.length <= 0) {
      setmodal2(true)
      setemptyerror12(true)
    }
    if (exparray.length <= 0 && time_period_end == "" && stillEmployed == undefined) {
      setmodal2(true)
      setemptyerror12(true)
    }
    if (militaryYes == "yes" && branch == "" && brancharray.length <= 0) {
      setmodal2(true)
      setemptyerror14(true)
    }
    if (militaryYes == "yes" && mos == "" && mosarray.length <= 0) {
      setmodal2(true)
      setemptyerror15(true)
    }
    if (militaryYes == "yes" && country == "" && mosarray.length <= 0) {
      setmodal2(true)
      setemptyerror21(true)
    }

    if (militaryYes == "yes" && security_clearance == "" && mosarray.length <= 0) {
      setmodal2(true)
      setemptyerror17(true)
    }
    if (name == !"" && contact_numberref === "") {
      setmodal2(true)
      setemptyerror18(true)
    }

    if (awardarray.length > 0 && titleaward !== "" && titleaward !== undefined && titleaward !== null) {
      awardarray.push({
        title: titleaward,
        date: dateaward
      })
      setawardarray(awardarray)
    }
    if (linkarray.length > 0 && slink !== "" && slink !== undefined && slink !== null) {

      linkarray.push({
        link: slink
      })
      setlinkarray(linkarray)
    }
    if (certificationarray.length > 0 && titlecertification !== "" && titlecertification !== undefined && titlecertification !== null) {

      certificationarray.push({
        title: titlecertification,
        date: datecertification
      })
      setcertificationarray(certificationarray)
    }
    if (eduarray.length > 0 && school !== "" && school !== undefined && school !== null) {

      eduarray.push({
        level_of_education: level_of_education,
        school: school,
        degree: degree,
        major: major,
        city: cityedu,
        date_of_completion: currentlyEnrolled == false ? date_of_completion : "Currently enrolled"
      })
      seteduarray(eduarray)
    }
    if (langarray.length > 0 && language !== "" && language !== undefined && language !== null) {

      langarray.push({
        lang: language,
        level: level
      })
      setlangarray(langarray)
    }
    if (volunteerarray.length > 0 && title !== "" && title !== undefined && title !== null) {

      volunteerarray.push({
        title: title,
        start: start,
        end: currentlyVolunteer == false ? end : "I currently volunteer here"
      })
      setvolunteerarray(volunteerarray)
    }
    if (referencearray.length > 0 && contact_numberref !== "" && contact_numberref !== undefined && contact_numberref !== null) {
      referencearray.push({
        name: name,
        contact_number: contact_numberref,
        relationship: relationship,
        email: emailref
      })
      setreferencearray(referencearray)
    }
    if (militaryarray.length > 0 && branch !== "" && branch !== undefined && branch !== null && country !== "" && country !== undefined && country !== null) {

      militaryarray.push({
        country: country,
        branch: branch,
        mos: mos,
        rank: rank,
        start_date: start_date,
        end_date: end_date,
        security_clearance: security_clearance
      })
      setmilitaryarray(militaryarray)
    }
    if (brancharray.length > 0 && branch !== "" && branch !== undefined && branch !== null) {

      brancharray.push({
        branch: branch,
      })
      setbrancharray(brancharray)
    }
    if (mosarray.length > 0 && mos !== "" && mos !== undefined && mos !== null) {

      mosarray.push({
        mos: mos,
      })
      setbrancharray(brancharray)
    }
    if (first_name == "") {
      setemptyerror1(true)
    }
    else if (last_name == "" && allArray[0].job[0].last_name == "") {
      setemptyerror2(true)
    }
    else if (address == "" && allArray[0].job[0].city == "") {
      setemptyerror3(true)
    }
    else if (state == "" && allArray[0].job[0].state == "") {
      setemptyerror4(true)
    }
    else if (zipcode !== "" && allArray[0].job[0].zipcode !== "" && zipcode.length < 5) {
      setBilingModalIsOpenzip(true)
    }
    else if (email == "") {
      setemptyerror6(true)
    }
    else if (employment_eligibility == "" && allArray[0].job[0].employment_eligibility == "") {
      setemptyerror5(true)
    }
    else if (level_of_education == "" && eduarray.length <= 0) {
      setemptyerror7(true)
    }
    else if (setdegree == "" && eduarray.length <= 0) {
      setemptyerror19(true)
    }
    else if (setmajor == "" && eduarray.length <= 0) {
      setemptyerror20(true)
    }
    else if (setcityedu == "" && eduarray.length <= 0) {
      setemptyerror22(true)
    }
    else if (description.length > 2500) {
      setemptyerror100(true)
    }
    else if (job_title == "" && exparray.lenght <= 0) {
      setemptyerror8(true)
    }
    else if (duties == "" && job_title !== "") {
      setmodalduties(true)
    }
    else if (duties !== "" && job_title !== "" && duties.length > 2500) {
      setmodalduties(true)
    }
    else if (company == "" && exparray.lenght <= 0) {
      setemptyerror9(true)
    }
    else if (company == "" && job_title !== "") {
      setemptyerror9(true)
    }
    else if (cityexp == "" && exparray.lenght <= 0) {
      setemptyerror10(true)
    }
    else if (cityexp == "" && job_title !== "") {
      setemptyerror10(true)
    }
    else if (stateexp == "" && exparray.lenght <= 0) {
      setemptyerror11(true)
    }
    else if (stateexp == "" && job_title !== "") {
      setemptyerror11(true)
    }
    else if (time_period_start == "" && exparray.lenght <= 0) {
      setemptyerror12(true)
    }
    else if (time_period_start == "" && job_title !== "") {
      setemptyerror12(true)
    }
    else if (exparray.length <= 0 && time_period_end == "" && stillEmployed == false) {
      setemptyerror12(true)
    }
    else if (time_period_end == "" && stillEmployed == false && job_title !== "") {
      setemptyerror12(true)
    }
    else if (militaryYes == "yes" && branch == "" && brancharray.length <= 0) {
      setemptyerror14(true)
    }
    else if (militaryYes == "yes" && mos == "" && mosarray.length <= 0) {
      setemptyerror15(true)
    }
    else if (militaryYes == "yes" && country == "" && mosarray.length <= 0) {
      setemptyerror21(true)
    }
    else if (militaryYes == "yes" && security_clearance == "" && mosarray.length <= 0) {
      setemptyerror17(true)
    }
    else if (stillEmployed == false && (new Date(time_period_start).getTime() > new Date(time_period_end).getTime())) {
      setmodal3(true)
    }
    else if (stillEmployed == true && (new Date(time_period_start).getTime() > new Date().getTime())) {
      setmodal3(true)
    }

    else {
      militaryYes == "yes" ?
        await props.ResumeCreate(
          first_name,
          middle_name !== "" ? middle_name : allArray[0].job[0].middle_name,
          last_name !== "" ? last_name : allArray[0].job[0].last_name,
          address !== "" ? address.split(",")[0] : allArray[0].job[0].city,
          corr.lat !== "" && corr.lat !== null && corr.lat !== undefined ? corr.lat : allArray[0].job[0].latitude,
          corr.lng !== "" && corr.lng !== null && corr.lng !== undefined ? corr.lng : allArray[0].job[0].longitude,
          state !== "" ? state : allArray[0].job[0].state,
          zipcode !== "" ? zipcode : allArray[0].job[0].zipcode,
          email,
          contact_number,
          add_contact_number,
          employment_eligibility !== "" ? employment_eligibility : allArray[0].job[0].employment_eligibility,
          datafalse !== true ? description : allArray && allArray.length > 0 && allArray[0].job && allArray[0].job.length > 0 && allArray[0].job[0].professional_summary !== "" ? allArray[0].job[0].professional_summary : "",
          awardarray.length > 0 ? awardarray : titleaward !== "" && dateaward !== "" ? [{
            title: titleaward,
            date: dateaward
          }] : [],
          linkarray.length > 0 ? linkarray : slink !== "" ? [{
            link: slink
          }] : [],
          certificationarray.length > 0 ? certificationarray : titlecertification !== "" && datecertification !== "" ? [{
            title: titlecertification,
            date: datecertification
          }] : [],
          eduarray.length > 0 ? eduarray : level_of_education !== "" ? [{
            level_of_education: level_of_education,
            school: school,
            degree: degree,
            major: major,
            city: cityedu,
            date_of_completion: currentlyEnrolled == false ? date_of_completion : "Currently enrolled"
          }] : [],
          exparray.length > 0 ? exparray : job_title !== "" ? [{
            job_title: job_title,
            company: company,
            city: cityexp,
            state: stateexp,
            time_period_start: time_period_start ,
            time_period_end: stillEmployed == false ? time_period_end  : "Still currently employed here",
            duties: duties
          }] : [],
          langarray.length > 0 ? langarray : language !== "" && level !== "" ? [{
            lang: language,
            level: level
          }] : [],
          volunteerarray.length > 0 ? volunteerarray : title !== "" ? [{
            title: title,
            start: start,
            end: currentlyVolunteer == false ? end : "I currently volunteer here"
          }] : [],
          referencearray.length > 0 ? referencearray : name !== "" ? [{
            name: name,
            contact_number: contact_numberref,
            relationship: relationship,
            email: emailref
          }] : [],
          militaryarray.length > 0 ? militaryarray : [{
            country: country !== "" ? country : allArray[0].military && allArray[0].military.length > 0 ? allArray[0].military[0].country !== null && allArray[0].military[0].country !== undefined && allArray[0].military[0].country !== "" ? allArray[0].military[0].country : "" : "",
            military_service: militaryYes?.toLowerCase() === "yes" ? 1 : 0,
            rank: rank !== "" ? rank : allArray[0].military && allArray[0].military.length > 0 ? allArray[0].military[0].rank !== null && allArray[0].military[0].rank !== undefined && allArray[0].military[0].rank !== "" ? allArray[0].military[0].rank : "" : "",
            start_date: start_date !== "" ? start_date : allArray[0].military && allArray[0].military.length > 0 ? allArray[0].military[0].start_date !== null && allArray[0].military[0].start_date !== undefined && allArray[0].military[0].start_date !== "" ? allArray[0].military[0].start_date : "" : "",
            end_date: end_date !== "" ? end_date : allArray[0].military && allArray[0].military.length > 0 ? allArray[0].military[0].end_date !== null && allArray[0].military[0].end_date !== undefined && allArray[0].military[0].end_date !== "" ? allArray[0].military[0].end_date : "" : "",
            security_clearance: security_clearance !== "" ? security_clearance : allArray[0].military && allArray[0].military.length > 0 ? allArray[0].military[0].security_clearance !== null && allArray[0].military[0].security_clearance !== undefined && allArray[0].military[0].security_clearance !== "" ? allArray[0].military[0].security_clearance : "" : ""
          }],
          brancharray.length > 0 ? brancharray : branch !== "" ? [{
            branch: branch,
          }] : [],
          mosarray.length > 0 ? mosarray : mos !== "" ? [{
            mos: mos,
          }] : []
        )
        :
        await props.ResumeCreate(
          first_name,
          middle_name !== "" ? middle_name : allArray[0].job[0].middle_name,
          last_name !== "" ? last_name : allArray[0].job[0].last_name,
          address !== "" ? address.split(",")[0] : allArray[0].job[0].city,
          corr.lat !== "" && corr.lat !== null && corr.lat !== undefined ? corr.lat : allArray[0].job[0].latitude,
          corr.lng !== "" && corr.lng !== null && corr.lng !== undefined ? corr.lng : allArray[0].job[0].longitude,
          state !== "" ? state : allArray[0].job[0].state,
          zipcode !== "" ? zipcode : allArray[0].job[0].zipcode,
          email,
          contact_number,
          add_contact_number,
          employment_eligibility !== "" ? employment_eligibility : allArray[0].job[0].employment_eligibility,
          datafalse !== true ? description : allArray && allArray.length > 0 && allArray[0].job && allArray[0].job.length > 0 && allArray[0].job[0].professional_summary !== "" ? allArray[0].job[0].professional_summary : "",
          awardarray.length > 0 ? awardarray : titleaward !== "" && dateaward !== "" ? [{
            title: titleaward,
            date: dateaward
          }] : [],
          linkarray.length > 0 ? linkarray : slink !== "" ? [{
            link: slink
          }] : [],
          certificationarray.length > 0 ? certificationarray : titlecertification !== "" && datecertification !== "" ? [{
            title: titlecertification,
            date: datecertification
          }] : [],
          eduarray.length > 0 ? eduarray : level_of_education !== "" ? [{
            level_of_education: level_of_education,
            school: school,
            degree: degree,
            major: major,
            city: cityedu,
            date_of_completion: currentlyEnrolled == false ? date_of_completion : "Currently enrolled"
          }] : [],
          exparray.length > 0 ? exparray : job_title !== "" ? [{
            job_title: job_title,
            company: company,
            city: cityexp,
            state: stateexp,
            time_period_start: time_period_start ,
            time_period_end: stillEmployed == false ? time_period_end  : "Still currently employed here",
            duties: duties
          }] : [],
          langarray.length > 0 ? langarray : language !== "" && level !== "" ? [{
            lang: language,
            level: level
          }] : [],
          volunteerarray.length > 0 ? volunteerarray : title !== "" ? [{
            title: title,
            start: start,
            end: currentlyVolunteer == false ? end : "I currently volunteer here"
          }] : [],
          referencearray.length > 0 ? referencearray : name !== "" ? [{
            name: name,
            contact_number: contact_numberref,
            relationship: relationship,
            email: emailref
          }] : [],
          [],
          [],
          []
        )
    }

    setname("")
    setrelationship("")
    setemailref("")
    setcontact_numberref("")

    setlevel_of_education("")
    setschool("")
    setdegree("")
    setmajor("")
    setcityedu("")
    setdate_of_completion("")
  }

  const [address, setaddress] = useState("")
  const [corr, setcorr] = useState({
    lat: null,
    lng: null
  })
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)

    const getZipCode = result?.[0]?.address_components?.find(
      (component) => component?.types?.includes('postal_code')
      )?.long_name;
      if(getZipCode){
        statecorrect(getZipCode)
      }

    const ll = await getLatLng(result[0])
    setstate(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
    setaddress(value)
    setcorr(ll)
  }
  const handleSelectExp = async (value) => {
    const result = await geocodeByAddress(value)
    setcityexp(value)
    setstateexp(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
  }

  const [order, setorder] = useState("ASC")
  const [orderaward, setorderaward] = useState("ASC")
  const [ordercertification, setordercertification] = useState("ASC")
  const [ordervolunteer, setordervolunteer] = useState("ASC")
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...eduarray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      seteduarray(sorted)
      setorder("DSC")
    }
    if (order === "DSC") {
      const sorted = [...eduarray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      seteduarray(sorted)
      setorder("ASC")
    }

  }
  const sorting1 = (col) => {
    if (order === "ASC") {
      const sorted = [...exparray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setexparray(sorted)
      setorder("DSC")
    }
    if (order === "DSC") {
      const sorted = [...exparray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setexparray(sorted)
      setorder("ASC")
    }

  }
  const sortingaward = (col) => {
    if (orderaward === "ASC") {
      const sorted = [...awardarray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setawardarray(sorted)
      setorderaward("DSC")
    }
    if (orderaward === "DSC") {
      const sorted = [...awardarray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setawardarray(sorted)
      setorderaward("ASC")
    }

  }
  const sortingcertification = (col) => {
    if (ordercertification === "ASC") {
      const sorted = [...certificationarray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setcertificationarray(sorted)
      setordercertification("DSC")
    }
    if (ordercertification === "DSC") {
      const sorted = [...certificationarray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setcertificationarray(sorted)
      setordercertification("ASC")
    }

  }
  const sortingvolunteer = (col) => {
    if (ordervolunteer === "ASC") {
      const sorted = [...volunteerarray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setvolunteerarray(sorted)
      setordervolunteer("DSC")
    }
    if (ordervolunteer === "DSC") {
      const sorted = [...volunteerarray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setvolunteerarray(sorted)
      setordervolunteer("ASC")
    }

  }

  const { height, width } = useWindowDimensions();

  const [modalwidth, setmodalwidth] = useState(450)

  const getUniversityNames = () => {
    axios.get(`${config['baseUrl']}/institude/get`, {
      method: 'GET',
      headers:{
        "Content-Type": "application/json"
      },
      params:{school}
    }).then((res) =>{
      const schoolsName = res?.data?.data?.map((name) => name.name)
      settop100Films(schoolsName)
    }).catch((err) => {
        console.log(err, "error")
    })
  }

  let ref;
  let lastTime;
  const timeBasedDebounce = (func, timer) => {
    if (ref) {
      clearTimeout(ref);
      ref = null;
      timer = lastTime - Date.now();
    }
    ref = setTimeout(() => {
      func();
      lastTime = 0;
      ref = null;
    }, timer);
    lastTime = Date.now() + timer;
  };

  useEffect(() => {
    if (width <= 900) {
      setmodalwidth("340px")
    }
  }, []);
  return (
    <>
      {
        props.ResumeReducer.loading == true && load !== true ?
          <>
            {
              allArray && allArray.length > 0 ? allArray.map(value => (
                <div className="container-fluid">
                  <div className="container">
                    <Dropdownnav />
                    {/* header======================================================================*/}
                    <div className="create-resume-main main-content p-5 ">
                      <form>
                        <h5 onClick={() => {
                        }}>Upload Resume</h5>

                        <label className="upload-label"> Select File
                          <input type="file" accept=".pdf,.doc,.docx,.rft,.text,.txt,.pdf" onChange={(e) => handleFile(e.target.files[0])} />
                        </label>
                        <label htmlFor="exampleInputEmail1">.doc, .docx, .rft, .text or .pdf (5 MB limit)</label><br />
                        {
                          filearray.length > 0 ?
                            <a href={`${config["baseUrl"]}${filearray[0].filepath}`} download={true} target="blank" className="mb-2">View uploaded file <br /></a>
                            : ""
                        }
                        <div>
                          <label htmlFor="exampleInputEmail1"><asterisk style={{ color: "red" }}> *</asterisk > Required Fields</label><br />
                        </div>
                        <div id="personal-info">
                          <h5 ref={PERSONALINFO} className="mb-2 mt-3">Personal Information</h5>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">First Name <asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <input type="text"
                              onChange={(e) => {
                                setemptyerror1(false)
                                setfirst_name(e.target.value)
                              }}
                              maxLength={150}
                              defaultValue={first_name !== "" ? first_name : value.job[0].first_name}
                              className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" />
                            {emptyerror1 == true ? <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> : ""}

                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Middle Initial <span className="text-dark"> (Optional)</span></label>
                            <input type="text"
                              onChange={(e) => setmiddle_name(e.target.value)}
                              defaultValue={middle_name !== "" ? middle_name : value.job[0].middle_name}
                              className="form-control employee-input-email job-search" id="exampleInputEmail1" maxLength={1} aria-describedby="emailHelp" placeholder="Middle Name" />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Last Name <asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <input type="text"
                              onChange={(e) => {
                                setemptyerror2(false)
                                setlast_name(e.target.value)
                              }}
                              maxLength={150}
                              defaultValue={last_name !== "" ? last_name : value.job[0].last_name}
                              className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last Name" />
                            {emptyerror2 == true ? <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> : ""}
                          </div>
                        </div>
                        <div className="form-group" style={{ position: "relative" }}>
                          <label htmlFor="exampleInputEmail1">City <asterisk style={{ color: "red" }}> *</asterisk ></label>
                          <PlacesAutocomplete
                            value={address.split(",")[0]}
                            onChange={setaddress}
                            onSelect={handleSelect}
                            searchOptions={{ componentRestrictions: { country: 'us' } }}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <input
                                  id="exampleInputPassword1"
                                  className="city-input-bug form-control employee-input-email job-search w-25"
                                  {...getInputProps({
                                    placeholder: 'City',
                                    className: 'location-search-input',
                                  })}
                                />
                                {emptyerror3 == true ? <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> : ""}

                                <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? 'suggestion-item p-2 --active'
                                      : 'suggestion-item p-2 ';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span onClick={() => { setemptyerror3(false) }}>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                        <div style={{ width: '300px', display: 'flex' }}>
                          <div className="col-md-6 p-0">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">State <asterisk style={{ color: "red" }}> *</asterisk ></label>
                              <select onChange={(e) => {
                                setemptyerror4(false)
                                setstate(e.target.value)
                              }} style={{ width: '60px !important', height: '40px', borderRadius: "8px !important" }} className="employee-input-ema" name id
                                value={state}
                              >
                                <option value={state !== "" ? state : value.job[0].state}>{state !== "" ? state : value.job[0].state}</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA	">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                              {emptyerror4 == true ? <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> : ""}
                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Zip Code:</label>
                              <input
                                onChange={(e) => statecorrect(e.target.value)}
                                maxLength={20}
                                defaultValue={value.job[0].zipcode}
                                value={zipcode||value.job[0].zipcode}
                                style={{ width: '60px !important', height: '40px', borderRadius: "8px !important" }} type="text" className="form-control employee-input-ema job-search" id="exampleInput1" placeholder="Zip" />
                              {errzip == true ? <small className="text-danger" style={{ color: "red" }}>*Zip Code requires at least five digits</small> : ""}
                            </div>
                          </div>
                        </div>
                        <div className="main-content-session">
                          <label htmlFor="exampleInputEmail1">Employment Eligibility <asterisk style={{ color: "red" }}> *</asterisk></label>
                          <div className="role role2">
                            <label>
                              {value.job[0].employment_eligibility == "Authorized to work in the USA for any employer" ?
                                <input
                                  onChange={(e) => {
                                    setemptyerror5(false)
                                    setemployment_eligibility(e.target.value)
                                  }}
                                  type="radio" value="Authorized to work in the USA for any employer" name="radio21" defaultChecked />
                                :
                                <input
                                  onChange={(e) => {
                                    setemptyerror5(false)
                                    setemployment_eligibility(e.target.value)
                                  }}
                                  type="radio" value="Authorized to work in the USA for any employer" name="radio21" />
                              }
                              <span className="sp-clr">Authorized to work in the USA for any employer</span>
                            </label>
                            <label>
                              {value.job[0].employment_eligibility == "Sponsorship required to work in the USA" ?
                                <input
                                  onChange={(e) => {
                                    setemptyerror5(false)
                                    setemployment_eligibility(e.target.value)
                                  }}
                                  type="radio" name="radio21" value="Sponsorship required to work in the USA" defaultChecked />
                                :
                                <input
                                  onChange={(e) => {
                                    setemptyerror5(false)
                                    setemployment_eligibility(e.target.value)
                                  }}
                                  type="radio" name="radio21" value="Sponsorship required to work in the USA" />
                              }

                              <span className="sp-clr">Sponsorship required to work in the USA</span>
                            </label>
                            <label>
                              {value.job[0].employment_eligibility == "Not specified" ?
                                <input
                                  onChange={(e) => {
                                    setemptyerror5(false)
                                    setemployment_eligibility(e.target.value)
                                  }}
                                  type="radio" name="radio21" value="Not specified" defaultChecked />
                                :
                                <input
                                  onChange={(e) => {
                                    setemptyerror5(false)
                                    setemployment_eligibility(e.target.value)
                                  }}
                                  type="radio" name="radio21" value="Not specified" />
                              }

                              <span className="sp-clr">Not specified</span>
                            </label>
                          </div>
                          {emptyerror5 == true ? <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> : ""}

                        </div>

                        <h5 className="mb-2 mt-3">Contact Information</h5>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Email</label> <asterisk style={{ color: "red" }}> *</asterisk>
                          <input type="text"
                            onChange={(e) => {
                              setemptyerror6(false)
                              setemail(e.target.value)
                            }}
                            maxLength={150}
                            className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email" defaultValue={email !== "" && email !== null && email !== undefined ? email : localStorage.getItem("email")} />
                          {emptyerror6 == true ? <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> : ""}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Contact Number</label>
                          <InputMask {...props} mask="(999) 999-9999" defaultValue={contact_number !== "" ? contact_number : value.job[0].contact_number} className="form-control employee-input-email job-search" onChange={(e) => setcontact_number(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Additional Contact Number</label>
                          <InputMask {...props} mask="(999) 999-9999" defaultValue={value.job[0].add_contact_number} className="form-control employee-input-email job-search" onChange={(e) => setadd_contact_number(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
                        </div>

                        <div id="education">
                          <h5 className="my-4" ref={EDUCATION}>Education</h5>
                          {
                            eduarray && eduarray.length > 0 ?
                              <div className="w-100 usr-tbl">
                                <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                        Level {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("level_of_education")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("level_of_education")} />}</th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '28% !important' }}>
                                        School</th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Degree
                                      </th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Major
                                      </th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>City & State
                                      </th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Last Attended {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("date_of_completion")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("date_of_completion")} />}
                                      </th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      eduarray && eduarray.length > 0 ? eduarray.map((value, index) => (
                                        <tr>
                                          <td>{`${value.level_of_education !== null && value.level_of_education !== undefined && value.level_of_education !== "" ? value.level_of_education : ""}`} </td>
                                          <td>{`${value.school !== null && value.school !== undefined && value.school !== "" ? value.school : ""}`}</td>
                                          <td>{`${value.degree !== null && value.degree !== undefined && value.degree !== "" ? value.degree : ""}`}</td>
                                          <td>{`${value.major !== null && value.major !== undefined && value.major !== "" ? value.major : ""}`}</td>
                                          <td>{`${value.city !== null && value.city !== undefined && value.city !== "" ? value.city : ""}`}</td>

                                          <td>{`${value.date_of_completion !== null && value.date_of_completion !== undefined && value.date_of_completion !== "" && value.date_of_completion !== "Currently enrolled" ? dayjs(new Date(value.date_of_completion)).format("MM-DD-YYYY") : "Currently enrolled"}`}</td>

                                          <td><i class="fa fa-trash text-danger" onClick={(e) => deleteEdu(index)}></i>
                                            <i class="fa fa-edit text-success ml-2" style={{ position: "relative", top: "1px" }} onClick={(e) => editEdu(index)}></i>
                                          </td>
                                        </tr>
                                      )) : ""
                                    }



                                  </tbody>
                                </table>
                              </div>
                              : ""
                          }
                          <div className="form-group d-flex flex-column">
                            <label htmlFor="exampleInputPassword1">Level of Education <asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <select className="form-control employee-input-email job-search"
                              onChange={(e) => {
                                setemptyerror7(false)
                                setlevel_of_education(e.target.value)
                                if(e.target.value !== level_of_education){
                                  seteditschool("");
                                  setschool("");
                                }
                              }}
                              value={level_of_education}
                            >
                              <option value="None">None</option>
                              <option value="High School Diploma">High School Diploma</option>
                              <option value="Associates Degree">Associates Degree</option>
                              <option value="Bachelor’s Degree">Bachelor’s Degree</option>
                              <option value="Master’s Degree">Master’s Degree</option>
                              <option value="Doctorate">Doctorate</option>
                              <option value="Other">Other</option>
                            </select>
                            {emptyerror7 == true ? <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> : ""}

                          </div>
                          <div style={{ width: 293 }} className="form-group">
                          <label htmlFor="exampleInputPassword1">School <asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <Autocomplete
                              options={top100Films}  
                              onChange={(event, selectedOption, reason) => { 
                                if(reason){
                                  seteditschool(event.target.value);
                                  setschool(event.target.value);
                                }
                                seteditschool(selectedOption ? selectedOption : "");
                                setschool(selectedOption ? selectedOption : "");
                                setemptyerror22(false)
                              }}
                              sx={{
                                '& ::before':{
                                  border: 'none !important'
                                }
                              }}
                              freeSolo
                              disableClearable
                              renderInput={(params) => (
                              <TextField onChange={(e) => {
                                seteditschool(e.target.value)
                                setschool(e.target.value)
                                setemptyerror22(false)
                                if(e.target.value !== ""){
                                  timeBasedDebounce(getUniversityNames, 300)
                                } else if (e.target.value === "") {
                                  setTimeout(() => {
                                    settop100Films([])
                                  }, 300)
                                }
                              }} 
                              {...params} label="School" variant="filled" />
                              )}
                              value={top100Films?.find((option) => option === school) || ""}
                            />
                            {emptyerror22 && <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small>}
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Degree / Diploma / Apprenticeship <asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <input
                              onChange={(e) => {
                                setdegree(e.target.value)
                                setemptyerror19(false)
                              }}
                              maxLength={150}
                              type="text" value={degree} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Associate of Science, Bachelor of Arts, etc." />
                            {emptyerror19 && <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small>}
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Major / Field of Study <asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <input
                              onChange={(e) => {
                                setmajor(e.target.value)
                                setemptyerror20(false)
                              }}
                              maxLength={150}
                              type="text" value={major} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Biology, Business, Computer Science, etc." />
                            {emptyerror20 && <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small>}
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">City &amp; State – United States <asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <PlacesAutocomplete
                              value={cityedu}
                              onChange={(e) => {
                                setcityedu(e)
                                setemptyerror23(false)
                              }}
                              onSelect={e=>setcityedu(e?.split(",").slice(0,2).join(", "))}
                              searchOptions={{ componentRestrictions: { country: 'us' } }}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    id="exampleInputPassword1"
                                    style={{ borderRadius: "8px" }}
                                    className="form-control employee-input-email job-search w-25"
                                    {...getInputProps({
                                      placeholder: 'City',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  {emptyerror23 && <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> }
                                  <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item p-2 --active'
                                        : 'suggestion-item p-2 ';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span onClick={() => setemptyerror23(false)}>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                          <div className="main-content-session">
                            <div className="role role2">
                              <label>
                                {
                                  currentlyEnrolled == true ?
                                    <input type="checkbox" checked onChange={() => setCurrentlyEnrolled(false)} name="radio22" />
                                    :
                                    <input type="checkbox" onChange={() => setCurrentlyEnrolled(true)} name="radio200" />
                                }
                                <span className="sp-clr">Currently enrolled</span>
                              </label>
                            </div>
                          </div>
                          {
                            currentlyEnrolled == false ?

                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Date of Completion or Last Attended</label>
                                <DatePicker value={date_of_completion} onChange={(value) => {
                                  setdate_of_completionReq(false)
                                  setdate_of_completion(value)}} sx={{ width: { xs: "100%", lg: "293px" } }}/>
                                  {date_of_completionReq&& <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small>}
                              </div>
                              : ""
                          }
                          <div className="main-content-session inner-session">
                            <Link to="#" onClick={() => AddMoreEducation()} className="send-invitation fgh">Add More Education</Link>
                          </div>
                        </div>
                        <div ref={WORKEXPERIENCE} id="work-experience">
                          <h5 className="mb-2 mt-3">Work Experience</h5>
                          {
                            exparray && exparray.length > 0 ?
                              <div className="w-100 usr-tbl">
                                <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                        Job Title {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting1("job_title")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting1("job_title")} />}</th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '28% !important' }}>
                                        Company</th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>City
                                      </th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>State
                                      </th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>From
                                        {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting1("time_period_start")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting1("time_period_start")} />}
                                      </th>

                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>To
                                        {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting1("time_period_end")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting1("time_period_end")} />}
                                      </th>

                                      <th className="sorting res-dis-none-table-display" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending">Responsibilities
                                      </th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      exparray.length > 0 ? exparray.map((value, index) => (
                                        <tr>
                                          <td>{`${value.job_title !== null && value.job_title !== undefined && value.job_title !== "" ? value.job_title : ""}`} </td>
                                          <td>{`${value.company !== null && value.company !== undefined && value.company !== "" ? value.company : ""}`}</td>
                                          <td>{`${value.city !== null && value.city !== undefined && value.city !== "" ? value.city : ""}`}</td>
                                          <td>{`${value.state !== null && value.state !== undefined && value.state !== "" ? value.state : ""}`}</td>
                                          <td>{`${value.time_period_start !== null && value.time_period_start !== undefined && value.time_period_start !== "" ? dayjs(new Date(value.time_period_start)).format("MM-DD-YYYY") : ""}`}</td>
                                          <td>{`${value.time_period_end !== null && value.time_period_end !== undefined && value.time_period_end !== "" && value.time_period_end !== "Still currently employed here" ? dayjs(new Date(value.time_period_end)).format("MM-DD-YYYY") : "Still currently employed here"}`}</td>
                                          <td className="res-dis-none-table-display">{value.duties !== null && value.duties !== undefined && value.duties !== "" ? parse(value.duties) : ""}</td>
                                          <td><i class="fa fa-trash text-danger" onClick={(e) => deleteExp(index)}></i>
                                            <i class="fa fa-edit text-success ml-2" style={{ position: "relative", top: "1px" }} onClick={(e) => editExp(index)}></i>
                                          </td>
                                        </tr>
                                      )) : ""
                                    }
                                  </tbody>
                                </table>
                              </div>
                              : ""
                          }
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Job Title<asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <input
                              onChange={(e) => {
                                setjob_titleErr(false)
                                setjob_title(e.target.value)
                              }}
                              maxLength={150}
                              type="text" value={job_title} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Job Title" />
                            {job_titleErr && <small className="text-danger mb-2" style={{ color: "red" }}>{job_titleErr}</small>}

                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Company<asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <input
                              onChange={(e) => {
                                setcompanyErr(false)
                                setcompany(e.target.value)
                              }}
                              maxLength={150}
                              type="text" value={company} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Company" />
                            {companyErr && <small className="text-danger mb-2" style={{ color: "red" }}>{ companyErr}</small>}

                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">City <asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <PlacesAutocomplete
                              value={cityexp.split(",")[0]}
                              onChange={setcityexp}
                              onSelect={handleSelectExp}
                              searchOptions={{ componentRestrictions: { country: 'us' } }}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    id="exampleInputPassword1"
                                    style={{ borderRadius: "8px" }}
                                    className="form-control employee-input-email job-search w-25"
                                    {...getInputProps({
                                      placeholder: 'City',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  {emptyerror3 == true ? <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> : ""}
                                  <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item p-2 --active'
                                        : 'suggestion-item p-2 ';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span onClick={() => setemptyerror3(false)}>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            {cityexpErr && <small className="text-danger mb-2" style={{ color: "red" }}>{cityexpErr }</small>}

                          </div>
                          <div className="form-group">
                            <label htmlFor>State <asterisk style={{ color: "red" }}> *</asterisk ></label>
                            <select style={{ color: "black" }} className="form-control employee-input-email job-search" name id
                              onChange={(e) => {
                                setstateexpErr(false)
                                setstateexp(e.target.value)
                              }}
                              value={stateexp}
                            >
                              <option value={stateexp == "" ? "" : stateexp}>{stateexp == "" ? "Select State" : stateexp}</option>
                              <option style={{ color: "black" }} value="AL">Alabama</option>
                              <option style={{ color: "black" }} value="AK">Alaska</option>
                              <option style={{ color: "black" }} value="AZ">Arizona</option>
                              <option style={{ color: "black" }} value="AR">Arkansas</option>
                              <option style={{ color: "black" }} value="CA">California</option>
                              <option style={{ color: "black" }} value="CO">Colorado</option>
                              <option style={{ color: "black" }} value="CT">Connecticut</option>
                              <option style={{ color: "black" }} value="DE">Delaware</option>
                              <option style={{ color: "black" }} value="DC">District Of Columbia</option>
                              <option style={{ color: "black" }} value="FL">Florida</option>
                              <option style={{ color: "black" }} value="GA	">Georgia</option>
                              <option style={{ color: "black" }} value="HI">Hawaii</option>
                              <option style={{ color: "black" }} value="ID">Idaho</option>
                              <option style={{ color: "black" }} value="IL">Illinois</option>
                              <option style={{ color: "black" }} value="IN">Indiana</option>
                              <option style={{ color: "black" }} value="IA">Iowa</option>
                              <option style={{ color: "black" }} value="KS">Kansas</option>
                              <option style={{ color: "black" }} value="KY">Kentucky</option>
                              <option style={{ color: "black" }} value="LA">Louisiana</option>
                              <option style={{ color: "black" }} value="ME">Maine</option>
                              <option style={{ color: "black" }} value="MD">Maryland</option>
                              <option style={{ color: "black" }} value="MA">Massachusetts</option>
                              <option style={{ color: "black" }} value="MI">Michigan</option>
                              <option style={{ color: "black" }} value="MN">Minnesota</option>
                              <option style={{ color: "black" }} value="MS">Mississippi</option>
                              <option style={{ color: "black" }} value="MO">Missouri</option>
                              <option style={{ color: "black" }} value="MT">Montana</option>
                              <option style={{ color: "black" }} value="NE">Nebraska</option>
                              <option style={{ color: "black" }} value="NV">Nevada</option>
                              <option style={{ color: "black" }} value="NH">New Hampshire</option>
                              <option style={{ color: "black" }} value="NJ">New Jersey</option>
                              <option style={{ color: "black" }} value="NM">New Mexico</option>
                              <option style={{ color: "black" }} value="NY">New York</option>
                              <option style={{ color: "black" }} value="NC">North Carolina</option>
                              <option style={{ color: "black" }} value="ND">North Dakota</option>
                              <option style={{ color: "black" }} value="OH">Ohio</option>
                              <option style={{ color: "black" }} value="OK">Oklahoma</option>
                              <option style={{ color: "black" }} value="OR">Oregon</option>
                              <option style={{ color: "black" }} value="PA">Pennsylvania</option>
                              <option style={{ color: "black" }} value="RI">Rhode Island</option>
                              <option style={{ color: "black" }} value="SC">South Carolina</option>
                              <option style={{ color: "black" }} value="SD">South Dakota</option>
                              <option style={{ color: "black" }} value="TN">Tennessee</option>
                              <option style={{ color: "black" }} value="TX">Texas</option>
                              <option style={{ color: "black" }} value="UT">Utah</option>
                              <option style={{ color: "black" }} value="VT">Vermont</option>
                              <option style={{ color: "black" }} value="VA">Virginia</option>
                              <option style={{ color: "black" }} value="WA">Washington</option>
                              <option style={{ color: "black" }} value="WV">West Virginia</option>
                              <option style={{ color: "black" }} value="WI">Wisconsin</option>
                              <option style={{ color: "black" }} value="WY">Wyoming</option>
                            </select>
                            {stateexpErr && <small className="text-danger mb-2" style={{ color: "red" }}>{stateexpErr }</small>}

                          </div>
                          <div className="main-content-session">
                            <div className="d-flex flex-column p-0 m-0">
                              <label htmlFor className="m-0">Time Period <asterisk style={{ color: "red" }}> *</asterisk ></label>
                              {emptyerror12 == true ? <small className="text-danger mb-0" style={{ color: "red" }}>Please complete this field</small> : ""}
                            </div>
                            <div className="role role2">
                              <label>
                                {
                                  stillEmployed == true ?
                                    <input type="checkbox" checked onChange={() => {
                                      setemptyerror12(false)
                                      setStillEmployed(!stillEmployed)
                                    }} name="radio22" />
                                    :
                                    <input type="checkbox" onChange={() => {
                                      setemptyerror12(false)
                                      setStillEmployed(!stillEmployed)
                                    }} name="radio200" />
                                }
                                {/* <input type="checkbox" onChange={() => {
                                  setemptyerror12(false)
                                  setStillEmployed(!stillEmployed)
                                }} name="radio22" /> */}
                                <span className="sp-clr">Still currently employed here </span>
                              </label>
                            </div>
                          </div>
                          <div className="work-update-resume-still">
                            {/* <div className="col-lg-5 col-12 pl-0 pr-0">*/}
                              <div className="form-group mb-0"> 
                                <DatePicker value={time_period_start} onChange={(value) => {
                                    settime_period_startErr(false)
                                    settime_period_start(value)
                                  }} sx={{ width: "135px" }}/>
                                {time_period_startErr && <small className="text-danger mb-0" style={{ color: "red" }}>{time_period_startErr}</small>}

                              {/* </div>*/}
                            </div> 

                            {
                              stillEmployed == false ?
                                <>
                                  {/* <div className="col-md-2 pl-3 pt-2 pr-0"> */}
                                  <h6 className="www mx-2" style={{height:"40px",display:"flex",alignItems:"center"}}>To</h6>
                                  {/* </div>
                                  <div className="col-lg-5 col-12 pl-0 pr-0">*/}
                                    <div className="form-group mb-0"> 
                                  <DatePicker value={time_period_end} onChange={(value) => {
                                          settime_period_endErr(false)
                                          settime_period_end(value)
                                        }} sx={{ width: "135px" }}/>
                                      {time_period_endErr && <small className="text-danger mb-0" style={{ color: "red" }}>{time_period_endErr}</small>}

                                    {/* </div> */}
                                  </div> 
                                </>
                                : ""}
                          </div>
                          <div className="form-group create-resume d-flex flex-column mt-3">
                            <label htmlFor>Duties and/or Responsibilities<asterisk style={{ color: "red" }}> *</asterisk ><span style={{ float: 'right' }} id="counter">{duties !== "" && duties !== null && duties !== undefined ? duties.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}/2,500</span></label>

                            <CKEditor
                              editor={ClassicEditor}
                              data={duties}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setDutiesErr(false)
                                CharacterCountany(data)
                              }}
                            />
                            {dutiesErr && <small className="text-danger mb-2" style={{ color: "red" }}>{dutiesErr}</small>}
                          </div>
                        </div>
                        <div className="main-content-session inner-session">
                          <Link to="#" onClick={() => AddMoreExperience()} className="send-invitation fgh">Add More Experience</Link>
                        </div>
                        <div ref={PROFESSIONALSUMMARY} id="PROFESSIONAL-SUMMARY">
                          <div className="form-group create-resume d-flex flex-column mb-0">
                            <h5 className="mt-4 mb-3">Professional Summary</h5>
                            <CKEditor
                              editor={ClassicEditor}
                              data={description !== "" || datafalse !== true ? description : allArray && allArray.length > 0 && allArray[0].job && allArray[0].job.length > 0 && allArray[0].job[0].professional_summary !== "" ? allArray[0].job[0].professional_summary : ""}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                CharacterCount3(data)
                              }}
                            />
                          </div>
                          <div className="d-flex create-resume align-items-center justify-content-end">
                            <p id="counter">Count {overcompany !== "" && overcompany !== null && overcompany !== undefined ? overcompany : allArray[0] && allArray[0].length > 0 && allArray[0].job && allArray[0].job.length > 0 && allArray[0].job[0].professional_summary !== "" ? allArray[0].job[0].professional_summary.length : "0"} (Max Amount: 2,500)</p>
                          </div>
                        </div>
                        {/* ======================================================================================================== */}
                        <div ref={MILITARY} className="inner-session">
                          <div id="military">
                            <h5 className="mt-4 mb-3">Military Information</h5>
                            <div className="main-content-session pt-0">
                              <label htmlFor>Military Service</label>
                              <div className="role role2">

                                <>
                                  <label>
                                    <input type="radio" onChange={() => setMilitaryYes("yes")} name="radio24" checked={militaryYes == "yes" ? true : false} />
                                    <span className="sp-clr">Yes</span>
                                  </label>
                                  <label>
                                    <input type="radio" onChange={() => setMilitaryYes("no")} name="radio24" checked={militaryYes == "no" ? true : false} />
                                    <span className="sp-clr">No</span>
                                  </label>
                                </>

                              </div>
                            </div>
                          </div>
                          {militaryYes == "yes" ?
                            <>
                              <div className="form-group d-flex flex-column">
                                <label htmlFor="exampleInputPassword1">Country <asterisk style={{ color: "red" }}> *</asterisk ></label>
                                <select
                                  onChange={(e) => {
                                    setemptyerror21(false)
                                    setcountry(e.target.value)
                                  }}
                                  style={{ width: 293 }}
                                  className="form-control employee-input-email" name id
                                  value={country}
                                >
                                  <option value="United States of America">United States of America</option>
                                  <option value="Afghanistan">Afghanistan</option>
                                  <option value="Åland Islands">Åland Islands</option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antarctica">Antarctica</option>
                                  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Bouvet Island">Bouvet Island</option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">Burkina Faso</option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">Cayman Islands</option>
                                  <option value="Central African Republic">Central African Republic</option>
                                  <option value="Chad">Chad</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">Christmas Island</option>
                                  <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                  <option value="Cook Islands">Cook Islands</option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">Czech Republic</option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">Dominican Republic</option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">El Salvador</option>
                                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                  <option value="Faroe Islands">Faroe Islands</option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">French Guiana</option>
                                  <option value="French Polynesia">French Polynesia</option>
                                  <option value="French Southern Territories">French Southern Territories</option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guernsey">Guernsey</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guinea-bissau">Guinea-bissau</option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                  <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="India">India</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of Man">Isle of Man</option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jersey">Jersey</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                  <option value="Korea, Republic of">Korea, Republic of</option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                  <option value="Liechtenstein">Liechtenstein</option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macao">Macao</option>
                                  <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">Marshall Islands</option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                  <option value="Moldova, Republic of">Moldova, Republic of</option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montenegro">Montenegro</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Namibia">Namibia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherlands">Netherlands</option>
                                  <option value="Netherlands Antilles">Netherlands Antilles</option>
                                  <option value="New Caledonia">New Caledonia</option>
                                  <option value="New Zealand">New Zealand</option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">Norfolk Island</option>
                                  <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau">Palau</option>
                                  <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">Papua New Guinea</option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Philippines">Philippines</option>
                                  <option value="Pitcairn">Pitcairn</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">Puerto Rico</option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Reunion">Reunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russian Federation">Russian Federation</option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="Saint Helena">Saint Helena</option>
                                  <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                  <option value="Saint Lucia">Saint Lucia</option>
                                  <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                  <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                  <option value="Saudi Arabia">Saudi Arabia</option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Serbia">Serbia</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">Sierra Leone</option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">Solomon Islands</option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">South Africa</option>
                                  <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                  <option value="Swaziland">Swaziland</option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">Switzerland</option>
                                  <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Timor-leste">Timor-leste</option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">Turkmenistan</option>
                                  <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Emirates">United Arab Emirates</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                  <option value="Uruguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Viet Nam">Viet Nam</option>
                                  <option value="Virgin Islands, British">Virgin Islands, British</option>
                                  <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                                  <option value="Western Sahara">Western Sahara</option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                                {emptyerror21 == true ? <small className="text-danger mb-0" style={{ color: "red" }}>Please complete this field</small> : ""}

                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Branch Of Service <asterisk style={{ color: "red" }}> *</asterisk ></label>
                                {
                                  brancharray && brancharray.length > 0 ? brancharray[0].branch !== "" && brancharray[0].branch !== undefined && brancharray[0].branch !== null ?
                                    <div className="w-100 usr-tbl">
                                      <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                        <thead>
                                          <tr role="row">
                                            <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                              Branch Of Service</th>
                                            <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            brancharray.length > 0 ? brancharray.map((value, index) => (
                                              <tr>
                                                <td>{`${value.branch !== null && value.branch !== undefined && value.branch !== "" ? value.branch : ""}`} </td>
                                                <td><i class="fa fa-trash text-danger" onClick={(e) => deleteBranch(index)}></i></td>
                                              </tr>
                                            )) : ""
                                          }
                                        </tbody>
                                      </table>
                                    </div>
                                    : ""
                                    : ""
                                }
                                <div className="d-flex flex-broke-responsive">
                                  {
                                    brancharray.length > 1 ?
                                      <div>
                                        <input
                                          readOnly
                                          onChange={(e) => {
                                            setemptyerror14(false)
                                            setbranch(e.target.value)
                                          }}
                                          maxLength={150}
                                          type="text" value={branch} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Branch Of Service" />
                                        {emptyerror14 == true ? <small className="text-danger mb-0" style={{ color: "red" }}>Please complete this field</small> : ""}
                                      </div>
                                      :
                                      <div>
                                        <input
                                          onChange={(e) => {
                                            setemptyerror14(false)
                                            setbranch(e.target.value)
                                          }}
                                          maxLength={150}
                                          type="text" value={branch} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Branch Of Service" />
                                        {emptyerror14 == true ? <small className="text-danger mb-0" style={{ color: "red" }}>Please complete this field</small> : ""}
                                      </div>
                                  }

                                  {
                                    brancharray.length > 1 ?
                                      ""
                                      :
                                      <Link to="#" onClick={() => AddMoreBranch()} className="send-invitation ml-2 mb-0 fgh">Add More</Link>
                                  }
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Military Occupational Specialty (MOS) <asterisk style={{ color: "red" }}> *</asterisk ></label>
                                {
                                  mosarray && mosarray.length > 0 ? mosarray[0].mos !== "" && mosarray[0].mos !== null && mosarray[0].mos !== undefined ?
                                    <div className="w-100 usr-tbl">
                                      <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                        <thead>
                                          <tr role="row">
                                            <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                              MOS</th>
                                            <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            mosarray.length > 0 ? mosarray.map((value, index) => (
                                              value.mos !== "" ?
                                                <tr>
                                                  <td>{`${value.mos !== null && value.mos !== undefined && value.mos !== "" ? value.mos : ""}`} </td>
                                                  <td><i class="fa fa-trash text-danger" onClick={(e) => deleteMOS(index)}></i></td>
                                                </tr>
                                                : ""
                                            )) : ""
                                          }
                                        </tbody>
                                      </table>
                                    </div>
                                    : ""
                                    : ""
                                }
                                <div className="d-flex flex-broke-responsive">
                                  {
                                    mosarray.length > 7 ?
                                      <div>
                                        <input
                                          readOnly
                                          onChange={(e) => {
                                            setemptyerror15(false)
                                            setmos(e.target.value)
                                          }}
                                          maxLength={150}
                                          type="text" value={mos} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="MOS" />
                                        {emptyerror15 == true ? <small className="text-danger mb-0" style={{ color: "red" }}>Please complete this field</small> : ""}
                                      </div>
                                      :
                                      <div>
                                        <input
                                          onChange={(e) => {
                                            setemptyerror15(false)
                                            setmos(e.target.value)
                                          }}
                                          maxLength={150}
                                          type="text" value={mos} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="MOS" />
                                        {emptyerror15 == true ? <small className="text-danger mb-0" style={{ color: "red" }}>Please complete this field</small> : ""}
                                      </div>
                                  }

                                  {
                                    mosarray.length > 7 ?
                                      ""
                                      :
                                      <Link to="#" onClick={() => AddMoreMOS()} className="send-invitation ml-2 mb-0 fgh">Add More</Link>
                                  }
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Rank</label>
                                <input
                                  onChange={(e) => setrank(e.target.value)}
                                  maxLength={150}
                                  defaultValue={value.military && value.military.length > 0 ? [0].rank !== null && value.military[0].rank !== undefined && value.military[0].rank !== "" ? value.military[0].rank : "" : ""}
                                  type="text" className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="E-5, Sargent; O-3, Captain etc." />
                              </div>
                              <label htmlFor>Dates</label>
                              <div className="row align-items-center">
                                <div className="col-lg-5 col-12">
                                  <div className="d-flex">
                                    <div className="col-md-12 p-0">
                                      <div className="form-group p-1 pl-0">
                                        <DatePicker value={value?.military?.length ? value?.military?.[0]?.start_date : start_date } onChange={(value) => setstart_date(value)} sx={{ width: { xs: "100%", lg: "293px" } }}/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <label>To</label>
                                <div className="col-lg-6 col-12">
                                  <div className="d-flex">
                                    <div className="col-md-12 p-0">
                                      <div className="form-group p-1">
                                        <DatePicker value={value?.military?.length ? value?.military?.[0]?.end_date : end_date } onChange={(value) => setend_date(value)} sx={{ width: { xs: "100%", lg: "293px" } }}/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group d-flex flex-column">
                                <h5 className="mt-4 mb-3">Security Clearance <asterisk style={{ color: "red" }}> *</asterisk ></h5>
                                <select
                                  style={{ width: 293 }}
                                  className="form-control employee-input-email"
                                  name id
                                  onChange={(e) => {
                                    setemptyerror17(false)
                                    setsecurity_clearance(e.target.value)
                                  }}
                                  value={security_clearance}
                                >
                                  <option value={value.military && value.military.length > 0 ? value.military[0].security_clearance !== null && value.military[0].security_clearance !== undefined && value.military[0].security_clearance !== "" ? value.military[0].security_clearance : "Select" : "Select"}>{value.military && value.military.length > 0 ? value.military[0].security_clearance !== null && value.military[0].security_clearance !== undefined && value.military[0].security_clearance !== "" ? value.military[0].security_clearance : "Select" : "Select"}</option>
                                  <option value="Inactive Clearance">Inactive Clearance</option>
                                  <option value="Active Confidential">Active Confidential</option>
                                  <option value="Active Secret">Active Secret</option>
                                  <option value="Active Top Secret">Active Top Secret</option>
                                  <option value="Active Top Secret/SCI">Active Top Secret/SCI</option>
                                  <option value="Other Active Clearance">Other Active Clearance</option>
                                </select>
                                {emptyerror17 == true ? <small className="text-danger mb-0" style={{ color: "red" }}>Please complete this field</small> : ""}

                              </div>

                            </>
                            : ""
                          }

                          <div className="form-group">
                            <h5 ref={ADDITIONAL} className="mt-4 mb-3">Languages</h5>
                            {
                              langarray && langarray.length > 0 ?
                                <div className="w-100 usr-tbl">
                                  <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                    <thead>
                                      <tr role="row">
                                        <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                          Language</th>
                                        <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                          Level</th>
                                        <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        langarray.length > 0 ? langarray.map((value, index) => (
                                          <tr>
                                            <td>{`${value.lang !== null && value.lang !== undefined && value.lang !== "" ? value.lang : ""}`} </td>
                                            <td>{`${value.level !== null && value.level !== undefined && value.level !== "" ? value.level : ""}`} </td>
                                            <td><i class="fa fa-trash text-danger" onClick={(e) => deleteLang(index)}></i></td>
                                          </tr>
                                        )) : ""
                                      }
                                    </tbody>
                                  </table>
                                </div>
                                : ""
                            }
                            <div className="d-flex flex-broke-responsive">
                              <div className="d-flex flex-broke-responsive">
                                <input
                                  onChange={(e) => setlanguage(e.target.value)}
                                  maxLength={150}
                                  type="text" value={language} style={{ height: "100%" }} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="English, Spanish, French, etc." />
                                <select
                                  onChange={(e) => setlevel(e.target.value)}
                                  className="employee-input-email salect-form-update-responsive" name id
                                  value={level}
                                >
                                  <option value="None">None</option>
                                  <option value="Basic">Basic</option>
                                  <option value="Conversational">Conversational</option>
                                  <option value="Fluent">Fluent</option>
                                  <option value="Native">Native</option>
                                </select>
                              </div>
                              {
                                langarray.length > 6 ?
                                  ""
                                  :
                                  <Link to="#" onClick={() => AddMoreLanguage()} className="send-invitation ml-2 mb-0 fgh">Add More</Link>
                              }
                            </div>
                          </div>
                          <div className="form-group">
                            <h5 className="mt-4 mb-3">Certification</h5>
                            {
                              certificationarray && certificationarray.length > 0 ?
                                <div className="w-100 usr-tbl">
                                  <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                    <thead>
                                      <tr role="row">
                                        <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                          Title</th>
                                        <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                          Date {ordercertification == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sortingcertification("date")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sortingcertification("date")} />}</th>
                                        <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        certificationarray.length > 0 ? certificationarray.map((value, index) => (
                                          <tr>
                                            <td>{`${value.title !== null && value.title !== undefined && value.title !== "" ? value.title : ""}`} </td>
                                            <td>{`${value.date !== null && value.date !== undefined && value.date !== "" ? dayjs(new Date(value.date)).format("MM-DD-YYYY") : ""}`} </td>
                                            <td><i class="fa fa-trash text-danger" onClick={(e) => deleteCert(index)}></i></td>
                                          </tr>
                                        )) : ""
                                      }
                                    </tbody>
                                  </table>
                                </div>
                                : ""
                            }
                            <div className="d-flex flex-broke-responsive">
                              <div className="d-flex flex-broke-responsive">
                                <input
                                  onChange={(e) => settitlecertification(e.target.value)}
                                  maxLength={150}
                                  type="text" style={{ height: "100%" }} value={titlecertification} className="form-control employee-input-email job-search mr-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Title of certification" />
                                <DatePicker value={datecertification} onChange={(value) => setdatecertification(value)} sx={{ width: { xs: "100%", lg: "293px" } }}/>
                              </div>
                              {
                                certificationarray.length > 6 ?
                                  ""
                                  :
                                  <Link to="#" onClick={() => AddMoreCertification()} className="send-invitation ml-2 mb-0 fgh">Add More</Link>
                              }
                            </div>
                          </div>
                          <div className="form-group">
                            <h5 className="mt-4 mb-3">Award</h5>
                            {
                              awardarray && awardarray.length > 0 ?
                                <div className="w-100 usr-tbl">
                                  <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                    <thead>
                                      <tr role="row">
                                        <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                          Title</th>
                                        <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                          Date {orderaward == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sortingaward("date")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sortingaward("date")} />}</th>
                                        <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        awardarray.length > 0 ? awardarray.map((value, index) => (
                                          <tr>
                                            <td>{`${value.title !== null && value.title !== undefined && value.title !== "" ? value.title : ""}`} </td>
                                            <td>{`${value.date !== null && value.date !== undefined && value.date !== "" ? dayjs(new Date(value.date)).format("MM-DD-YYYY") : ""}`} </td>
                                            <td><i class="fa fa-trash text-danger" onClick={(e) => deleteAward(index)}></i></td>
                                          </tr>
                                        )) : ""
                                      }
                                    </tbody>
                                  </table>
                                </div>
                                : ""
                            }
                            <div className="d-flex flex-broke-responsive">
                              <div className="d-flex flex-broke-responsive">
                                <input
                                  onChange={(e) => settitleaward(e.target.value)}
                                  maxLength={150}
                                  type="text" style={{ height: "100%" }} value={titleaward} className="form-control employee-input-email job-search mr-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Title of Award" />
                                <DatePicker value={dateaward} onChange={(value) => setdateaward(value)} sx={{ width: { xs: "100%", lg: "293px" } }}/>
                              </div>
                              {
                                awardarray.length > 4 ?
                                  ""
                                  :
                                  <Link to="#" onClick={() => AddMoreAward()} className="send-invitation ml-2 mb-0 fgh">Add More</Link>
                              }
                            </div>
                          </div>
                        </div>
                        {/* ================================================================================================================================================== */}
                        <div ref={VOLLUNTEER} id="additional">
                          <h5 className="mt-4 mb-3">Volunteer Work</h5>
                          {
                            volunteerarray && volunteerarray.length > 0 ?
                              <div className="w-100 usr-tbl">
                                <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                        Title</th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '28% !important' }}>
                                        Start {ordervolunteer == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sortingvolunteer("start")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sortingvolunteer("start")} />}</th>

                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>End
                                        {ordervolunteer == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sortingvolunteer("end")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sortingvolunteer("end")} />}</th>

                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      volunteerarray.length > 0 ? volunteerarray.map((value, index) => (
                                        <tr>
                                          <td>{`${value.title !== null && value.title !== undefined && value.title !== "" ? value.title : ""}`} </td>
                                          <td>{`${value.start !== null && value.start !== undefined && value.start !== "" ? dayjs(new Date(value.start)).format("MM-DD-YYYY") : ""}`}</td>

                                          <td>{`${value.end !== null && value.end !== undefined && value.end !== "" && value.end !== "I currently volunteer here" ? dayjs(new Date(value.end)).format("MM-DD-YYYY") : "I currently volunteer here"}`}</td>

                                          <td><i class="fa fa-trash text-danger" onClick={(e) => deleteVol(index)}></i></td>
                                        </tr>
                                      )) : ""
                                    }



                                  </tbody>
                                </table>
                              </div>
                              : ""
                          }

                          <div className="row align-items-center">
                            <div className="col-md-3">
                              <div className="form-group">
                                <input
                                  onChange={(e) => settitle(e.target.value)}
                                  maxLength={150}
                                  type="text" value={title} style={{ width: "100%" }} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Title / Assignment" />
                              </div>
                            </div>
                            <div className="col-lg-2 col-12 pr-lg-0">
                              <div className="form-group p-1 pl-0">
                                <DatePicker value={start} onChange={(value) => setstart(value)} sx={{ width: '155px' }}/>
                              </div>
                            </div>
                            {
                              currentlyVolunteer == false ?
                                <>
                                  <div className="col-md-1 px-lg-0 text-center">
                                    <h6 className="www">To</h6>
                                  </div>
                                  <div className="col-lg-2 col-12 pl-lg-0">
                                    <div className="form-group p-1 pl-0">
                                      <DatePicker value={end} onChange={(value) => setend(value)} sx={{ width: '155px' }}/>
                                    </div>
                                  </div>
                                </>
                                : ""
                            }
                          </div>
                        </div>
                        <div className="main-content-session pt-0">
                          <div className="role role2">
                            <label>
                              <input type="checkbox" onChange={() => setCurrentlyVolunteer(!currentlyVolunteer)} name="radio24" />
                              <span className="sp-clr">I currently volunteer here </span>
                            </label>
                          </div>
                        </div>
                        <div style={{ width: '300px' }} className="form-group inner-session">
                          <Link to="#" onClick={() => AddMoreVolunteer()} className="send-invitation ml-2 fgh">Add More</Link>
                        </div>
                        <div className="form-group">
                          <h5 className="mt-4 mb-3">Social Network Links</h5>
                          {
                            linkarray && linkarray.length > 0 ?
                              <div className="w-100 usr-tbl">
                                <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                        Link</th>
                                      <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                        Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      linkarray.length > 0 ? linkarray.map((value, index) => (
                                        <tr>
                                          <td>{`${value.link !== null && value.link !== undefined && value.link !== "" ? value.link : ""}`} </td>
                                          <td><i class="fa fa-trash text-danger" onClick={(e) => deleteLinks(index)}></i></td>
                                        </tr>
                                      )) : ""
                                    }



                                  </tbody>
                                </table>
                              </div>
                              : ""
                          }
                          <input onChange={(e) => setslink(e.target.value)} value={slink} type="text" maxLength={150} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="LinkedIn, Facebook, Twitter, Blog Site, etc." />
                        </div>
                        <div className="main-content-session inner-session pt-0">
                          <Link to="#" onClick={() => AddMoreLinks()} className="send-invitation fgh">Add More</Link>
                        </div>
                        <h5 className="mt-4 mb-3">References</h5>
                        {
                          referencearray && referencearray.length > 0 ?
                            <div className="w-100 usr-tbl">
                              <table id="example" className="table table-responsive table-striped dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                                <thead>
                                  <tr role="row">
                                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '28% !important' }}>
                                      Name</th>
                                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '28% !important' }}>
                                      Relationship</th>
                                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '28% !important' }}>
                                      Number</th>
                                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '28% !important' }}>
                                      Email</th>
                                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '28% !important' }}>Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    referencearray.length > 0 ? referencearray.map((value, index) => (
                                      <tr>
                                        <td>{`${value.name !== null && value.name !== undefined && value.name !== "" ? value.name : ""}`} </td>
                                        <td>{`${value.relationship !== null && value.relationship !== undefined && value.relationship !== "" ? value.relationship : ""}`}</td>
                                        <td>{`${value.contact_number !== null && value.contact_number !== undefined && value.contact_number !== "" ? value.contact_number : ""}`}</td>
                                        <td>{`${value.email !== null && value.email !== undefined && value.email !== "" ? value.email : ""}`}</td>
                                        <td><i class="fa fa-trash text-danger" onClick={(e) => deleteRef(index)}></i></td>
                                      </tr>
                                    )) : ""
                                  }
                                </tbody>
                              </table>
                            </div>
                            : ""
                        }
                        <div className="main-content-session pt-0">
                          <div className="role role2">
                            {
                              referencearray.length > 0 ?
                                ""
                                :
                                <label>
                                  {
                                    provideReference == false ?
                                      <input type="checkbox" onChange={() => setProvideReference(!provideReference)} name="radio24" defaultChecked />
                                      :
                                      <input type="checkbox" onChange={() => setProvideReference(!provideReference)} name="radio24" />
                                  }
                                  <span className="sp-clr">Will provide references on request</span>
                                </label>
                            }
                          </div>
                        </div>
                        {
                          referencearray.length > 0 || provideReference == true ?
                            <>
                              <div className="row align-items-center ">
                                <div className="col-lg-5 col-12 ">
                                  <div className="d-flex">
                                    <div className="col-lg-12 p-0">
                                      <div className="form-group p-1 mb-0">
                                        <input
                                          onChange={(e) => setname(e.target.value)}
                                          maxLength={150}
                                          style={{ width: '100%' }} type="text" value={name} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-12">
                                  <div className="d-flex">
                                    <div className="col-lg-12 p-0">
                                      <div className="form-group p-1 mb-0">
                                        <select
                                          onChange={(e) => setrelationship(e.target.value)}
                                          style={{ width: '100%' }}
                                          className="employee-input-email"
                                          name id
                                          value={relationship}
                                        >
                                          <option value="None">Relationship</option>
                                          <option value="Friend">Friend</option>
                                          <option value="Family Member ">Family Member</option>
                                          <option value="Professional">Professional</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-lg-5 col-12">
                                  <div className="d-flex">
                                    <div className="col-lg-12 p-0">
                                      <div className="form-group p-1 mb-0">
                                        <InputMask {...props} mask="(999) 999-9999" value={contact_numberref} className="form-control employee-input-email job-search w-100" onChange={(e) => setcontact_numberref(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-12">
                                  <div className="d-flex">
                                    <div className="col-lg-12 p-0">
                                      <div className="form-group p-1 mb-0">
                                        <input
                                          onChange={(e) => setemailref(e.target.value)}
                                          maxLength={150}
                                          style={{ width: '100%' }} value={emailref} type="email" className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email Address: " />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ width: '300px' }} className="form-group mt-3 inner-session">
                                  <Link to="#" onClick={() => AddMoreReference()} className="send-invitation ml-2 w-50 fgh">Add More</Link>
                                </div>
                              </div>
                            </>
                            : ""
                        }


                        <div className="main-content-session inner-session w-100 d-flex align-items-center justify-content-center">
                          <Link to="#" onClick={() => sendresume()} className="send-invitation fgh">UPDATE</Link>
                        </div>


                      </form>
                    </div>
                    {/* footer====================================================================== */}

                  </div>
                </div>
              )) : ""
            }
          </>
          : <FullPageLoader1 />
      }

      <Modal
        isOpen={modal3}
        onRequestClose={() => setmodal3(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "210px",
            top: "calc(50% - 105px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>Please enter start and/or end dates to continue</h4>
          <div className="d-flex  align-items-center justify-content-center search-session">
            <Link to="#" className="fgh" onClick={() => setmodal3(false)}>OK</Link>
          </div>

        </div>
      </Modal>
      <Modal
        isOpen={modalduties}
        onRequestClose={() => setmodalduties(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "230px",
            top: "calc(50% - 105px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>Duties and/or Responsibilities should be less than 2,500.</h4>
          <div className="d-flex  align-items-center justify-content-center search-session">
            <Link to="#" className="fgh" onClick={() => setmodalduties(false)}>OK</Link>
          </div>

        </div>
      </Modal>

      <Modal
        isOpen={modal2}
        onRequestClose={() => setmodal2(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "230px",
            top: "calc(50% - 105px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>Please complete all of the required fields to continue</h4>
          <div className="d-flex  align-items-center justify-content-center search-session">
            <Link to="#" className="fgh" onClick={() => setmodal2(false)}>OK</Link>
          </div>

        </div>
      </Modal>



      <Modal
        isOpen={modal4}
        onRequestClose={() => setmodal4(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "220px",
            top: "calc(50% - 110px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>Please add a contact number to continue</h4>
          <div className="d-flex  align-items-center justify-content-center search-session">
            <Link to="#" className="fgh" onClick={() => setmodal4(false)}>OK</Link>
          </div>

        </div>
      </Modal>
      <Modal
        isOpen={BilingmodalIsOpenzip}
        onRequestClose={() => setBilingModalIsOpenzip(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please enter a valid zip code</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link to="#" className="fgh" onClick={() => setBilingModalIsOpenzip(false)}>OK</Link>
          </div>

        </div>
      </Modal>
      <Modal
        isOpen={emptyerror100}
        onRequestClose={() => setemptyerror100(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>You have reached 2,500 character limit for summary</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link to="#" className="fgh" onClick={() => setemptyerror100(false)}>OK</Link>
          </div>

        </div>
      </Modal>
      <Modal
        isOpen={modaleditedu}
        onRequestClose={() => setmodaleditedu(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "230px",
            top: "calc(50% - 105px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-2">
          <h4>Please add the previous record first.</h4>
          <div className="d-flex  align-items-center justify-content-center search-session">
            <Link to="#" className="fgh" onClick={() => setmodaleditedu(false)}>OK</Link>
          </div>

        </div>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  ReportJobReducer: state.ReportJobReducer,
  authReducer: state.authReducer,
  ResumeReducer: state.ResumeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  ReportJobAction: (job_id, jobtitle, details) =>
    dispatch(ReportJobAction(job_id, jobtitle, details)),
  ResumeCreate: (first_name, middle_name, last_name, city, latitude, longitude, state, zipcode, email, contact_number, add_contact_number, employment_eligibility, professional_summary, award, links, certification, education, experience, language, volunteer, reference, military, military_branch, military_mos) =>
    dispatch(ResumeCreate(first_name, middle_name, last_name, city, latitude, longitude, state, zipcode, email, contact_number, add_contact_number, employment_eligibility, professional_summary, award, links, certification, education, experience, language, volunteer, reference, military, military_branch, military_mos)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Updateresume);

// code for scoop solutions