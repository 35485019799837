const style = {
  inputContainer: (isLightTheme) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "40px",
    background: isLightTheme ? "#f2f2f2" : "#fff",
    borderRadius: "8px",
    p: "10px",
    color:"#000"
  }),
  value: {
    flexGrow: 1,
  },
};

export default style;
