import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import Jobmanage from "../components/jobmanage/Jobmanage"
import HomeFooter from "../components/homeFooter/HomeFooter";

const ManageJob = () => {
    return (
        <>
            <EmployeeNav2 />
            <Jobmanage />
            <HomeFooter />
        </>
    )
}
export default ManageJob


// code for scoop solutions