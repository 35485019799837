const Button = ({
  title,
  onClick = () => {},
  style = {},
  disabled,
  loading,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="border-0 px-3"
      style={{
        backgroundColor: "orange",
        borderRadius: "5px",
        fontWeight: "900",
        paddingTop: "6px",
        paddingBottom: "4px",
        ...style,
      }}
    >
      {loading ? "Loading..." : title}
    </button>
  );
};

export default Button;
