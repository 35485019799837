import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import InputMask from 'react-input-mask';
import Employernav from "../dropdownnav/Employernav";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const EmployeeBilingCreate = (props) => {
  const [BilingmodalIsOpen3, setBilingModalIsOpen3] = useState(false);
  const [BilingmodalIsOpen4, setBilingModalIsOpen4] = useState(false);
  const [BilingmodalIsOpenzip, setBilingModalIsOpenzip] = useState(false);
  const [first_name, setfirst_name] = useState("")
  const [last_name, setlast_name] = useState("")
  const [company_name, setcompany_name] = useState("")
  const [billing_email, setbilling_email] = useState("")
  const [phone, setphone] = useState("")
  const [fax, setfax] = useState("")
  const [address, setaddress] = useState("")
  const [address_1, setaddress_1] = useState("")
  const [address_2, setaddress_2] = useState("")
  const [city, setcity] = useState("")
  const [state, setstate] = useState("")
  const [zip, setzip] = useState("")
  const [allArray, setallArray] = useState([])
  const [load, setload] = useState(false)
  const [errzip, seterrzip] = useState(false)
  const statecorrect = (e) => {
    setzip(e)
    const dumbdata = e.split('')
    if (dumbdata.length < 5) {
      seterrzip(true)
    }
    else {
      seterrzip(false)
    }
  }

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)
    const ll = await getLatLng(result[0])
    setstate(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
    setaddress(value)
  }

  useEffect(() => {
    setload(true)
    fetch(`${config['baseUrl']}/billings/getbyid/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response.billing
      var temp = []
      temp = [...alljobs]
      setallArray([...temp])
    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
  }, []);
  const createBilling = async () => {
    const reg = /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z]+)\.(com+)$/;
    const zipreg = /^\d{0,}$/;
    if (first_name == "") {
      setBilingModalIsOpen4(true)
    }
    else if (last_name == "") {
      setBilingModalIsOpen4(true)
    }
    else if (company_name == "") {
      setBilingModalIsOpen4(true)
    }
    else if (billing_email == "") {
      setBilingModalIsOpen4(true)
    }
    else if (reg.test(billing_email) === false) {
      setBilingModalIsOpen3(true)
    }
    else if (phone == "") {
      setBilingModalIsOpen4(true)
    }
    else if (fax == "") {
      setBilingModalIsOpen4(true)
    }
    else if (address_1 == "") {
      setBilingModalIsOpen4(true)
    }
    else if (address_2 == "") {
      setBilingModalIsOpen4(true)
    }
    else if (city == "") {
      setBilingModalIsOpen4(true)
    }
    else if (state == "") {
      setBilingModalIsOpen4(true)
    }
    else if (zip == "") {
      setBilingModalIsOpen4(true)
    }
    else if (zip.length < 5) {
      setBilingModalIsOpen4(true)
    }
    else if (zipreg.test(zip) === false) {
      setBilingModalIsOpenzip(true)
    }
    else {
      await fetch(`${config['baseUrl']}/billings/insert`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "userid": localStorage.getItem("userid"),
          "first_name": first_name,
          "last_name": last_name,
          "company_name": company_name,
          "billing_email": billing_email,
          "phone": phone,
          "fax": fax,
          "address_1": address_1,
          "address_2": address_2,
          "city": city,
          "state": state,
          "zip": zip,
        })
      }).then(res => {

        if (res.status !== 200 && res.status !== 201) {
          
        }
        return res.json();
      }).then((response) => {

        if (response.message == "The billing has been successfully inserted.") {
          // window.location = "/bilinginfo"
        }
        else {
          console.log("Something went wrong..")
        }

      }).catch((error) => {
        console.log(error)

      })
    }
  }
  return (
    <>
      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          {/* header======================================================================*/}
          <div className="main-content billing-page">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <div className=" d-flex align-items-center justify-content-between py-2">
                  <h5>Billing Contact Information</h5>
                </div>
                <div className=" d-flex align-items-center justify-content-between py-2">
                  <p className="m-0">Account information</p>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">First Name:</label>
                  <input type="text" onChange={e => setfirst_name(e.target.value)} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Last Name:</label>
                  <input type="text" onChange={e => setlast_name(e.target.value)} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last Name" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Company Name:</label>
                  <input type="text" onChange={e => setcompany_name(e.target.value)} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Company Name" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Billing Email:</label>
                  <input type="email" onChange={e => setbilling_email(e.target.value)} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Billing Email" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Phone Number:</label>
                  <InputMask {...props} mask="(999) 999-9999" value={phone} className="form-control employee-input-email job-search w-100" onChange={(e) => setphone(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Fax:</label>
                  <InputMask {...props} mask="(999) 999-9999" value={fax} className="form-control employee-input-email job-search w-100" onChange={(e) => setfax(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12 pt-5 form-2-section">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Address Line 1:</label>
                  <input type="text" onChange={e => setaddress_1(e.target.value)} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Address Line 1" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Address Line 2:</label>
                  <input type="text" onChange={e => setaddress_2(e.target.value)} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Address Line 2" />
                </div>
                <div className="form-group ghg">
                    <label htmlFor="exampleInputPassword1">City:   <asterisk style={{ color: "red" }}> *</asterisk ></label>
                    <PlacesAutocomplete
                      value={address.split(",")[0]}
                      onChange={setaddress}
                      onSelect={handleSelect}
                      searchOptions={{ componentRestrictions: { country: 'us' } }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            id="exampleInputPassword1"
                            className="form-control employee-input-email job-search"
                            {...getInputProps({
                              placeholder: 'City',
                              className: 'location-search-input',
                            })}
                          />
                          <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item p-2 --active'
                                : 'suggestion-item p-2 ';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">State</label>
                  <select onChange={(e) => setstate(e.target.value)} style={{ width: '60px !important', height: '36px' }} className="employee-input-ema" name id
                    value={state}
                  >
                    <option selected value="Select">Select</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA	">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Zip</label>
                  <input type="text" onChange={(e) => statecorrect(e.target.value)} className="form-control employee-input-email job-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Zip" />
                  {errzip == true ? <small className="text-danger" style={{ color: "red" }}>*Zip Code requires at least five digits</small> : ""}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="main-content-session inner-session">
                  {
                    allArray && allArray.length > 0 ?
                      // <Link to="/bilinginfo" className="send-invitation ">SAVE</Link>
                      <></>
                      :
                      <Link onClick={() => createBilling()} className="send-invitation ">SAVE</Link>
                  }
                  <Link to="/" className="send-invitation ml-3">Cancel</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={BilingmodalIsOpen4}
        onRequestClose={() => setBilingModalIsOpen4(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please complete all of the required fields to continue</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpen4(false)}>OK</Link>
          </div>

        </div>
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpen3}
        onRequestClose={() => setBilingModalIsOpen3(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "240px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please enter a valid email address</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpen3(false)}>OK</Link>
          </div>

        </div>
      </Modal>

      <Modal
        isOpen={BilingmodalIsOpenzip}
        onRequestClose={() => setBilingModalIsOpenzip(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "180px",
            top: "calc(50% - 90px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>Please enter valid zip code</h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link className="fgh" onClick={() => setBilingModalIsOpenzip(false)}>OK</Link>
          </div>

        </div>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeBilingCreate);


// code for scoop solutions