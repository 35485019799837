import React from "react";
import "./HomeStepSection.css";
import arrow from "../../assets/arrow.png";
import { useLocation } from "react-router-dom";

const HomeStepSection = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDarkMode = searchParams.get("if") === "true";

  return (
    <div className="resizeParentContainer">
      <div style={{ maxWidth: 1350, margin: "auto" }}>
        <div className="row px-5 pt-5 pb-3 pb-md-5 containerResize">
          <div className="col-12 d-flex justify-content-center">
            <div>
              <p className="firstHeadTop text-center">Working Process</p>
              <p className="secondHeadTop">HOW IT WORKS</p>
              <div
                style={{
                  backgroundColor: "#F48A20",
                  height: 2,
                  margin: "3px auto",
                  width: "50%",
                }}
              />
            </div>
          </div>
        </div>
        <div className="row row-spacing px-1 px-md-5 ">
          <div className="row row-spacing d-flex justify-content-center align-items-stretch mb-0 mb-md-5">
            <div
              className={`col-8 col-lg-3 mt-3 mt-lg-0 stepCard px-xl-3 mb-5 mb-md-0 ${
                isDarkMode ? "dark-cover-shadow" : ""
              }`}
            >
              <div className="text-center">
                <button
                  onClick={() => null}
                  className={`rounded border-mute mute-next bg-dark freeBtn`}
                >
                  Step 1
                </button>
                <p className="stepHeading">SIGN UP FOR FREE</p>
                <p className="stepPara pl-2">
                  Registering is quick and easy! This only takes a few minutes
                  and you can update your information any time.
                </p>
              </div>
            </div>
            <div className="roundBox col-md-1 d-lg-flex justify-content-center align-items-center d-none d-sm-none">
              <p className="greatherStyle">
                <img src={arrow} alt="right arrow" />
              </p>
            </div>
            <div
              className={`col-8 col-lg-3 mt-3 mt-lg-0 stepCard  px-xl-3  mb-5 mb-md-0 ${
                isDarkMode ? "dark-cover-shadow" : ""
              }`}
            >
              <div className="text-center">
                <button
                  onClick={() => null}
                  className={`rounded border-mute mute-next bg-dark freeBtn`}
                >
                  Step 2
                </button>
                <p className="stepHeading">UPLOAD YOUR RESUME</p>
                <p className="stepPara pl-2">
                  Upload your resume and complete your profile with all the
                  details to make it stand out
                </p>
              </div>
            </div>
            <div className="roundBox col-md-1 d-lg-flex justify-content-center align-items-center d-none d-sm-none">
              <p className="greatherStyle">
                <img src={arrow} alt="right arrow" />
              </p>
            </div>
            <div
              className={`col-8 col-lg-3 mt-3 mt-lg-0 stepCard  px-xl-3 mb-5 mb-md-0 ${
                isDarkMode ? "dark-cover-shadow" : ""
              }`}
            >
              <div className="text-center">
                <button
                  onClick={() => null}
                  className={`rounded border-mute mute-next bg-dark freeBtn`}
                >
                  Step 3
                </button>
                <p className="stepHeading">APPLY FOR JOBS</p>
                <p className="stepPara pl-2">
                  Browse open jobs or narrow your search using specific keywords
                  and/or use our available filter options
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeStepSection;
