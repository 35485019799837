import React, { useState, useEffect } from "react";
import "./Footer.css";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/youtube.png";
import lin from "../../assets/ftr-lin.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { AppliedJob } from "../../actions/Appliedjobaction";
import { getTheme } from "../../actions/localtheme";
import { getUser } from "../../actions/Getuseraction";
import { createSearch } from "../../actions/Jobsearchaction";
import Modal from "react-modal";
import { Drawer, Button, Space, Radio } from "antd";
import { linkClasses } from "@mui/material";

const config = require("../../helpers/config.json");

const Footer = (props) => {
  const [localstate, setlocalstate] = useState(localStorage.getItem("themee"));
  const search = useLocation();
  console.log(search);
  useEffect(() => {
    if (localStorage.getItem("themee") == null) {
      localStorage.setItem("themee", false);
    }
  }, []);
  const [themechange, setthemechange] = useState(true);
  const theme = async (e) => {
    fetch(`${config["baseUrl"]}/theme`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        theme: Number(e),
        id: localStorage.getItem("userid"),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.message !== "successfull") {
          console.log(
            "Can't add new command when connection is in closed state"
          );
        } else {
          window.location = `${search?.pathname + search?.search}`;
        }
      })
      .catch((error) => {
        const authError = JSON.stringify(error);
        console.log(authError);
      });
  };
  const themechangelocal = (e) => {
    if (
      window.location.href.search("false") == -1 &&
      window.location.href.search("true") == -1
    ) {
      setTimeout(() => {
        window.location.href = `${search?.pathname}${
          search.search == "" ? `?if=${true}` : `?${search?.search}&if=${true}`
        }`;
      }, 500);
    } else if (window.location.href.search("false") !== -1) {
      setTimeout(() => {
        window.location.href = `${search?.pathname}${
          search.search == ""
            ? `?`
            : `${search.search.replace("false", "true")}`
        }`;
      }, 500);
    } else {
      setTimeout(() => {
        window.location.href = `${search?.pathname}${
          search.search == ""
            ? `?`
            : `${search?.search?.replace("true", "false")}`
        }`;
      }, 500);
    }
    // window.location.reload(false)
    // if (e == true) {
    //   document.getElementById("tg").classList.remove('white');
    //   document.getElementById("tg").classList.add('black');
    //   props.getTheme(true)
    // }
    // else if (e == false) {
    //   document.getElementById("tg").classList.remove('black');
    //   document.getElementById("tg").classList.add('white');
    //   props.getTheme(false)
    // }
  };
  const signOut = () => {
    localStorage.removeItem("array");
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("zip");
    localStorage.removeItem("role");
    localStorage.removeItem("role1");
    window.location = `/${
      props.GetUserReducer.users.theme == 1 ? "?if=true" : "?if=false"
    }`;
  };
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [themeanime, setthemeanime] = useState(true);
  return (
    <>
      {props.GetUserReducer.users.role == 1 ? (
        <div className="header-temporary-footer-responsive">
          <div className="header-temporary-footer-responsive-inner d-flex">
            <div className="d-flex align-items-center justify-content-center tred-round flex-column">
              <i class="fa fa-credit-card hirehome-21" aria-hidden="true"></i>
              <a
                href={`/get-premium${
                  window.location.href.search("false") == -1 &&
                  window.location.href.search("true") == -1
                    ? `/`
                    : `?if=${
                        window.location.href.search("false") == -1
                          ? true
                          : false
                      }`
                }`}
                className="text-dark hirehome-21"
              >
                Pricing
              </a>
            </div>

            <div className="d-flex align-items-center justify-content-center tred-round flex-column">
              <i class="fa fa-inbox hirehome-21" aria-hidden="true"></i>
              <a href="/manage-job" className="text-dark hirehome-21">
                Manage Job
              </a>
            </div>

            <div className="d-flex align-items-center justify-content-center tred-round-ex flex-column">
              <i class="fa fa-briefcase hirehome" aria-hidden="true"></i>
              <a href="/postjob" className="text-light hirehome">
                Post Jobs
              </a>
            </div>

            {/* <div className="d-flex align-items-center justify-content-center tred-round flex-column">
              <i class="fa fa-file-text-o hirehome-21" aria-hidden="true"></i>
              <a href="/search-resume" className="text-dark hirehome-21">
                Resumes
              </a>
            </div> */}

            <div className="d-flex align-items-center justify-content-center tred-round flex-column">
              <i class="fa fa-sign-out hirehome-21" aria-hidden="true"></i>
              <small
                className="text-dark hirehome-21"
                onClick={() => {
                  signOut();
                }}
              >
                Log out
              </small>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {props.GetUserReducer.users.role == 0 ? (
        <div className="header-temporary-footer-responsive">
          <div className="header-temporary-footer-responsive-inner d-flex">
            <div className="d-flex align-items-center justify-content-center tred-round flex-column">
              <i class="fa fa-user-circle-o hirehome-21" aria-hidden="true"></i>
              <a href="/profile" className="text-dark hirehome-21">
                Profile
              </a>
            </div>

            <div className="d-flex align-items-center justify-content-center tred-round flex-column">
              <i class="fa fa-inbox hirehome-21" aria-hidden="true"></i>
              <a href="/save-jobs" className="text-dark hirehome-21">
                Saved Jobs
              </a>
            </div>

            <div className="d-flex align-items-center justify-content-center tred-round-ex flex-column">
              <i class="fa fa-file-text-o hirehome" aria-hidden="true"></i>
              <a href="/previewresume" className="text-light hirehome">
                Preview Resume
              </a>
            </div>

            <div className="d-flex align-items-center justify-content-center tred-round flex-column">
              <i class="fa fa-history hirehome-21" aria-hidden="true"></i>
              <a href="/applicationhistory" className="text-dark hirehome-21">
                History
              </a>
            </div>

            <div className="d-flex align-items-center justify-content-center tred-round flex-column">
              <i class="fa fa-sign-out hirehome-21" aria-hidden="true"></i>
              <small
                className="text-dark hirehome-21"
                onClick={() => {
                  signOut();
                }}
              >
                Log out
              </small>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex header-temporary-footer align-items-center flex-column justify-content-center pt-3 p-3">
        <div className="d-flex footer-img-small">
          <a href="https://www.facebook.com/Hire-Choices-LLC-106119302081524/">
            {" "}
            <img src={fb} />
          </a>
          <a href="https://instagram.com/hirechoices?igshid=YmMyMTA2M2Y=">
            <img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
          </a>
          <a href="https://www.linkedin.com/in/hire-choices-llc-64a95a231/">
            <img src="https://img.icons8.com/color/48/000000/linkedin.png" />
          </a>
          <a href="https://www.youtube.com/channel/UC3Le1tWkmOnCIBvM27_kmOA">
            <img src={yt} />
          </a>
          <div className="d-flex align-items-center px-4">
            {props.GetUserReducer.users &&
            props.GetUserReducer.users.theme !== null &&
            props.GetUserReducer.users.theme !== undefined &&
            props.GetUserReducer.users.theme !== "" ? (
              props.GetUserReducer.users &&
              props.GetUserReducer.users.theme == 0 ? (
                <div>
                  <div className="d-flex align-items-center">
                    <b>Dark Mode: </b>
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="checkbox"
                      defaultChecked={
                        props.GetUserReducer.users &&
                        props.GetUserReducer.users.theme == 0
                          ? false
                          : true
                      }
                      onChange={() => {
                        setthemeanime(false);
                        theme(1);
                      }}
                    />
                    <label for="checkbox" className="label ml-3">
                      {props.GetUserReducer.users.theme == 1 ? (
                        <i class="fa fa-sun-o"></i>
                      ) : (
                        <i class="fa fa-moon-o"></i>
                      )}
                      <div
                        style={{
                          transform:
                            themeanime == false
                              ? "translateX(-18px)"
                              : "translateX(0px)",
                        }}
                        class="ball"
                      ></div>
                    </label>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex align-items-center">
                    <b>Dark Mode: </b>
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="checkbox"
                      defaultChecked={
                        props.GetUserReducer.users &&
                        props.GetUserReducer.users.theme == 0
                          ? false
                          : true
                      }
                      onChange={() => {
                        theme(0);
                        setthemeanime(false);
                      }}
                    />
                    <label for="checkbox" className="label ml-3">
                      {props.GetUserReducer.users.theme == 1 ? (
                        <i class="fa fa-sun-o"></i>
                      ) : (
                        <i class="fa fa-moon-o"></i>
                      )}
                      <div
                        style={{
                          transform:
                            themeanime == false
                              ? "translateX(-18px)"
                              : "translateX(0px)",
                        }}
                        class="ball"
                      ></div>
                    </label>
                  </div>
                </div>
              )
            ) : (
              <>
                {window.location.href == "https://hirechoices.com/?if=false" ||
                window.location.href == "https://hirechoices.com/" ? (
                  <div>
                    <div className="d-flex align-items-center">
                      <b>Dark Mode: </b>
                      <input
                        type="checkbox"
                        class="checkbox"
                        id="checkbox"
                        defaultChecked={
                          localStorage.getItem("themee") !== false
                            ? true
                            : false
                        }
                        onChange={() => {
                          setthemechange(!themechange);
                          localStorage.setItem(
                            "themee",
                            localStorage.getItem("themee") !== false
                              ? true
                              : false
                          );
                          themechangelocal(themechange);
                          setthemeanime(false);
                        }}
                      />
                      <label for="checkbox" className="label ml-3">
                        <i class="fa fa-moon-o"></i>
                        <div
                          style={{
                            transform:
                              window.location.href ==
                                "https://hirechoices.com/?if=true" ||
                              themeanime == false
                                ? "translateX(-18px)"
                                : "translateX(0px)",
                          }}
                          class="ball"
                        ></div>
                      </label>
                    </div>
                  </div>
                ) : (
                  // <div>
                  //   <div className="d-flex align-items-center">
                  //     <b>Dark Mode: </b>
                  //     <input type="checkbox" class="checkbox" id="checkbox" defaultChecked={localStorage.getItem("themee") !== false ? true : false} onChange={() => {
                  //       setthemechange(!themechange)
                  //       themechangelocal(themechange)
                  //       setthemeanime(true)
                  //     }
                  //     } />
                  //     <label for="checkbox" className="label ml-3">
                  //       <i  class="fa fa-sun-o"></i>
                  //       <div style={{ transform: window.location.href == "https://hirechoices.com/?if=false"||themeanime == true ? "translateX(0px)" : "translateX(-18px)" }} class='ball'></div>
                  //     </label>
                  //   </div>
                  // </div>
                  <div>
                    <div className="d-flex align-items-center">
                      <b>Dark Mode: </b>
                      <input
                        type="checkbox"
                        class="checkbox"
                        id="checkbox"
                        defaultChecked={
                          localStorage.getItem("themee") !== false
                            ? true
                            : false
                        }
                        onChange={() => {
                          setthemechange(!themechange);
                          themechangelocal(themechange);
                          setthemeanime(false);
                        }}
                      />
                      <label for="checkbox" className="label ml-3">
                        <i class="fa fa-sun-o"></i>
                        <div
                          style={{
                            transform:
                              window.location.href ==
                                "https://hirechoices.com/?if=false" ||
                              themeanime == false
                                ? "translateX(-18px)"
                                : "translateX(0px)",
                          }}
                          class="ball"
                        ></div>
                      </label>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <p>© 2022 - Hire Choices</p>
        <p>
          {" "}
          <Link
            className="fgh"
            to={`/contact-us${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
          >
            Contact Us
          </Link>{" "}
          |{" "}
          <Link
            className="fgh"
            to={`/about-us${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
          >
            About Us |{" "}
          </Link>
          <Link
            className="fgh"
            to={`/help-center${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
          >
            Help Center
          </Link>{" "}
          |{" "}
          <Link
            className="fgh"
            to={`/terms-condition${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
          >
            Terms of Services
          </Link>{" "}
          |{" "}
          <Link
            className="fgh"
            to={`/privacy-policy${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
          >
            Privacy Policy
          </Link>
        </p>
      </div>
      <Modal
        isOpen={BilingmodalIsOpen}
        onRequestClose={() => setBilingModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "1000",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "80%",
            height: "250px",
            borderRadius: "10px",
            top: "calc(50% - 125px)",
            left: "0",
            right: "0",
            background: "#fff",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "20px",
            zIndex: "1000",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <a
            href={`/employer-login${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
            className="responsive-login-btn"
          >
            Employer <i class="fa fa-angle-right" aria-hidden="true"></i>
          </a>
          <a
            href={`/candidate-login${
              window.location.href.search("false") == -1 &&
              window.location.href.search("true") == -1
                ? `/`
                : `?if=${
                    window.location.href.search("false") == -1 ? true : false
                  }`
            }`}
            className="responsive-login-btn"
          >
            Job Seeker <i class="fa fa-angle-right" aria-hidden="true"></i>
          </a>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  AppliedJobReducer: state.AppliedJobReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  AppliedJob: (jobid, jobtitle) => dispatch(AppliedJob(jobid, jobtitle)),
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
  getUser: () => dispatch(getUser()),
  getTheme: (theme) => dispatch(getTheme(theme)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);

// code for scoop solutions
