import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import { AlljobsGet } from "../../actions/Alljobs"
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import useWindowDimensions from '../administrator/innerwidthcom';
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import Employernav from "../dropdownnav/Employernav";
import DropdownNav from "../dropdownnav/Dropdownnav"
import dayjs from "dayjs";import NotifyDot from "../notifydot";
;
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");

const JobManage = (props) => {
  const { height, width } = useWindowDimensions();

  const updateStatus = async (e, id) => {
    await fetch(`${config['baseUrl']}/jobs/update/status`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "status": e,
        "id": id
      })
    }).then(res => {

      if (res.status !== 200 && res.status !== 201) {
        
      }
      return res.json();
    }).then((response) => {

      if (response.message == "Resume Successfully." || response.message == "Pause Successfully.") {
        window.location = "/manage-job"
      }
      else {
        console.log("Something went wrong..")
      }

    }).catch((error) => {
      console.log(error)

    })
  }
  const [jobid, setjobid] = useState("")
  const deletemodal = (e) => {
    setBilingModalIsOpen(true)
    setjobid(e)
  }
  const refreshmodal = (e) => {
    setBilingModalIsOpen2(true)
    setjobid(e)
  }
  const UpdateStatus = async (id) => {
    await fetch(`${config['baseUrl']}/jobs/refresh`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "id": id
      })
    }).then(res => {

      if (res.status !== 200 && res.status !== 201) {
        
      }
      return res.json();
    }).then((response) => {

      if (response.message == "Refreshed.") {
        window.location = "/manage-job"
      }
      else {
        console.log("Something went wrong..")
      }

    }).catch((error) => {
      console.log(error)

    })
  }
  const DeleteJob = async (id) => {
    await fetch(`${config['baseUrl']}/jobs/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "id": jobid
      })
    }).then(res => {

      if (res.status !== 200 && res.status !== 201) {
        
      }
      return res.json();
    }).then((response) => {

      if (response.message == "The Job has been successfully Deleted.") {
        window.location = "/manage-job"
      }
      else {
        console.log("Something went wrong..")
      }

    }).catch((error) => {
      console.log(error)

    })
  }
  const [load, setload] = useState(false)
  const [allArray, setallArray] = useState([])
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const [modalwidth, setmodalwidth] = useState(850)

  useEffect(async () => {
    if (width <= 900) {
      setmodalwidth("340px")
    }
    loadgetjob()
  }, []);
  const loadgetjob = async () => {
    setload(true)
    setTimeout(() => {
      setload(false)
    }, 2000);
    await fetch(`${config['baseUrl']}/jobs/getbyuserid/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response.job
      setallArray(...allArray, alljobs)
        ;
    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
    return null
  }
  const [order, setorder] = useState("ASC")
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("DSC")
    }
    if (order === "DSC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("ASC")
    }

  }
  return (
    <>
      {
        load == false ?
          <>
            <div className="container-fluid" id="Employee">
              <div className="container">
                <Employernav />
                {/* header======================================================================*/}
                <div className="main-content ">
                  <div className="d-flex main-content-session inner-session py-2">
                    <h5>Manage Jobs</h5>
                  </div>
                  {
                    allArray && allArray.length > 0 ?
                      <div className="usr-tbl">
                        <table id="example" className="table table-striped table-bordered dataTable table-responsive" cellSpacing={0} width="100%" role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                          <thead>
                            <tr role="row">
                              <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '20%' }}>
                                Jobs Open {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("job_title")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("job_title")} />}</th>
                              <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '25%' }}>
                                Original Date Opened {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("created_at")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("created_at")} />}</th>
                              <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '20%' }}>
                                Last Date Refreshed
                              </th>
                              <th className="sorting_desc" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Age: activate to sort column ascending" aria-sort="descending" style={{ width: '5%' }}>Refresh</th>
                              <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Start date: activate to sort column ascending" style={{ width: '20%' }}>
                                Opened By {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("email")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("email")} />}</th>
                                <th className="sorting_desc" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Age: activate to sort column ascending" aria-sort="descending" style={{ width: '5%' }}>Applied</th>
                              <th className="sorting text-center" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Salary: activate to sort column ascending" style={{ width: '20%' }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          {allArray && allArray.length > 0 ? allArray.map(job => (
                            <tbody>

                              <tr role="row" className="odd" key={job.id}>
                                <td>{job.job_title !== null && job.job_title !== undefined && job.job_title !== "" ? job.job_title : ""}{props?.AppliedJobReducer?.jobIds?.includes(job?.id) && <NotifyDot customStyle={{marginLeft:"4px"}}/>}</td>
                                <td className="sorting_1">{job.created_at !== null && job.created_at !== undefined && job.created_at !== "" ? dayjs(job.created_at).format("MM-DD-YYYY") : ""}</td>
                                <td>{job.updated_at !== null && job.updated_at !== undefined && job.updated_at !== "" ? dayjs(job.updated_at).format("MM-DD-YYYY") : "Not refreshed "}</td>
                                <td>
                                  {
                                    job.updated_at !== null && job.updated_at !== undefined && job.updated_at !== "" ?
                                    `${job.updated_at.split("T")[0]}`<new Date().toISOString().split("T")[0]
                                    ?
                                    <div className={props.GetUserReducer.users && props.GetUserReducer.users.theme !== null && props.GetUserReducer.users.theme !== undefined && props.GetUserReducer.users.theme !== "" && props.GetUserReducer.users.theme !== 0 ? "account-managmrnt-custom-radio-111" : "account-managmrnt-custom-radio-1"} onClick={() => UpdateStatus(job.id)}>
                                    </div>
                                      :
                                      <div className="account-managmrnt-custom-radio-0" onClick={() => UpdateStatus(job.id)}>
                                      </div>
                                      :
                                      <div className={props.GetUserReducer.users && props.GetUserReducer.users.theme !== null && props.GetUserReducer.users.theme !== undefined && props.GetUserReducer.users.theme !== "" && props.GetUserReducer.users.theme !== 0 ? "account-managmrnt-custom-radio-111" : "account-managmrnt-custom-radio-1"} onClick={() => UpdateStatus(job.id)}>
                                      </div>
                                  }

                                </td>
                                <td>{job.email !== null && job.email !== undefined && job.email !== "" ? job.email : ""}</td>
                                <td><Link to={`/apply-to-job-specific?id=${job.id}`} className="viewhover">View</Link></td>
                                <td style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Link to={`/edit-job?id=${job.id}`} data-toggle="modal" data-target="#exampleModal-add-2" href className="tab-edit fgh"><i className="fa fa-pencil-square-o" aria-hidden="true" /></Link>
                                  {
                                    job.status !== null && job.status !== undefined && job.status !== "" ? job.status == 1 ?
                                      <a onClick={(e) => updateStatus(0, job.id)} className="tab-edit"><i className="fa fa-pause " aria-hidden="true" /></a>
                                      :
                                      <a onClick={(e) => updateStatus(1, job.id)} className="tab-edit"><i className="fa fa-play" aria-hidden="true" /></a>
                                      : ""
                                  }
                                  <a onClick={(e) => deletemodal(job.id)} className="tab-remove ml-2" data- toggle="modal" data-target="#exampleModal-remove-3" href><i className="fa fa-trash" aria-hidden="true" /></a></td>
                              </tr>
                            </tbody>

                          )) :
                            <div className="d-flex w-100 aliign-items-center justify-content-center not-found px-5">
                              no jobs found
                            </div>
                          }
                        </table>
                      </div>
                      : <div className="d-flex w-100 aliign-items-center justify-content-center not-found px-5 nofournd">
                        no jobs found
                      </div>
                  }
                </div>
                {/* footer====================================================================== */}

              </div>
            </div>
            <Modal
              isOpen={BilingmodalIsOpen2}
              onRequestClose={() => setBilingModalIsOpen2(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: modalwidth,
                  height: "200px",
                  top: "calc(50% - 100px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className=" justify-content-center align-items-center pt-3">
                <h5 className="text-center" style={{ color: "black" }}>Do you want to refresh this Job?</h5>
                <div className="d-flex justify-content-center mt-4">
                  <p onClick={(e) => UpdateStatus()} className="p-3 rounded" style={{ color: "white", cursor: "pointer", backgroundColor: "#8dc63f" }}><b>CONTINUE</b></p>
                  <p onClick={() => setBilingModalIsOpen2(false)} className="ml-5 pt-3" style={{ color: "black", cursor: "pointer" }}><b>Cancel</b></p>
                </div>
              </div>
            </Modal>
            <Modal
              isOpen={BilingmodalIsOpen}
              onRequestClose={() => setBilingModalIsOpen(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: modalwidth,
                  height: "200px",
                  top: "calc(50% - 100px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className=" justify-content-center align-items-center pt-3">
                <h5 className="text-center" style={{ color: "black" }}>Do you want to delete this Job?</h5>
                <div className="d-flex justify-content-center mt-4">
                  <p onClick={(e) => DeleteJob()} className="p-3 rounded" style={{ color: "white", cursor: "pointer", backgroundColor: "#8dc63f" }}><b>CONTINUE</b></p>
                  <p onClick={() => setBilingModalIsOpen(false)} className="ml-5 pt-3" style={{ color: "black", cursor: "pointer" }}><b>Cancel</b></p>
                </div>
              </div>
            </Modal>
          </>
          :
          <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  AppliedJobReducer: state.AppliedJobReducer,
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  AllJobsReducer: state.AllJobsReducer
});

const mapDispatchToProps = (dispatch) => ({
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
  AlljobsGet: () =>
    dispatch(AlljobsGet()),
});
export default connect(mapStateToProps, mapDispatchToProps)(JobManage);


// code for scoop solutions