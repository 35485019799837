import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { AppliedJob } from "../../actions/Appliedjobaction";
import { getUser } from "../../actions/Getuseraction";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Drawer, Button, Space, Radio } from "antd";
import fb from "../../assets/ftr-fb.png";
import yt from "../../assets/ftr-yt.png";
import logo from "../../assets/logo.png";
import FullPageLoader from "../fullpageloader/fullPageLoader";
import { createSearch } from "../../actions/Jobsearchaction";
import NotifyDot from "../notifydot";

const config = require("../../helpers/config.json");

Modal.setAppElement("#root");

const EmployerNav = (props) => {
  const subscription = useSelector(state=>state?.SubscriptionInfo)

  const isNewRequest = !!props.AppliedJobReducer?.jobIds?.length
  
  const showDrawer = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setVisible(false);
  };

  const [themechange, setthemechange] = useState(true);
  const [themeanime, setthemeanime] = useState(true);
  const theme = async (e) => {
    fetch(`${config["baseUrl"]}/theme`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        theme: Number(e),
        id: localStorage.getItem("userid"),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.message !== "successfull") {
          console.log(
            "Can't add new command when connection is in closed state"
          );
        } else {
          window.location = `${search?.pathname + search?.search}`;
        }
      })
      .catch((error) => {
        const authError = JSON.stringify(error);
        console.log(authError);
      });
  };
  const themechangelocal = (e) => {
    if (e == true) {
      document.getElementById("tg").classList.remove("white");
      document.getElementById("tg").classList.add("black");
    } else if (e == false) {
      document.getElementById("tg").classList.remove("black");
      document.getElementById("tg").classList.add("white");
    }
  };

  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("left");
  const search = useLocation();
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const [allArray, setallArray] = useState([]);
  const [load, setload] = useState(false);
  useEffect(() => {
    setload(true);
    fetch(
      `${config["baseUrl"]}/billings/getbyid/${localStorage.getItem("userid")}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if(!!response.billing){
          setload(false);
          const alljobs = response.billing;
          var temp = [];
          temp = alljobs;
          setallArray([...temp]);
        }
      })
      .catch((error) => {
        setload(false);
        console.log("error", error);
      });
  }, []);
  
  const { SubMenu } = Menu;

  const menu = (
    <Menu>
      <Menu.ItemGroup>
        {props.GetUserReducer.users &&
        props.GetUserReducer.users &&
        props.GetUserReducer.users.subscription !== null &&
        props.GetUserReducer.users.subscription !== undefined &&
        props.GetUserReducer.users.subscription !== "" ? (
          ""
        ) : (
            <>
          <Menu.Item>
            <Link className="fgh" to="/company-administrator">
              Profile
            </Link>
          </Menu.Item>
            <Menu.Item>
              <Link className="fgh" to="/subscription">
                Subscription
              </Link>
            </Menu.Item>
          <Menu.Item>
            <Link className="fgh" to="/manage-job">
              Job Management {isNewRequest && <NotifyDot />}
            </Link>
          </Menu.Item>
            </>
        )}
        {/* {props.GetUserReducer.users &&
        props.GetUserReducer.users &&
        props.GetUserReducer.users.subscription !== null &&
        props.GetUserReducer.users.subscription !== undefined &&
        props.GetUserReducer.users.subscription !== "" ? (
          ""
        ) : (
          <Menu.Item>
            <>
              <Link className="fgh" to="/account-management">
                Account Management
              </Link>
            </>
          </Menu.Item>
        )} */}
        {/* {props.GetUserReducer.users &&
        props.GetUserReducer.users &&
        props.GetUserReducer.users.billing_info !== null &&
        props.GetUserReducer.users.billing_info !== undefined &&
        props.GetUserReducer.users.billing_info !== "" &&
        props.GetUserReducer.users.billing_info !== 0 ? (
          <SubMenu title="Billing">
            {allArray && allArray.length > 0 ? (
              <Menu.Item>
                <Link className="fgh" to="/bilinginfo">
                  Payment Method
                </Link>
              </Menu.Item>
            ) : (
              <Menu.Item>
                <Link className="fgh" to="/biling">
                  Payment Method
                </Link>
              </Menu.Item>
            )}

            <Menu.Item>
              <Link className="fgh" to="/invoice">
                Invoices
              </Link>
            </Menu.Item>
          </SubMenu>
        ) : (
          ""
        )} */}

        <Menu.Item>
          <Link className=" fgh" onClick={() => signOut()}>
            Sign Out
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const signOut = () => {
    localStorage.removeItem("array");
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("zip");
    localStorage.removeItem("role");
    localStorage.removeItem("role1");
    window.location = `/`;
  };
  return (
    <>
      <nav
        id="header"
        className="navbar header-temporary-header navbar-expand-lg navbar-light px-0"
      >
        <div id="header-left">
          <div className="d-flex align-items-center">
            <Link
              className="fgh"
              to={`/${
                window.location.href.search("false") == -1 &&
                window.location.href.search("true") == -1
                  ? ``
                  : `?if=${
                      window.location.href.search("false") == -1 ? true : false
                    }`
              }`}
            >
              <img className="img-fluid" src={logo} alt="" />
            </Link>
            <div className="res-dis-none in-menu d-flex h-100 align-items-center d-flex align-items-center justify-content-end">
              {props.GetUserReducer.users &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.zip_code !== null &&
              props.GetUserReducer.users.zip_code !== undefined &&
              props.GetUserReducer.users.zip_code !== "" ? (
                props.GetUserReducer.users.create_posting !== null &&
                props.GetUserReducer.users.create_posting !== undefined &&
                props.GetUserReducer.users.create_posting !== "" &&
                props.GetUserReducer.users.create_posting !== 0 ? (
                  <>
                    <Link to="/postjob" className="employers-button-emp fgh">
                      Post Jobs
                    </Link>
                    |{" "}
                    <Link to="/manage-job" className="employers-button-emp fgh">
                      Job Management {isNewRequest && <NotifyDot />}
                    </Link>{" "}
                    |
                  </>
                ) : (
                  <>
                    {" "}
                    <Link
                      onClick={() => setBilingModalIsOpen2(true)}
                      className="employers-button-emp fgh"
                    >
                      Post Jobs
                    </Link>{" "}
                    |
                    <Link
                      onClick={() => setBilingModalIsOpen2(true)}
                      className="employers-button-emp fgh"
                    >
                      Job Management {isNewRequest && <NotifyDot />}
                    </Link>{" "}
                    |
                  </>
                )
              ) : (
                <>
                  {" "}
                  <Link
                    to={`/employer-login${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                        ? `/`
                        : `?if=${
                            window.location.href.search("false") == -1
                              ? true
                              : false
                          }`
                    }`}
                    className="employers-button-emp fgh"
                  >
                    Post Jobs
                  </Link>{" "}
                  |
                  <Link to="/manage-job" className="employers-button-emp fgh">
                    Job Management {isNewRequest && <NotifyDot />}
                  </Link>{" "}
                  |
                </>
              )}
              {props.GetUserReducer.users &&
              props.GetUserReducer.users &&
              props.GetUserReducer.users.billing_info !== null &&
              props.GetUserReducer.users.billing_info !== undefined &&
              props.GetUserReducer.users.billing_info !== "" &&
              props.GetUserReducer.users.billing_info !== 0 ? (
                <>
                  {" "}
                  <Link
                    to={`/get-premium${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                        ? `/`
                        : `?if=${
                            window.location.href.search("false") == -1
                              ? true
                              : false
                          }`
                    }`}
                    className="employers-button-emp fgh"
                  >
                    Product &amp; Pricing
                  </Link>{" "}
                </>
              ) : (
                <>
                  {" "}
                  <Link
                    onClick={() => setBilingModalIsOpen2(true)}
                    className="employers-button-emp fgh"
                  >
                    Product &amp; Pricing
                  </Link>{" "}
                </>
              )}
              {/* {!subscription?.isPayAsYouGo && <>
                { props.GetUserReducer.users &&
                !!props.GetUserReducer.users.resume_info &&
                props.GetUserReducer.users.resume_info !== 0 ? (
                  <>
                  
                    <Link
                      to="/search-resume"
                      className="employers-button-emp fgh"
                    >
                      Search Resumes
                    </Link>
                  </>
                ) : (
                  <>
                  |
                    <Link
                      onClick={() => setBilingModalIsOpen2(true)}
                      className="employers-button-emp fgh"
                    >
                      Search Resumes
                    </Link>
                  </>
                )}
              </>} */}
            </div>
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse simpkle-nav-res-padding"
          id="navbarText"
        >
          <ul className="navbar-nav mr-auto"></ul>
          <span className=" navbar-text" id="header-right">
            <div className="d-flex h-100 align-items-center d-flex align-items-center justify-content-end">
              <div style={{ display: "none" }}>
                <button
                  className="employers-button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Employers
                  <i className="fa fa-angle-down ml-2" aria-hidden="true" />
                </button>
                <div
                  className="dropdown-menu"
                  style={{ right: "0px !important", left: "auto !important" }}
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link dropdddd"
                  onClick={(e) => e.preventDefault()}
                >
                  {localStorage.getItem("email")} <DownOutlined />
                </a>
              </Dropdown>
              <button
                onClick={showDrawer}
                className="res-bar-ham res-dis-none-op"
              >
                <i class="dropdddd fa fa-bars" aria-hidden="true"></i>
              </button>

              <Drawer
                placement={placement}
                width={300}
                onClose={onClose}
                visible={visible}
              >
               <div>
                  <Link
                    className="text-dark"
                    to={`/postjob`}
                  >
                    Post Jobs
                  </Link>
                </div>
                <hr />
                {subscription?.isPayAsYouGo && (
                  <>
                    <div>
                    <Link
                      className="text-dark"
                      to={`/subscription`}
                      >
                      Subscription
                    </Link>
                    </div>
                    <hr />
                  </>
                )}
                <div>
                  <Link
                    className="text-dark"
                    to={`/manage-job`}
                  >
                    Job Management 
                  </Link>
                </div>
                <hr />
                <div>
                  <Link
                    className="text-dark"
                    to={`/get-premium${
                      window.location.href.search("false") == -1 &&
                      window.location.href.search("true") == -1
                        ? `/`
                        : `?if=${
                            window.location.href.search("false") == -1
                              ? true
                              : false
                          }`
                    }`}
                  >
                    Products &amp; Pricing
                  </Link>
                </div>
                {/* <div>
                  {!subscription?.isPayAsYouGo && (
                    <>
                    <hr />
                      {props.GetUserReducer.users &&
                      !!props.GetUserReducer.users.resume_info &&
                      props.GetUserReducer.users.resume_info !== 0 ? (
                        <Link to="/search-resume" className="text-dark">
                          Search Resumes
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          onClick={() => setBilingModalIsOpen2(true)}
                          className="text-dark"
                        >
                          Search Resumes
                        </Link>
                      )}
                    </>
                  )}
                </div> */}
                <hr />



                <div className="d-flex align-items-start flex-column justify-content-center pt-3 px-0">
                  <div className="d-flex footer-img-small">
                    <a href="https://www.facebook.com/Hire-Choices-LLC-106119302081524/">
                      {" "}
                      <img src={fb} />
                    </a>
                    <a href="https://instagram.com/hirechoices?igshid=YmMyMTA2M2Y=">
                      <img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
                    </a>
                    <a href="https://www.linkedin.com/in/hire-choices-llc-64a95a231/">
                      <img src="https://img.icons8.com/color/48/000000/linkedin.png" />
                    </a>
                    <a href="https://www.youtube.com/channel/UC3Le1tWkmOnCIBvM27_kmOA">
                      <img src={yt} />
                    </a>
                  </div>
                  <p>© 2022 - Hire Choices</p>
                  <div className="d-flex align-items-center">
                    {props.GetUserReducer.users &&
                    props.GetUserReducer.users.theme !== null &&
                    props.GetUserReducer.users.theme !== undefined &&
                    props.GetUserReducer.users.theme !== "" ? (
                      props.GetUserReducer.users &&
                      props.GetUserReducer.users.theme == 0 ? (
                        <div>
                          <div className="d-flex align-items-center">
                            <b>Dark Mode: </b>
                            <input
                              type="checkbox"
                              class="checkbox"
                              id="checkbox"
                              defaultChecked={
                                props.GetUserReducer.users &&
                                props.GetUserReducer.users.theme == 0
                                  ? false
                                  : true
                              }
                              onChange={() => {
                                setthemeanime(false);
                                theme(1);
                              }}
                            />
                            <label for="checkbox" className="label ml-3">
                              {props.GetUserReducer.users.theme == 1 ? (
                                <i class="fa fa-sun-o"></i>
                              ) : (
                                <i class="fa fa-moon-o"></i>
                              )}
                              <div
                                style={{
                                  transform:
                                    themeanime == false
                                      ? "translateX(-18px)"
                                      : "translateX(0px)",
                                }}
                                class="ball"
                              ></div>
                            </label>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex align-items-center">
                            <b>Dark Mode: </b>
                            <input
                              type="checkbox"
                              class="checkbox"
                              id="checkbox"
                              defaultChecked={
                                props.GetUserReducer.users &&
                                props.GetUserReducer.users.theme == 0
                                  ? false
                                  : true
                              }
                              onChange={() => {
                                theme(0);
                                setthemeanime(false);
                              }}
                            />
                            <label for="checkbox" className="label ml-3">
                              {props.GetUserReducer.users.theme == 1 ? (
                                <i class="fa fa-sun-o"></i>
                              ) : (
                                <i class="fa fa-moon-o"></i>
                              )}
                              <div
                                style={{
                                  transform:
                                    themeanime == false
                                      ? "translateX(-18px)"
                                      : "translateX(0px)",
                                }}
                                class="ball"
                              ></div>
                            </label>
                          </div>
                        </div>
                      )
                    ) : (
                      <>
                        {themeanime == true ? (
                          <div>
                            <div className="d-flex align-items-center">
                              <b>Dark Mode: </b>
                              <input
                                type="checkbox"
                                class="checkbox"
                                id="checkbox"
                                defaultChecked={
                                  localStorage.getItem("themee") !== false
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  setthemechange(!themechange);
                                  themechangelocal(themechange);
                                  setthemeanime(false);
                                }}
                              />
                              <label for="checkbox" className="label ml-3">
                                <i class="fa fa-moon-o"></i>
                                <div
                                  style={{
                                    transform:
                                      themeanime == false
                                        ? "translateX(-18px)"
                                        : "translateX(0px)",
                                  }}
                                  class="ball"
                                ></div>
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="d-flex align-items-center">
                              <b>Dark Mode: </b>
                              <input
                                type="checkbox"
                                class="checkbox"
                                id="checkbox"
                                defaultChecked={
                                  localStorage.getItem("themee") !== false
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  setthemechange(!themechange);
                                  themechangelocal(themechange);
                                  setthemeanime(true);
                                }}
                              />
                              <label for="checkbox" className="label ml-3">
                                <i
                                  style={{ transform: "translateX(18px)" }}
                                  class="fa fa-sun-o"
                                ></i>
                                <div
                                  style={{
                                    transform:
                                      themeanime == true
                                        ? "translateX(0px)"
                                        : "translateX(-18px)",
                                  }}
                                  class="ball"
                                ></div>
                              </label>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Drawer>
            </div>
          </span>
        </div>
      </nav>
      <Modal
        isOpen={BilingmodalIsOpen2}
        onRequestClose={() => setBilingModalIsOpen2(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "490px",
            height: "200px",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className=" justify-content-center align-items-center pt-3">
          <h5 className="text-center" style={{ color: "black" }}>
            You dont have permission to view this.
          </h5>
          <div className="d-flex justify-content-center mt-4">
            <p
              onClick={(e) => setBilingModalIsOpen2(false)}
              className="p-3 rounded"
              style={{
                color: "white",
                cursor: "pointer",
                backgroundColor: "#8dc63f",
              }}
            >
              <b>OK</b>
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  AppliedJobReducer: state.AppliedJobReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  AppliedJob: (jobid, jobtitle) => dispatch(AppliedJob(jobid, jobtitle)),
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
  getUser: () => dispatch(getUser()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployerNav);

// code for scoop solutions
