import React from "react"
import Employerlogin from "../components/employerlogin/Employerlogin"
import Navbar from "../components/navbar/Navbar"
import HomeFooter from "../components/homeFooter/HomeFooter";

const LoginEmployer = () => {
    return (
        <>
            <Navbar />
            <Employerlogin />
            <HomeFooter />
        </>
    )
}
export default LoginEmployer


// code for scoop solutions