import React from "react"
import EmployeeNav2 from "../components/employeenav2/Employeenav2"
import SearchresumeMobile from "../components/searchresume/Searchresume-mobile"
import HomeFooter from "../components/homeFooter/HomeFooter";

const ResumeSearch = () => {
    return (
        <>
            <EmployeeNav2 />
            <SearchresumeMobile />
            <HomeFooter />
        </>
    )
}
export default ResumeSearch


// code for scoop solutions