import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Empty } from 'antd';
import Button from 'react-bootstrap/Button';
import { ReportJobAction } from "../../actions/Reportjobaction";
import "./employeejobsearchresult.css"
import { AppliedJob } from "../../actions/Appliedjobaction";
import { Collapse } from 'antd';
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import { createSearch } from "../../actions/Jobsearchaction";
import parse from 'html-react-parser';
import Employernav from "../dropdownnav/Employernav";
import useWindowDimensions from '../administrator/innerwidthcom';
import Dropdownnav from "../dropdownnav/Dropdownnav";
import {  useParams } from 'react-router-dom';
const config = require('../../helpers/config.json');

Modal.setAppElement("#root");
const EmployeeJobSearchResult = (props) => {
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  const params = useParams()
  const foo = params?.id;
  const [BilingmodalIsOpentrue, setBilingmodalIsOpentrue] = useState(false);
  const [BilingmodalIsOpentrue1, setBilingmodalIsOpentrue1] = useState(false);
  const [searchres, setsearchres] = useState([])


  const [resjid, setresjid] = useState("")
  const [saveerrormessage, setsaveerrormessage] = useState("")
  const savedjobmodal = (jid) => {
    setBilingmodalIsOpentrue(true)
    setresjid(jid)
  }
  const savedjob = async (id) => {
    await fetch(`${config['baseUrl']}/SaveJob`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "user_id": Number(localStorage.getItem("userid")),
        "job_id": Number(resjid)
      })
    }).then(res => {

      if (res.status !== 200 && res.status !== 201) {

      }
      return res.json();
    }).then((response) => {

      if (response.message == "Save Job") {
        window.location = "/save-jobs"
      }
      else if (response.message == "Already in Save Jobs") {
        setsaveerrormessage(response.message)
        setBilingmodalIsOpentrue(false)
        setBilingmodalIsOpentrue1(true)
      }
      else if (response.message == "Unable to Save Job...") {
        setsaveerrormessage(response.message)
        setBilingmodalIsOpentrue(false)
        setBilingmodalIsOpentrue1(true)
      }
    }).catch((error) => {
      console.log(error)

    })
  }
  const { height, width } = useWindowDimensions();


  const [modalwidth, setmodalwidth] = useState(450)

  useEffect(() => {
    if (width <= 900) {
      setmodalwidth("340px")
    }
  }, []);
  useEffect(() => {
    fetch(`${config['baseUrl']}/jobs/getbyid/${foo}`)
      .then(res => res.json())
      .then(responce => {
        setsearchres(responce.job)
      })
  }, [])




  return (
    <>

      <div className="container-fluid" id="Employee">
        <div className="container">
          {
            props.GetUserReducer.users && props.GetUserReducer.users && props.GetUserReducer.users.role !== null && props.GetUserReducer.users.role !== undefined && props.GetUserReducer.users.role !== "" && props.GetUserReducer.users.role !== 0 ?
              <Employernav /> :
              <Dropdownnav />
          }
          {/* header======================================================================*/}
          <div className="col-12 main-content">
            <div className="row">
              <div className="col-lg-12 col-md-6 col-12 px-0">
                <div className="tab-content" id="v-pills-tabContent">
                  {
                    searchres.length > 0 ? searchres.map(res => (
                      <>
                        <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="v-pills-home-tab">
                          <div className="search-result-3-scrool-box px-0 mt-3">
                            <div className="d-flex flex-column p-2">
                              <div className="job-search-result-left-box d-flex align-items-center justify-content-between">
                                <div>
                                  <div className="d-flex align-items-center">
                                    <a href className="mr-1">{res.company_name !== null && res.company_name !== undefined && res.company_name !== "" ? res.company_name : ""}</a> - <p className="mb-0 ml-1">{res.job_title !== null && res.job_title !== undefined && res.job_title !== "" ? res.job_title : ""}</p>
                                    <button style={{ background: "transparent", border: "none" }}><i style={{ fontSize: 23 }} className="ml-3 fa fa-heart text-danger" onClick={() => savedjobmodal(res.id)}></i></button>

                                  </div>
                                  {
                                    res.display_amount !== 0 ?
                                      <p>{res.city !== null && res.city !== undefined && res.city !== "" ? res.city : ""},<span className="ml-1"></span>{res.state !== null && res.state !== undefined && res.state !== "" ? res.state : ""}<span className="ml-1"></span>{res.zip_code !== null && res.zip_code !== undefined && res.zip_code !== "" ? res.zip_code : ""} | {res.employment !== null && res.employment !== undefined && res.employment !== "" ? res.employment : ""} | {`${res.pay_rate_minimum !== "" ? `${res.pay_rate_minimum}` : ""} to ${res.pay_rate_maximum !== "" ? `${res.pay_rate_maximum}` : ""}`} <span className="paytyejob"> ({res.pay_rate_type !== "" ? res.pay_rate_type : ""})</span></p>
                                      :
                                      <p>{res.city !== null && res.city !== undefined && res.city !== "" ? res.city : ""},<span className="ml-1"></span>{res.state !== null && res.state !== undefined && res.state !== "" ? res.state : ""}<span className="ml-1"></span>{res.zip_code !== null && res.zip_code !== undefined && res.zip_code !== "" ? res.zip_code : ""} | {res.employment !== null && res.employment !== undefined && res.employment !== "" ? res.employment : ""} </p>
                                  }

                                </div>
                              </div>
                              {res.company_overview !== null && res.company_overview !== undefined && res.company_overview !== "" && res.company_overview !== "None" ?
                                <>
                                  <h5 className="mb-0 mt-3" style={{ color: "orange" }}>Company Overview</h5>
                                  <p className="mt-2">{res.company_overview !== null && res.company_overview !== undefined && res.company_overview !== "" ? parse(res.company_overview) : ""}</p>
                                </>
                                : ""}
                              {res.description !== null && res.description !== undefined && res.description !== "" && res.description !== "None" ?
                                <>
                                  <h5 className="mb-0 mt-3" style={{ color: "orange" }}>Description</h5>
                                  <p className="mt-2">{res.description !== null && res.description !== undefined && res.description !== "" ? parse(res.description) : ""}</p>
                                </> : ""}






                              <h5 className="mb-0 mt-3" style={{ color: "orange" }}>Experience Level</h5>
                              <p >{res.experience_level !== null && res.experience_level !== undefined && res.experience_level !== "" ? res.experience_level : ""}</p>

                              <h5 className="mb-0 mt-3" style={{ color: "orange" }}>Education</h5>
                              <p >{res.education !== null && res.education !== undefined && res.education !== "" ? res.education : ""}</p>

                              {res.display_amount !== null && res.display_amount !== "" && res.display_amount == 0 ?
                                ""
                                :
                                <>
                                  <h5 className="mb-0 mt-3" style={{ color: "orange" }}>Pay Rate</h5>
                                  <p >{res.pay_rate_minimum !== null && res.pay_rate_minimum !== undefined && res.pay_rate_minimum !== "" ? res.pay_rate_minimum : ""} To {res.pay_rate_maximum !== null && res.pay_rate_maximum !== undefined && res.pay_rate_maximum !== "" ? res.pay_rate_maximum : ""}</p>
                                </>
                              }

                              {res.other_compensations !== null && res.other_compensations !== undefined && res.other_compensations !== "" && res.other_compensations !== "None" ?
                                <>
                                  <h5 className="mb-0 mt-3" style={{ color: "orange" }}>Other Compensations</h5>
                                  <p >{res.other_compensations !== null && res.other_compensations !== undefined && res.other_compensations !== "" ? res.other_compensations : ""}</p>
                                </>
                                : ""}


                              {res.position_opened !== null && res.position_opened !== undefined && res.position_opened !== "" ?
                                <>
                                  <h5 className="mb-0 mt-3" style={{ color: "orange" }}>Positions Available</h5>
                                  <p >{res.position_opened !== null && res.position_opened !== undefined && res.position_opened !== "" ? res.position_opened : ""}</p>
                                </> : ""
                              }



                              {res.travel !== null && res.travel !== undefined && res.travel !== "" && res.travel !== "None" ?
                                <>
                                  <h5 className="mb-0 mt-3" style={{ color: "orange" }}>Travel</h5>
                                  <p >{res.travel !== null && res.travel !== undefined && res.travel !== "" ? res.travel : ""}</p>
                                </>
                                : ""}
                            </div>
                          </div>
                        </div>
                      </>
                    )) : ""

                  }
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <Modal
        isOpen={BilingmodalIsOpentrue}
        onRequestClose={() => setBilingmodalIsOpentrue(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "200px",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",
            border: "1px solid orange",
            background: "#fff",

            outline: "none",
            padding: "20px",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className=" justify-content-center align-items-center pt-3">
          <h5 className="text-center" style={{ color: "black" }}>Do you want to save this Job?</h5>
          <div className="d-flex justify-content-center mt-4">
            <p onClick={(e) => savedjob()} className="p-3 rounded" style={{ color: "white", cursor: "pointer", backgroundColor: "#8dc63f" }}><b>CONTINUE</b></p>
            <p onClick={() => setBilingmodalIsOpentrue(false)} className="ml-5 pt-3" style={{ color: "black", cursor: "pointer" }}><b>Cancel</b></p>
          </div>
        </div>
      </Modal>


      <Modal
        isOpen={BilingmodalIsOpentrue1}
        onRequestClose={() => setBilingmodalIsOpentrue1(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "200px",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",
            border: "1px solid orange",
            background: "#fff",

            outline: "none",
            padding: "20px",
            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className=" justify-content-center align-items-center pt-3">
          <h5 className="text-center" style={{ color: "black" }}>{saveerrormessage}</h5>
          <div className="d-flex justify-content-center mt-4">
            <p onClick={(e) => setBilingmodalIsOpentrue1(false)} className="p-3 rounded" style={{ color: "white", cursor: "pointer", backgroundColor: "#8dc63f" }}><b>OK</b></p>
          </div>
        </div>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
  AppliedJobReducer: state.AppliedJobReducer,
  ReportJobReducer: state.ReportJobReducer,
});

const mapDispatchToProps = (dispatch) => ({
  AppliedJob: (jobid, jobtitle) =>
    dispatch(AppliedJob(jobid, jobtitle)),
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
  ReportJobAction: (job_id, jobtitle, details, jobTitle, Location) =>
    dispatch(ReportJobAction(job_id, jobtitle, details, jobTitle, Location)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeJobSearchResult);

// code for scoop solutions