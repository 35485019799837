import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moblogo from "../../assets/mob-logo.png";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import Employernav from "../dropdownnav/Employernav";
const config = require('../../helpers/config.json');


const PreviouslyContacted = (props) => {
  const [load, setload] = useState(false)
  const [allArray, setallArray] = useState([])
  useEffect(async () => {
    loadgetjob()
  }, []);
  const loadgetjob = async () => {
    setload(true)
    await fetch(`${config['baseUrl']}/previously-contacted/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      setallArray(...allArray, alljobs)
        ;
    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
    return null
  }
  const [order, setorder] = useState("ASC")
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("DSC")
    }
    if (order === "DSC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("ASC")
    }

  }
  return (
    <>

      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          {/* header======================================================================*/}

          <div className="main-content">
            <div className="d-flex main-content-session inner-session py-2">
              <h5>Previously Contacted</h5>
            </div>
            <div className="usr-tbl">
              <table id="example" className="table  table-striped table-bordered dataTable" cellSpacing={0} width="100%" role="grid" aria-describedby="example_info" style={{ width: '100%' }}>
                <thead>
                  <tr role="row">
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '30%' }}>Name Of
                      Candidate</th>
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '30%' }}>
                      Job Contacted For</th>
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '20%' }}>Date
                      Contacted {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("date_contacted")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("date_contacted")} />}
                    </th>
                    <th className="sorting_desc" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Age: activate to sort column ascending" aria-sort="descending" style={{ width: '20%' }}>
                      Full Resume Downloaded</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allArray && allArray.length > 0 ? allArray.map(val => (
                      <tr role="row" className="odd">
                        <td className>{val.candidate_name}</td>
                        <td>{val.job_applied}</td>
                        <td>{val.date_contacted.split(" ")[0]}</td>
                        <td className="sorting_1">Yes</td>
                      </tr>
                    )) : ""
                  }

                </tbody>
              </table>
            </div>
          </div>
          {/* footer====================================================================== */}

        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(PreviouslyContacted);


// code for scoop solutions