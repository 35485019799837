import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { createSearch } from "../../actions/Jobsearchaction";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import CurrencyInput from 'react-currency-input';
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import Postjob2 from "./Postjob2";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import textbox from "../../assets/textbox.PNG"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';
import EmployerNav from "../dropdownnav/Employernav"
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import useWindowDimensions from '../administrator/innerwidthcom';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
const config = require('../../helpers/config.json');


const PostJob1 = (props) => {
  const { height, width } = useWindowDimensions();
  const [modalwidth, setmodalwidth] = useState(500)
  useEffect(() => {

    if (width <= 900) {
      setmodalwidth("340px")
    }
  }, []);
  const [modal1, setmodal1] = useState(false)
  const [disable, setdisable] = useState(false)
  const[checkcity,setcheckcity]=useState(false)
  const [trans, setrans] = useState("transformlogo");
  const listenScrollEvent = (e) => {
    if (window.scrollY > 30) {
      setrans("ultra");
    } else {
      setrans("transformlogo");
    }
  };
  const [errzip, seterrzip] = useState(false)
  const statecorrect = (e) => {
    setzip_code(e)
    const dumbdata = e.split('')
    if (dumbdata.length < 5) {
      seterrzip(true)
    }
    else {
      seterrzip(false)
    }
  }
  const [BilingmodalIsOpenzip, setBilingModalIsOpenzip] = useState("")
  const [BilingmodalIsOpenzip1, setBilingModalIsOpenzip1] = useState("")
  const [BilingmodalIsOpenzip1comp,setBilingModalIsOpenzip1comp]=useState("")
  const [BilingmodalIsOpenzip2, setBilingModalIsOpenzip2] = useState("")
  const [BilingmodalIsOpenzip5, setBilingModalIsOpenzip5] = useState("")
  const [first_name, setfirst_name] = useState("")
  const [last_name, setlast_name] = useState("")
  const [company_name, setcompany_name] = useState("")
  const [company_size, setcompany_size] = useState("")
  const [phone_number, setphone_number] = useState("")
  const [hear_about_us, sethear_about_us] = useState("")
  const [job_title, setjob_title] = useState("")
  const [location, setlocation] = useState("")
  const [zipcode, setzipcode] = useState("")

  const [city, setcity] = useState("")
  const [state, setstate] = useState("")
  const [zip_code, setzip_code] = useState("")
  const [employment, setemployment] = useState("")
  const [experience_level, setexperience_level] = useState("")
  const [education, seteducation] = useState("")
  const [pay_rate_minimum, setpay_rate_minimum] = useState("")
  const [pay_rate_maximum, setpay_rate_maximum] = useState("")
  const [pay_rate_type, setpay_rate_type] = useState("Hourly")
  const [pay_rate_typeEX, setpay_rate_typeEX] = useState(2)
  const [other_compensations, setother_compensations] = useState("")
  const [description, setdescription] = useState("")
  const [descriptioncount, setdescriptioncount] = useState("")
  const [company_overview, setcompany_overview] = useState("")
  const [travel, settravel] = useState("")
  const [position_opened, setposition_opened] = useState("")
  const [display_amount, setdisplay_amount] = useState(false)
  const [benefits, setbenefits] = useState([])
  const [linked, setlinked] = useState("")
  const [twitter, settwitter] = useState("")
  const [facebook, setfacebook] = useState("")
  const [blog, setblog] = useState("")
  const [responsibility, setresponsibility] = useState([])
  const [professional_summarycount, setprofessional_summarycount] = useState("")
  const [mainduty, setmainduty] = useState("")
  const [overcompany, setovercompany] = useState()

  const [respons, setrespons] = useState("")
  const [jobPostingAvailability, setJobPostingAvailability] = useState(false)
  const [overview, setoverview] = useState("")
  const [loading, setloading] = useState(false)
















  let componentDidMount_super = CurrencyInput.prototype.componentDidMount;
  CurrencyInput.prototype.componentDidMount = function () {
    if (!this.props.autoFocus) {
      let setSelectionRange_super = this.theInput.setSelectionRange;
      this.theInput.setSelectionRange = () => { };
      componentDidMount_super.call(this, ...arguments);
      this.theInput.setSelectionRange = setSelectionRange_super;
    }
    else {
      componentDidMount_super.call(this, ...arguments);
    }
  };


  let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate;
  CurrencyInput.prototype.componentDidUpdate = function () {
    if (!this.props.autoFocus) {
      let setSelectionRange_super = this.theInput.setSelectionRange;
      this.theInput.setSelectionRange = () => { };
      componentDidUpdate_super.call(this, ...arguments);
      this.theInput.setSelectionRange = setSelectionRange_super;
    }
    else {
      componentDidMount_super.call(this, ...arguments);
    }
  };
  const CharacterCountany = (e) => {
    if (e.length > 0) {
      setcompany_overview(e)
      setoverview(e)
      setovercompany(e.length);
      if(e.length > 2500){
        var text2=company_overview
        var text3=overview
        text2.substring(0, 2500)
        text3.substring(0, 2500)
        setcompany_overview(text2)
        setoverview(text3)
      }
    }
    else {
      setcompany_overview(e)
      setoverview(e)
      setovercompany(e.length);
    }
  }
  const CharacterCount = (e) => {
    if (e.length > 0) {
      setdescription(e)
      setdescriptioncount(e.length)
      if(e.length > 2500){
        var text=description
        text.substring(0, 2500)
        setdescription(text)
      }
    }
    else {
      setdescription(e)
      setdescriptioncount(e.length)
    }
  }

  const [rescon, setrescon] = useState(false)
  const [discon, setdiscon] = useState(false)





  const CharacterCount2 = (e) => {
    if (e.length > 0) {
      setrescon(true)
      setrespons(e)
      setmainduty(e.length)
      if(e.length > 2500){
        var text1=respons
        text1.substring(0, 2500)
        setrespons(text1)
      }
    }
    else {
      setrescon(false)
      setrespons(e)
      setmainduty(e.length)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    setloading(true)
    console.log("props?.GetUserReducer",props?.GetUserReducer?.user?.id)
    fetch(`${config['baseUrl']}/jobs/available/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.json()).then((response) => {
      console.log(response?.status, "response")
      setloading(false)
      setJobPostingAvailability(response?.status)
      const users = response
    }).catch((error) => {
      setloading(false)
      console.log("error", error);
    })
  }, [props?.GetUserReducer]);
  const onchange = (event, ev) => {
    const ischecked = event
    if (ischecked) {
      benefits.push({
        benefit: ev
      })
      setbenefits(benefits)
    } else {
      const index = benefits.indexOf(ev)
      benefits.splice(index, 1)
      setbenefits(benefits)
    }
  }
  const [dis, setdis] = useState(true)
  const [changehead, setchangehead] = useState(false)

  const createjob = async () => {
    setdis(false)
    responsibility.push({
      responsibility: respons
    })
    setresponsibility(responsibility)

    await fetch(`${config['baseUrl']}/jobs/insert`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "user_id": Number(localStorage.getItem("userid")),
        "first_name": first_name,
        "last_name": last_name,
        "company_name": company_name,
        "company_size": company_size,
        "phone_number": phone_number,
        "hear_about_us": hear_about_us,
        "job_title": job_title,
        "location": address.split(",")[0],
        "city": location,
        "latitude": corr.lat !== null && corr.lat !== undefined && corr.lat !== "" ? corr.lat : 0,
        "longitude": corr.lng !== null && corr.lng !== undefined && corr.lng !== "" ? corr.lng : 0,
        "state": state,
        "zip_code": zip_code,
        "employment": employment,
        "experience_level": experience_level,
        "education": education,
        "pay_rate_minimum": pay_rate_minimum,
        "pay_rate_maximum": pay_rate_maximum,
        "pay_rate_type": pay_rate_type,
        "other_compensations": other_compensations,
        "display_amount": display_amount == false ? 1 : 0,
        "description": description,
        "company_overview": company_overview,
        "travel": travel,
        "position_opened": position_opened,
        "salary": `${pay_rate_minimum}-${pay_rate_maximum}`,
        "linkedin": linked,
        "facebook": facebook,
        "twitter": twitter,
        "blog": blog,
        "benefits": benefits,
        "responsibility": responsibility,
        "email": localStorage.getItem("email")
      })
    }).then(res => {
      // 
      setchangehead(true)
      if (res.status !== 200 && res.status !== 201) {
        
      }
      return res.json();
    }).then((response) => {

      if (response.message == "The Job has been successfully inserted.") {
        setTimeout(() => {
          window.location = "/manage-job"
        }, 3000);
      }
      else {
        console.log("Something went wrong..")
      }

    }).catch((error) => {
      console.log(error)

    })
  }


  const [require1, setrequire1] = useState(false)
  const [require2, setrequire2] = useState(false)
  const [require3, setrequire3] = useState(false)
  const [require4, setrequire4] = useState(false)
  const [require5, setrequire5] = useState(false)
  const [require6, setrequire6] = useState(false)
  const [require7, setrequire7] = useState(false)
  const [require8, setrequire8] = useState(false)
  const [require9, setrequire9] = useState(false)
  const [require10, setrequire10] = useState(false)
  const [require11, setrequire11] = useState(false)
  const [require12, setrequire12] = useState(false)


  const [step1, setstep1] = useState(true)
  const [step2, setstep2] = useState(false)
  const [step3, setstep3] = useState(false)
  const step1func = () => {
    if (first_name !== "" && last_name !== "" && company_name !== "") {
      setstep2(true)
      setstep1(false)
      setstep3(false)
    }
    else {
      if (first_name == "") {
        setrequire1(true)
          setBilingModalIsOpenzip5(true)
      }
      if (last_name == "") {
        setrequire2(true)
        setBilingModalIsOpenzip5(true)
      }
      if (company_name == "") {
        setrequire3(true)
        setBilingModalIsOpenzip5(true)
      }
    }
  }
  const step2func = () => {
    if (descriptioncount>0 && descriptioncount > 2500) {
      setBilingModalIsOpenzip1(true)
    }
    else if (rescon == true && (mainduty < 50 || mainduty > 2500)) {
      setBilingModalIsOpenzip2(true)
    }
    else if (overcompany>0 && (overcompany < 50 || overcompany > 2500)) {
      setBilingModalIsOpenzip1comp(true)
    }
    else {
      if (job_title !== ""&&corr.lat!==null && address !== "" && state !== ""  && education !== "" && experience_level !== "" && employment !== "" && respons !== "") {
          setstep2(false)
          setstep1(false)
          setstep3(true)
      }
      else {
        if (job_title == "") {
          setrequire4(true)
          setBilingModalIsOpenzip5(true)
        }
        if (address == "") {
          setrequire5(true)
          setBilingModalIsOpenzip5(true)
        }
        if (corr.lat==null ) {
          setcheckcity(true)
          setBilingModalIsOpenzip5(true)
        }
        if (address.split(",").length==1 ) {
          setcheckcity(true)
          setBilingModalIsOpenzip5(true)
        }
        if (state == "") {
          setrequire6(true)
          setBilingModalIsOpenzip5(true)
        }
        if (employment == "") {
          setrequire8(true)
          setBilingModalIsOpenzip5(true)
        }
        if (experience_level == "") {
          setrequire8(true)
          setBilingModalIsOpenzip5(true)
        }
        if (education == "") {
          setrequire8(true)
          setBilingModalIsOpenzip5(true)
        }
        if (respons == "") {
          setrequire12(true)
          setBilingModalIsOpenzip5(true)
        }
      }
    }
  }
  const step2backfunc = () => {
    setstep2(false)
    setstep1(true)
    setstep3(false)
  }
  const step3backfunc = () => {
    setstep2(true)
    setstep1(false)
    setstep3(false)
  }
  const [address, setaddress] = useState("")
  const [corr, setcorr] = useState({
    lat: null,
    lng: null
  })
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)
    
    const getZipCode = result?.[0]?.address_components?.find(
      (component) => component?.types?.includes('postal_code')
      )?.long_name;
      if(getZipCode){
        statecorrect(getZipCode)
      }
      const getCity =
        result?.[0]?.address_components?.find((component) =>
          component?.types?.includes("locality")
        )?.long_name || "";
        if(getCity){
          setlocation(getCity);
        }

    const ll = await getLatLng(result[0])
    setstate(result[0]?.address_components?.find(i=>i?.types?.includes("administrative_area_level_1"))?.short_name)
    setaddress(value)
    setcorr(ll)
  }


  if(loading){
    return <FullPageLoader1 />
  }

  return (
    <>
      {
        props.GetUserReducer.loading == false ?
          <>

            <div className="container-fluid" id="Employee">
              <div className="container">
                <EmployerNav />
                <div className="main-content emp-sign-up">
                  {!jobPostingAvailability ? 
                  <div className="alert alert-warning" role="alert">You have reached your max limit of job slots per your subscription.</div> : ""}
                  {
                    step1 == true ?
                      <div className="col-lg-6 col-12 p-0 mt-5">
                        <div className="d-flex">
                          <div className="w-50">
                            <div className="form-group ">
                              <label htmlFor="exampleInputEmail1">First Name <span className="allred">*</span></label>
                              <input
                                disabled={!jobPostingAvailability}
                                onChange={(e) => {
                                  setrequire1(false)
                                  setfirst_name(e.target.value)
                                }}
                                value={first_name}
                                className="employee-input-email" type="text" placeholder="First Name" />
                              {require1 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                            </div>
                          </div>
                          <div className="w-50 ml-1">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Last Name <span className="allred">*</span></label>
                              <input
                                disabled={!jobPostingAvailability}
                                onChange={(e) => {
                                  setrequire2(false)
                                  setlast_name(e.target.value)
                                }}
                                value={last_name}
                                className="employee-input-email" type="text" placeholder="Last Name" />
                              {require2 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex btm-frm-mgn">
                          <div className="w-100">
                            <label htmlFor="exampleInputEmail1">Company Name <span className="allred">*</span></label>
                            <input
                              disabled={!jobPostingAvailability}
                              onChange={(e) => {
                                setrequire3(false)
                                setcompany_name(e.target.value)
                              }}
                              value={company_name}
                              className="employee-input-email" type="text" placeholder="Company Name" />
                            {require3 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                          </div>
                        </div>
                        <div className="d-flex btm-frm-mgn">
                          <div className="w-100">
                            <label htmlFor="exampleInputEmail1">Company Size</label>
                            <div className="role role2">
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "1-49" ? true : false}
                                  onChange={(e) => setcompany_size("1-49")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">1-49</span>
                              </label>
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "50-149" ? true : false}
                                  onChange={(e) => setcompany_size("50-149")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">50-149</span>
                              </label>
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "150-249" ? true : false}
                                  onChange={(e) => setcompany_size("150-249")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">150-249</span>
                              </label>
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "250-349" ? true : false}
                                  onChange={(e) => setcompany_size("250-349")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">250-349</span>
                              </label>
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "350-449" ? true : false}
                                  onChange={(e) => setcompany_size("350-449")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">350-449</span>
                              </label>
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "450-549" ? true : false}
                                  onChange={(e) => setcompany_size("450-549")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">450-549</span>
                              </label>
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "550-649" ? true : false}
                                  onChange={(e) => setcompany_size("550-649")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">550-649</span>
                              </label>
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "650-749" ? true : false}
                                  onChange={(e) => setcompany_size("650-749")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">650-749</span>
                              </label>
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "750-999" ? true : false}
                                  onChange={(e) => setcompany_size("750-999")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">750-999</span>
                              </label>
                              <label className="mx-2">
                                <input
                                  disabled={!jobPostingAvailability}
                                  defaultChecked={company_size == "1000+" ? true : false}
                                  onChange={(e) => setcompany_size("1000+")}
                                  type="radio" name="radio" />
                                <span className="sp-clr">1000+</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex btm-frm-mgn">
                          <div className="w-100">
                            <label htmlFor="exampleInputEmail1">Phone Number:</label>
                            <InputMask {...props} disabled={!jobPostingAvailability} mask="(999) 999-9999" value={phone_number} className="form-control employee-input-email job-search w-100" onChange={(e) => setphone_number(e.target.value.toString())} maskChar=" " placeholder="(xxx) xxx-xxxx" />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="w-100">
                            <label htmlFor="exampleInputEmail1">How did you hear about us?</label>
                            <select
                              disabled={!jobPostingAvailability}
                              onChange={(e) => sethear_about_us(e.target.value)}
                              className="employee-input-email" style={{ height: '45px' }} name id>
                              {
                                hear_about_us !== "" && hear_about_us !== null && hear_about_us !== undefined ?
                                  <option value={hear_about_us}>{hear_about_us}</option>
                                  :
                                  <option value="Select Option">Select Option</option>
                              }
                              <option value="TV">TV</option>
                              <option value="Radio (AM/FM/XM)">Radio (AM/FM/XM)</option>
                              <option value="Search Engine">Search Engine</option>
                              <option value="Facebook">Facebook</option>
                              <option value="Instagram">Instagram</option>
                              <option value="Tik Tok">Tik Tok</option>
                              <option value="LinkedIn">LinkedIn</option>
                              <option value="Twitter">Twitter</option>
                              <option value="YouTube">YouTube</option>
                              <option value="Billboard">Billboard</option>
                              <option value="Word of Mouth">Word of Mouth</option>
                            </select>
                          </div>
                        </div>
                        <div className="main-content-session inner-session">
                          <button disabled={!jobPostingAvailability} onClick={() => step1func()} className="send-invitation fgh">CONTINUE</button>
                        </div>
                      </div>
                      : ""
                  }
                  {
                    step2 == true ?
                      <>
                        <div className="col-lg-7 col-md-7 col-12 p-0 mt-5">
                          <div className="d-flex my-2">
                            <div className="w-100">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Job Title <span className="allred">*</span></label>
                                <input
                                  onChange={(e) => {
                                    setjob_title(e.target.value)
                                    setrequire4(false)
                                  }}
                                  className="employee-input-email" value={job_title} type="text" placeholder="Job Title" />
                                {require4 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <div className="w-100">
                              <label htmlFor="exampleInputEmail1">Location Street Address</label>
                              
                              <PlacesAutocomplete
                                value={address.split(",")[0]}
                                onChange={setaddress}
                                onSelect={handleSelect}
                                searchOptions={{ componentRestrictions: { country: 'us' } }}
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                  <div>
                                    <input
                                      id="exampleInputPassword1"
                                      style={{ height: "40px" }}
                                      className="form-control employee-input-email job-search w-25"
                                      {...getInputProps({
                                        placeholder: 'Street Address',
                                        className: 'location-search-input',
                                      })}

                                    />
                                    


                                    <div className={"autocomplete-dropdown-container mt-1 " + (suggestions?.length ? "py-2" : "")}>
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                          ? 'suggestion-item p-2 --active'
                                          : 'suggestion-item p-2 ';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span onClick={() => { setrequire5(false) }}>{suggestion.description}</span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </div>
                          </div>
                          <div className="d-flex my-4">
                            <div className="w-50">
                              <label htmlFor="exampleInputEmail1">City <span className="allred">*</span></label>
                              <input
                                onChange={(e) =>{
                                  setcheckcity(false);
                                  setlocation(e.target.value)
                                }}
                                className="employee-input-email" value={location} type="text"
                                placeholder="City"
                              />
                              {checkcity == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}
                            </div>
                            <div className="w-25 ml-1">
                              <label htmlFor="exampleInputEmail1">State <span className="allred">*</span></label>
                              <select onChange={(e) => {
                                setstate(e.target.value)
                                setrequire6(false)
                              }} style={{ width: '60px !important', height: '40px' }} className="employee-input-ema text-dark" name id
                                value={state}
                              >
                                {
                                  state !== "" && state !== null && state !== undefined ?
                                    <option selected value={state}>{state}</option>
                                    :
                                    <option selected value="Select">Select</option>
                                }
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA	">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                              {require6 == true ? <small className="text-danger" style={{ color: "red" }}>Please complete this field</small> : ""}

                            </div>
                            <div className="w-25 ml-1">
                              <label htmlFor="exampleInputEmail1">Zip</label>
                              <input
                                maxLength={20}
                                onChange={(e) => statecorrect(e.target.value)}
                                className="employee-input-email" value={zip_code} type="text" placeholder="Zip Code" />
                              {errzip == true ? <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>*Zip code needs to be 5 digits</p> : ""}
                            </div>
                          </div>
                          <div className="d-flex flex-column mt-4 mb-3">
                            <h5 className="mb-0">Job Requirements <span className="allred">*</span></h5>
                            {require8 == true ? <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }} className="mb-2">Please fill out this fields</p> : ""}
                          </div>
                          <div className="row mb-2 mt-0 pt-0 role role2 main-content-session job-req-post-job">
                            <div className="col-lg-4 col-12 job-req-post-job-1">
                              <h5 className="mb-3 hirehome">Employment</h5>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={employment !== "" && employment == "Full-Time" ? true : false}
                                    onChange={(e) => {
                                      setemployment(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="Full-Time"
                                    type="radio" name="radio12" />
                                  <span className="sp-clr">Full-Time</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={employment !== "" && employment == "Part-Time" ? true : false}
                                    onChange={(e) => {
                                      setemployment(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="Part-Time"
                                    type="radio" name="radio12" />
                                  <span className="sp-clr">Part-Time</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={employment !== "" && employment == "Contract/Temporary" ? true : false}
                                    onChange={(e) => {
                                      setemployment(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="Contract/Temporary"
                                    type="radio" name="radio12" />
                                  <span className="sp-clr">Contract/Temporary</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={employment !== "" && employment == "Internship" ? true : false}
                                    onChange={(e) => {
                                      setemployment(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="Internship"
                                    type="radio" name="radio12" />
                                  <span className="sp-clr">Internship</span>
                                </label>
                              </div>

                            </div>
                            <div className="col-lg-4 col-12 job-req-post-job-2">
                              <h5 className="mb-3 hirehome">Experience Level</h5>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={experience_level == "Not-Disclosed" ? true : false}
                                    onChange={(e) => {
                                      setexperience_level("Not-Disclosed")
                                      setrequire8(false)
                                    }}
                                    type="radio" name="radio2" />
                                  <span className="sp-clr">Not Specified</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={experience_level == "Less Than 1 Year" ? true : false}
                                    onChange={(e) => {
                                      setexperience_level("Less Than 1 Year")
                                      setrequire8(false)
                                    }}
                                    type="radio" name="radio2" />
                                  <span className="sp-clr">Less Than 1 Year</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={experience_level == "1-2 Years" ? true : false}
                                    onChange={(e) => {
                                      setexperience_level("1-2 Years")
                                      setrequire8(false)
                                    }}
                                    type="radio" name="radio2" />
                                  <span className="sp-clr">1-2 Years</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={experience_level == "2-5 Years" ? true : false}
                                    onChange={(e) => {
                                      setexperience_level("2-5 Years")
                                      setrequire8(false)
                                    }}
                                    type="radio" name="radio2" />
                                  <span className="sp-clr">2-5 Years</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={experience_level == "5+ Years" ? true : false}
                                    onChange={(e) => {
                                      setexperience_level("5+ Years")
                                      setrequire8(false)
                                    }}
                                    type="radio" name="radio2" />
                                  <span className="sp-clr">5+ Years</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-4 col-12 job-req-post-job-3">
                              <h5 className="mb-3 hirehome">Education</h5>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={education == "Not Specified" ? true : false}
                                    onChange={(e) => {
                                      seteducation(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="Not Specified"
                                    type="radio" name="radio3" />
                                  <span className="sp-clr">Not Specified</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={education == "High School" ? true : false}
                                    onChange={(e) => {
                                      seteducation(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="High School"
                                    type="radio" name="radio3" />
                                  <span className="sp-clr">High School</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={education == "Associates Degree" ? true : false}
                                    onChange={(e) => {
                                      seteducation(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="Associates Degree"
                                    type="radio" name="radio3" />
                                  <span className="sp-clr">Associates Degree</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={education == "Bachelor’s Degree" ? true : false}
                                    onChange={(e) => {
                                      seteducation(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="Bachelor’s Degree"
                                    type="radio" name="radio3" />
                                  <span className="sp-clr">Bachelor’s Degree</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={education == "Graduate Degree" ? true : false}
                                    onChange={(e) => {
                                      seteducation(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="Graduate Degree"
                                    type="radio" name="radio3" />
                                  <span className="sp-clr">Graduate Degree</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={education == "Doctorate" ? true : false}
                                    onChange={(e) => {
                                      seteducation(e.target.value)
                                      setrequire8(false)
                                    }}
                                    value="Doctorate"
                                    type="radio" name="radio3" />
                                  <span className="sp-clr">Doctorate</span>
                                </label>
                              </div>

                            </div>
                          </div>
                          <div className="d-flex mt-4">
                            <h5 className="mb-3">Pay Rate</h5>
                          </div>
                          <div className="d-flex align-items-center flex-direction-in-res-justice amount-input-decoration">

                            {
                              pay_rate_type == "Hourly" ?
                                <CurrencyInput autoFocus={false} boolean={true} prefix="$" maxLength={7} decimalScale={pay_rate_typeEX} value={pay_rate_minimum} onChange={(e) => { setpay_rate_minimum(e) }} />
                                :
                                <CurrencyInput autoFocus={false} precision="0" prefix="$" maxLength={13} value={pay_rate_minimum} onChange={(e) => { setpay_rate_minimum(e) }} />
                            }


                            <span className="mx-2 sp-clr">To</span>
                            {
                              pay_rate_type == "Annually" ?
                                <CurrencyInput autoFocus={false} precision="0" prefix="$" maxLength={13} value={pay_rate_maximum} onChange={(e) => setpay_rate_maximum(e)} />
                                :
                                <CurrencyInput autoFocus={false} boolean={true} prefix="$" maxLength={7} decimalScale={pay_rate_typeEX} value={pay_rate_maximum} onChange={(e) => { setpay_rate_maximum(e) }} />
                            }

                            <div className="d-flex role role2">
                              <div className="d-flex align-items-center ml-2 flex-direction-in-res-margin-left">
                                <label>
                                  <input
                                    defaultChecked={pay_rate_type == "Hourly" ? true : false}
                                    onChange={(e) => {
                                      setpay_rate_minimum("")
                                      setpay_rate_maximum("")
                                      setpay_rate_type("Hourly")
                                      setpay_rate_typeEX(0)
                                    }}
                                    name="radio45"
                                    type="radio" />
                                  <span className="sp-clr">Hourly</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center ml-2">
                                <label>
                                  <input
                                    defaultChecked={pay_rate_type == "Annually" ? true : false}
                                    onChange={(e) => {
                                      setpay_rate_typeEX(2)
                                      setpay_rate_type("Annually")
                                    }}
                                    name="radio45"
                                    type="radio" />
                                  <span className="sp-clr">Annually</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center my-3 role role2">
                            {
                              display_amount == false ?
                                <label style={{flexDirection:"column"}}>
                                  <input type="checkbox" name="radio5" onClick={() => setdisplay_amount(true)} />
                                  <span  className="sp-clr"> Do not display compensation in job description
                                  </span>
                                  <p style={{margin: "5px 0px 5px 25px"}} >
                                    *Displaying the pay rate is recommended to increase job viewing
                                  </p>
                                </label>
                                :
                                <label style={{flexDirection:"column"}}>
                                  <input type="checkbox" name="radio5" onClick={() => setdisplay_amount(false)} checked />
                                  <span className="sp-clr"> Do not display compensation in job description
                                  </span>
                                  <p style={{margin: "5px 0px 5px 25px"}} >
                                    *Displaying the pay rate is recommended to increase job viewing
                                  </p>
                                </label>
                            }
                          </div>
                          <div className="d-flex flex-column role role2 my-4">
                            <p className="m-0 mb-0">Other compensation included:</p>
                            <div className="d-flex flex-direction-in-res">
                              <div className="d-flex align-items-center ml-0 my-2">
                                <label>
                                  <input
                                    defaultChecked={other_compensations == "Bonus" ? true : false}
                                    onChange={(e) => setother_compensations("Bonus")}
                                    type="radio" name="radio6" />
                                  <span className="sp-clr">Bonus</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center ml-2 flex-direction-in-res-margin-left my-2">
                                <label>
                                  <input
                                    defaultChecked={other_compensations == "Commission" ? true : false}
                                    onChange={(e) => setother_compensations("Commission")}
                                    type="radio" name="radio6" />
                                  <span className="sp-clr">Commission</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center ml-2 flex-direction-in-res-margin-left my-2">
                                <label>
                                  <input
                                    defaultChecked={other_compensations == "Tips" ? true : false}
                                    onChange={(e) => setother_compensations("Tips")}
                                    type="radio" name="radio6" />
                                  <span className="sp-clr">Tips</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center ml-2 flex-direction-in-res-margin-left my-2">
                                <label>
                                  <input
                                    defaultChecked={other_compensations == "None" ? true : false}
                                    onChange={(e) => setother_compensations("None")}
                                    type="radio" name="radio6" />
                                  <span className="sp-clr">None</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <h5 className="mb-2">Job Description</h5>
                            <p>This Job Description has a 2,500 character limit that includes HTML formatting.</p>
                            <div style={{ textAlign: 'right' }} id="counter">{descriptioncount !== "" && descriptioncount !== undefined && descriptioncount !== null ? descriptioncount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}/2,500</div>
                          </div>
                          <div className="form-group create-resume1 d-flex flex-column">
                            <CKEditor
                              editor={ClassicEditor}
                              data={description}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                              CharacterCount(data)
                              }}
                            />
                          </div>
                          <div>
                            <div className="d-flex flex-column mt-4 mb-0">
                              <h5 className="mb-0"> Main Duties &amp; Responsibilities <span className="allred">*</span></h5>
                              {require12 == true ? <small className="text-danger mb-2" style={{ color: "red" }}>Please complete this field</small> : ""}
                            </div>
                            <p>
                            The Main Duties & Responsibilities have a 2,500 character limit that includes HTML formatting (50 characters minimum).
                            </p>
                          </div>
                          <div className="form-group create-resume1 d-flex flex-column">
                            <div style={{ textAlign: 'right' }} id="counter">{mainduty !== "" && mainduty !== undefined && mainduty !== null ? mainduty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}/2,500</div>
                            <CKEditor
                              editor={ClassicEditor}
                              data={respons}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                CharacterCount2(data)
                                setrequire12(false)
                              }}
                            />
                          </div>
                          <div className="my-4">
                            <h5 className="mb-2 mt-2">Company Overview</h5>
                            <p>
                            The Company Overview has a 2,500 character limit that includes HTML formatting.
                            </p>
                            <div style={{ textAlign: 'right' }} id="counter">{company_overview !== "" && company_overview !== undefined && company_overview !== null ? company_overview.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}/2,500</div>
                            <div className="form-group create-resume1 d-flex flex-column">
                            <CKEditor
                              editor={ClassicEditor}
                              data={overview}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                CharacterCountany(data)
                              }}
                            />

                            </div>
                          </div>
                          <div className="d-flex mt-4">
                            <h5>Benefits Offered</h5>
                          </div>
                          <div className="row my-2">
                            <div className="col-lg-4 col-12">
                              <div className="d-flex flex-column role role2">
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Health Insurance").length > 0 ? benefits.filter(data => data.benefit == "Health Insurance").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Health Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  ))
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Health Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Health Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }

                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Health Insurance</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Dental Insurance").length > 0 ? benefits.filter(data => data.benefit == "Dental Insurance").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Dental Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Dental Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Dental Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }

                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Dental Insurance</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Vision Insurance").length > 0 ? benefits.filter(data => data.benefit == "Vision Insurance").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Vision Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Vision Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Vision Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Vision Insurance</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "401(k)").length > 0 ? benefits.filter(data => data.benefit == "401(k)").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="401(k)" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="401(k)" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="401(k)" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    401(k)</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Paid Time Off (PTO)").length > 0 ? benefits.filter(data => data.benefit == "Paid Time Off (PTO)").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Paid Time Off (PTO)" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Paid Time Off (PTO)" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Paid Time Off (PTO)" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Paid Time Off (PTO)</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Life Insurance").length > 0 ? benefits.filter(data => data.benefit == "Life Insurance").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Life Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Life Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Life Insurance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Life Insurance</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-4 col-12">
                              <div className="d-flex flex-column role role2">
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Short-Term Disability").length > 0 ? benefits.filter(data => data.benefit == "Short-Term Disability").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Short-Term Disability" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Short-Term Disability" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Short-Term Disability" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Short-Term Disability</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Long-Term Disability").length > 0 ? benefits.filter(data => data.benefit == "Long-Term Disability").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Long-Term Disability" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Long-Term Disability" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Long-Term Disability" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Long-Term Disability</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Referral Bonus").length > 0 ? benefits.filter(data => data.benefit == "Referral Bonus").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Referral Bonus" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Referral Bonus" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Referral Bonus" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Referral Bonus</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Tuition Reimbursement").length > 0 ? benefits.filter(data => data.benefit == "Tuition Reimbursement").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Tuition Reimbursement" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Tuition Reimbursement" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Tuition Reimbursement" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Tuition Reimbursement</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Employee Discounts").length > 0 ? benefits.filter(data => data.benefit == "Employee Discounts").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Employee Discounts" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Employee Discounts" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Employee Discounts" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Employee Discounts</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Flexible Schedule").length > 0 ? benefits.filter(data => data.benefit == "Flexible Schedule").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Flexible Schedule" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Flexible Schedule" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Flexible Schedule" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Flexible Schedule</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-4 col-12">
                              <div className="d-flex flex-column role role2">
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Retirement Options").length > 0 ? benefits.filter(data => data.benefit == "Retirement Options").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Retirement Options" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Retirement Options" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Retirement Options" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Retirement Options</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Relocation Assistance").length > 0 ? benefits.filter(data => data.benefit == "Relocation Assistance").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Relocation Assistance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Relocation Assistance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Relocation Assistance" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Relocation Assistance</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "Other").length > 0 ? benefits.filter(data => data.benefit == "Other").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="Other" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Other" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="Other" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    Other</span>
                                </label>
                                <label>
                                  {benefits.length > 0 ? benefits.filter(data => data.benefit == "None").length > 0 ? benefits.filter(data => data.benefit == "None").map(val => (
                                    <input type="checkbox"
                                      defaultChecked={val !== "" && val !== undefined && val !== null ? true : false}
                                      className="mt-1 mr-2" value="None" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  )) :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="None" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                    :
                                    <input type="checkbox"
                                      className="mt-1 mr-2" value="None" onChange={(e) => onchange(e.target.checked, e.target.value)} />
                                  }
                                  <span className="resume-data sp-clr" style={{ borderRadius: '10px', alignItems: 'flex-start' }}>
                                    None</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mt-5">
                            <h5 className="mb-2">Travel</h5>
                          </div>
                          <div className="row  role role2 ">
                            <div className="col-lg-4 col-12">
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={travel == "Not Disclosed" ? true : false}
                                    onChange={(e) => settravel(e.target.value)}
                                    value="Not Disclosed"
                                    type="radio" name="radio1" />
                                  <span className="sp-clr"> Not Disclosed</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={travel == "None" ? true : false}
                                    onChange={(e) => settravel(e.target.value)}
                                    value="None"
                                    type="radio" name="radio1" />
                                  <span className="sp-clr">None</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={travel == "Up to 25%" ? true : false}
                                    onChange={(e) => settravel(e.target.value)}
                                    value="Up to 25%"
                                    type="radio" name="radio1" />
                                  <span className="sp-clr">Up to 25%</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={travel == "Up to 50%" ? true : false}
                                    onChange={(e) => settravel(e.target.value)}
                                    value="Up to 50%"
                                    type="radio" name="radio1" />
                                  <span className="sp-clr">Up to 50%</span>
                                </label>
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <label>
                                  <input
                                    defaultChecked={travel == "Road Warrior" ? true : false}
                                    onChange={(e) => settravel(e.target.value)}
                                    value="Road Warrior"
                                    type="radio" name="radio1" />
                                  <span className="sp-clr">Road Warrior</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <div className="w-50">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1"><h5 className="mb-2 mt-3">Positions Open</h5></label>
                                <select
                                  onChange={(e) => setposition_opened(e.target.value)}
                                  className="employee-input-email" name id>
                                  {
                                    position_opened !== "" && position_opened !== null && position_opened !== undefined ?
                                      <option selected value={position_opened}>{position_opened}</option>
                                      :
                                      <option value="Numbers of candidate needed">Numbers of candidate needed</option>
                                  }
                                  {
                                    position_opened !== "1" ?
                                      <option value="1">1</option> : ""
                                  }
                                  {
                                    position_opened !== "2" ?
                                      <option value="2">2</option> : ""
                                  }
                                  {
                                    position_opened !== "3" ?
                                      <option value="3">3</option> : ""
                                  }
                                  {
                                    position_opened !== "4" ?
                                      <option value="4">4</option> : ""
                                  }
                                  {
                                    position_opened !== "5" ?
                                      <option value="5">5</option> : ""
                                  }
                                  {
                                    position_opened !== "6" ?
                                      <option value="6">6</option> : ""
                                  }
                                  {
                                    position_opened !== "7" ?
                                      <option value="7">7</option> : ""
                                  }
                                  {
                                    position_opened !== "8" ?
                                      <option value="8">8</option> : ""
                                  }
                                  {
                                    position_opened !== "9" ?
                                      <option value="9">9</option> : ""
                                  }
                                  {
                                    position_opened !== "10" ?
                                      <option value="10">10</option> : ""
                                  }
                                  {
                                    position_opened !== "10+" ?
                                      <option value="10+">10+</option> : ""
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <div className="w-75">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1"><h5 className="mb-0 mt-3">Social Media Links</h5></label>
                                <div className="d-flex">
                                  <div className="w-50 my-2">
                                    <input
                                      onChange={(e) => setlinked(e.target.value)}
                                      value={linked}
                                      className="employee-input-email" type="text" placeholder="LinkedIn" />
                                  </div>
                                  <div className="w-50 my-2  ml-2">
                                    <input
                                      onChange={(e) => settwitter(e.target.value)}
                                      value={twitter}
                                      className="employee-input-email" type="text" placeholder="Twitter" />
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="w-50 my-2">
                                    <input
                                      onChange={(e) => setfacebook(e.target.value)}
                                      value={facebook}
                                      className="employee-input-email" type="text" placeholder="Facebook" />
                                  </div>
                                  <div className="w-50 my-2 ml-2">
                                    <input
                                      onChange={(e) => setblog(e.target.value)}
                                      value={blog}
                                      className="employee-input-email" type="text" placeholder="Blog" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>
                          
                        <div className="main-content-session inner-session">
                          <Link className="send-invitation fgh" onClick={() => step2backfunc()}>BACK</Link>
                          <Link onClick={() => step2func()} className="send-invitation fgh ml-4">CONTINUE</Link>
                        </div>
                      </>
                      : ""
                  }


                  {
                    step3 == true ?
                      <>
                        <div className="job-form-2 mt-2">
                          <div className="col-12 post-job-3 p-0">
                            <div className={trans}>
                              {trans == "ultra" ?
                                <img height="100px" width="100px" className="mr-3" src={logo} alt="" />
                                : ""
                              }
                              <div className="postjob3 px-0" style={{ width: "80%", zIndex: 200, paddingTop: "10px", paddingBottom: "10px" }}>
                                <div className="d-flex align-items-center">
                                  <h6 className="mb-0 bold-text">{company_name !== "" ? company_name : "Company Name"}</h6><span className="mx-1"> -- </span>
                                  <h6 className="mb-0 bold-text"> {job_title !== "" ? job_title : "Position Title"}</h6>
                                </div>
                                <p className="my-2">{location !== "" ? location : ""}</p>
                                <p>{address !== "" ? address.split(",")[0] : "City"}<asd className="pl-0 pr-1">,</asd>{state !== "" ? state : "State"}{zip_code !== "" ? ` ${zip_code}` : ""}{employment !== "" ? ` | ${employment}` : ""}
                                  {
                                    display_amount == false ?
                                      <> {pay_rate_maximum !== "" && pay_rate_maximum !== "$0.00" && pay_rate_maximum !== "$0" ? "|" : ""} {pay_rate_minimum !== "" && pay_rate_minimum !== "$0.00" && pay_rate_minimum !== "$0" ? `${pay_rate_minimum}` : ""} {pay_rate_maximum !== "" && pay_rate_maximum !== "$0.00" && pay_rate_maximum !== "$0" ? "To" : ""} {pay_rate_maximum !== "" && pay_rate_maximum !== "$0.00" && pay_rate_maximum !== "$0" ? `${pay_rate_maximum}` : ""} {pay_rate_maximum !== "" && pay_rate_maximum !== "$0.00" && pay_rate_maximum !== "$0" ? `${pay_rate_type}` : ""}</>
                                      : ""
                                  }
                                </p>
                              </div>
                            </div>
                            {
                              company_overview !== undefined && company_overview !== null && company_overview !== "" ?
                                <>
                                  <p className="mt-4 bold-text all-tags-edit-3">Company Overview</p>
                                  <p className="company-post-job-3-p">
                                    {parse(company_overview)}
                                  </p>
                                </> : ""
                            }

                            {
                              description == "" ? "" :
                                <>
                                  <hr />
                                  <p className="mt-4 bold-text all-tags-edit-3">Job Description</p>

                                  <p className="company-post-job-3-p">
                                    {parse(description)}
                                  </p>
                                </>
                            }
                            <hr />
                            <p className="mt-4 bold-text all-tags-edit-3">Main Duties &amp; Resposiblites</p>

                            <p className="company-post-job-3-p">
                              {parse(respons)}
                            </p>



                            {
                              benefits.length > 0 ? benefits.filter(data => data.benefit == "None").length > 0 ?
                                "" :
                                <>
                                  <hr />

                                  <p className="mt-4 bold-text all-tags-edit-3">Benefits Offered</p>
                                  <ul className="hjh">
                                    {
                                      benefits.length > 0 ? benefits.map(ben =>
                                        ben.benefit == "None" ? "" :
                                          <li>{ben.benefit}</li>

                                      ) : ""
                                    }
                                  </ul>
                                </> : ""
                            }
                            <hr />

                            <p className="mt-4 bold-text all-tags-edit-3">Experience Level</p>

                            <p className="company-post-job-3-p">
                              {experience_level}
                            </p>
                            <hr />
                            <p className="mt-4 bold-text all-tags-edit-3">Education</p>

                            <p className="company-post-job-3-p">
                              {education}
                            </p>
                            {position_opened==""? "" :
                            <hr/>
                            }
                            {position_opened == "" ? "" :
                              <>

                                <p className="mt-4 bold-text all-tags-edit-3">Positions Available</p>
                                <p className="company-post-job-3-p">
                                  {position_opened}
                                </p>
                                </>
                            }
                            {travel == "" || travel == "None" ? "" :
                            <hr/>
                            }
                            {travel == "" || travel == "None" ? "" :
                              <>
                                <p className="mt-4 bold-text all-tags-edit-3">Travel</p>

                                <p className="company-post-job-3-p">
                                  {travel}
                                </p>

                              </>
                            }
                            {facebook !== null && facebook !== undefined && facebook !== "" || twitter !== null && twitter !== undefined && twitter !== "" || linked !== null && linked !== undefined && linked !== "" || blog !== null && blog !== undefined && blog !== "" ? <hr /> :
                            ""
                            }
                            {facebook !== null && facebook !== undefined && facebook !== "" || twitter !== null && twitter !== undefined && twitter !== "" || linked !== null && linked !== undefined && linked !== "" || blog !== null && blog !== undefined && blog !== "" ?
                              <>
                                <p className="mt-4 bold-text all-tags-edit-3">Social Links</p>
                              </>
                              : ""}
                            {facebook !== null && facebook !== undefined && facebook !== "" ?
                              <p className="company-post-job-3-p">
                                Facebook: {facebook !== null && facebook !== undefined && facebook !== "" ? facebook : " -"}
                              </p>
                              : ""}


                            {twitter !== null && twitter !== undefined && twitter !== "" ?
                              <p className="company-post-job-3-p">
                                Twitter: {twitter !== null && twitter !== undefined && twitter !== "" ? twitter : " -"}
                              </p>
                              : ""}
                            {linked !== null && linked !== undefined && linked !== "" ?
                              <p className="company-post-job-3-p">
                                Linkedin: {linked !== null && linked !== undefined && linked !== "" ? linked : " -"}
                              </p>
                              : ""}
                            {blog !== null && blog !== undefined && blog !== "" ?
                              <p className="company-post-job-3-p">
                                Blog: {blog !== null && blog !== undefined && blog !== "" ? blog : " -"}
                              </p>
                              : ""}

                          </div>
                          <div className="main-content-session inner-session">
                            <Link className="send-invitation fgh" onClick={() => step3backfunc()}>BACK</Link>
                            {
                              dis == true ?
                                <Link className="send-invitation fgh ml-4 ml-responsive" onClick={() => {
                          setdisable(true)
                          createjob()
                        }}>POST JOB</Link>
                                :
                                <Link className="send-invitation fgh ml-4 ml-responsive" disabled={true}>POST JOB</Link>
                            }
                          </div>
                        </div>
                      </>
                      : ""
                  }



                </div>
                {/* footer====================================================================== */}

              </div>
            </div>



            <Modal
              isOpen={modal1}
              onRequestClose={() => setmodal1(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: modalwidth,
                  height: "210px",
                  top: "calc(50% - 105px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",
                  // 
                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-2">
                <h4>Please complete all of the required fields to continue</h4>
                <div className="d-flex  align-items-center justify-content-center search-session">
                  <Link className="fgh" onClick={() => setmodal1(false)}>OK</Link>
                </div>

              </div>
            </Modal>


            <Modal
              isOpen={BilingmodalIsOpenzip}
              onRequestClose={() => setBilingModalIsOpenzip(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: modalwidth,
                  height: "180px",
                  top: "calc(50% - 90px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Please enter valid zip code</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link className="fgh" onClick={() => setBilingModalIsOpenzip(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={BilingmodalIsOpenzip1}
              onRequestClose={() => setBilingModalIsOpenzip1(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: modalwidth,
                  height: "240px",
                  top: "calc(50% - 120px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Description must be more than 50 characters and less than 2,500 characters includes HTML formatting</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link className="fgh" onClick={() => setBilingModalIsOpenzip1(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={BilingmodalIsOpenzip1comp}
              onRequestClose={() => setBilingModalIsOpenzip1comp(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: modalwidth,
                  height: "240px",
                  top: "calc(50% - 120px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>The Company Overview has a 2,500 character limit that includes HTML formatting.</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link className="fgh" onClick={() => setBilingModalIsOpenzip1comp(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={BilingmodalIsOpenzip2}
              onRequestClose={() => setBilingModalIsOpenzip2(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: modalwidth,
                  height: "240px",
                  top: "calc(50% - 120px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",

                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Main duties & responsibilities must be more than 50 characters and less than 2,500 characters includes HTML formatting</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link className="fgh" onClick={() => setBilingModalIsOpenzip2(false)}>OK</Link>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={BilingmodalIsOpenzip5}
              onRequestClose={() => setBilingModalIsOpenzip5(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  zIndex: "100",
                },
                content: {
                  position: "absolute",
                  margin: "0 auto",
                  width: modalwidth,
                  height: "240px",
                  top: "calc(50% - 120px)",
                  left: "0",
                  right: "0",
                  bottom: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",

                  outline: "none",
                  padding: "20px",

                  background: "#fff",
                  border: "1px solid #fff",
                },
              }}
            >
              <div className="text-center mt-3">
                <h5>Please complete all required fields marked with an asterisk to continue</h5>
                <div className="d-flex mt-3 align-items-center justify-content-center search-session">
                  <Link className="fgh" onClick={() => setBilingModalIsOpenzip5(false)}>OK</Link>
                </div>
              </div>
            </Modal>
          </>
          : <FullPageLoader1 />
      }
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createSearch: (jobtitle, location) =>
    dispatch(createSearch(jobtitle, location)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PostJob1);


// code for scoop solutions

