const config = require('../helpers/config.json');

export const getUser = (userId) => {
    return (dispatch) => {
        /// get request
        fetch(`${config['baseUrl']}/getuser/${localStorage.getItem("userid")}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(res => res.json()).then((response) => {
            const users = response
            dispatch({
                type: "GET_USERS",
                users: users,
                usersresponse: "got it",
                loading: false
            });
            localStorage.setItem("zip", users.zip_code)
            localStorage.setItem("role1", users.role)
            if (users.theme !== null && users.theme !== undefined && users.theme !== "") {
                localStorage.setItem("themelocal", users.theme)
            }
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: "GET_USERS",
                users: {},
                usersresponse: null,
                loading: false
            });
            // 
        })

    }


}

// code for scoop solutions