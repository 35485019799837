import React, { useEffect } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { getUser } from "../src/actions/Getuseraction";
import Routing from "./routing/Routing";
import config from "./helpers/config.json";
import { AxiosGET } from "./libs/axios";
import { setNewApplicantInJob } from "./actions/setNewApplicantInJob";
import { setSubscriptionInfoOldVersion } from "./actions/Subscriptioninfo";
import ReactGA from "react-ga4";
import { isLocalhost } from "./components/employeejobsearchresult/helper";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { ColorModeContext, useColorMode } from "./theme";

function App(props) {
  const { mode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (localStorage.getItem("colorMode") == null) {
      localStorage.setItem("colorMode", 'light');
    }
    
  },[])

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  if (!isLocalhost) {
    ReactGA.initialize("G-13123213");
  }

  const role = props.GetUserReducer?.users?.role;
  useEffect(() => {
    loaduser();
  }, []);

  useEffect(() => {
    if (role === 1) {
      props.setNewApplicantInJob();
    }
  }, [role]);

  const loaduser = async () => {
    await props.getUser();
    await props.setSubscriptionInfoOldVersion();
    return null;
  };

  return (
    <ColorModeContext.Provider value={{ mode, toggleColorMode }}>
      <ThemeProvider theme={theme}>
        <div
          id="tg"
          className={!mode ? 'white' :mode == "light" ? "white":"black"}
          // className={
          //   props.GetUserReducer.users &&
          //   props.GetUserReducer.users.theme !== null &&
          //   props.GetUserReducer.users.theme !== undefined &&
          //   props.GetUserReducer.users.theme !== "" &&
          //   props.GetUserReducer.users.theme !== 0
          //     ? "black"
          //     : window.location.href.search("true") !== -1
          //     ? "black"
          //     : "white"
          // }
        >
          <Routing />
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: () =>
    dispatch(getUser()),
    setNewApplicantInJob: () => dispatch(setNewApplicantInJob()),
    setSubscriptionInfoOldVersion: () => dispatch(setSubscriptionInfoOldVersion()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);

// code for scoop solutions
