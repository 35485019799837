import React, { useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import { AxiosPOST } from "../../libs/axios";
import STRIPE_PUBLISHABLE from "../../stripe";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import config from "../../helpers/config.json";
Modal.setAppElement("#root");

const QuickPayment = ({ onConfirm = () => {}, children, message, amount=1 }) => {
  const [alertMsg, setAlertMsg] = useState("");

  const amountInCents = amount * 100;

  const handleToken = (token) => {
    AxiosPOST({
      url: `${config["baseUrl"]}/stripe/quick-pay`,
      body: {
        token,
        amount: amountInCents,
      },
    })
      .then(async (res) => {
        try {
          const { data, status, error } = res;
          if (status === 200) {
            await onConfirm();
            setAlertMsg(message);
          } else {
            setAlertMsg(error);
          }
        } catch (error) {
          console.log("error", error);
        }
      })
      .catch((error) => {
        setAlertMsg(error.response.data.error);
      });
  };

  return (
    <>
      <StripeCheckout
        token={handleToken}
        stripeKey={STRIPE_PUBLISHABLE}
        amount={amountInCents}
        currency="USD"
        // name={name}
        billingAddress
        zipCode
        allowRememberMe
      >
        {children}
      </StripeCheckout>
      <Modal
        isOpen={alertMsg}
        onRequestClose={() => setAlertMsg("")}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "450px",
            height: "fit-content",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "40px 20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center">
          <h5>{alertMsg}</h5>
          <div className="d-flex flex-column gap-3 flex-sm-row mt-4 align-items-center justify-content-center search-session">
            <Link
              to="#"
              className="fgh mb-0 w-100"
              onClick={() => setAlertMsg("")}
            >
              Ok
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QuickPayment;
