import React, { useState, useEffect } from "react"
import "../navbar/Navbar.css"
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moblogo from "../../assets/mob-logo.png"
import fb from "../../assets/ftr-fb.png"
import twi from "../../assets/ftr-twitter.png"
import yt from "../../assets/ftr-yt.png"
import lin from "../../assets/ftr-lin.png"
import logo from "../../assets/logo.png"
import Employernav from "../dropdownnav/Employernav";
import Modal from "react-modal";
import dayjs from "dayjs";

const config = require('../../helpers/config.json');
Modal.setAppElement("#root");

const SavedResume = (props) => {
  const [load, setload] = useState(false)
  const [allArray, setallArray] = useState([])
  useEffect(async () => {
    loadgetjob()
  }, []);
  const loadgetjob = async () => {
    setload(true)
    await fetch(`${config['baseUrl']}/resume/save/get/${localStorage.getItem("userid")}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', },
    }).then(res => res.json()).then((response) => {
      setload(false)
      const alljobs = response
      allArray.push(alljobs)
      setallArray(allArray)
    }).catch((error) => {
      setload(false)
      console.log("error", error);

    })
    return null
  }
  const [BilingmodalIsOpen2, setBilingModalIsOpen2] = useState(false);
  const [saveresumeid, setsaveresumeid] = useState("")
  const savemodal = (e) => {
    setBilingModalIsOpen2(true)
    setsaveresumeid(e)
  }
  const DeleteResume = async (id) => {
    await fetch(`${config['baseUrl']}/resume/save/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "id": id
      })
    }).then(res => {

      if (res.status !== 200 && res.status !== 201) {
        
      }
      return res.json();
    }).then((response) => {

      if (response.message == "Save Resume has been successfully Deleted.") {
        window.location = "/saved-resume"
      }
      else {
        console.log("Something went wrong..")
      }
      setBilingModalIsOpen2(false)
    }).catch((error) => {
      console.log(error)

      setBilingModalIsOpen2(false)
    })
  }
  const [order, setorder] = useState("ASC")
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("DSC")
    }
    if (order === "DSC") {
      const sorted = [...allArray].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setallArray(sorted)
      setorder("ASC")
    }

  }
  return (
    <>

      <div className="container-fluid" id="Employee">
        <div className="container">
          <Employernav />
          {/* header======================================================================*/}

          <div className="main-content">
            <div className="d-flex main-content-session inner-session py-2">
              <h5>Saved Resumes</h5>
            </div>
            <div className="usr-tbl">
              <table id="example" className="table  table-striped table-bordered dataTable" cellSpacing={0} role="grid" aria-describedby="example_info" style={{ width: 'auto' }}>
                <thead>
                  <tr role="row">
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '40%' }}>
                      Name of Candidate</th>
                      {/* {order == "DSC" ? <i class="fa fa-arrow-down pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("First_name")} /> : <i class="fa fa-arrow-up pl-4" style={{ color: "gray", cursor: "pointer" }} onClick={() => sorting("First_name")} />} */}
                    <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '30%' }}>
                      Date Saved</th>
                    <th className="sorting text-center" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '30%' }}>Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allArray && allArray.length > 0 ? allArray.map(val => (
                      val.date_saved && val.date_saved.length > 0 ? val.date_saved.map(arr => (
                        <tr role="row" className="odd">
                          {props.GetUserReducer.users && props.GetUserReducer.users.Is_packaged !== null && props.GetUserReducer.users.Is_packaged !== undefined && props.GetUserReducer.users.Is_packaged !== "" && props.GetUserReducer.users.Is_packaged !== 0 ?
                          <td className><Link to={`/saved-preview-resume?id=${arr.UserId}`} style={{color:"black"}}><p className="fgh tb-link">{arr.First_name !== "" && arr.First_name !== null && arr.First_name !== undefined ? arr.First_name : ""} - {arr.City !== "" && arr.City !== null && arr.City !== undefined ? arr.City : ""}{arr.State !== "" && arr.State !== null && arr.State !== undefined ?", " : ""}{arr.State !== "" && arr.State !== null && arr.State !== undefined ? arr.State : ""}</p></Link></td>
                          :
                          <td className><Link to={`/saved-preview-resume?id=${arr.UserId}`} style={{color:"black"}}><p className="fgh tb-link">candidate – {arr.City !== "" && arr.City !== null && arr.City !== undefined ? arr.City : ""}{arr.State !== "" && arr.State !== null && arr.State !== undefined ?", " : ""}{arr.State !== "" && arr.State !== null && arr.State !== undefined ? arr.State : ""} </p></Link></td>
                      }
                          <td>{dayjs(arr.saved_at).format("MM-DD-YYYY")}</td>
                          <td className="sorting_1 text-center"><a className="text-danger" data-toggle="modal" data-target="#exampleModal-remove-1" onClick={() => DeleteResume(arr.id)}><i className="fa fa-trash" aria-hidden="true" /></a></td>
                          {/* remove modal====================================================== */}
                        </tr>
                      )) : ""
                    )) : ""
                  }

                </tbody>
              </table>
              <div className="col-6">
              </div>
            </div>
          </div>
          {/* footer====================================================================== */}

        </div>
      </div>
      <Modal
        isOpen={BilingmodalIsOpen2}
        onRequestClose={() => setBilingModalIsOpen2(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: "490px",
            height: "200px",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className=" justify-content-center align-items-center pt-3">
          <h5 className="text-center" style={{ color: "black" }}>Do you want to delete this resume?</h5>
          <div className="d-flex justify-content-center mt-4">
            <p onClick={(e) => DeleteResume()} className="p-3 rounded" style={{ color: "white", cursor: "pointer", backgroundColor: "#8dc63f" }}><b>CONTINUE</b></p>
            <p onClick={() => setBilingModalIsOpen2(false)} className="ml-5 pt-3" style={{ color: "black", cursor: "pointer" }}><b>Cancel</b></p>
          </div>
        </div>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  SearchReducer: state.SearchReducer,
  GetUserReducer: state.GetUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(SavedResume);

// code for scoop solutions