import React, { useState, useEffect } from "react";
import "../navbar/Navbar.css";
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { SignIn, googleLogin, facebookLogin } from "../../actions/loginaction";
import useWindowDimensions from "../administrator/innerwidthcom";
import moblogo from "../../assets/mob-logo.png";
import fb from "../../assets/ftr-fb.png";
import twi from "../../assets/ftr-twitter.png";
import yt from "../../assets/ftr-yt.png";
import lin from "../../assets/ftr-lin.png";
import logo from "../../assets/logo.png";
import FullPageLoader1 from "../fullpageloader/fullPageLoader";
import LinkedInPage2 from "../../getURL";
import Dropdownnav from "../dropdownnav/Dropdownnav";
import { clearLoginError, setLoginError } from "../../actions/LoginErrorAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom";
const config = require("../../helpers/config.json");

Modal.setAppElement("#root");

const EmployeeLogin = (props) => {
  const { height, width } = useWindowDimensions();
  const [BilingmodalIsOpen, setBilingModalIsOpen] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [loading, setloading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [modalwidth, setmodalwidth] = useState(450);
  const history = useHistory()
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setlatitude(position.coords.latitude);
      setlongitude(position.coords.longitude);
    });
    if (width <= 900) {
      setmodalwidth("340px");
    }
  }, []);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const addLogin1 = (e) => {
    if (email == "" && password == "") {
      console.log("Enter first name");
    } else {
      props.SignIn(email, password);
    }
  };

  const addLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setloading(true);
    if (email?.trim()?.length < 6 || password?.trim()?.length < 3) {
      setloading(false);
      return props.setLoginError("You have entered an invalid username or password");

    }
    fetch(`${config["baseUrl"]}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
        role: 0,
      }),
    })
      .then((res) => {
        if (res.status == 422) {
          setloading(false);
          setShowLoginError(true);
          props.setLoginError("You have entered an invalid username or password");
        }
        return res.json();
      })
      .then((response) => {
        if (response.message !== "No Role Exits!!..") {
          const token = response.token;
          const id = response.user.id;
          const name = `${response.user.first_name} ${response.user.last_name}`;
          const email = response.user.email;
          const role = response.user.role;
          if (
            response.user &&
            response.user.approve !== null &&
            response.user.approve !== undefined &&
            response.user.approve !== 0
          ) {
            localStorage.setItem("token", token);
            localStorage.setItem("userid", id);
            localStorage.setItem("username", name);
            localStorage.setItem("email", email);
            localStorage.setItem("role", role);
            setloading(false)
            // history.push("/")
            // props.setLoginError("You have entered an invalid username or password");

            window.location = "/";
          } else {
            setloading(false);
            setBilingModalIsOpen(true);
            props.setLoginError("This account has not yet been approved. We will notify you once it has been completed.");

          }
        } else {
          setloading(false);
          setShowLoginError(true);
          props.setLoginError("You have entered an invalid username or password");
          setemail("");
          setpassword("");
        }
      })
      .catch((error) => {
        setloading(false);
        props.setLoginError("You have entered an invalid username or password");
        const authError = JSON.stringify(error);
        console.log(authError);
      });

  };
  const setcookies = () => {
    var u = document.getElementById("emplogemail").value;
    var p = document.getElementById("password-field4").value;

    document.cookie =
      "myusername=" +
      u +
      ";path=https://hirefrontend.coderouting.comcandidate-login";
    document.cookie =
      "mypswd=" +
      p +
      ";path=https://hirefrontend.coderouting.comcandidate-login";
  };

  const getonloadcookie = () => {
    var user = getcookie("myusername");
    var pass = getcookie("mypswd");
    document.getElementById("emplogemail").value = user;
    document.getElementById("password-field4").value = pass;
    setemail(user);
    setpassword(pass);
  };
  const getcookie = (cname) => {
    var name = cname + "=";
    var decodecookie = decodeURIComponent(document.cookie);
    var ca = decodecookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  let preLoad = true


  if (preLoad) {
    setTimeout(() => {
      preLoad = false
    }, 500);
  }

  const location = useLocation()

  React.useEffect(() => {
    props.clearLoginError()
    // runs on location, i.e. route, change
    console.log('handle route change here', location)
  }, [location])

  return (
    <>
      <div
        className="container-fluid"
        id="Employee"
        onLoad={() => getonloadcookie()}
      >
        <div className="container">
          <Dropdownnav />
          <div className="main-content contact-or-rejection d-flex justify-content-center">
            <form>
              <a href>
                <div
                  className="sign-in-social-tag1"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.facebookLogin(latitude, longitude)}
                >
                  <i
                    className="fa fa-facebook mr-2 text-white"
                    aria-hidden="true"
                  />
                  Sign in with Facebook
                </div>
              </a>
              <a href>
                <div
                  className="sign-in-social-tag2"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.googleLogin(latitude, longitude)}
                >
                  <i
                    className="fa fa-google mr-2 text-white"
                    aria-hidden="true"
                  />
                  Sign in with Google
                </div>
              </a>
              <a href>
                <div className="sign-in-social-tag3">
                  <LinkedInPage2 />
                </div>
              </a>
              <hr className="hr-text" data-content="OR" />
              {props?.loginError.hasError && (
                <div className="loginError">
                  {props?.loginError.errorMessage}
                  {/* You have entered an invalid username or password */}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email Address</label>
                <input
                  autoComplete="new-password"
                  id="emplogemail"
                  onChange={(e) => {
                    if (e.target.value) {
                      setemail(e.target.value);
                      setShowLoginError(false);
                      if (!preLoad) props.clearLoginError();

                    }
                  }}
                  type="email"
                  className="form-control employee-input-email"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                />
              </div>
              <div
                style={{ position: "relative" }}
                className="d-flex flex-column"
              >
                <label htmlFor="exampleInputPassword1">Password:</label>
                <input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      addLogin(e);
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value) {
                      setpassword(e.target.value);
                      setShowLoginError(false);
                      if (!preLoad) props.clearLoginError();

                    }
                  }}
                  style={{ width: "300px !important" }}
                  id="password-field4"
                  type={passwordType}
                  className="employee-input-email job-search"
                  name="password"
                  placeholder="Password"
                />
                <span toggle="" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <i
                      style={{ top: 38 }}
                      className="fa fa-eye-slash field-icon toggle-password pl-2"
                    ></i>
                  ) : (
                    <i
                      style={{ top: 38 }}
                      className="fa fa-eye field-icon toggle-password pl-2"
                    ></i>
                  )}
                </span>
              </div>
              <div className="role mt-3">
                <label>
                  <input
                    type="checkbox"
                    name="radio"
                    onChange={() => setcookies()}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        addLogin(e);
                      }
                    }}
                  />
                  <span className="sp-clr">Remember Me</span>
                </label>
              </div>
              <div className="inner-session mt-3">
                <Link
                  onClick={(e) => addLogin(e)}
                  className="send-invitation fgh"
                >
                  {loading ? "Loading..." : "SIGN IN"}
                </Link>
              </div>
              <Link
                className="fgh"
                to={"/forgot-password-email"}
              // to={`/forgot-password-email${window.location.href.search("false") == -1 &&
              //   window.location.href.search("true") == -1
              //   ? `/`
              //   : `?if=${window.location.href.search("false") == -1
              //     ? true
              //     : false
              //   }`
              //   }`}
              >
                Forgot Your Password?{" "}
              </Link>{" "}
              <br />
              Don’t have an account?{" "}
              <Link
                className="fgh"
                to={"/signup"}
              // to={`/signup${window.location.href.search("false") == -1 &&
              //   window.location.href.search("true") == -1
              //   ? `/`
              //   : `?if=${window.location.href.search("false") == -1
              //     ? true
              //     : false
              //   }`
              //   }`}
              >
                Create one
              </Link>{" "}
              for free.
              <br />
              <br />
              By signing into your Hire Choices account, <br /> you agree
              and consent to our{" "}
              <Link
                className="fgh"
                to={"/privacy-policy"}
              // to={`/privacy-policy${window.location.href.search("false") == -1 &&
              //   window.location.href.search("true") == -1
              //   ? `/`
              //   : `?if=${window.location.href.search("false") == -1
              //     ? true
              //     : false
              //   }`
              //   }`}
              >
                {" "}
                Privacy Policy{" "}
              </Link>{" "}
              and <br />
              <Link
                className="fgh"
                to={"/terms-condition"}
              // to={`/terms-condition${window.location.href.search("false") == -1 &&
              //   window.location.href.search("true") == -1
              //   ? `/`
              //   : `?if=${window.location.href.search("false") == -1
              //     ? true
              //     : false
              //   }`
              //   }`}
              >
                {" "}
                Terms of Services.{" "}
              </Link>
            </form>
          </div>
        </div>
      </div>

      <Modal
        isOpen={BilingmodalIsOpen}
        onRequestClose={() => setBilingModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "100",
          },
          content: {
            position: "absolute",
            margin: "0 auto",
            width: modalwidth,
            height: "200px",
            top: "calc(50% - 100px)",
            left: "0",
            right: "0",
            bottom: "100px",

            background: "#fff",

            outline: "none",
            padding: "20px",

            background: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        <div className="text-center mt-3">
          <h5>
            This account has not yet been approved. We will notify you once
            it has been completed.
          </h5>
          <div className="d-flex mt-3 align-items-center justify-content-center search-session">
            <Link
              className="fgh"
              onClick={() => setBilingModalIsOpen(false)}
            >
              OK
            </Link>
          </div>
        </div>
      </Modal>

    </>
  );
};
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loginError: state?.loginErrorReducer.loginError,

});

const mapDispatchToProps = (dispatch) => ({
  SignIn: (email, password) => dispatch(SignIn(email, password)),
  googleLogin: (latitude, longitude) =>
    dispatch(googleLogin(latitude, longitude)),
  facebookLogin: (latitude, longitude) =>
    dispatch(facebookLogin(latitude, longitude)),

  setLoginError: (errorMessage) => dispatch(setLoginError(errorMessage)),
  clearLoginError: () => dispatch(clearLoginError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeLogin);

// code for scoop solutions
